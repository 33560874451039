import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Headline from 'ui/Headline'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Thumb from 'ui/Thumb'
import { SMART_FILTERS } from 'configs/categories'
import ThumbWrapper, { ThumbLabel } from 'ui/ThumbWrapper'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import COLORS from 'themes/primary/palette'
import Icon from 'ui/Icon'
import lighting from 'icons/basic-icons/lightning-icon.svg'
import ghost from 'icons/basic-icons/ghost.svg'
import SortGamesPage from 'scenes/SortGamesPage'
import Label from 'ui/Label'

const filterPagePath = '/automater/kategorier/sort'

export const ImageText = styled(Text)`
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
`

const Grid = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        grid-template-columns: repeat(5, 1fr);
    }
`

const StyledThumbLabel = styled(ThumbLabel)`
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.light};
    width: 100%;
    font-size: 1rem;

    @media (min-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
        font-size: 1.2rem;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        font-size: 0.8rem;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`

const CategoryPage = ({}) => {
    const match = useRouteMatch()
    const getCategories = (category) =>
        SMART_FILTERS.filter((item) => item.category === category).sort(
            (a, b) => a.label.localeCompare(b.label)
        ) || []

    return match.params?.subCategory ? (
        <SortGamesPage />
    ) : (
        <Box>
            <TitleWrapper>
                <Headline
                    as={'h3'}
                    color={COLORS.dark}
                    label={getCategories('features').length}
                    colorLabel={COLORS.gray}
                >
                    Funktioner
                </Headline>
            </TitleWrapper>
            <Text mb={3}>
                Alle spil har forskellige funktioner i spillet, hvilket gør
                hvert spil unikt.
            </Text>
            <Grid>
                {getCategories('features').map((item, key) => (
                    <ThumbWrapper
                        as={Link}
                        key={key}
                        to={`${filterPagePath}?key=features&value=${item.name}`}
                    >
                        <Thumb src={item.image} maxHeight={'initial'} />
                        <StyledThumbLabel align={'center'}>
                            {item.label}
                        </StyledThumbLabel>
                    </ThumbWrapper>
                ))}
            </Grid>

            <TitleWrapper>
                <Headline
                    as={'h3'}
                    color={COLORS.dark}
                    label={getCategories('theme').length}
                    colorLabel={COLORS.gray}
                >
                    Temaer
                </Headline>
            </TitleWrapper>
            <Text mb={3}>
                Vi har inddelt alle vores spil i forskellige kategorier, så det
                er nemmere for dig at sortere mellem de temaer, du godt kan
                lide.
            </Text>
            <Grid>
                {getCategories('theme').map((item, key) => (
                    <ThumbWrapper
                        key={key}
                        as={Link}
                        to={`${filterPagePath}?key=theme&value=${item.name}`}
                    >
                        <Thumb src={item.image} maxHeight={'179px'} />
                        <StyledThumbLabel
                            align={'center'}
                            style={{
                                bottom: '50%',
                                transform: 'translateX(-50%) translateY(50%)',
                            }}
                        >
                            {item.label}
                        </StyledThumbLabel>
                    </ThumbWrapper>
                ))}
            </Grid>
        </Box>
    )
}

export default CategoryPage
