import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import GlobalInject from 'themes/global-inject'
import { ThemeProvider } from 'styled-components'
import Layout from '../layouts'
import '../index.css'
import primaryTheme from 'themes/themePrimary'
import themeService from 'services/ThemeService'
import { byPlatform } from 'common/adapters/DeviceDetectAdapter'
import {
    getBottomNavigationHeight,
    getFullHeaderHeight,
    getNavigationHeight,
    getTopBarHeight,
} from 'configs/layout'
import { initCookieWindow } from 'utils/cookie'
import UserAdapter from 'common/adapters/UserAdapter'

const App = () => {
    const [theme, setTheme] = useState(primaryTheme)

    useEffect(() => {
        //initCookieWindow()
        UserAdapter.registerUserDeviceInCMS()

        if (window.navigator.standalone === true) {
            console.log('display-mode is standalone')
        }

        if (window.matchMedia('(display-mode: standalone)').matches) {
            console.log('display-mode is standalone')
        }

        const themeSubscription = themeService
            .getTheme()
            .subscribe((themeValue) => {
                setTheme({
                    ...themeValue,
                    FULL_HEADER_HEIGHT: getFullHeaderHeight(),
                    NAVIGATION_HEIGHT: getNavigationHeight(),
                    TOP_BAR_HEIGHT: getTopBarHeight(),
                    BOTTOM_NAVIGATION_HEIGHT: byPlatform(
                        getBottomNavigationHeight(),
                        0
                    ),
                })
            })

        return () => {
            themeSubscription.unsubscribe()
        }
    }, [])

    return (
        <>
            <GlobalInject />
            <BrowserRouter basename={'/casino'}>
                <ThemeProvider theme={theme}>
                    <Layout theme={theme} />
                </ThemeProvider>
            </BrowserRouter>
        </>
    )
}

export default App
