const USER_STATUS_UNAPPROVED = 'temp'
//const USER_STATUS_OPEN = 'open';

export default class User {
    id = 0
    userName = null
    firstName = null
    lastName = null
    email = null
    balance = null
    status = null
    hasNewsletterSubscription = false
    lastLogin = null

    /**
     * Determines if user account was verified automatically through NEM ID
     * or verified manually sending photo or health insurance document to support.
     * @returns {boolean}
     */
    isAccountApproved = () => {
        return this.status !== USER_STATUS_UNAPPROVED
    }

    /**
     *
     * @param   {Object} data
     * @returns {User}
     */
    static unserialize(data) {
        const user = new User()
        user.id = data.userId
        user.userName = data.userName
        user.firstName = data.firstName
        user.lastName = data.lastName
        user.email = data.email
        user.balance = data.balance
        user.status = data.status
        user.extraInfo = data.extraInfo
        user.hasNewsletterSubscription = !!data.newsletter
        user.lastLogin = this.getLastLoginDate(data)
        return data
    }

    static getLastLoginDate(data) {
        let info = data.extraInfo || {},
            date = info.lastLogin ? info.lastLogin : new Date()

        return date
    }
}
