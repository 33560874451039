import React from 'react'
import styled, { css } from 'styled-components'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Flex from 'ui/Flex'
import PropTypes from 'prop-types'
import theme from 'themes/themePrimary'
import Icon from 'ui/Icon'
import succes from 'images/icons/basic-icons/succes.svg'

const StyledFlex = styled(Flex)`
    position: relative;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.lightGray};
    }
    ${(props) =>
        props.active &&
        css`
            background-color: ${props.theme.lightGray};
            color: white;
            &:hover {
                background-color: ${props.theme.lightGray};
            }
        `};
`

const StyledBox = styled(Box)`
    border-bottom: 1px solid #00000014;
`

const SortSection = ({ options = [], values = [], onChange = (f) => f }) => {
    const onChangeHandler = (element) => {
        let isSelected = values.find(
            (item) => element && item.value === element.value
        )

        if (!!isSelected) {
            const activeEl = values.filter(
                (item) => item.value !== element.value
            )
            onChange(activeEl.length ? [activeEl.length] : [])
        } else {
            onChange([element])
        }
    }

    return (
        <StyledBox width={'100%'} background={theme.light}>
            {options.map((button, index) => {
                let selected = values.find(
                    (item) => button.value === item.value
                )
                return (
                    <StyledFlex
                        key={index}
                        py={2}
                        px={3}
                        active={selected}
                        justify={'justify-content'}
                        onClick={() => onChangeHandler(button)}
                    >
                        <Flex style={{ position: 'absolute' }}>
                            {selected && (
                                <Icon
                                    src={succes}
                                    size={'sm'}
                                    mr={2}
                                    color={'gray'}
                                />
                            )}
                        </Flex>
                        <Text
                            style={{ width: '100%' }}
                            size={'md'}
                            ml={4}
                            color={theme.dark}
                            align={'left'}
                            lineHeight={2}
                        >
                            {button.label}
                        </Text>

                        {button.subLabel && (
                            <Text
                                style={{ minWidth: '30%' }}
                                size={'md'}
                                color={theme.gray}
                                lineHeight={2}
                                align={'right'}
                            >
                                {button.subLabel}
                            </Text>
                        )}
                    </StyledFlex>
                )
            })}
        </StyledBox>
    )
}

SortSection.propTypes = {
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
}

export default SortSection
