import { keyframes } from 'styled-components'

export const slideToLeft = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
`

export const slideToRight = keyframes`
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
`

export const slideToTop = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
`

export const slideToBottom = keyframes`
    0% { transform: translateY(0%); }
    100% { transform: translateY(100%); }
`
