const t = window.T
export const FEATURE = {
    lowfreq: t.gameInfoModal.volatilityValues.low,
    midfreq: t.gameInfoModal.volatilityValues.mid,
    hifreq: t.gameInfoModal.volatilityValues.hig,
}

export const GAME_INFO_DATA = [
    {
        name: 'min_stake',
        title: 'Min. indsats',
        units: 'kr.',
        tooltipText: '',
        tooltipPosition: '',
    },
    {
        name: 'payback',
        title: 'RTP',
        units: '',
        tooltipText:
            '<p>RTP er en måde at forudse, hvad et spil potentielt kan give tilbage.</p> <p>For eksempel, hvis et spil har en RTP på 96%, betyder det, at for hver 100 kr., du spiller for, kan du teoretisk forvente at få omkring 96 kr. tilbage over tid. Dette er et fast udgangspunkt for at vurdere et spils potentielle udbetalinger. Men husk, det rigtige beløb, du vinder eller taber, vil variere og kan være højere eller lavere.</p>',
        tooltipPosition: '',
    },
    {
        name: 'feature',
        title: 'Volatilitet',
        valueReplace: FEATURE,
        units: '',
        tooltipText:
            'Det handler om, hvor ofte og hvor stort du kan vinde i et spil. Hvis et spil har høj volatilitet, kan du vinde store gevinster, men det sker ikke så tit. Hvis et spil har lav volatilitet, vinder du mindre beløb, men det sker oftere.',
        tooltipPosition: 'right',
    },
    {
        name: 'paylines',
        title: 'Gevinstlinjer',
        units: 'stk.',
        tooltipText:
            'Tænk på gevinstlinjer som de veje, symbolerne skal lande på for at vinde i et spil. Hvis de rigtige symboler lander på den samme vej (eller linje), vinder du! Før i tiden havde spilleautomater kun en enkelt gevinstlinje i midten, men moderne online spil kan have hundredevis af gevinstlinjer, der strækker sig i alle retninger.',
        tooltipPosition: '',
    },
    {
        name: 'category',
        title: 'Spiltype',
        units: '',
        tooltipText: '',
        tooltipPosition: '',
    },
    {
        name: 'subprovider',
        title: 'Udbyder',
        units: '',
        tooltipText: '',
        tooltipPosition: '',
    },
]
