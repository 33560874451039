import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { modalService } from 'services/ModalService'
import useKeyDown from 'common/Hooks/UseKeyDown'
import { ESC_KEY_CODE } from 'common/constants'
import UrlHistoryService from 'services/UrlHistoryService'

const el = document.getElementById('root')

const ModalHandler = (props) => {
    const [modals, setModals] = useState([])

    useEffect(() => {
        const subscription = modalService.getModal().subscribe((res) => {
            if (res && res.visible) {
                let newModal = props.modalsMap.find(
                    (modal) => modal.name === res.modalName
                )
                let modalsName = modals.map((el) => el.name)

                newModal.modalData = { props: {}, ...res.modalData }
                const closeModalsBefore = newModal.modalData.props.closeBefore

                if (closeModalsBefore) {
                    let leftModals = modals.filter(
                        (modal) => !closeModalsBefore.includes(modal.name)
                    )
                    closeModalsBefore.forEach((modalName) => {
                        UrlHistoryService.removeFromModalStack(modalName)
                    })
                    setModals([...leftModals, newModal])
                } else {
                    let visibleModals =
                        modalsName.indexOf(newModal.name) >= 0
                            ? [...modals]
                            : [...modals, newModal]
                    setModals(visibleModals)
                }
            } else if (res && !res.visible) {
                let visibleModals = res.hideAll
                    ? []
                    : modals.filter((modal) => modal.name !== res.modalName)
                setModals(visibleModals)
            }
        })
        props.isModalVisible(modals.length)

        return () => {
            subscription.unsubscribe()
        }
    })

    useKeyDown(ESC_KEY_CODE, () => modalService.closeModal())

    const showModal = () => {}

    const hideModal = (name) => {
        modalService.closeModal(name)
    }

    return ReactDOM.createPortal(
        <>
            {modals.map((modal, index) => {
                return props.children({
                    modal: modal.component,
                    data: modal.modalData,
                    type: modal.type,
                    key: index,
                    priority: index,
                    actions: {
                        showModal,
                        hideModal,
                    },
                })
            })}
        </>,
        el
    )
}

export default ModalHandler
