import React, { useState } from 'react'
import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import bannerImg from 'images/banners/page/unsubscribe.png'
import Text from 'ui/Text'
import Button from 'ui/controls/Button'
import Banner from 'ui/Banner'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'
import ValidInput from 'ui/controls/ValidInput'
import withValidation from 'common/Hocs/WithValidation'
import Flex from 'ui/Flex'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = window.T

const MainTextWrapper = styled(Wrapper)`
    width: 600px;
    margin: 50px auto;
    max-width: 90%;
`
const Resubscribe = styled.span`
    color: #333;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
`

const StyledInput = styled(Flex)`
    margin: 1rem 0;
    width: ${detectMobile() ? '100%' : '70%'};
`

const formText = window.T.unsubscribeText
const confirmText = window.T.usubscribeConfirm

const UnsubscribePage = ({ setValidationHandler, validateAllFields }) => {
    const getEmail = new URL(window.location.href).searchParams.get('email')

    const [subscribed, setSubscribed] = useState(true)
    const [email, setEmail] = useState(getEmail)
    const history = useHistory()

    const unsubscribe = () => {
        if (validateAllFields() && email) {
            UserAdapter.disableNewsletterSubscription(0, email).then((res) => {
                if (!res.success) {
                    NotificationConductor.error(t.validation.emailNotFound)
                } else {
                    setSubscribed(false)
                    setEmail('')
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        } else {
            NotificationConductor.error('Ugyldig email')
        }
    }
    const resubscribe = () => {
        UserAdapter.enableNewsletterSubscription()
            .then((res) => {
                if (res.success) {
                    NotificationConductor.success(
                        'Du er nu tilmeldt vores nyhedsbrev'
                    )
                    history.push('/')
                }
            })
            .catch((e) => {
                console.error('Subscribe error: ', e)
                NotificationConductor.error('Fejl. Ugyldig email.')
            })
    }

    return subscribed ? (
        <Wrapper padding="0">
            <Banner src={bannerImg} />
            <MainTextWrapper>
                <Text size="md" bold color="#333" mb={4}>
                    {formText.title}
                </Text>
                <Text size="md" bold color="#333" mb={4}>
                    {formText.p1}
                </Text>
                <Text size="md" color="#333" mb={4}>
                    {formText.p2}
                </Text>
                <Text size="md" color="#333" mb={4}>
                    {formText.p3}
                </Text>
                <StyledInput>
                    <ValidInput
                        onChange={(v) => {
                            setEmail(v)
                        }}
                        value={email}
                        autocomplete={'email'}
                        validate={{
                            required: true,
                            email: true,
                        }}
                        placeholder={formText.emailAddress}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('email', validatorHandler)
                        }
                    />
                </StyledInput>
                <Button onClick={unsubscribe} mt={4} mr={2} bold size={'mdB'}>
                    {formText.unsubscribeButton}
                </Button>
                <Button
                    onClick={() => {
                        history.push('/')
                    }}
                    variant="light"
                    bold
                    size={'mdB'}
                    style={{ background: '#EBEBEB' }}
                >
                    {formText.cancel}
                </Button>
            </MainTextWrapper>
        </Wrapper>
    ) : (
        <MainTextWrapper>
            <Text size="md" color="#489260" bold mb={4}>
                {confirmText.title}
            </Text>
            <Text size="md" color="#333" mb={4}>
                {confirmText.p1}
            </Text>
            <Text size="md" color="#333" mb={4}>
                {confirmText.p2}{' '}
                <Resubscribe onClick={resubscribe}>
                    {confirmText.resubscribe}
                </Resubscribe>
            </Text>
            <Button
                onClick={() => {
                    history.push('/')
                }}
                mt={2}
                bold
            >
                {confirmText.cancel}
            </Button>
        </MainTextWrapper>
    )
}

export default withValidation(UnsubscribePage)
