export const post = async (url, data, setting) => {
    let options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data),
        ...setting,
    }

    const response = await fetch(url, options)

    return response.json()
}

export const get = async (url, data = {}, setting) => {
    let options = {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        ...setting,
    }
    const response = await fetch(
        url + (Object.keys(data).length ? '?' + new URLSearchParams(data) : ''),
        options
    )
    return response.json()
}

export const fetchDelete = async (url, data, setting) => {
    let options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data),
        ...setting,
    }

    const response = await fetch(url, options)

    return response.json()
}
