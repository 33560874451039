import React, { useState } from 'react'
import styled from 'styled-components'
import { paddingProps } from '@/ui/SpacingUtility'
import Icon from 'ui/Icon'
import deleteIcon from '@/images/icons/basic-icons/delete.svg'
import eyeIcon from '@/images/icons/basic-icons/eye.svg'
import crossEyeIcon from '@/images/icons/basic-icons/cross-eye.svg'

const LIGHT_GRAY = 'rgba(51, 51, 51, .25)'

const InputPlaceholder = styled.span`
    pointer-events: none;
    position: absolute;
    color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : LIGHT_GRAY};
    font-weight: 400;
    opacity: 1;
    font-size: 1rem;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    width: 85%;
`

const textPosition = 4

const InputControl = styled.input.attrs((props) => ({
    autoComplete: props.autocomplete || 'off',
}))`
    border: none;
    background: none;
    width: 100%;
    padding: 0 !important;
    font-size: 1rem;
    color: ${(props) => (props.color ? props.color : '#6e6e6e')};
    font-weight: 400;
    transition: all 0.5s ease-in-out;

    &::placeholder {
        color: transparent;
    }

    &:focus + span {
        line-height: 1;
        top: ${textPosition}px;
        font-size: 0.67rem;
    }

    &:not([value='']) + span {
        line-height: 1;
        top: ${textPosition}px;
        font-size: 0.67rem;
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
`

const InputWrapper = styled.div`
    border: 1px solid
        ${(props) => (props.borderColor ? props.borderColor : LIGHT_GRAY)};
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '30px')};
    background: ${(props) => (props.background ? props.background : '#f1f1f1')};
    border-radius: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 28px 30px;
    ${paddingProps};
    text-align: ${(props) => (!props.align ? 'left' : props.align)};

    &:focus-within {
        margin: 0;
    }
`

const RightText = styled.div`
    position: absolute;
    right: 15px;
`

const IconsWrapper = styled.div`
    position: absolute;
    bottom: 50%;
    z-index: 1;
    right: 20px;
    transform: translateY(50%);
    cursor: pointer;
    visibility: ${({ value }) => (value ? 'inherit' : 'hidden')};

    ${Icon} {
        margin-left: 10px;
    }
`

const Input = ({ align = 'left', ...props }) => {
    const [showPassword, setShowPassword] = useState(false)
    const inputRef = React.createRef()
    const onInputFocused = () => {
        if (props.onInputFocusedHandler) {
            props.onInputFocusedHandler()
        }
    }

    const onInputBluered = () => {
        if (props.onInputBlueredHandler) {
            props.onInputBlueredHandler()
        }
    }

    const onInputValueChanged = (event) => {
        event.stopPropagation()
        const { value } = event.target

        props.onChange(value)
    }

    const onInputKeyPress = (event) => {
        if (props.onKeyPress) {
            event.stopPropagation()
            props.onKeyPress(event)
        }
    }

    let inputProps = {
        ...props,
        onChange: onInputValueChanged,
        onFocus: onInputFocused,
        onBlur: onInputBluered,
        onKeyPress: onInputKeyPress,
        placeholder: props.placeholder,
        align: props.align,
    }
    if (props.type === 'password') {
        inputProps.type = showPassword ? 'text' : props.type
    }
    return (
        <InputWrapper {...props} onClick={() => inputRef.current.focus()}>
            <InputControl {...inputProps} ref={inputRef} />
            <InputPlaceholder
                placeholderColor={props.placeholderColor}
                align={props.align}
            >
                {props.placeholder}
            </InputPlaceholder>
            <RightText>{props.rightText}</RightText>
            <IconsWrapper value={props.value}>
                {props.visiblePassword && (
                    <Icon
                        onClick={() => setShowPassword(!showPassword)}
                        size={'md'}
                        src={!showPassword ? eyeIcon : crossEyeIcon}
                        color={props.eyeColor ? props.eyeColor : props.color}
                    />
                )}
                {!props.hideCancel && (
                    <Icon
                        onClick={() => props.onChange('')}
                        size={'md'}
                        src={deleteIcon}
                        color={
                            props.cancelColor ? props.cancelColor : props.color
                        }
                    />
                )}
            </IconsWrapper>
            {props.validationError ? (
                <span
                    style={{
                        color: props.errorColor ? props.errorColor : 'red',
                        fontSize: '0.67rem',
                        position: 'absolute',
                        bottom: textPosition,
                        lineHeight: '1',
                    }}
                >
                    {props.validationError}
                </span>
            ) : null}
        </InputWrapper>
    )
}

export default Input
export { Input, InputWrapper, InputControl }
