import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card, CardContent, CardFooter, CardHeader } from '@/ui/Card'
import Spinner from '@/ui/Spinner'
import DotsIndicator from '@/ui/DotsIndicator'
import Button, { SIZES } from '@/ui/controls/Button'
import Switcher from '@/ui/controls/Switcher'
import Icon from '@/ui/Icon'
import Headline from 'ui/Headline'
import Wrapper from '@/ui/Wrapper'
import Text from '@/ui/Text'
import Box from '@/ui/Box'
import Loader from '@/ui/Loader'
import Checkbox from '@/ui/controls/Checkbox'
import { TITLE_DIMENSIONS, MEDIAS, MEDIAS_BASIC, PALETTE } from '@/themes'
import Flex from '@/ui/Flex'
import NotificationConductor from '@/common/conductors/NotificationConductor'
import ValidationForm from '@/ui/BrandBookForm'
import Select from '@/ui/controls/Select'
import { modalService } from 'services/ModalService'
import { getImagePathsFromDirectory } from 'utils/imageImportHelper'
import casino from 'images/banners/page/casino-min.jpg'
import news from 'images/banners/page/news-min.jpg'
import tournament from 'images/banners/page/tournament-min.jpg'
import Slider, { Slide as SlideElement } from 'features/Carousel/Slider'
import themeService from 'services/ThemeService'
import primaryTheme from 'themes/themePrimary'
import secondaryTheme from 'themes/themeSecondary'
import Displayer from 'ui/Displayer'

const Row = styled.div``

const Col = styled.div``

export const IconPaths = getImagePathsFromDirectory(
    require.context('../images/icons/basic-icons/', false, /\.(png|jpe?g|svg)$/)
)

const BrandBook = () => {
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const [fruit, setFruit] = useState('apple')
    const [switcherChecked, setSwitcherChecked] = useState(false)

    useEffect(() => {
        const themeSubscription = themeService
            .getTheme()
            .subscribe((themeValue) => {
                setSwitcherChecked(themeValue.primary !== '#DE1C1B')
            })

        return () => {
            themeSubscription.unsubscribe()
        }
    }, [])

    return (
        <Wrapper style={{ overflow: 'hidden' }}>
            <Headline>Theme provider</Headline>
            <Text size="sm">Enables dark mode for a whole site</Text>
            <Displayer display={'block'}>
                <Box p={2}>
                    <Switcher
                        checked={switcherChecked}
                        onChange={() => {
                            setSwitcherChecked(!switcherChecked)
                            switcherChecked
                                ? themeService.setTheme(primaryTheme)
                                : themeService.setTheme(secondaryTheme)
                        }}
                    />
                </Box>
            </Displayer>
            <Headline>Slider</Headline>
            <Slider
                perPage={5}
                renderNextBtn={(next) => <button onClick={next}>next</button>}
                renderPrevBtn={(prev) => <button onClick={prev}>prev</button>}
            >
                <SlideElement src={casino} alt="slide1" loading="lazy" />
                <SlideElement src={news} alt="slide2" loading="lazy" />
                <SlideElement src={tournament} alt="slide4" loading="lazy" />
                <SlideElement src={casino} alt="slide1" loading="lazy" />
                <SlideElement src={news} alt="slide2" loading="lazy" />
                <SlideElement src={tournament} alt="slide4" loading="lazy" />
            </Slider>

            <Slider perPage={1} autoChangeTime={5000}>
                <SlideElement src={casino} alt="slide1" loading="lazy" />
                <SlideElement src={news} alt="slide2" loading="lazy" />
                <SlideElement src={tournament} alt="slide4" loading="lazy" />
                <SlideElement src={casino} alt="slide1" loading="lazy" />
                <SlideElement src={news} alt="slide2" loading="lazy" />
                <SlideElement src={tournament} alt="slide4" loading="lazy" />
            </Slider>
            <Headline>Palette</Headline>
            <Wrapper>
                {Object.keys(PALETTE).map((item, index) => (
                    <Row style={{ margin: '10px 0' }} key={index}>
                        <Col sm={12} md={6}>
                            <Text>{`${item} - ${PALETTE[item]}`}</Text>
                        </Col>
                        <Col sm={12} md={6}>
                            <Flex
                                background={PALETTE[item]}
                                height={'40px'}
                                width={'200px'}
                                boxShadow={'1px 3px 6px #00000029'}
                            />
                        </Col>
                    </Row>
                ))}
            </Wrapper>

            <Headline>Headline</Headline>
            <Wrapper>
                {TITLE_DIMENSIONS.map((element, index) => (
                    <Headline as={element.name} key={index}>
                        Headline as="
                        {element.name}"
                    </Headline>
                ))}
                <Headline color={PALETTE.primary}>
                    Headline color="PALETTE.primary"
                </Headline>
            </Wrapper>

            <Headline>Text</Headline>
            <Wrapper>
                {MEDIAS_BASIC.map((element, index) => (
                    <Text size={MEDIAS_BASIC[index]} key={index}>
                        Text size="
                        {MEDIAS_BASIC[index]}"
                    </Text>
                ))}
                <Text size="xl" color={PALETTE.primary}>
                    Text color="PALETTE.primary"
                </Text>
                <Text>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur
                </Text>
            </Wrapper>
            <Headline>Icon</Headline>
            <Wrapper>
                <Text>There are list of sizes:</Text>
                {MEDIAS.map((media, index) => (
                    <Row key={index}>
                        <Col>
                            <Text>
                                Icon size="
                                {media}
                                ":
                            </Text>
                        </Col>
                        <Col>
                            <Icon size={media} src={IconPaths['new']} />
                        </Col>
                    </Row>
                ))}
                <Row>
                    <Col>
                        <Text>Icon color="PALETTE.primary"</Text>
                    </Col>
                    <Col>
                        <Icon
                            size={'xxl'}
                            src={IconPaths['new']}
                            color={PALETTE.primary}
                        />
                    </Col>
                </Row>
            </Wrapper>
            <Text>There are all icons we have:</Text>

            {Object.keys(IconPaths).map((name, index) => (
                <Icon
                    size={'xl'}
                    src={IconPaths[name]}
                    key={index}
                    color={'black'}
                    m={2}
                />
            ))}

            <Icon
                size={'xl'}
                src={IconPaths['casino']}
                color={PALETTE.primary}
                m={2}
            />

            <Box background={PALETTE.accent} borderRadius={1} m={2}>
                <Icon
                    size={'lg'}
                    src={IconPaths['deposit']}
                    color={PALETTE.dark}
                    m={2}
                />
            </Box>
            <Box background={PALETTE.primaryHover} borderRadius={1} m={2}>
                <Icon
                    size={'lg'}
                    src={IconPaths['user']}
                    color={PALETTE.light}
                    m={2}
                />
            </Box>
            <Wrapper />
            <Headline>Button</Headline>
            <Wrapper>
                <Text>There are few types</Text>
                <Button m={2} uppercase>
                    warning
                </Button>
                <Button variant={'danger'} m={2} uppercase>
                    danger
                </Button>
                <Button variant={'light'} m={2} uppercase>
                    light
                </Button>
                <Button variant={'dark'} m={2} uppercase>
                    dark
                </Button>
                <Button variant={'lightHollow'} m={2} uppercase>
                    lightHollow
                </Button>
                <Wrapper>
                    <Button block>Block</Button>
                </Wrapper>

                <Button disabled m={2}>
                    Disabled
                </Button>

                <Button lowercase m={2}>
                    Lowercase
                </Button>

                <Button capitalize m={2}>
                    Capitalize
                </Button>

                <Button uppercase m={2}>
                    uppercase
                </Button>

                <Button m={2}>Default</Button>

                <Button m={2} rounded>
                    Rounded
                </Button>
                <Button m={2} rounded variant={'lightHollow'}>
                    Rounded
                </Button>
                <Button m={2} rounded variant={'lightHollow'} disabled>
                    Disabled
                </Button>
            </Wrapper>
            <Wrapper>
                <Text>There are buttons with different sizes:</Text>
                {Object.keys(SIZES).map((size, key) => (
                    <Button size={size} key={key} m={2}>
                        {size}
                    </Button>
                ))}
            </Wrapper>

            <Headline>Spinner</Headline>
            <Wrapper>
                {MEDIAS.map((size, index) => (
                    <Spinner
                        color={PALETTE.darkGray}
                        size={size}
                        key={index}
                        m={3}
                    />
                ))}
            </Wrapper>
            <Headline>Loader</Headline>
            <Wrapper>
                <Loader />
                <Loader dotsNumber={4} color={PALETTE.accent} m={3} />
            </Wrapper>

            <Headline>DotsIndicator</Headline>
            <Wrapper>
                <Wrapper>
                    <Row>
                        <Col xs={8}>
                            <Text>
                                DotsIndicator with single dot highlighted
                                (activeDot=
                                {2})
                            </Text>
                        </Col>
                        <Col xs={4}>
                            <DotsIndicator
                                activeDot={2}
                                dotsNumber={4}
                                color={PALETTE.lightGrey}
                                activeDotColor={PALETTE.primary}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <Text>
                                DotsIndicator with sequentially highlighted dots
                                by activeDot index (activeDot=
                                {3})
                            </Text>
                        </Col>
                        <Col xs={4}>
                            <DotsIndicator
                                activeDot={3}
                                dotsNumber={4}
                                sequentially
                                color={PALETTE.lightGrey}
                                activeDotColor={PALETTE.primary}
                            />
                        </Col>
                    </Row>
                </Wrapper>
            </Wrapper>

            <Headline>Switcher</Headline>
            <Wrapper>
                <Row>
                    <Col xs={8}>
                        <Text>Default Switcher</Text>
                    </Col>
                    <Col xs={4}>
                        <Switcher
                            m={1}
                            checked={switcherChecked}
                            onChange={() =>
                                setSwitcherChecked(!switcherChecked)
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <Text>
                            {' '}
                            Switcher with activeColor=
                            <Text as={'span'} color={PALETTE.primary}>
                                PALETTE.jade
                            </Text>{' '}
                            and color=
                            <Text as={'span'} color={PALETTE.darkGray}>
                                PALETTE.darkGray
                            </Text>
                        </Text>
                    </Col>
                    <Col xs={4}>
                        <Switcher
                            m={1}
                            checked={switcherChecked}
                            secondary
                            activeColor={PALETTE.primary}
                            color={PALETTE.darkGray}
                            onChange={() =>
                                setSwitcherChecked(!switcherChecked)
                            }
                        />
                    </Col>
                </Row>
            </Wrapper>

            <Headline>Checkbox</Headline>
            <Wrapper>
                <Row>
                    <Col xs={8}>
                        <Text>Checkbox</Text>
                    </Col>
                    <Col xs={4}>
                        <Checkbox
                            checked={checkboxChecked}
                            onChange={() =>
                                setCheckboxChecked(!checkboxChecked)
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <Text>
                            {' '}
                            Switcher with activeColor=
                            <Text as={'span'} color={PALETTE.categoryNewGames}>
                                PALETTE.jade
                            </Text>{' '}
                            and color=
                            <Text as={'span'} color={PALETTE.darkGray}>
                                PALETTE.darkGray
                            </Text>
                        </Text>
                    </Col>
                    <Col xs={4}>
                        <Checkbox
                            checked={checkboxChecked}
                            activeColor={PALETTE.categoryNewGames}
                            color={PALETTE.darkGray}
                            onChange={() =>
                                setCheckboxChecked(!checkboxChecked)
                            }
                        />
                    </Col>
                </Row>
            </Wrapper>

            <Headline>Dropdown</Headline>
            <Wrapper>
                <Select
                    placeholder="Select a fruit"
                    onChange={(value) => {
                        setFruit(value)
                    }}
                    value={fruit}
                >
                    <option value="apple">apple</option>
                    <option value="mango">mango</option>
                    <option value="orange">orange</option>
                    <option value="banana">banana</option>
                </Select>
            </Wrapper>

            <Headline>Notification</Headline>
            <Wrapper>
                {['error', 'warning', 'success', 'info'].map((alert, index) => (
                    <Button
                        m={2}
                        onClick={() =>
                            NotificationConductor[alert](alert + ' some text')
                        }
                        key={index}
                    >
                        {alert}
                    </Button>
                ))}
            </Wrapper>
            <Headline mt={3}>Form</Headline>
            <Wrapper>
                <ValidationForm />
            </Wrapper>

            <Headline>Modal</Headline>
            <Wrapper>
                <Button
                    m={2}
                    onClick={() => modalService.showModal('BRAND_BOOK')}
                >
                    Show modal
                </Button>
            </Wrapper>

            <Headline>Card Component</Headline>
            <Wrapper>
                <Card>
                    <CardHeader>Card Header</CardHeader>
                    <CardContent>Card Content</CardContent>
                    <CardFooter>Card Footer</CardFooter>
                </Card>
            </Wrapper>
        </Wrapper>
    )
}

export default BrandBook
