import { BehaviorSubject, Subject } from 'rxjs'
import { MAIN_REST_BASE_API, CMS_PATH, API_PHP } from 'configs/rest'
import { get, post } from 'common/adapters/FetchAdapter'
import { CMS_REST_BASE_URL } from '../configs/rest'
import { isDateYongerDays } from 'utils/dateTime'

/**
 * @TODO: make DTO for campaign
 */
const imagePathMapper = (campaign) => {
    if (!campaign) return {}

    campaign.bgimage = CMS_PATH + campaign.bgimage
    campaign.bgimage_mob = CMS_PATH + campaign.bgimage_mob

    return campaign
}

const imageMapper = (item) => {
    return {
        ...item,
        ...{ image: CMS_PATH + item.image },
    }
}

const campaignMapper = (campaign) => {
    if (!campaign) return {}

    campaign.game_id =
        campaign.game_id === 'disabled' || campaign.game_id === 'disabled2'
            ? ''
            : campaign.game_id
    campaign.game_id_mob =
        campaign.game_id_mob === 'disabled' ||
        campaign.game_id_mob === 'disabled2'
            ? ''
            : campaign.game_id_mob

    return campaign
}

const getSEODataFromJson = () => {
    try {
        const json = document.getElementById('seo_data').textContent
        return json ? JSON.parse(json) : {}
    } catch (e) {
        console.log('error', e)
    }
}

class RestService {
    cache = {}

    async Campaigns(cb) {
        let response = await fetch(
            `${MAIN_REST_BASE_API}${API_PHP}?r=Campaigns`
        )
        let jsonRes = await response.json()
        cb(campaignMapper(jsonRes))
    }

    async getCampaignBySeo(cb, seo) {
        try {
            let response = await fetch(
                `${MAIN_REST_BASE_API}${API_PHP}?r=Campaign&seo=` + seo
            )
            let jsonRes = await response.json()
            cb(imagePathMapper(campaignMapper(jsonRes)))
        } catch (e) {
            cb({}, e)
        }
    }

    async Highlights(successCallback, errorCallback) {
        try {
            let response = await fetch(
                `${MAIN_REST_BASE_API}${API_PHP}?r=Highlights`
            )
            let jsonRes = await response.json()
            successCallback(jsonRes)
        } catch (e) {
            errorCallback(e)
        }
    }

    async GetText(id, cb) {
        if (id === 'vilkaar-og-betingelser') {
            id = 'terms'
        }

        let response = await fetch(
            `${MAIN_REST_BASE_API}${API_PHP}?r=Texts&id=${id}`
        )
        let jsonRes = await response.json()
        cb(jsonRes)
    }

    GetUserNotifications(userId, cb) {
        get(
            window.config.baseCmsPath +
                '/rest-bet25/get-user-notifications?client_id=' +
                userId
        )
            .then((res) => {
                cb(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    CleanUserNotifications(type, userId, cb) {
        post(
            window.config.baseCmsPath +
                '/rest-bet25/set-notification-as-viewed',
            {
                client_id: userId,
                type: type,
            }
        )
            .then((res) => {
                cb(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    async getTournamentBySeoUrl(params, cb) {
        let response = await fetch(
            `${MAIN_REST_BASE_API}${API_PHP}?r=TournamentSeo&seo=${params.seo}`
        )
        let jsonRes = await response.json()

        response.ok ? cb(imageMapper(jsonRes)) : cb(jsonRes)
    }

    sendFeedback(type, text, email, step) {
        return post(CMS_PATH + '/rest-bet25/registration-feedback', {
            client_answer: type,
            step_number: step,
            app_name: 'rod25',
            client_text: text,
            client_email: email,
        })
    }

    getSEOData(cb) {
        let seo = getSEODataFromJson()

        if (seo) {
            return cb(seo.data)
        } else {
            return get(CMS_REST_BASE_URL + 'seo/get-seo-data?site=rod')
                .then((response) => {
                    cb(response.data)
                })
                .catch(function(error) {
                    cb(null, error)
                })
        }
    }

    getTournamentList(type, cb) {
        get(`${MAIN_REST_BASE_API}${API_PHP}?r=` + type)
            .then((response) => {
                cb(response.data)
            })
            .catch(function(error) {
                cb(null, error)
            })
    }

    getTopList(params, cb) {
        get(`${MAIN_REST_BASE_API}${API_PHP}?r=Leaderboard&id=${params.id}`)
            .then((res) => {
                console.log(res)
                if (res) {
                    console.log('Leaderboard', res.data.occurence_id)
                } else {
                    console.error(
                        "ajaxNetEnt.getLeaderBoard err: can't connect"
                    )
                }
            })
            .catch(function(error) {
                cb(null, error)
            })
    }

    getConfig() {
        return get(`${MAIN_REST_BASE_API}${API_PHP}`, { r: 'GetConfig' })
    }
}

export const restService = new RestService()

const Texts = new BehaviorSubject({})
const highlightsList = new BehaviorSubject([])
const campaignsList = new BehaviorSubject([])
const tournamentsList = new BehaviorSubject([])
const SEOData = new BehaviorSubject([])
const siteConfig = new Subject()
const highlights = new Subject()

export const setHighlights = () => {
    restService
        .Highlights(
            (res) => {
                highlights.next(res)
            },
            (error) => {
                console.error(error)
            }
        )
        .finally(() => {})
}

export const getHighlights = () => highlights.asObservable()
export const filterCampaigns = (res) => {
    let registerDateYonger = ''
    const regUserDate = localStorage.getItem('registerDate')

    if (regUserDate && regUserDate.indexOf('-') > -1) {
        registerDateYonger = isDateYongerDays(regUserDate)
    }

    return res.filter((item) => {
        // anonymous - not registered
        // show all elements in case user is anonymous
        if (!regUserDate) return true

        // show elements that is not intended to be only for anonymous
        if (item.is_anon === 'false') return true

        // show for anonymous and who registered not more than 7 days ago
        return item.is_anon === 'true' && registerDateYonger
    })
}

export default {
    setConfig: () =>
        restService.getConfig().then((res) => siteConfig.next(res)),
    getConfig: () => siteConfig.asObservable(),

    setCampaignsList: () => {
        restService.Campaigns((res) => {
            const filteredCampaigns = filterCampaigns(res)

            campaignsList.next(
                filteredCampaigns.map((item) => imagePathMapper(item))
            )
        })
    },
    getCampaignsList: () => campaignsList.asObservable(),

    setTournamentsList: (type = 'Tournaments') => {
        restService.getTournamentList(type, (res) => {
            if (res)
                tournamentsList.next(
                    res.map((item) => {
                        item.image = CMS_PATH + item.image
                        return item
                    })
                )
        })
    },
    getTournamentsList: () => tournamentsList.asObservable(),

    setSEOData: (url = '/') => {
        restService.getSEOData((res) => {
            if (res) {
                let el
                res.forEach(function(item) {
                    if (item.url === url || item.url === '/') {
                        el = item
                    }
                })
                SEOData.next(el)
            }
        })
    },
    getSEOData: () => SEOData.asObservable(),
}
