import React, { useEffect, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import styled, { withTheme } from 'styled-components'
import ValidInput from 'ui/controls/ValidInput'
import NotificationConductor from 'common/conductors/NotificationConductor'
import withValidation from '@/common/Hocs/WithValidation'
import UserAdapter from 'common/adapters/UserAdapter'
import Box from 'ui/Box'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import { pipe } from 'rxjs'
import Icon from 'ui/Icon'
import edit from 'images/icons/basic-icons/edit.svg'
import userService from 'services/UserService'
import { formatDate } from '../../../utils/dateTime'

const t = window.T

const BackgroundHolder = styled(Box)`
    height: 100%;
    background: ${(props) => props.theme.background};
`

const StyledInput = styled(Flex)`
    margin: 1rem 0;
`

const YourInfo = ({
    setValidationHandler,
    validateAllFields,
    theme,
    data: generalInfo = {},
}) => {
    const [userProfile, setUserProfile] = useState({
        firstName: '',
        lastName: '',
    })
    const [userInfo, setUserInfo] = useState({})

    const [updateButtonDisable, setUpdateButtonDisable] = useState(true)

    const [email, setEmail] = useState(generalInfo.email || '')
    const [address, setAddress] = useState(generalInfo.address || '')
    const [zip, setZip] = useState(generalInfo.zip || '')
    const [phoneNr, setMobile] = useState(generalInfo.phoneNr || '')
    const [city, setCity] = useState(generalInfo.city || '')

    const submitHandler = (e) => {
        e.preventDefault()

        if (validateAllFields()) {
            UserAdapter.updateProfile({
                email,
                address,
                zip,
                phoneNr,
                city,
            }).then((res) => {
                userService.setIsUserLoggedIn()
                res.success
                    ? NotificationConductor.success(
                          'Dine kontooplysninger er blevet ændret'
                      )
                    : NotificationConductor.error(res.errorMessage)
            })
        } else {
            NotificationConductor.error(t.filledWrong)
        }
    }

    useEffect(() => {
        UserAdapter.isUserLoggedIn().then((res) => {
            setUserInfo(res)
            setEmail(res.email)
        })

        UserAdapter.getProfile()
            .then((res) => {
                setUserProfile(res)
                setAddress(res.address)
                setZip(res.zip)
                setMobile(res.phoneNr)
                setCity(res.city)
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })
    }, [])

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0

        if (key === 'Enter' && !updateButtonDisable) {
            e.preventDefault()
            submitHandler(e)
        }
    }

    return (
        <BackgroundHolder>
            <ModalTopBar background={theme.primary} back color={theme.light}>
                <Text size={'md'} color={theme.light} my={2}>
                    Kontooplysninger
                </Text>
            </ModalTopBar>

            <Wrapper style={{ textAlign: 'center', background: '#f9f9f9' }}>
                <Text
                    padding="20px 0 0"
                    align={'center'}
                    bold
                    margin={'10px 0 35px'}
                >
                    {`${generalInfo.firstName ||
                        userProfile.firstName} ${generalInfo.lastName ||
                        userProfile.lastName}`}
                </Text>
                <Text size={'sm'} align={'center'} color={'gray'}>
                    Sidste log ind:{' '}
                    {userInfo.extraInfo?.lastLogin
                        ? new Date(
                              userInfo.extraInfo.lastLogin
                          ).toLocaleString()
                        : new Date().toLocaleString()}
                </Text>
                <StyledInput>
                    <ValidInput
                        onChange={(v) => {
                            setEmail(v)
                            setUpdateButtonDisable(v === userInfo.email)
                        }}
                        value={email}
                        validate={{
                            required: true,
                            email: true,
                        }}
                        placeholder={t.signupWindow.step4.email}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('email', validatorHandler)
                        }
                        rightText={<Icon src={edit} size={'sm'} />}
                        hideCancel
                        onKeyPress={keyPressHandler}
                    />
                </StyledInput>
                <StyledInput>
                    <ValidInput
                        onChange={(v) => {
                            setAddress(v)
                            setUpdateButtonDisable(v === userProfile.address)
                        }}
                        value={address}
                        placeholder={t.user.address}
                        validate={{
                            required: true,
                        }}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('address', validatorHandler)
                        }
                        rightText={<Icon src={edit} size={'sm'} />}
                        hideCancel
                        onKeyPress={keyPressHandler}
                    />
                </StyledInput>
                <StyledInput>
                    <ValidInput
                        onChange={(v) => {
                            setZip(v)
                            setUpdateButtonDisable(v === userProfile.zip)
                        }}
                        value={zip}
                        placeholder={t.user.zip}
                        validate={{
                            required: true,
                        }}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('zip', validatorHandler)
                        }
                        rightText={<Icon src={edit} size={'sm'} />}
                        hideCancel
                        onKeyPress={keyPressHandler}
                    />
                </StyledInput>
                <StyledInput>
                    <ValidInput
                        onChange={(v) => {
                            setCity(v)
                            setUpdateButtonDisable(v === userProfile.city)
                        }}
                        value={city}
                        placeholder={t.user.city}
                        validate={{
                            required: true,
                        }}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('city', validatorHandler)
                        }
                        rightText={<Icon src={edit} size={'sm'} />}
                        hideCancel
                        onKeyPress={keyPressHandler}
                    />
                </StyledInput>
                <StyledInput>
                    <ValidInput
                        onChange={(v) => {
                            setMobile(v)
                            setUpdateButtonDisable(v === userProfile.phoneNr)
                        }}
                        value={phoneNr}
                        placeholder={t.signupWindow.step4.mobile}
                        validate={{
                            required: true,
                            numeric: true,
                            minLength: 8,
                        }}
                        getValidator={(validatorHandler) =>
                            setValidationHandler(
                                'mobileNumber',
                                validatorHandler
                            )
                        }
                        rightText={<Icon src={edit} size={'sm'} />}
                        hideCancel
                        onKeyPress={keyPressHandler}
                    />
                </StyledInput>

                <Flex>
                    <Button
                        size={'lgB'}
                        onClick={submitHandler}
                        variant={'danger'}
                        style={{ flex: '50%' }}
                        disabled={updateButtonDisable}
                    >
                        {t.submit}
                    </Button>
                </Flex>
            </Wrapper>
        </BackgroundHolder>
    )
}

export default pipe(
    withValidation,
    withTheme,
    withAnimation
)(YourInfo)
