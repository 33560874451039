import React from 'react'

/**
 *
 * @param Component
 * @return {function(*): *}
 */
const withValidation = (Component) => (props) => {
    const validationHandlers = {}

    const setValidationHandler = (key, handler) => {
        validationHandlers[key] = handler
    }

    const validateAllFields = () => {
        let isValid = true

        Object.keys(validationHandlers).map((key) => {
            if (!validationHandlers[key]()) {
                isValid = false
            }

            return key
        })

        return isValid
    }

    const hocProps = { setValidationHandler, validateAllFields }

    return <Component {...hocProps} {...props} />
}

export default withValidation
