import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import NotificationConductor from 'common/conductors/NotificationConductor'

const Error = () => {
    const history = useHistory()

    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }

    const query = useQuery()

    const error = query.get('error')
    useEffect(() => {
        history.push('/')
        NotificationConductor.error(error || 'MitID verification failed')
    }, [])

    return null
}

export default Error
