import React, { useEffect, useState } from 'react'
import PageWrapper from 'ui/PageWrapper'
import PageContent from 'ui/PageContent'
import ProvidersList from 'features/ProvidersContent/ProvidersList'
import ProviderItem from 'features/ProvidersContent/ProviderItem'
import { useHistory, useRouteMatch } from 'react-router-dom'
import GamesService from 'services/GamesService'
import { PROVIDERS } from 'common/providers'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
//temp
import bg1 from 'images/providers/slidesTemp/All_providers.jpg'
import BannerSlider from 'ui/BannerSlider'
import { CASINO_CATEGORIES } from 'configs/categories'

const ProvidersPage = () => {
    const defaultSlides = [bg1] // temp

    const [pageState, setPageState] = useState('')
    const [pageData, setPageData] = useState({})
    const [providersGames, setProvidersGames] = useState({})
    const [providersForShowing, setProvidersForShowing] = useState([])
    const [gamesData, setGamesData] = useState([])
    const [slides, setSlides] = useState(defaultSlides)

    const match = useRouteMatch()
    const history = useHistory()

    const onProviderClick = (data) => {
        setPageState(match.params?.subCategory || '')
        setPageData(data)
        setGamesData(providersGames[data.value])
        history.push(`/automater/spiludviklere/${data.value}`)
    }

    const getCurrentCategory = () =>
        CASINO_CATEGORIES.find((e) => e.url === match.params?.category)

    useEffect(
        () => {
            const category = getCurrentCategory()?.name

            setPageState(match.params?.subCategory || '')
            if (category === 'providers') {
                if (
                    match.params?.subCategory &&
                    providersGames &&
                    Object.keys(providersGames).length
                ) {
                    const findProvider = PROVIDERS.find(
                        (item) => item.value === match.params.subCategory
                    )

                    if (!findProvider) {
                        history.push('/404')
                    } else {
                        if (findProvider?.background) {
                            setSlides([findProvider.background])
                        }
                        setGamesData(providersGames[match.params.subCategory])
                    }
                } else {
                    setSlides(defaultSlides)
                }
            }
        },
        [match.params?.subCategory, providersGames]
    )

    useEffect(() => {
        const providersStatusSub = GamesService.getLiveCasinoGamesStatus().subscribe(
            (status) => {
                setProvidersForShowing(
                    PROVIDERS.filter((el) => {
                        return (
                            status[el.value] ||
                            Object.keys(status).indexOf(el.value)
                        )
                    })
                )
            }
        )
        const sub = GamesService.combineAllGames().subscribe((games) => {
            if (games.length) {
                setProvidersGames(GamesService.getProvidersGamesCount(games))
            }
        })

        return () => {
            sub.unsubscribe()
            providersStatusSub.unsubscribe()
        }
    }, [])

    return (
        <PageContent maxWidth={'1140px'}>
            {!Object.keys(providersGames).length ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : pageState.length ? (
                <ProviderItem data={{ provider: pageData, games: gamesData }} />
            ) : (
                <ProvidersList
                    providerClick={onProviderClick}
                    providersGames={providersGames}
                    providersList={providersForShowing}
                />
            )}
        </PageContent>
    )
}

export default ProvidersPage
