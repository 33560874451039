/**
 * Row object
 {
    alt: "0"
    bonus: ""
    bonusrunder: ""
    category: "slots"
    created_at: "2017-09-25 16:43:08"
    feature: "lowfreq"
    features: ""
    game_id: "starburst_not_mobile"
    go_hide: "0"
    go_hot: "0"
    go_new: "0"
    go_priority: "406"
    group_id: "0"
    id: "132"
    info: ""
    jackpot: ""
    min_stake: "1"
    name: "Starburst"
    payback: "0"
    paylines: ""
    platform: "web"
    priority: "406"
    provider: "netent"
    provider_id: "0"
    real_id: ""
    rod_hide: "0"
    rod_hot: "1"
    rod_new: "0"
    spinsrunder: ""
    subcat: ""
    table_id: ""
    tag_hot: "1"
    tag_new: "0"
    theme: ""
    type: ""
    video: "false"
    wide: "false"
 }
 **/

class GameDTO {
    constructor(game = {}) {
        this.jackpot_amount = ''
    }
    /**
     * @param {object} game Raw response object.
     * @see propertyMap to investigate its shape
     * @returns {object} ready for use in the program
     */
    static unserialize(game) {
        return {
            ...game,
            ...new GameDTO(game),
        }
    }

    /**
     * Attributes with [Amount] postfix will be sent with values of [AmountChange]
     * attributes as server expects data to be received this way.
     *
     * @returns {Object} serialized object ready for sending to server side
     */
    static serialize(game) {
        return {}
    }
}

export default GameDTO
