import React from 'react'
import { createGlobalStyle } from 'styled-components'
import 'react-notifications/lib/notifications.css'
import { PALETTE } from '@/themes'

const TOASTER_OPACITY = 0.95

const DKGlobalInject = createGlobalStyle`
        body {
            margin: 0;
            padding: 0;
            font-family: 'Roboto', sans-serif;
            position: relative;
            left: 0;
            top: 0;
            right: 0;
            color: ${PALETTE.darkGray};
            line-height: 1.5em;
        }
        .notification-error {
          background-color: ${PALETTE.primaryHover};
         }
      
        .Toastify .Toastify__toast--error {
          background-color: rgb(198, 25, 24, ${TOASTER_OPACITY});
        } 

        .Toastify .Toastify__toast--warning {
          background-color: rgb(251, 152, 0, ${TOASTER_OPACITY});
        }

        .Toastify .Toastify__toast--success {
          background-color: rgb(81, 163, 81, ${TOASTER_OPACITY});
        } 

        .Toastify .Toastify__toast--info {
          background-color: rgb(0, 122, 254, ${TOASTER_OPACITY});
        }
        
        .Toastify__toast-container--top-right {
          top: 0 !important;
          right: 0 !important;
        } 
        
        .tooltip-game-info {
          border: 1px solid #70707033 !important;
          :before {
            border-bottom-color: #70707033 !important;
          }
        }
        
        h1 {
          font-size: 2em;
          line-height: 1.25em;
        }
        
        h2 {
          font-size: 1.625em;
          line-height: 1.154em;
        }
        
        h3 {
          font-size: 1.375em;
          line-height: 1.136em;
        }
        
        h4 {
          font-size: 1.125em;
          line-height: 1.111em;
        }
        
        h5 {
          font-size: .83em;
          line-height: 1em;
        }
        
        h6 {
          font-size: .67em;
          line-height: 1em;
        }
        
    `

export default DKGlobalInject
