import React, { useEffect } from 'react'
import styled from 'styled-components'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import Box from 'ui/Box'
import Flex from 'ui/Flex'

const t = window.T

const Amount = styled(Flex)`
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-end;
`

const Limits = ({ theme, ...props }) => {
    const limits = props.limits

    return (
        limits && (
            <Box>
                <ProfileCard
                    borderRadiusTop
                    title={t.userArea.depositLimit.currentLimit}
                    background={theme.light}
                    subtitleColor={theme.gray}
                >
                    <Amount>{limits.current}</Amount>
                </ProfileCard>
                <ProfileCard
                    borderRadiusBottom
                    title={t.userArea.depositLimit.newLimit}
                    background={theme.light}
                    subtitleColor={theme.gray}
                    subtitle={limits.date ? limits.date : '-'}
                >
                    <Amount>{limits.newLimit}</Amount>
                </ProfileCard>
            </Box>
        )
    )
}

export default Limits
