import React from 'react'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import Box from 'ui/Box'
import { modalService } from 'services/ModalService'
import ModalTopBar from 'ui/ModalTopBar'

const GeoDataWrapper = styled(Box)`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        border-radius: 8px;
        width: 500px;
        position: relative;
        top: 10vh;
        left: 50%;
        transform: translateX(-50%);
    }
`

const GeoDataModal = () => {
    const onReload = () => {
        window.location.reload()
    }
    return (
        <GeoDataWrapper>
            <ModalTopBar
                background={'white'}
                crossColor={'#4E4E4E'}
                style={{
                    borderTopLeftRadius: detectMobile() ? '22px' : '8px',
                    borderTopRightRadius: detectMobile() ? '22px' : '8px',
                }}
                closeAction={() => {
                    modalService.closeModal()
                }}
            >
                <Flex>
                    <Text as={'span'} bold color={'black'} size={'xl'}>
                        Vi kan ikke finde dig?
                    </Text>
                </Flex>
            </ModalTopBar>

            <Box px={detectMobile() ? 3 : 5} width={'100%'}>
                <Box width={'100%'} pb={2}>
                    <Text size={'lg'} my={3}>
                        Er din internetforbindelse dårlig eller befinder du dig
                        uden for Danmark? På grund af licensreglerne kan vi
                        desværre ikke tilbyde spil uden for Danmark. Prøv igen
                        eller kontakt os via chatten hvis du har spørgsmål.
                    </Text>
                </Box>
                <Button
                    type={'accent'}
                    size={'lgB'}
                    block
                    mt={5}
                    mb={5}
                    onClick={() => onReload()}
                >
                    Prøv igen
                </Button>
            </Box>
        </GeoDataWrapper>
    )
}

export default GeoDataModal
