import styled from 'styled-components'

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
`

export default PageWrapper
