import gigaBg from 'images/casino/jackpots/Giga.png'
import megaBg from 'images/casino/jackpots/Mega.png'
import storBg from 'images/casino/jackpots/Stor.png'
import lilleBg from 'images/casino/jackpots/Lille.png'

export const LEVELS_NAME = {
    ultra: 'ultra',
    monthly: 'DIAMOND',
    weekly: 'SAPPHIRE',
    daily: 'EMERALD',
}

export const JACKPOTS_LEVELS = [
    {
        name: LEVELS_NAME.ultra,
        title: 'Ultra Jackpot',
        description: 'Vilkårlige gevinster',
        background: gigaBg,
        color: '#002FCB',
    },
    {
        name: LEVELS_NAME.monthly,
        title: 'Super Jackpot Drop',
        description: 'Gevinst hver 2. måned i gnsn.',
        background: megaBg,
        color: '#F68511',
    },
    {
        name: LEVELS_NAME.weekly,
        title: 'Ugentlig Jackpot Drop',
        description: 'Gevinst hver uge',
        background: storBg,
        color: '#27BB36',
    },
    {
        name: LEVELS_NAME.daily,
        title: 'Daglig Jackpot Drop',
        description: 'Gevinst hver dag',
        background: lilleBg,
        color: '#B622B7',
    },
]
