import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const animationSpeed = 200

const Container = styled.div`
    min-width: 56px;
    width: 56px;
    height: 28px;
    background: ${(props) =>
        props.checked
            ? props.activeColor || '#4AE164'
            : props.color || '#F1F1F1'};
    border-radius: 28px;
    position: relative;
    margin: ${(props) => (props.margin ? props.margin : '0')};
`

const Indicator = styled.div`
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 24px;
    position: absolute;
    margin: 2px;
    left: ${(props) => (props.checked ? '50%' : '0px')};
    transition: all ${animationSpeed}ms linear;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.4);
`

const Switcher = (props) => {
    return (
        <Container {...props} checked={props.checked} onClick={props.onChange}>
            <Indicator checked={props.checked} />
        </Container>
    )
}

Switcher.propTypes = {
    onChange: PropTypes.func,
}

export default Switcher
