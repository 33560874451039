export const DIBS_OPERATION_DEPOSIT = 'deposit'
export const DIBS_CLIENT_NAME = 'bet25'
export const DIBS_PMNT = 'Casino'

// const ORIGIN = window.location.origin
//
// export const DIBS_EXCHANGE_DK = 'DKK'
// export const DIBS_ACCEPT_RETURN_URL = ORIGIN + '/dibs'
// export const DIBS_CANCEL_RETURN_URL = ORIGIN + '/deposit/cancel'
// export const DIBS_SUCCESS_URL = ORIGIN + '/deposit/success'
// export const DIBS_ERROR_URL = ORIGIN + '/deposit/cancel'

const ORIGIN_HOST = window.location.origin

export const DIBS_EXCHANGE_DK = 'DKK'
export const DIBS_ERROR_URL = ORIGIN_HOST + '/deposit/error'
