import React, { useEffect, useState } from 'react'
import { pipe } from 'rxjs'
import ModalTopBar from 'ui/ModalTopBar'
import styled, { withTheme } from 'styled-components'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Switcher from 'ui/controls/Switcher'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import letter from 'images/icons/basic-icons/aсcount-area/letter.svg'
import { withAnimation } from 'features/UserArea/Components/withAnimation'

import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'services/UserService'
import COLORS from 'themes/primary/palette'

const NotificationsWrapper = styled(Box)`
    height: 100%;
    background: ${(props) => props.theme.background};

    a {
        color: #1b4ef7;
    }
`

const NotificationSection = ({
    theme,
    value,
    switchHandler,
    children,
    description,
}) => (
    <Box p={3}>
        <ProfileCard
            borderRadiusTop
            title={children}
            iconSrc={letter}
            background={theme.light}
            subtitleColor={theme.gray}
            color={theme.gray}
        >
            <Switcher
                style={{ width: '65px' }}
                checked={value}
                onChange={() => {
                    switchHandler(!value)
                }}
            />
        </ProfileCard>
        <ProfileCard borderRadiusBottom background={theme.light}>
            {value ? (
                <Text m={3}>
                    Du vil nu modtage nyhedsbreve med gode tilbud, freespins og
                    kampagner.
                </Text>
            ) : (
                <Text m={3}>{description}</Text>
            )}
        </ProfileCard>
    </Box>
)

const Notifications = ({ theme, data: news = {} }) => {
    const [userInfo, setUserInfo] = useState({})
    const [enableNews, setEnableNews] = useState(news.newsletter)

    const newsCheckHandler = (state) => {
        setEnableNews(state)

        if (state) {
            UserAdapter.enableNewsletterSubscription()
        } else {
            UserAdapter.disableNewsletterSubscription(0, userInfo.email)
        }

        setTimeout(() => {
            UserService.setIsUserLoggedIn()
        }, 100)
    }

    useEffect(() => {
        UserAdapter.isUserLoggedIn().then((res) => {
            setUserInfo(res)
            setEnableNews(res.newsletter)
        })
    }, [])

    const descriptionText = (
        <>
            Ved tilmelding gives tilladelse til at Bet25 A/S (herunder CasinoGo)
            må behandle oplysninger om mig og sende mig nyheder og markedsføring
            af vores onlinecasino, væddemål, events, kampagner og konkurrencer
            via e-mail og sms. Du kan altid tilbagekalde dit samtykke på{' '}
            <a target="_blank" href="https://www.bet25.dk/" rel="noreferrer">
                Bet25.dk
            </a>{' '}
            og{' '}
            <a target="_blank" href="https://www.casinogo.dk" rel="noreferrer">
                CasinoGo.dk
            </a>
            , samt via vores apps eller via link i nyhedsbreve. Læs mere om
            regler for beskyttelse af personlige oplysninger her:{' '}
            <a
                target="_blank"
                href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-bet25-a-s"
                rel="noreferrer"
            >
                persondatapolitik
            </a>
            .
        </>
    )

    return (
        <>
            <NotificationsWrapper>
                <ModalTopBar
                    background={theme.primary}
                    back
                    color={theme.light}
                >
                    <Text size={'md'} color={theme.light} my={2}>
                        {'Beskedindstillinger'}
                    </Text>
                </ModalTopBar>
                <NotificationSection
                    theme={theme}
                    value={enableNews}
                    switchHandler={newsCheckHandler}
                    description={descriptionText}
                >
                    Nyhedsbrev
                </NotificationSection>
            </NotificationsWrapper>
        </>
    )
}

export default pipe(
    withTheme,
    withAnimation
)(Notifications)
