import React, { useEffect, useState } from 'react'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import { accountAreaIcons } from 'features/UserArea/Components/ProfileInfo'
import PropType from 'prop-types'
import styled from 'styled-components'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Button from 'ui/controls/Button'
import check from 'images/icons/basic-icons/check.svg'
import close from 'images/icons/basic-icons/close.svg'
import Icon from 'ui/Icon'

const BonusDescription = () => (
    <Text size={'sm'}>
        Bonusbeløbet skal omsættes før din gevinst kan bruges på din 25-konto.
        Alle spil fra Menuen:{' '}
        <Text bold as={'span'}>
            Automater
        </Text>{' '}
        tæller med i omsætningen. Husk, at du altid spiller for egne penge
        først, dernæst bonuspenge.
    </Text>
)

const Control = styled(Button)`
    width: 100%;
    font-size: 0.9rem;
    font-weight: bold;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
`

const StatusIcon = styled(Icon)`
    padding: 0 2px;
    margin: 0 1px;
`

const BonusInfo = ({ bonus = {} }) => (
    <Flex justify={'space-between'}>
        <Text size={'sm'} style={{ flex: '1' }}>
            Min. indbetaling:
            <br />
            Max. bonus:
            <br />
            Omsætningskrav:
            <br />
            Udløber:
        </Text>
        <Text size={'sm'} bold style={{ flex: '1' }}>
            {bonus.minAmount} kr. <br />
            {bonus.maxAmount} kr.
            <br />
            {`${bonus.rollover}x, inden ${bonus.days || '60'} dage.`} <br />
            {bonus.expires}.
        </Text>
    </Flex>
)

const Card = styled.div`
    background: white;
    padding: 1rem;
    border-radius: 0 0 4px 4px;
`

const BonusList = ({
    bonusList = [],
    theme,
    bonusEntity,
    setBonusInactive = (f) => f,
    setBonusActive = (f) => f,
}) => {
    const [select, setSelect] = useState('unset')
    const [descriptionVisible, setDescriptionVisible] = useState(true)

    return (
        <>
            {bonusList.map((bonus) => (
                <div key={bonus.externalId}>
                    <ProfileCard
                        mt={3}
                        borderRadiusTop
                        title={bonus.title}
                        iconSrc={accountAreaIcons.gift}
                        background={theme.light}
                        color={theme.gray}
                        onClick={() => {
                            setDescriptionVisible(true)
                        }}
                        style={{ borderBottom: '0', boxShadow: 'none' }}
                    >
                        {select !== 'unset' ? (
                            <>
                                {bonus.externalId === bonusEntity.externalId ? (
                                    <StatusIcon
                                        size={'lg'}
                                        src={check}
                                        color={theme.jade}
                                    />
                                ) : (
                                    <StatusIcon
                                        size={'lg'}
                                        src={close}
                                        color={theme.gray}
                                    />
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </ProfileCard>
                    {descriptionVisible && (
                        <Card>
                            <BonusInfo bonus={bonus} />
                            <br />
                            <BonusDescription />
                            <Flex mt={3}>
                                {
                                    <Flex flex={'1'} mr={2}>
                                        <Control
                                            onClick={() => {
                                                setBonusActive(bonus)
                                                setSelect('select')
                                                setDescriptionVisible(false)
                                            }}
                                        >
                                            Accepter
                                        </Control>
                                    </Flex>
                                }
                                {
                                    <Flex flex={'1'} ml={2}>
                                        <Control
                                            style={{ background: '#F1F1F1' }}
                                            onClick={() => {
                                                setBonusInactive(bonus)
                                                setSelect('unselect')
                                                setDescriptionVisible(false)
                                            }}
                                        >
                                            Afvis
                                        </Control>
                                    </Flex>
                                }
                            </Flex>
                        </Card>
                    )}
                </div>
            ))}
        </>
    )
}

BonusList.propType = {
    bonusList: PropType.array,
    bonusEntity: PropType.object,
    setBonusInactive: PropType.func,
    setBonusActive: PropType.func,
}

export default BonusList
