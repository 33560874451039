import styled, { css } from 'styled-components'

const spacingRange = [0, 1, 2, 3, 4, 5]

/** Prop passing Shorthands for Styled-components
 * The approach was inspired by Bootstrap 4 spacing https://getbootstrap.com/docs/4.4/utilities/spacing/
 **/

/**
 *
 * @param number {int}
 * @returns number {float}
 */
const getSpacingByNumber = (number) => {
    if (number && !spacingRange.includes(number)) {
        throw new Error(
            `Spacing must be one of numbers ${spacingRange}, instead ${number} type of ${typeof number} was supplied`
        )
    }

    switch (number) {
        case 0:
            return 0
        case 1:
            return 0.25
        case 2:
            return 0.5
        case 3:
            return 1
        case 4:
            return 1.5
        case 5:
            return 3
        default:
            return 0
    }
}

export const borderProps = (props) => css`
    ${props.borderBottom &&
        `border-bottom: ${props.borderWidth || '1px'} solid ${color.border}`};
    ${props.borderTop &&
        `border-top: ${props.borderWidth || '1px'} solid ${color.border}`};
    ${props.borderLeft &&
        `border-left: ${props.borderWidth || '1px'} solid ${color.border}`};
    ${props.borderRight &&
        `border-right: ${props.borderWidth || '1px'} solid ${color.border}`};
`

export const marginProps = (props) => css`
    ${spacingRange.includes(props.mb) &&
        `margin-bottom: ${getSpacingByNumber(props.mb) + 'rem'}`};
    ${spacingRange.includes(props.mt) &&
        `margin-top: ${getSpacingByNumber(props.mt) + 'rem'}`};
    ${spacingRange.includes(props.ml) &&
        `margin-left: ${getSpacingByNumber(props.ml) + 'rem'}`};
    ${spacingRange.includes(props.mr) &&
        `margin-right: ${getSpacingByNumber(props.mr) + 'rem'}`};
    ${spacingRange.includes(props.m) &&
        `margin: ${getSpacingByNumber(props.m) + 'rem'}`};
    ${spacingRange.includes(props.mx) &&
        `margin-right: ${getSpacingByNumber(props.mx) + 'rem'};
         margin-left: ${getSpacingByNumber(props.mx) + 'rem'};`};
    ${spacingRange.includes(props.my) &&
        `margin-top: ${getSpacingByNumber(props.my) + 'rem'};
         margin-bottom: ${getSpacingByNumber(props.my) + 'rem'};`};
`

export const paddingProps = (props) => css`
    ${props.pb && `padding-bottom: ${getSpacingByNumber(props.pb) + 'rem'}`};
    ${props.pt && `padding-top: ${getSpacingByNumber(props.pt) + 'rem'}`};
    ${props.pl && `padding-left: ${getSpacingByNumber(props.pl) + 'rem'}`};
    ${props.pr && `padding-right: ${getSpacingByNumber(props.pr) + 'rem'}`};
    ${props.px &&
        `padding-right: ${getSpacingByNumber(props.px) + 'rem'};
         padding-left: ${getSpacingByNumber(props.px) + 'rem'};`};
    ${props.py &&
        `padding-top: ${getSpacingByNumber(props.py) + 'rem'};
         padding-bottom: ${getSpacingByNumber(props.py) + 'rem'};`};
    ${spacingRange.includes(props.p) &&
        `padding: ${getSpacingByNumber(props.p) + 'rem'}`};
`

export const borderRadiusProps = (props) => css`
    border-radius: ${getSpacingByNumber(props.borderRadius) + 'rem'};
`
// An example of how you can use it with your components
const SomeDiv = styled.div`
    ${borderProps};
    ${marginProps};
`

// An example of using spacing as with component props
const TotalSpacing = `<Component m={1} p={2} />`
const ParticularSpacing = `<Component ml={1} pt={2} />`

// This lets you pass all borderProps to the component like so:

// <SomeDiv borderTop borderBottom borderLeft borderRight marginVertical>
