import Flex from 'ui/Flex'
import Text from 'ui/Text'
import Loader from 'ui/Loader'
import React from 'react'
import PropTypes from 'prop-types'

const LoaderExposer = ({
    items = [],
    isItemsReceived,
    notFoundText = 'Ingen match fundet.',
}) => {
    return (
        <>
            {!items.length && (
                <Flex m={3}>
                    {isItemsReceived ? <Text>{notFoundText}</Text> : <Loader />}
                </Flex>
            )}
        </>
    )
}

LoaderExposer.propTypes = {
    items: PropTypes.array,
    isItemsReceived: PropTypes.bool,
    notFoundText: PropTypes.string,
}

export default LoaderExposer
