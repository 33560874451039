import { modalService } from 'services/ModalService'
import { EXCLUSIVE_GAME_LABEL } from 'configs/ui'
import moment from 'moment/moment'
import Headline from 'ui/Headline'
import COLORS from 'themes/primary/palette'
import { renderLoader } from 'ui/RenderLoader'
import Slider from 'features/Carousel/Slider'
import SliderControl from 'ui/controls/SliderControl'
import Icon from 'ui/Icon'
import arrowRight from 'icons/basic-icons/arrowRight.svg'
import arrowLeft from 'icons/basic-icons/arrowLeft.svg'
import { byMedia, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import GameCard from 'features/GamesList/GameCard'
import Text from 'ui/Text'
import ReactTooltip from 'react-tooltip'
import React from 'react'
import styled, { css } from 'styled-components'
import Box from 'ui/Box'

const StyledBox = styled(Box)`
    position: relative;
`
const SlideWrapper = styled.div`
    display: block;
    padding: 5px;
    min-height: ${!detectMobile() ? '320px' : '250px'};
    transition: all 0.3s ease;

    ${({ disable }) =>
        !disable &&
        css`
            &:hover {
                transform: scale(1.01);
                cursor: pointer;
            }
        `};
`

const ImageOverlay = styled.a`
    display: block;
    position: relative;
    &:before {
        content: ' ';
        display: inline-block;
        background: black;
        opacity: 0.7;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: 1;
    }
`
const ImageText = styled(Text)`
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
`

const StyledWrapper = styled(Box)`
    position: relative;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 80px);
`

const onPage = byMedia({
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
})

const GamesSlider = ({
    title = '',
    allButton = false,
    gamesList = [],
    onClick,
    upcoming = false,
    loader = true,
    showFavoriteIcon = true,
}) => {
    const exclusiveGameLabel = (game) => {
        return game.exclusive === true || game.exclusive === '1'
            ? EXCLUSIVE_GAME_LABEL
            : null
    }

    const getWeekNumber = (date) =>
        moment(date, 'YYYY-MM-DD HH:mm:ss')
            .locale('da')
            .format('W')

    return (
        <StyledBox px={2}>
            <StyledWrapper>
                <Headline as={'h3'} capitalize color={COLORS.dark}>
                    {title}
                </Headline>
            </StyledWrapper>
            {loader ? (
                renderLoader()
            ) : gamesList.length ? (
                <Slider
                    renderNextBtn={(next) => (
                        <SliderControl right top={'1rem'} onClick={next}>
                            <Icon
                                src={arrowRight}
                                size={'xs'}
                                scale={0.9}
                                color={'black'}
                            />
                        </SliderControl>
                    )}
                    renderPrevBtn={(prev) => (
                        <SliderControl left top={'1rem'} onClick={prev}>
                            <Icon
                                src={arrowLeft}
                                size={'xs'}
                                scale={0.9}
                                color={'black'}
                            />
                        </SliderControl>
                    )}
                    perPage={onPage}
                    slidesToScroll={1}
                    infinite={true}
                    loop={false}
                    arrows={false}
                >
                    {!upcoming
                        ? gamesList.map((game, index) => (
                              <SlideWrapper
                                  key={index}
                                  onClick={(e) => onClick(game)}
                                  disable={!game.game_id}
                              >
                                  <GameCard
                                      game={game}
                                      vertical
                                      labelProps={exclusiveGameLabel(game)}
                                      gameClickHandler={() => onClick(game)}
                                  />
                                  <Text
                                      align={'center'}
                                      style={{ paddingTop: '5px' }}
                                  >
                                      {game.name ? game.name : ''}
                                  </Text>
                              </SlideWrapper>
                          ))
                        : gamesList.map((game, index) => (
                              <SlideWrapper
                                  key={index}
                                  onClick={() => onClick(game)}
                                  disable={!game.game_id}
                              >
                                  <ImageOverlay>
                                      <GameCard
                                          game={game}
                                          vertical
                                          labelProps={exclusiveGameLabel(game)}
                                          showInfoIcon={false}
                                          showFavoriteIcon={showFavoriteIcon}
                                      />
                                      {game.release_date &&
                                          !isNaN(
                                              getWeekNumber(game.release_date)
                                          ) && (
                                              <ImageText bold size={'md'}>
                                                  UDKOMMER I UGE{' '}
                                                  {getWeekNumber(
                                                      game.release_date
                                                  )}
                                              </ImageText>
                                          )}
                                  </ImageOverlay>
                                  <Text
                                      align={'center'}
                                      style={{ paddingTop: '5px' }}
                                  >
                                      {game.name ? game.name : ''}
                                  </Text>
                              </SlideWrapper>
                          ))}
                </Slider>
            ) : (
                <Text align={'center'} pt={3}>
                    Ingen match fundet.
                </Text>
            )}
            <ReactTooltip id={'game-info'} />
        </StyledBox>
    )
}

export default GamesSlider
