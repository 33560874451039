import React from 'react'
import styled from 'styled-components'
import Text from 'ui/Text'
import { Img } from '@it25syv/25syv-ui'

import mobilePay from 'images/icons/mobilepay.svg'
import creditcards from 'images/icons/creditcards.svg'
import { byPlatform } from 'common/adapters/DeviceDetectAdapter'

const DepositWrapper = styled.div`
    text-align: center;
    position: relative;
`

const DepositSubtitle = styled(Text)`
    color: #797979;
    font-size: 16px;
    text-align: center;
    line-height: 19px;
    margin: 10px 0 20px;
`

const PaymentTypesWrapper = styled.div`
    margin: 0 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 0 30px;
    border-bottom: 1px solid #e1e1e1;

    @media screen and (max-width: 768px) {
        justify-content: space-evenly;
    }

    @media screen and (max-width: 475px) {
        margin: 0;
    }
`

const PaymentTypeBtn = styled.div`
    border: 3px solid #cbcbcb;
    background: #ffffff;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 8px;

    &.active {
        border-color: #0daa13;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 475px) {
        border-radius: 3px;
        height: 48px;
    }
`

const PaymentTypeBtnTitle = styled.div`
    font-size: 10px;
    color: #333333;
    font-weight: 700;
    line-height: 14px;

    @media screen and (max-width: 768px) {
        font-size: 11px;
    }
`

const ApplePayBtn = styled(PaymentTypeBtn)`
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid ${({ active }) => (active ? '#0daa13' : '#cbcbcb')};

    @supports (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            width: 100%;
            height: 100%;
            -webkit-appearance: -apple-pay-button;
        }
        .apple-pay-button > * {
            display: none;
        }
        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }
        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }
        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    }
    @supports not (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            --apple-pay-scale: 1; /* (height / 32) */
            display: inline-flex;
            justify-content: center;
            font-size: 12px;
            border-radius: 5px;
            padding: 0px;
            box-sizing: border-box;
            min-width: 200px;
            min-height: 32px;
            max-height: 64px;
        }
        .apple-pay-button-black {
            background-color: black;
            color: white;
        }
        .apple-pay-button-white {
            background-color: white;
            color: black;
        }
        .apple-pay-button-white-with-line {
            background-color: white;
            color: black;
            border: 0.5px solid black;
        }
        .apple-pay-button.apple-pay-button-black > .logo {
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-color: black;
        }
        .apple-pay-button.apple-pay-button-white > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button.apple-pay-button-white-with-line > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button > .text {
            font-family: -apple-system;
            font-size: calc(1em * var(--apple-pay-scale));
            font-weight: 300;
            align-self: center;
            margin-right: calc(2px * var(--apple-pay-scale));
        }
        .apple-pay-button > .logo {
            width: calc(35px * var(--scale));
            height: 100%;
            background-size: 100% 60%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            margin-left: calc(2px * var(--apple-pay-scale));
            border: none;
        }
    }
`

const PaymentType = ({ payType, setPayType = (f) => f }) => {
    return (
        <DepositWrapper>
            <DepositSubtitle>Vælg betalingsmetode:</DepositSubtitle>
            <PaymentTypesWrapper>
                <PaymentTypeBtn
                    className={payType === 'mobilePay' && 'active'}
                    onClick={() => setPayType('mobilePay')}
                >
                    <Img
                        src={mobilePay}
                        alt="MobilePay"
                        height={byPlatform('29px', '32px')}
                    />
                </PaymentTypeBtn>
                <PaymentTypeBtn
                    className={payType === 'card' && 'active'}
                    onClick={() => setPayType('card')}
                >
                    <PaymentTypeBtnTitle>BETALINGKORT</PaymentTypeBtnTitle>
                    <Img src={creditcards} height={'18px'} alt="" />
                </PaymentTypeBtn>
                {window.ApplePaySession &&
                    ApplePaySession.canMakePayments() && (
                        <ApplePayBtn
                            className={payType === 'applepay' && 'active'}
                            onClick={() => setPayType('applepay')}
                        >
                            <div className="apple-pay-button apple-pay-button-white">
                                <span className="logo" />
                            </div>
                        </ApplePayBtn>
                    )}
            </PaymentTypesWrapper>
        </DepositWrapper>
    )
}

export default PaymentType
