import React from 'react'
import Slider from 'features/Carousel/Slider'
import SliderControl from 'ui/controls/SliderControl'
import Icon from 'ui/Icon'
import arrowRight from 'icons/basic-icons/arrowRight.svg'
import arrowLeft from 'icons/basic-icons/arrowLeft.svg'
import { SlideGamesWrapper, GameIndex } from 'features/ProvidersContent/styled'
import GameCard from 'features/GamesList/GameCard'
import Text from 'ui/Text'
import { byMedia, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { modalService } from 'services/ModalService'

const t = window.T

const ProviderGamesSlider = ({
    games,
    topGames = false,
    onpage = {
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
    },
}) => {
    const history = useHistory()

    const onPage = byMedia(onpage)
    const showControls = games.length > onPage
    const startGame = (game) => {
        if (game.game_id) {
            GamesService.removeMultipleGames()

            if (
                (game.liveCas || game.category === 'liveint') &&
                (localStorage.getItem('logged') === 'false' ||
                    localStorage.getItem('logged') === null)
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
                return
            }

            if (
                (localStorage.getItem('logged') === 'false' ||
                    localStorage.getItem('logged') === null) &&
                detectMobile()
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
            }

            GamesService.checkLoginBeforeStart(game, history)

            if (
                localStorage.getItem('logged') !== null &&
                localStorage.getItem('logged') !== 'false'
            ) {
                GamesService.pushGameToRecentlyPlayed(game)
            }
        }
    }

    return (
        <Slider
            renderNextBtn={(next) =>
                showControls && (
                    <SliderControl
                        right
                        onClick={next}
                        top={topGames ? '12px' : '44px'}
                    >
                        <Icon
                            src={arrowRight}
                            size={'xs'}
                            scale={0.9}
                            color={'black'}
                        />
                    </SliderControl>
                )
            }
            renderPrevBtn={(prev) =>
                showControls && (
                    <SliderControl
                        left
                        onClick={prev}
                        top={topGames ? '12px' : '44px'}
                    >
                        <Icon
                            src={arrowLeft}
                            size={'xs'}
                            scale={0.9}
                            color={'black'}
                        />
                    </SliderControl>
                )
            }
            perPage={onPage}
            slidesToScroll={1}
            infinite={true}
            loop={true}
            autoChangeTime={5000}
        >
            {games.map((game, index) => (
                <SlideGamesWrapper
                    key={game.name}
                    onClick={() => startGame(game)}
                    disable={!game.game_id}
                    topGames={topGames}
                >
                    {topGames && <GameIndex>{++index}</GameIndex>}
                    <GameCard
                        key={`game-card-${game.name}`}
                        game={game}
                        vertical={!topGames}
                        labelProps={
                            !topGames && {
                                background: '#011E36',
                                textColor: '#31A8FF',
                                text: 'NYT',
                            }
                        }
                        gameClickHandler={() => startGame(game)}
                    />
                    <Text align={'center'} style={{ paddingTop: '5px' }}>
                        {game.name ? game.name : ''}
                    </Text>
                </SlideGamesWrapper>
            ))}
        </Slider>
    )
}

export default ProviderGamesSlider
