import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Box from 'ui/Box'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import Displayer from 'ui/Displayer'
import WithGames from 'common/Hocs/WithGames'
import { casinoFilters } from 'configs/categories'
import MediaGrid from 'ui/MediaGrid'
import recently from 'images/icons/basic-icons/return.svg'
import recommended from 'images/icons/basic-icons/recommended.svg'
import nyheder from 'images/icons/basic-icons/nyheder.svg'
import like from 'images/icons/basic-icons/like.svg'
import tableGames from 'images/icons/basic-icons/tableGames.svg'
import {
    byPlatform,
    detectIpad,
    detectMobile,
} from 'common/adapters/DeviceDetectAdapter'
import { renderLoader } from 'ui/RenderLoader'
import { modalService } from 'services/ModalService'
import LoaderExposer from 'features/GamesList/LoaderExposer'
import SearchResult from 'ui/SearchResult'
import { depositAction } from 'services/DepositService'
import GamesService from 'services/GamesService'
import GamesGridTitle from 'ui/GamesGridTitle'
import { signupHelper } from 'utils/signupHelper'
import { sortingByHotClicks } from 'utils/sorting'

import HighlightSlider from 'features/Highlight/HighlightSlider'
import GameList from 'features/GamesList/GameList'
import ProductTransition from '@/layouts/sections/Footer/ProductTransition'

// const HighlightSlider = lazy(() => import('features/Highlight/HighlightSlider'))
// const GameList = lazy(() => import('features/GamesList/GameList'))
// const ProductTransition = lazy(() =>
//     import('@/layouts/sections/Footer/ProductTransition')
// )

const HomePage = ({
    theme,
    searchText = '',
    gamesToMap = [],
    userProfile = {},
    isGamesReceived,
    geoData,
    recentlyGames,
}) => {
    const [gamesToRender, setGamesToRender] = useState([])
    const [liveCasinoGames, setLiveCasinoGames] = useState([])
    const [gamesByCategories, setGamesByCategories] = useState([])
    const [topPopularGames, setTopPopularGames] = useState([])
    const [popularGames, setPopularGames] = useState([])
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()

    const TOP_GAMES_LIMIT = 5
    const POPULAR_GAMES_LIMIT = 8

    const mapLiveCasinoGames = (casinoGames) => {
        if (casinoGames && casinoGames.length) {
            const games = casinoGames.filter(
                (g) => g.liveCas && g.liveCas.open !== false
            )
            return sortingByHotClicks(games)
        }
    }

    useEffect(
        () => {
            if (location.pathname.indexOf('/promo') > -1) {
                setTimeout(() => {
                    window.location.href = `https://www.promo.bet25.dk/casino/${
                        window.location.pathname.split('/').slice(-1)[0]
                    }`
                }, 0)
            }
            if (location.pathname.indexOf('/mitid-verify/confirm') > -1) {
                setTimeout(() => {
                    modalService.showModal('CPR_CONFIRMATION', {
                        initialProps: { modalTopMargin: '0' },
                        idp: match.params.type,
                    })
                }, 0)
            }
            if (location.pathname.indexOf('/mitid-verify/success') > -1) {
                signupHelper(match, history)
            }
            if (location.state?.deposit) {
                const { url, status } = location.state
                const parsedUrl = new URL(url)
                const amount = parsedUrl.searchParams.get('amount')
                const reason = parsedUrl.searchParams.get('reason')
                depositAction(status, amount, reason)
                history.replace({
                    ...history.location,
                    state: { deposit: false },
                })
            }
        },
        [location]
    )

    useEffect(
        () => {
            setGamesToRender(gamesToMap)
        },
        [gamesToMap]
    )

    useEffect(
        () => {
            if (geoData) {
                if (!geoData.allowed) {
                    modalService.showModal('GEO_DATA')
                } else {
                    if (
                        history.location.pathname === '/opret-konto' ||
                        history.location.pathname === '/opret-bruger'
                    ) {
                        setTimeout(() => {
                            modalService.showModal('SIGN_UP', {
                                initialProps: {
                                    modalTopMargin: '0',
                                    ...byPlatform('', { autoScroll: true }),
                                },
                            })
                        }, 0)
                    } else if (history.location.pathname === '/log-ind') {
                        modalService.showModal('LOGIN', {
                            initialProps: { modalTopMargin: '0' },
                        })
                    } else if (
                        history.location.pathname === '/testing/log-ind'
                    ) {
                        modalService.showModal('LOGIN', {
                            initialProps: { modalTopMargin: '0' },
                            isMitId: false,
                        })
                    } else if (
                        history.location.pathname ===
                        '/mitid-verify/forgotpassword'
                    ) {
                        if (Object.keys(userProfile).length) {
                            modalService.showModal('RESTORE_PASS', {
                                userProfile: userProfile,
                                initialProps: { modalTopMargin: '0' },
                            })
                        } else {
                            history.push('/404')
                        }
                    }
                }
            }
        },
        [geoData?.allowed]
    )

    useEffect(() => {
        const gamesFeaturesSub = GamesService.getGamesByCategories().subscribe(
            (res) => {
                const filteredGames = res.filter((el) => el.frontpage)
                setGamesByCategories(filteredGames)
            }
        )

        const liveGamesSub = GamesService.getLiveCasinoGames().subscribe(
            (res) => {
                setLiveCasinoGames(mapLiveCasinoGames(res))
            }
        )

        GamesService.getPopularGames({
            limit: TOP_GAMES_LIMIT,
            period: 1,
            excludeHot: true,
        })
            .then((res) => {
                if (res.length) {
                    setTopPopularGames(res)
                }
            })
            .catch((e) => console.error(e))
        GamesService.getPopularGames({
            limit: POPULAR_GAMES_LIMIT,
            period: 7,
            rodHot: true,
        })
            .then((res) => {
                if (res.length) {
                    setPopularGames(sortingByHotClicks(res))
                }
            })
            .catch((e) => console.error(e))
        return () => {
            liveGamesSub.unsubscribe()
            gamesFeaturesSub.unsubscribe()
        }
    }, [])

    return (
        <PageWrapper>
            <Suspense fallback={renderLoader('100px')}>
                <HighlightSlider
                    theme={theme}
                    loop={detectMobile() ? false : true}
                />

                <PageContent px={3} pb={4} maxWidth={'1140px'}>
                    {searchText.length !== 0 && (
                        <Displayer>
                            <SearchResult align={'center'} pt={3}>
                                {gamesToRender.length} spil fundet
                            </SearchResult>
                            <Box my={3}>
                                <MediaGrid>
                                    <GameList
                                        games={gamesToRender}
                                        limit={20}
                                    />
                                </MediaGrid>
                            </Box>
                        </Displayer>
                    )}

                    {!!gamesByCategories.length &&
                        gamesByCategories.map(
                            (category) =>
                                category.games.length ? (
                                    <GamesGridTitle
                                        key={category.key + '-frontpage'}
                                        icon={category.icon}
                                        title={category.title}
                                        buttonLink={`/automater/kategorier/sort?key=${
                                            category.categoryName
                                        }&value=${category.key}`}
                                    >
                                        <MediaGrid>
                                            <GameList
                                                games={category.games}
                                                limit={8}
                                            />
                                        </MediaGrid>
                                    </GamesGridTitle>
                                ) : null
                        )}
                    <Displayer
                        display={
                            gamesToRender.length && !searchText
                                ? 'block'
                                : 'none'
                        }
                    >
                        {!!topPopularGames.length && (
                            <GamesGridTitle
                                icon={recommended}
                                title={'DAGENS TOP 5'}
                            >
                                <MediaGrid itemsPerRow={5}>
                                    <GameList
                                        games={topPopularGames}
                                        limit={TOP_GAMES_LIMIT}
                                    />
                                </MediaGrid>
                            </GamesGridTitle>
                        )}
                        <GamesGridTitle
                            icon={nyheder}
                            title={'Nye spil'}
                            buttonLink={'/automater/nyheder'}
                        >
                            <MediaGrid>
                                <GameList
                                    games={gamesToRender
                                        .filter(casinoFilters.new)
                                        .sort(
                                            (a, b) => a.priority - b.priority
                                        )}
                                    limit={12}
                                />
                            </MediaGrid>
                        </GamesGridTitle>
                        <GamesGridTitle
                            icon={like}
                            title={'Populære automater'}
                            buttonLink={'/automater/populære'}
                        >
                            <MediaGrid>
                                <GameList games={popularGames} limit={8} />
                            </MediaGrid>
                        </GamesGridTitle>
                        <GamesGridTitle
                            icon={tableGames}
                            title={'Live casino'}
                            buttonLink={'/live-casino/alle'}
                        >
                            <MediaGrid>
                                <GameList games={liveCasinoGames} limit={8} />
                            </MediaGrid>
                        </GamesGridTitle>
                    </Displayer>
                    <LoaderExposer
                        items={gamesToRender}
                        isItemsReceived={Boolean(isGamesReceived)}
                    />

                    {detectMobile() && <ProductTransition theme={theme} />}
                </PageContent>
            </Suspense>
        </PageWrapper>
    )
}

export default withTheme(WithGames(HomePage))
