import React, { Fragment } from 'react'
import {
    Amount,
    CardWrapper,
    Title,
    TopWrapper,
} from 'features/Jackpots/styled'
import Text from 'ui/Text'
import { numberWithCommasAndDots } from 'utils/formatters'
import HotLabel from 'features/Jackpots/HotLabel'
import { renderLoader } from 'ui/RenderLoader'

const JackpotCard = ({
    item = {},
    handleScroll = () => {},
    gamesAmount = 0,
}) => {
    const {
        background = '',
        title = '',
        color = '',
        amount = 0,
        closeToWin = false,
    } = item

    return (
        <CardWrapper
            flexDirection={'column'}
            align={'start'}
            onClick={() => handleScroll()}
        >
            <TopWrapper
                flexDirection={'column'}
                align={'start'}
                backgroundImage={background}
            >
                <Title color={'#fff'}>{title}</Title>
                <Text size={'xs'} condensed color={'#fff'} opacity={'0.7'}>
                    {`${gamesAmount} spil`}
                </Text>
                {closeToWin && <HotLabel />}
            </TopWrapper>
            <Amount size={'xl'} color={color}>
                {amount <= 0 ? (
                    renderLoader()
                ) : (
                    <Fragment>
                        {numberWithCommasAndDots(amount)} <span>kr.</span>
                    </Fragment>
                )}
            </Amount>
        </CardWrapper>
    )
}

export default JackpotCard
