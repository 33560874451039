import { isMobile, isIPad13 } from 'react-device-detect'
import { BREAKPOINTS } from '@/themes/primary/breakpoints'

export const detectMobileByWidth = () => window.innerWidth < BREAKPOINTS.lg

export const detectMobileByAgent = () => isMobile

export const detectMobile = () => isMobile || isIPad13
export const detectIpad = () => isIPad13

export const byPlatform = (mobileCase, desktopCase) =>
    detectMobile() && !detectIpad() ? mobileCase : desktopCase

export const detectMobByScreen = (screenSize = BREAKPOINTS.md) =>
    screenSize >= window.innerWidth

export const getPlatformForApi = () =>
    !detectMobile() || detectIpad() ? 'web' : 'mobile'

export const byMedia = (settings) => {
    let payload = settings['sm']

    if (window.innerWidth >= BREAKPOINTS['sm']) {
        payload = settings['md']
    }

    if (window.innerWidth >= BREAKPOINTS['md']) {
        payload = settings['lg']
    }

    if (window.innerWidth >= BREAKPOINTS['lg']) {
        payload = settings['xl']
    }

    return payload
}

export const forMobile = (content) => (detectMobile() ? content : null)

export const forDesktop = (content) => (detectMobile() ? null : content)
