import React, { useState, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import { pipe } from 'rxjs'
import DepositBody from 'features/Deposit/Components/DepositBody'
import OperationTypeSwitcher from 'features/Deposit/Components/OperationTypeSwitcher'
import UserAdapter from 'common/adapters/UserAdapter'
import Loader from 'ui/Loader'

const t = window.T

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
    background: #f9f9f9;
`

const Index = ({ theme }) => {
    const [bonusList, setBonusList] = useState([])
    const [maxDeposit, setMaxDeposit] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        UserAdapter.getDepositBonuses()
            .then((bonuses) => {
                setBonusList(bonuses)
                setLoading(false)
            })
            .catch(console.error)
            .finally(() => setLoading(false))
        UserAdapter.getDepositLimits()
            .then((data) => setMaxDeposit(data.maxDeposit))
            .catch(console.error)
            .finally(() => setLoading(false))
    }, [])

    return (
        <Wrapper>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    {t.userArea.bank.title}
                </Text>
            </ModalTopBar>
            <Flex background={theme.primary} pb={3} px={3}>
                <OperationTypeSwitcher active={'DEPOSIT'} />
            </Flex>

            {loading ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : (
                <DepositBody
                    bonusList={bonusList}
                    theme={theme}
                    loading={loading}
                    maxDeposit={maxDeposit}
                />
            )}
        </Wrapper>
    )
}

export default pipe(
    withAnimation,
    withTheme
)(Index)
