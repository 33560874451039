import React, { Fragment } from 'react'
import DefaultText from 'ui/Text'
import Box from 'ui/Box'
import styled from 'styled-components'
import { PALETTE } from '@/themes'
import { formatDate } from 'utils/dateTime'

const StyledBox = styled(Box)`
    margin: 10px 0 0;
`

const Text = styled(DefaultText)`
    padding: 0;
    line-height: unset;

    a {
        color: ${PALETTE.azure};
        text-decoration: none;
    }
`

const Ul = styled(Text.withComponent('ul'))`
    padding: 0 0 0 15px;
    margin: 10px 0 0;
`

const Li = styled.li`
    line-height: 1.3;
`

const CurrentlyExcluded = ({ exclusionTime, permanent = false }) => {
    const dateTime = formatDate(exclusionTime)

    return (
        <Fragment>
            {exclusionTime && (
                <StyledBox>
                    <Text>
                        Din konto er nu lukket til: <strong>{dateTime}</strong>
                    </Text>
                </StyledBox>
            )}
            <StyledBox>
                <Text>
                    Du har valgt at selvudelukke dig fra din konto.{' '}
                    {permanent && (
                        <strong>
                            Din eventuelle balance vil blive udbetalt automatisk
                            til din Nemkonto.
                            <br />
                        </strong>
                    )}{' '}
                    Skulle du have spørgsmål, kan du kontakte vores support. Har
                    du spilleproblemer, eller er i tvivl om du har
                    spilleproblemer så kan du henvende dig til{' '}
                    <strong>Center for ludomani</strong> på telefon:{' '}
                    <a href="tel:70-11-18-10">70 11 18 10</a> eller e-mail på{' '}
                    <a href="mailto:info@ludomani.dk">info@ludomani.dk</a>. Du
                    kan læse mere på om ludomani på{' '}
                    <a href="https://ludomani.dk/" target="_blank">
                        ludomani.dk
                    </a>{' '}
                    hvor du også kan teste dig selv for ludomani. Du kan også
                    besøge Center for Ludomani på følge adresser:
                </Text>
            </StyledBox>

            <Ul>
                <Li>
                    Center for ludomani i København Fiolstræde 17B st tv. 1171
                    København
                </Li>
                <Li>Center for ludomani i Odense Østergade 42 5000 Odense</Li>
                <Li>
                    Center for ludomani i Århus Gammel Munkegade 6D 8000 Århus C
                </Li>
            </Ul>

            <StyledBox>
                <Text>
                    Du kan ligeledes henvende dig til Frederiksberg Centeret på
                    telefon: <a href="tel:33-21-73-00">33 21 73 00</a> eller
                    e-mail på{' '}
                    <a href="mailto:info@frederiksberg-centeret.dk">
                        info@frederiksberg-centeret.dk
                    </a>{' '}
                    eller læse mere på{' '}
                    <a
                        href="https://frederiksberg-centeret.dk/ludomani/"
                        target="_blank"
                    >
                        www.frederiksberg-centeret.dk/ludomani/
                    </a>
                </Text>
            </StyledBox>

            <Ul>
                <Li>
                    Frederiksberg Centeret - København Afdeling Esplanaden 8C,
                    2. sal 1263 København K.
                </Li>
            </Ul>
        </Fragment>
    )
}

export default CurrentlyExcluded
