import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

export const FixedWrapper = styled.div`
    position: fixed;
    background: ${(props) => props.theme.light};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
`

const Success = () => {
    const history = useHistory()
    useEffect(() => {
        let message = JSON.stringify({
            type: 'custom-event',
            status: 'success',
            url: window.location.href,
        })

        window.parent.postMessage(message, '*')
        history.push('/', {
            deposit: true,
            status: 'success',
            url: window.location.href,
        })
    }, [])

    return <FixedWrapper />
}

export default Success
