import React, { useEffect } from 'react'
import HomePage from 'scenes/HomePage'
import { modalService } from 'services/ModalService'
import UserAdapter from 'common/adapters/UserAdapter'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const LobbyPage = () => {
    useEffect(() => {
        UserAdapter.getProfile()
            .then((res) => {
                if (res.userId) {
                    setTimeout(() => {
                        modalService.showModal('DEPOSIT')
                    }, 10)
                } else {
                    modalService.showModal('LOGIN', {
                        initialProps: { modalTopMargin: '0' },
                    })
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    return <HomePage />
}

export default LobbyPage
