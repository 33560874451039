import React, { useEffect } from 'react'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import { useHistory } from 'react-router-dom'

const LobbyPage = () => {
    const history = useHistory()

    useEffect(() => {
        history.push('/')
        window.parent.postMessage('game-close', '*')
    }, [])

    return (
        <PageWrapper style={{ background: 'black' }}>
            <PageContent px={3} style={{ height: '100vh' }} />
        </PageWrapper>
    )
}

export default LobbyPage
