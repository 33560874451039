import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GameCard from 'features/GamesList/GameCard'
import closeIcon from 'icons/basic-icons/close-light.svg'
import closeRoundIcon from 'icons/basic-icons/info-round.svg'
import Icon from 'ui/Icon'
import { modalService } from 'services/ModalService'
import { SMART_FILTERS } from 'configs/categories'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import IconVolatility from 'features/GameInfo/VolatilityIcon'
import {
    CharsTableCol,
    CharsTableRow,
    CharsTableTitle,
    CharsTableValue,
    CloseButton,
    Divider,
    FooterModal,
    Headline,
    MainWrapperModal,
    StyledFavoriteButton,
    SubTitle,
    Title,
    Tooltip,
    WrapperModal,
} from 'features/GameInfo/styled'
import { GAME_INFO_DATA } from 'features/GameInfo/gameInfoConf'
import { PROVIDERS } from 'common/providers'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import FavoriteButton from 'features/FavoriteButton'
import COLORS from 'themes/primary/palette'

const GameInfo = (props) => {
    const { game, gameClickCallback } = props.data
    const history = useHistory()
    const [close, setClose] = useState(false)
    const childRef = useRef()

    const openCategoryPage = (key, value) => {
        closeModal()
        history.push(`/automater/kategorier/sort?key=${key}&value=${value}`)
    }

    const filterPropertyName = (name) => {
        const el = SMART_FILTERS.find((f) => f.name === name)
        return el ? el.label : null
    }

    const closeModal = () => {
        setClose(true)
        setTimeout(() => {
            modalService.closeModal('GAME_INFO')
        }, 300)
    }

    const handleOnClickFavorite = (event) => {
        event.preventDefault()
        event.stopPropagation()
        childRef?.current?.toggleFavorite(event)
    }

    const replaceValue = (item, game) => {
        let value = game[item.name]

        if (item.name === 'subprovider') {
            const provider = PROVIDERS.find(
                (obj) => obj.value === game[item.name]
            )
            if (provider && provider.label) {
                value = provider.label
            }
        }

        return item.valueReplace ? item.valueReplace[value] : value
    }

    const toggleIntercom = (isShow) => {
        const intercomNode = document.querySelectorAll(
            '.intercom-namespace,.intercom-lightweight-app'
        )
        if (intercomNode.length) {
            ;[...intercomNode].map(
                (node) => (node.style.display = isShow ? 'block' : 'none')
            )
        }
    }

    useEffect(() => {
        toggleIntercom(false)

        return () => {
            toggleIntercom(true)
        }
    }, [])

    return (
        <MainWrapperModal
            close={close}
            key={`close-animation-${close}`}
            mob={detectMobile()}
        >
            <WrapperModal>
                <Headline>
                    <Title>{game.name}</Title>
                    <CloseButton onClick={() => closeModal()} color="grey">
                        <Icon src={closeIcon} size={'md'} />
                    </CloseButton>
                </Headline>
                <GameCard
                    key={game.id}
                    game={game}
                    showInfoIcon={false}
                    showFavoriteIcon={false}
                />
                <CharsTableCol>
                    {GAME_INFO_DATA.map(
                        (el, index) =>
                            replaceValue(el, game) && (
                                <CharsTableRow key={index}>
                                    <CharsTableTitle>
                                        {el.title}
                                        {el.tooltipText && (
                                            <Icon
                                                src={closeRoundIcon}
                                                size={'md'}
                                                data-html={true}
                                                data-tip={el.tooltipText}
                                                data-place={
                                                    el.tooltipPosition
                                                        ? el.tooltipPosition
                                                        : 'left'
                                                }
                                                data-type={'light'}
                                                data-effect={'solid'}
                                                data-offset={
                                                    el.tooltipPosition ===
                                                    'right'
                                                        ? "{'right': 10}"
                                                        : "{'left': 10}"
                                                }
                                                data-border={true}
                                                data-for={'game-info-tooltip'}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )}
                                    </CharsTableTitle>
                                    <CharsTableValue>
                                        {el.name === 'feature' && (
                                            <IconVolatility
                                                level={game[el.name]}
                                            />
                                        )}
                                        {`${replaceValue(el, game)} ${
                                            el.units
                                        }`}
                                    </CharsTableValue>
                                </CharsTableRow>
                            )
                    )}
                </CharsTableCol>

                {game.features &&
                game.features.length &&
                game.features[0] !== '' ? (
                    <>
                        <Wrapper padding="0">
                            <SubTitle>Funktioner</SubTitle>
                            {game.features.map(
                                (el) =>
                                    filterPropertyName(el) && (
                                        <Button
                                            color={'#111111'}
                                            background={'#F5F7FC'}
                                            variant={'custom'}
                                            bold
                                            size={'smB'}
                                            m={1}
                                            key={el}
                                            onClick={() => {
                                                openCategoryPage('features', el)
                                            }}
                                        >
                                            {filterPropertyName(el)}
                                        </Button>
                                    )
                            )}
                        </Wrapper>
                        <Divider />
                    </>
                ) : null}

                {game.theme && game.theme !== '' ? (
                    <Wrapper padding="0">
                        <SubTitle>Temaer</SubTitle>
                        {game.theme.split('|').map(
                            (el) =>
                                filterPropertyName(el) && (
                                    <Button
                                        color={'#111111'}
                                        background={'#F5F7FC'}
                                        variant={'custom'}
                                        bold
                                        size={'smB'}
                                        m={1}
                                        key={el}
                                        onClick={() => {
                                            openCategoryPage('theme', el)
                                        }}
                                    >
                                        {filterPropertyName(el)}
                                    </Button>
                                )
                        )}
                    </Wrapper>
                ) : null}
                <Tooltip id={'game-info-tooltip'} />
            </WrapperModal>
            <FooterModal>
                <Button
                    block
                    variant={'danger'}
                    size={'mdB'}
                    onClick={() => {
                        closeModal()
                        gameClickCallback()
                    }}
                >
                    Start spil
                </Button>
                <StyledFavoriteButton
                    block
                    size={'mdB'}
                    ml={3}
                    onClick={handleOnClickFavorite}
                >
                    <FavoriteButton
                        game={game}
                        iconColor={COLORS.dark}
                        ref={childRef}
                    />
                </StyledFavoriteButton>
            </FooterModal>
        </MainWrapperModal>
    )
}

export default GameInfo
