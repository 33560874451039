import styled, { css } from 'styled-components'
import { marginProps, paddingProps } from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const sizes = {
    xs: 0.67,
    sm: 0.83,
    md: 1.125,
    lg: 1.75,
    xl: 2.25,
    xxl: 3,
}

/**
 *
 * @param size {string}
 * @param scale {int} Use this number to increase default dimensions
 * @return {string}
 */
const getSize = (size, scale = 1) => {
    let dim = scale * sizes[size]

    return `
        width: ${dim}rem;
        height: ${dim}rem;
    `
}

const Img = styled.img.attrs((props) => ({
    src: props.src,
    alt: props.alt ? props.alt : props.src,
}))`
    ${marginProps};
    ${paddingProps};
    ${(props) =>
        props.size
            ? getSize(props.size, props.scale ? props.scale : 1)
            : css`
                  width: ${(props) => (props.width ? props.width : 'auto')};
                  height: ${(props) => (props.height ? props.height : 'auto')};
              `};
`

Img.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
}

Img.defaultProps = {}

export default Img
