import React from 'react'
import styled, { keyframes } from 'styled-components'
import { marginProps } from '@/ui/SpacingUtility'
import { PALETTE } from 'themes/index'

const bounceDelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

const Bounce = styled.div`
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 3px;
    background: ${(props) =>
        props.background ? props.background : PALETTE.darkGray};

    border-radius: 100%;
    -webkit-animation: ${bounceDelay} 1.4s infinite ease-in-out both;
    animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`

const Wrapper = styled.div`
    ${marginProps};
    display: inline-block;
    text-align: center;
    & > :first-child {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    & > :nth-child(2) {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
`

const Loader = ({ dotsNumber = 3, color = PALETTE.darkGray, m }) => {
    return (
        <Wrapper m={m}>
            {[...Array(dotsNumber).keys()].map((value, index) => (
                <Bounce background={color} key={index} />
            ))}
        </Wrapper>
    )
}

export default Loader
