import { toast } from 'react-toastify'

class NotificationConductor {
    static NotificationDuration = 10000

    constructor() {
        this.handler = toast
    }

    success(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.success(message, { autoClose: duration })
    }

    info(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.info(message, { autoClose: duration })
    }

    warning(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.warning(message, { autoClose: duration })
    }

    error(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.error(message, { autoClose: duration })
    }
}

export default new NotificationConductor()
