import React from 'react'
import Text from 'ui/Text'
import styled, { withTheme } from 'styled-components'
import { PALETTE } from '@/themes'
import pageNotFound from 'images/404.png'
import Button from 'ui/controls/Button'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { useHistory } from 'react-router-dom'

const t = window.T
const screenSizes = {
    xxxl: 1720,
    xxl: 1570,
}

const PageWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
`

const PageBG = styled.div`
    width: 30%;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        width: 100%;
    }
    &:before {
        content: '';
        display: block;
        border-top: 100vh solid ${PALETTE.primary};
        border-left: 0 solid transparent;
        border-right: 250px solid transparent;
        width: 100%;
        @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
            display: none;
        }
    }
    &:after {
        content: '';
        background: url(${pageNotFound}) no-repeat center / contain;
        width: 950px;
        max-width: 950px;
        height: 100%;
        position: absolute;
        right: -510px;
        top: 0;
        @media (max-width: ${screenSizes.xxxl}px) {
            width: 700px;
            right: -410px;
        }
        @media (max-width: ${screenSizes.xxl}px) {
            width: 500px;
            right: -320px;
        }
        @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
            position: initial;
            display: block;
            width: 500px;
            height: 500px;
            top: initial;
            margin: auto;
            right: initial;
        }
        @media (max-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
            width: 300px;
            height: 300px;
        }
    }
`

const PageContent = styled.div`
    margin: 0 100px 0 400px;
    padding: 300px 0 0 0;
    max-width: 700px;
    @media (max-width: ${screenSizes.xxl}px) {
        margin-left: 300px;
        p {
            font-size: 1.375rem;
        }
    }
    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        padding: 0 50px 100px;
        margin: auto;
        max-width: 500px;
        p {
            font-size: 1rem;
        }
    }
`

const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px 0 0;
    @media (max-width: ${screenSizes.xxl}px) {
        padding: 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.xl + 'px'}) {
        flex-direction: column;
        justify-content: center;
    }
`

const CustomButton = styled(Button)`
    width: 280px;
    margin-bottom: 20px;

    @media (max-width: ${screenSizes.xxxl}px) {
        width: 260px;
    }
    @media (max-width: ${screenSizes.xxl}px) {
        margin-right: 20px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.xl + 'px'}) {
        margin-right: 0;
    }
`

const PageHeading = styled.h2`
    color: ${PALETTE.primary};
    font-size: 120px;
    margin: 0;

    @media (max-width: ${screenSizes.xxl}px) {
        font-size: 80px;
    }
`

const Page404 = (props) => {
    const history = useHistory()

    return (
        <PageWrapper>
            <PageBG />

            <PageContent>
                <PageHeading>Ups!</PageHeading>
                <Text mt={3} mb={5} size={'xl'}>
                    Det ser ud til, at du er faret vild. Den side, som du leder
                    efter er enten flyttet, slettet eller findes ikke længere.
                </Text>
                <ButtonsArea>
                    <CustomButton
                        size={'lgB'}
                        onClick={() => history.push('/')}
                        secondary
                    >
                        Gå til forsiden
                    </CustomButton>
                    <CustomButton
                        size={'lgB'}
                        onClick={() => IntercomAdapter.show()}
                        variant={'light'}
                    >
                        Kontakt kundeservice
                    </CustomButton>
                </ButtonsArea>
            </PageContent>
        </PageWrapper>
    )
}

export default withTheme(Page404)
