import { BehaviorSubject } from 'rxjs'

const filters = new BehaviorSubject([])
const tempFilters = new BehaviorSubject([])

const orFiltering = (games, filters) => {
    let gamesFiltered = [...games]

    filters.forEach((filter) => {
        const filterCallback = (game) => filter.value.includes(game[filter.key])

        gamesFiltered = gamesFiltered.filter(
            filter.customFilter
                ? filter.customFilter.bind(filter)
                : filterCallback
        )
    })

    return gamesFiltered
}

const reduceFilters = (filters) => {
    let reducedFilters = []

    const uniqueKeys = [...new Set(filters.map((f) => f.key))]

    uniqueKeys.forEach((k) => {
        let joinedFilerByKey = {
            key: k,
            value: [],
        }

        filters.forEach((f) => {
            if (f.key === k) {
                joinedFilerByKey.customFilter = f.customFilter
                joinedFilerByKey.value.push(f.value)
            }
        })

        reducedFilters.push(joinedFilerByKey)
    })

    return reducedFilters
}

export default {
    filterGames: (games, filters) => {
        let gamesToShow = orFiltering(games, reduceFilters(filters))

        return gamesToShow
    },

    setFilters: (filterValues) => {
        filters.next(filterValues)
    },
    setTempFilters: (filterValues) => {
        tempFilters.next(filterValues)
    },

    getFilters: () => filters.asObservable(),
    getTempFilters: () => tempFilters.asObservable(),
}
