import React from 'react'
import styled, { css } from 'styled-components'
import Box from 'ui/Box'
import Text from 'ui/Text'
import {
    EXCLUSION_TYPE_24_H,
    EXCLUSION_TYPE_MONTH,
    EXCLUSION_TYPE_PERMANENT,
} from 'common/adapters/UserAdapter'

const t = window.T

const PeriodButton = styled.button`
    width: 100%;
    background: #f1f1f1;
    border: 1px solid #33333340;
    border-radius: 3px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    font-size: 16px;

    ${(props) =>
        props.selected &&
        css`
            background: ${props.theme.dark};
            color: ${props.theme.light};
        `};
`

const ButtonLable = styled(Text)`
    color: ${(props) => props.theme.gray};
`

const PeriodButtons = ({ clickHandler, curPeriod }) => {
    return (
        <Box>
            <PeriodButton
                selected={curPeriod === EXCLUSION_TYPE_24_H}
                variant={'lightHollow'}
                onClick={() => {
                    clickHandler(EXCLUSION_TYPE_24_H)
                }}
            >
                {t.userArea.selfExclusion.hours24}
                <br />
                <ButtonLable size={'sm'} align={'center'}>
                    Spillepause
                </ButtonLable>
            </PeriodButton>
            {/*<PeriodButton*/}
            {/*   */}
            {/*    secondary={curPeriod === 'days'}*/}
            {/*    onClick={() => {*/}
            {/*        clickHandler('days')*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {t.userArea.selfExclusion.days7}*/}
            {/*</PeriodButton>*/}
            <PeriodButton
                variant={'lightHollow'}
                selected={curPeriod === EXCLUSION_TYPE_MONTH}
                onClick={() => {
                    clickHandler(EXCLUSION_TYPE_MONTH)
                }}
            >
                {t.userArea.selfExclusion.month}
                <br />
                <ButtonLable size={'sm'} align={'center'}>
                    Midlertidlig udelukkelse
                </ButtonLable>
            </PeriodButton>
            <PeriodButton
                variant={'lightHollow'}
                selected={curPeriod === EXCLUSION_TYPE_PERMANENT}
                onClick={() => {
                    clickHandler(EXCLUSION_TYPE_PERMANENT)
                }}
            >
                {t.userArea.selfExclusion.permanent}
                <br />
                <ButtonLable size={'sm'} align={'center'}>
                    Ubegrænset Udelukkelse
                </ButtonLable>
            </PeriodButton>
        </Box>
    )
}

export default PeriodButtons
