import styled, { css } from 'styled-components'
import { marginProps, paddingProps } from '@/ui/SpacingUtility'
import React from 'react'

const StyledHeadline = styled.h1`
    margin: 1rem 0;
    ${marginProps};
    ${paddingProps};
    color: ${(props) => (props.color ? props.color : 'inherit')};
    text-align: ${(props) => props.align || 'left'};
    font-weight: ${(props) => (props.normal ? 500 : 900)};
    ${(props) => (props.height ? `height: ${props.height}` : '')};
    ${(props) =>
        props.short
            ? `text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;`
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
    ${(props) => props.condensed && 'font-family: Roboto Condensed'};
    ${(props) =>
        props.capitalize &&
        css`
            text-transform: capitalize;
        `};

    span {
        color: ${(props) => (props.colorLabel ? props.colorLabel : 'inherit')};
    }
`

const Headline = (props) => {
    return (
        <StyledHeadline {...props}>
            {props.children} {props.label && <span>{props.label}</span>}
        </StyledHeadline>
    )
}

export default Headline
