import styled from 'styled-components'
import Img from 'ui/Img'
import Headline from 'ui/Headline'
import React from 'react'
import Box from 'ui/Box'
import { Card } from 'ui/Card'

const BannerContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
    position: relative;
    color: ${(props) => props.color};
    max-height: 400px;
`

const CardImg = styled(Img)`
    position: relative;
    bottom: -8px;
    left: 0;
`

const CardRegular = ({
    src,
    title = '',
    description,
    children,
    color = 'white',
    background,
    theme,
    p,
    px,
}) => {
    return (
        <Card background={background}>
            <BannerContainer>
                <CardImg src={src} height={'100px'} />
                <Box p={p} px={px}>
                    <Headline as={'h4'} color={theme.light} mb={1}>
                        {title}
                    </Headline>
                    {description}
                    {children}
                </Box>
            </BannerContainer>
        </Card>
    )
}

export default CardRegular
