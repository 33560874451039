import React, { useState } from 'react'
import Flex from 'ui/Flex'
import Box from 'ui/Box'
import Img from 'ui/Img'
import ButtonsArea from 'features/Deposit/Components/ButtonsArea'
import nemkonto from 'images/icons/basic-icons/nemkonto.svg'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import userService from 'services/UserService'
import Icon from 'ui/Icon'
import Info from 'icons/basic-icons/aсcount-area/info.svg'
import Text from 'ui/Text'
import { modalService } from 'services/ModalService'
import { accountAreaIcons } from '../UserArea/Components/ProfileInfo'
import ProfileCard from 'features/UserArea/Components/ProfileCard'

const t = window.T

const DepositBody = ({ theme, balance }) => {
    const [amount, setAmount] = useState('')
    const [loading, setLoading] = useState(false)

    const onButtonClickHandler = (value) => {
        setAmount(value)
    }

    const onAmountChangeHandler = (e) => {
        if (e.length <= 10) {
            const deposit = e.replace(/[^0-9.,]+/g, '')
            setAmount(deposit)
        }
    }

    const formatAmount = (amount) => {
        const str = amount + '' //convert to string
        if (str.includes(',')) {
            let a = amount.replace(',', '.')
            return parseFloat(a).toFixed(2)
        }

        return amount
    }

    const onSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)
        UserAdapter.withdraw(formatAmount(amount))
            .then((res) => {
                if (res.success) {
                    userService.setIsUserLoggedIn()
                    modalService.closeModal()
                    NotificationConductor.success(t.userArea.withdraw.success)
                } else {
                    NotificationConductor.error('Error: ' + res.errorMessage)
                }
                setLoading(false)
            })
            .catch((error) => {
                NotificationConductor.error(error.errorMessage)
                setLoading(false)
            })
    }

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            e.preventDefault()
            onSubmit()
        }
    }

    return (
        <>
            <Box p={3}>
                <ProfileCard
                    borderRadiusTop
                    borderRadiusBottom
                    title={t.userArea.userInfo.mainBalance}
                    iconSrc={accountAreaIcons.wallet}
                    background={theme.light}
                >
                    <Text bold color={theme.gray} mr={2}>
                        {balance}
                    </Text>
                    <Text color={theme.gray}>{t.currency}</Text>
                </ProfileCard>
            </Box>

            <Box px={3} pt={4}>
                <Flex width={'100%'} mt={3}>
                    <Icon size={'sm'} src={Info} mr={1} />
                    <Text size="sm" align="center" bold>
                        Minimum {t.minWithdraw} kr.
                    </Text>
                </Flex>

                <ButtonsArea
                    amount={amount}
                    onButtonClickHandler={onButtonClickHandler}
                    onAmountChangeHandler={onAmountChangeHandler}
                    onSubmit={onSubmit}
                    onKeyPress={keyPressHandler}
                    disable={loading}
                    type={'withdraw'}
                    minDeposit={t.minWithdraw}
                >
                    <Flex mt={5} mb={3}>
                        <Img src={nemkonto} />
                    </Flex>
                </ButtonsArea>
            </Box>
        </>
    )
}

export default DepositBody
