import React, { useEffect, useState } from 'react'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import { withTheme } from 'styled-components'
import { restService } from 'services/ContentService'
import Flex from 'ui/Flex'
import Spinner from 'ui/Spinner'

const InfoPage = ({ match, theme }) => {
    const [pageContent, setPageContent] = useState({})

    useEffect(
        () => {
            restService.GetText(match.params.id, (res) => {
                setPageContent(res)
            })
        },
        [match.params.id]
    )

    return (
        <PageWrapper>
            <PageContent px={3}>
                {!pageContent.content && (
                    <Flex height={'100%'}>
                        <Spinner color={theme.darkGray} size={'lg'} m={3} />
                    </Flex>
                )}
                <h2>{pageContent.title}</h2>
                <div
                    dangerouslySetInnerHTML={{ __html: pageContent.content }}
                />
            </PageContent>
        </PageWrapper>
    )
}

export default withTheme(InfoPage)
