const COLORS_DARK = {
    dark: '#000000',
    darkGray: '#646464',
    lightGray: '#F9F9F9',
    gray: '#F1F1F1',
    light: '#FFFFFF',
    primary: 'rgb(0, 0, 0, .7)',
    primaryHover: 'black',
    primaryAction: 'rgb(52, 52, 52)',
    primaryDisable: '#F7C7C6',
    accent: '#FBD600',
    accentHover: '#E1C200',
    accentAction: '#BCA300',
    accentDisabled: '#FFF5BF',
    categoryOffers: '#FFA300',
    orange: '#FB9800',
    jade: '#51a351',
    azure: '#007AFE',
    categoryJackpot: '#000000',
    categoryNewGames: '#4ADD64',
    categoryTournaments: '#007AFE',
}

export default COLORS_DARK
