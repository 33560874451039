import React, { useEffect } from 'react'
import GamesService from 'services/GamesService'
import gamesService from 'services/GamesService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'
import Flex from 'ui/Flex'
import { withTheme } from 'styled-components'
import { BACKGROUNDS } from 'themes'
import Loader from 'ui/Loader'

const FindGamePage = ({ match, theme }) => {
    const history = useHistory()

    useEffect(() => {
        const allGamesSub = gamesService
            .getAllProvidersGames()
            .subscribe((allGames) => {
                const getGameId = GamesService.getGameByProviderId(
                    match.params.id
                )
                if (allGames && allGames.length) {
                    if (getGameId) {
                        history.push('/game/' + getGameId)
                    } else if (getGameId === null) {
                        history.push('/automater/alle')
                    } else {
                        NotificationConductor.error(
                            t.userArea.bonuses.gameIsNotFound
                        )
                        history.push('/automater/alle')
                    }
                }
            })

        return () => {
            allGamesSub.unsubscribe()
        }
    }, [])

    return (
        <Flex height={'200px'}>
            <Loader color={BACKGROUNDS.primary} />
        </Flex>
    )
}

export default withTheme(FindGamePage)
