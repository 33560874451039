import React from 'react'
import Icon from 'ui/Icon'
import styled, { keyframes } from 'styled-components'
import spinner from 'icons/ic_spinner.png'
import { marginProps, paddingProps } from '@/ui/SpacingUtility'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(Icon)`
    display: inline-block;
    position: relative;
    ${marginProps};
    ${paddingProps};
    border-radius: 50%;
    animation: ${rotate360} 1.2s linear infinite;
`

export default ({ size, color, m }) => (
    <Spinner m={m} src={spinner} size={size} scaleOfSize={2} color={color} />
)
