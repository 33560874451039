import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { pipe } from 'rxjs'
import { modalService } from 'services/ModalService'
import ProfileInfo, {
    accountAreaIcons,
} from 'features/UserArea/Components/ProfileInfo'
import Box from 'ui/Box'
import Flex from 'ui/Flex'
import Button from 'ui/controls/Button'
import ModalTopBar from 'ui/ModalTopBar'
import Headline from 'ui/Headline'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import userService from 'services/UserService'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import doorOpen from 'images/icons/basic-icons/aсcount-area/doorOpen.svg'
import Icon from 'ui/Icon'

const t = window.T

const DepositButton = styled(Button)`
    flex: 50%;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
`

const UserArea = ({ theme, data = { initialProps: {} } }) => {
    const history = useHistory()
    const [userProfile, setUserProfile] = useState({})
    const [user, setUser] = useState({})

    const logOut = () => {
        userService.logOut()
        IntercomAdapter.shutdown()
        modalService.showModal('LOADER_COVER')
        setTimeout(() => {
            modalService.closeModal()
        }, 500)
    }

    const showModal = (name, data) => {
        modalService.showModal(name, {
            ...data,
            styles: { background: 'transparent' },
            initialProps: { animation: true, outsideClose: true },
        })
    }

    useEffect(
        () => {
            userService.checkUserLogin()

            const userSubscription = userService.getUser().subscribe((data) => {
                setUser(data)
            })
            return () => {
                userSubscription.unsubscribe()
            }
        },
        [history.location.pathname]
    )

    useEffect(() => {
        const userProfileSubscription = userService
            .getUserProfile()
            .subscribe((data) => {
                setUserProfile(data)
            })

        return () => {
            userProfileSubscription.unsubscribe()
        }
    }, [])

    return (
        <>
            <ModalTopBar
                background={theme.primaryHover}
                borderBottom={'none'}
                pt={4}
                customClose={() => {
                    modalService.closeModal()
                }}
            />
            {user ? (
                <ProfileInfo
                    theme={theme}
                    userName={user.userName}
                    balance={user.balance}
                    bonus={user.bonusInfo ? user.bonusInfo.amount : '0'}
                    px={3}
                />
            ) : null}
            <Box width={'100%'} px={3} background={'#F9F9F9'}>
                <Flex style={{ transform: 'translateY(-50%)' }}>
                    <DepositButton
                        flatRight
                        size={'xlB'}
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'red25.clickToPaymentSystem',
                                userId: user.userId,
                            })
                            showModal('DEPOSIT')
                        }}
                    >
                        {t.userArea.operations.insert}
                    </DepositButton>
                    <DepositButton
                        flatLeft
                        size={'xlB'}
                        variant={'light'}
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'red25.clickToPaymentSystem',
                                userId: user.userId,
                            })
                            showModal('WITHDRAW')
                        }}
                    >
                        {t.userArea.operations.withdraw}
                    </DepositButton>
                </Flex>

                <Headline as={'h5'} color={theme.gray} pt={0}>
                    DIT SPIL
                </Headline>
                <ProfileCard
                    borderRadiusTop
                    title={'Bonusomsætning'}
                    iconSrc={accountAreaIcons.gift}
                    background={theme.light}
                    color={theme.gray}
                    onClick={() => {
                        showModal('BONUS', {
                            totalBonus: userProfile.totalBonus,
                        })
                    }}
                />
                <ProfileCard
                    borderRadiusTop
                    title={'Voucher'}
                    iconSrc={accountAreaIcons.voucher}
                    background={theme.light}
                    color={theme.gray}
                    onClick={() => {
                        showModal('VOUCHER')
                    }}
                />
                <ProfileCard
                    borderRadiusBottom
                    title={'Kontohistorik'}
                    subtitle={'Transaktioner, Spilhistorik'}
                    iconSrc={accountAreaIcons.clockArrow}
                    background={theme.light}
                    subtitleColor={theme.gray}
                    color={theme.gray}
                    onClick={() => {
                        showModal('HISTORY')
                    }}
                />

                <Headline as={'h5'} color={theme.gray} mt={4}>
                    INDSTILLINGER
                </Headline>
                <ProfileCard
                    borderRadiusTop
                    title={'Kontooplysninger'}
                    iconSrc={accountAreaIcons.gear}
                    background={theme.light}
                    color={theme.gray}
                    onClick={() => {
                        showModal('YOUR_INFO', userProfile)
                    }}
                />
                <ProfileCard
                    title={'Beskedindstillinger'}
                    subtitle={'Nyhedsbrev'}
                    iconSrc={accountAreaIcons.bellSimple}
                    background={theme.light}
                    subtitleColor={theme.gray}
                    color={theme.gray}
                    onClick={() => {
                        showModal('NOTIFICATION', userProfile)
                    }}
                />
                <ProfileCard
                    borderRadiusBottom
                    title={'Sikkerhedsindstillinger'}
                    subtitle={'Ændre kodeord, PIN kode'}
                    iconSrc={accountAreaIcons.lock}
                    background={theme.light}
                    subtitleColor={theme.gray}
                    color={theme.gray}
                    onClick={() => {
                        showModal('SECURITY')
                    }}
                />

                <Headline as={'h5'} color={theme.gray} mt={4}>
                    ANSVARLIGT SPIL
                </Headline>
                <ProfileCard
                    borderRadiusTop
                    title={'Indbetalingsgrænse'}
                    iconSrc={accountAreaIcons.warn}
                    background={theme.light}
                    color={theme.gray}
                    onClick={() => {
                        showModal('DEPOSIT_LIMIT')
                    }}
                />
                <ProfileCard
                    borderRadiusBottom
                    title={'Selvudelukkelse'}
                    iconSrc={accountAreaIcons.shield}
                    background={theme.light}
                    subtitleColor={theme.gray}
                    color={theme.gray}
                    onClick={() => {
                        showModal('SELF_EXCLUSION')
                    }}
                />

                <Headline as={'h5'} color={theme.gray} mt={4}>
                    KUNDESERVICE
                </Headline>
                <ProfileCard
                    borderRadiusTop
                    title={'Chat med os'}
                    onClick={() => IntercomAdapter.show()}
                    iconSrc={accountAreaIcons.message}
                    background={theme.light}
                    color={theme.gray}
                />
                <ProfileCard
                    borderRadiusBottom
                    title={'Hjælpecenter'}
                    iconSrc={accountAreaIcons.book}
                    onClick={() => {
                        window.open('https://intercom.help/25syv/da/', '_blank')
                    }}
                    background={theme.light}
                    subtitleColor={theme.gray}
                    color={theme.gray}
                />

                <Headline as={'h5'} color={theme.gray} mt={4}>
                    Andet
                </Headline>
                <ProfileCard
                    rounded
                    title={'Anmeld os'}
                    subtitle={'Trustpilot, Facebook'}
                    iconSrc={accountAreaIcons.thumbUp}
                    background={theme.light}
                    color={theme.gray}
                    onClick={() => {
                        showModal('PRODUCT_REVIEW')
                        history.push('/konto/anmeld-os')
                    }}
                />

                <ProfileCard
                    rounded
                    my={3}
                    title={'Log ud'}
                    titleColor={theme.light}
                    icon={
                        <Icon
                            src={doorOpen}
                            color={'white'}
                            size={'lg'}
                            mr={4}
                        />
                    }
                    background={theme.dark}
                    color={theme.light}
                    onClick={logOut}
                />
            </Box>
        </>
    )
}

export default pipe(
    withTheme,
    withAnimation
)(UserArea)
