/**
 * @example setCookie("username", 'user', 365)
 *
 * @param cname {string}
 * @param cookieValue {string}
 * @param expirationDays {int}
 * @return void
 */
export const setCookie = (cname, cookieValue, expirationDays = 365) => {
    let d = new Date()
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cookieValue + ';' + expires + ';path=/'
}

/**
 * @example getCookie("username")
 *
 * @param cname {string}
 * @return {string|null}
 */
export const getCookie = (cname) => {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}
