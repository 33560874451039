import React, { useEffect } from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes'
import Button from 'ui/controls/Button'
import { modalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { useHistory } from 'react-router-dom'
import { setCookie } from 'services/CookieService'
import Img from 'ui/Img'
import appLogo from 'images/logos/casino-icon-app.svg'
import Flex from 'ui/Flex'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    width: 80%;
    background: ${BACKGROUNDS.white};
    color: ${BACKGROUNDS.primary};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
`
const Title = styled.h3`
    font-size: 1.5rem;
    margin: 0 0 19px;
`
const Description = styled.p`
    font-size: 1rem;
    color: #818181;
    padding: 0;
    margin: 0;
`
const AppTitle = styled.span`
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
`
const ButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled(Button)`
    width: 47%;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 8px;
    font-size: 1rem;
`

const t = window.T

const PWAPopup = (modalData) => {
    const { deferredPrompt } = modalData.data
    const history = useHistory()

    const closePopup = () => {
        modalService.closeModal()
        setCookie('hidePWAPrompt', true, 90)
        history.push('/')
    }

    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt()
            deferredPrompt.userChoice.then((choiceResult) => {
                closePopup()
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt')
                } else {
                    console.log('User dismissed the install prompt')
                }
            })
        }
    }

    return (
        <Wrapper>
            <Title>{t.pwaPopup.title}</Title>
            <Description>{t.pwaPopup.description}</Description>
            <Flex mt={3} mb={3} flexDirection={'column'}>
                <Img
                    src={appLogo}
                    width={'64px'}
                    height={'64px'}
                    alt={'Bet25 Casino app'}
                />
                <AppTitle>Bet25 Casino</AppTitle>
            </Flex>

            <ButtonsArea>
                <StyledButton
                    variant="light"
                    bold
                    size="lg"
                    onClick={closePopup}
                >
                    Annuller
                </StyledButton>
                <StyledButton size="lg" bold onClick={handleInstall}>
                    Installer
                </StyledButton>
            </ButtonsArea>
        </Wrapper>
    )
}

export default PWAPopup
