import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import Box from 'ui/Box'
import Icon from 'ui/Icon'
import Button from 'ui/controls/Button'
import PageContent from 'ui/PageContent'
import GameList from 'features/GamesList/GameList'
import Text from 'ui/Text'
import Displayer from 'ui/Displayer'
import WithGames from 'common/Hocs/WithGames'
import { useLocation } from 'react-router-dom'
import { SMART_FILTERS } from 'configs/categories'
import MediaGrid from 'ui/MediaGrid'
import gamesService from 'services/GamesService'
import COLORS from 'themes/primary/palette'
import closeIcon from 'icons/basic-icons/close-light.svg'
import { PALETTE } from 'themes'

const GoBackButton = styled(Button)`
    display: flex;
    align-items: center;
`

const ItemHeader = styled.h4`
    font-size: 24px;
    padding: 0;
    margin: 32px 0 24px;
    color: ${COLORS.dark};
`

const ItemCount = styled.span`
    color: ${COLORS.gray};
`

export const ControlIcon = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 5%);
    cursor: pointer;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;

    svg {
        cursor: pointer;
    }
`

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

const SortGamesPage = ({ gamesToMap = [], theme }) => {
    const categoriesTextClosedKey = 'categoriesTextClosed'
    const [text, setText] = useState('')
    const [allGames, setAllGames] = useState([])
    const [categoryTextStatus, setCategoryTextStatus] = useState([])

    const query = useQuery()

    const filterKey = query.get('key')
    const filterValue = query.get('value')
    const categoryTextName = filterKey + '_' + filterValue

    const getCategoriesClosed = JSON.parse(
        localStorage.getItem(categoriesTextClosedKey)
    )

    const filteredGames = () => {
        return allGames.filter((item) => item[filterKey].includes(filterValue))
    }

    const categoryThumb = SMART_FILTERS.find(
        (item) => item.name === filterValue
    )

    const storageTextClosed = () => {
        if (!localStorage.getItem(categoriesTextClosedKey)) {
            localStorage.setItem(
                categoriesTextClosedKey,
                JSON.stringify(categoryTextStatus)
            )
        } else {
            setCategoryTextStatus(getCategoriesClosed)
        }
    }

    const setTextClosed = () => {
        const categories = getCategoriesClosed
        categories.push(categoryTextName)

        setCategoryTextStatus(categories)
        localStorage.setItem(
            categoriesTextClosedKey,
            JSON.stringify(categories)
        )
    }

    const showText = () => categoryTextStatus.indexOf(categoryTextName) === -1

    useEffect(() => {
        storageTextClosed()
        const allGamesSubscription = gamesService
            .getAllProvidersGames()
            .subscribe((games) => {
                setAllGames(games)
            })

        if (filterKey === 'subprovider') {
            filteredGames().sort((a, b) => b.clicks - a.clicks)
        }
        return () => {
            allGamesSubscription.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            setText(SMART_FILTERS.find((f) => f.name === filterValue).text)
            const allGamesSubscription = gamesService
                .getAllProvidersGames()
                .subscribe((games) => {
                    setAllGames(games)
                })
            return () => {
                allGamesSubscription.unsubscribe()
            }
        },
        [filterValue]
    )

    return (
        <PageContent maxWidth={'1140px'}>
            <Displayer
                display={allGames.length ? 'block' : 'none'}
                style={{ clear: 'both' }}
            >
                {showText() && (
                    <Box
                        background={COLORS.background}
                        borderRadius={2}
                        p={4}
                        width={'100%'}
                        style={{ position: 'relative' }}
                    >
                        <ControlIcon onClick={() => setTextClosed()}>
                            <Icon
                                width="18px"
                                height="18px"
                                src={closeIcon}
                                color={PALETTE.dark}
                            />
                        </ControlIcon>
                        <Text bold color={COLORS.dark}>
                            {categoryThumb.label}
                        </Text>
                        <Text color={COLORS.dark}>{text}</Text>
                    </Box>
                )}
                <ItemHeader>
                    {categoryThumb.label}{' '}
                    <ItemCount>{filteredGames().length}</ItemCount>
                </ItemHeader>
                <Box my={3}>
                    <MediaGrid>
                        <GameList games={filteredGames()} />
                    </MediaGrid>
                </Box>
            </Displayer>
        </PageContent>
    )
}

export default withTheme(WithGames(SortGamesPage))
