import React from 'react'
import styled, { css } from 'styled-components'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Icon from 'ui/Icon'
import PropTypes from 'prop-types'
import { accountAreaIcons } from 'features/UserArea/Components/ProfileInfo'
import Img from 'ui/Img'

const StyledFlex = styled(Flex)`
    ${(props) => props.rounded && `border-radius: 4px`};
    position: relative;
    box-shadow: 0px 0px 6px #00000014;
    ${css`
        border-bottom: 1px solid #00000014;
    `};
    ${(props) => props.borderRadiusTop && `border-radius: 4px 4px 0 0`};
    ${(props) => props.borderRadiusBottom && `border-radius: 0 0 4px 4px`};
    &:hover {
        cursor: pointer;
    }
`

const ProfileCard = ({
    title,
    titleColor = '#333333',
    titleWidth,
    subtitle,
    subtitleColor,
    color,
    background,
    children,
    iconSrc,
    iconSize = 'lg',
    iconColor = '#333333',
    icon = <></>,
    p = 3,
    m,
    my,
    borderRadiusBottom,
    borderRadiusTop,
    rounded,
    ...props
}) => {
    return (
        <StyledFlex
            p={p}
            m={m}
            my={my}
            color={color}
            background={background}
            justify={'space-between'}
            borderRadiusBottom={borderRadiusBottom}
            borderRadiusTop={borderRadiusTop}
            rounded={rounded}
            {...props}
        >
            <>
                {iconSrc && (
                    <Img
                        src={iconSrc}
                        size={iconSize}
                        my={2}
                        ml={1}
                        mr={3}
                        scale={0.9}
                        color={'white'}
                    />
                )}
                {icon}
                {title && (
                    <Text
                        style={{
                            position: 'relative',
                            width: titleWidth ? titleWidth : '100%',
                        }}
                        size={'lg'}
                        color={titleColor}
                        my={0}
                        lineHeight={2}
                    >
                        {title}
                        <br />
                        {subtitle && (
                            <Text
                                as={'span'}
                                size={'xs'}
                                style={{ position: 'absolute', top: '30px' }}
                                color={subtitleColor}
                            >
                                {subtitle}
                            </Text>
                        )}
                    </Text>
                )}
            </>
            {children ? (
                children
            ) : (
                <Icon src={accountAreaIcons.arrow} size={'sm'} color={color} />
            )}
        </StyledFlex>
    )
}

ProfileCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.node,
    iconSrc: PropTypes.string,
    iconSize: PropTypes.string,
}

export default ProfileCard
