import React from 'react'
import styled, { withTheme } from 'styled-components'
import { modalService } from 'services/ModalService'
import Icon from '@/ui/Icon'
import Wrapper from '@/ui/Wrapper'
import Headline from 'ui/Headline'
import cross from '@/images/icons/basic-icons/cross.svg'
import backIcon from 'icons/basic-icons/ShouldDelete.svg'
import PropTypes from 'prop-types'
import { paddingProps } from 'ui/SpacingUtility'
import UrlHistoryService from 'services/UrlHistoryService'

const StyledWrapper = styled(Wrapper)`
    display: flex;
    justify-content: center;
    background: ${(props) => (props.background ? props.background : '#fffff')};
    border-bottom: ${(props) =>
        props.borderBottom
            ? props.borderBottom
            : `1px solid ${props.theme.lightGray}`};
    align-items: center;
    position: relative;
    ${paddingProps};
`

const closeModal = (custom) => {
    custom ? custom() : modalService.closeModal()
}

const ModalControl = styled(Icon)`
    cursor: pointer;
    position: absolute;
`

const StyledHeadline = styled(Headline)`
    color: ${(props) => (props.color ? props.color : props.theme.dark)};
    padding: 0;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
`

const ModalTopBar = (props) => {
    return (
        <StyledWrapper
            style={props.style}
            background={props.background}
            borderBottom={props.borderBottom}
            p={props.p}
            pt={props.pt}
        >
            {props.back || props.customBack ? (
                <ModalControl
                    size={'md'}
                    src={backIcon}
                    onClick={() => {
                        if (props.customBack) {
                            props.customBack()
                        } else {
                            modalService.backModal(
                                UrlHistoryService.getModalStack()[0].modalName
                            )
                        }
                    }}
                    color={
                        props.crossColor ? props.crossColor : props.theme.light
                    }
                    style={{ cursor: 'pointer', left: '20px' }}
                />
            ) : null}
            {props.children ? (
                props.children
            ) : (
                <StyledHeadline
                    as={'h2'}
                    color={props.color}
                    fontWeight={props.fontWeight}
                >
                    {props.title}
                </StyledHeadline>
            )}
            {!props.noClose ? (
                <ModalControl
                    size={'md'}
                    src={cross}
                    color={
                        props.crossColor ? props.crossColor : props.theme.light
                    }
                    onClick={() => {
                        closeModal(props.customClose)
                    }}
                    style={{ right: '20px' }}
                />
            ) : null}
        </StyledWrapper>
    )
}

ModalTopBar.propTypes = {
    background: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    borderBottom: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    crossColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    title: PropTypes.string,
    customClose: PropTypes.func,
    customBack: PropTypes.func,
    back: PropTypes.bool,
}

export default withTheme(ModalTopBar)
