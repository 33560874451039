import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Box from 'ui/Box'
import { modalService } from 'services/ModalService'
import Icon from 'ui/Icon'
import pinLoginIcon from 'icons/pin_login.svg'
import COLORS from 'themes/primary/palette'
import { PALETTE } from 'themes'
import Checkbox from 'ui/controls/Checkbox'

const Wrapper = styled(Box)`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: auto;
    min-height: 50%;
    color: ${COLORS.dark};
`
const CheckboxWrapper = styled.div`
    border: 1px solid ${COLORS.lightGray};
    width: auto;
    height: 44px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;

    span {
        color: ${COLORS.gray};
        margin: 0 0 0 8px;
    }
`

const RestrictPINButton = styled.div`
    display: block;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
`

const PinLoginPopup = (props) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const setPinStatus = () => {
        if (checkboxChecked) {
            localStorage.setItem('pinPopup', '' + checkboxChecked)
        }
    }
    const setPinLogin = () => {
        setPinStatus()
        modalService.showModal('PIN_LOGIN', {
            action: 'set',
            noBack: true,
            props: { closeBefore: ['LOGIN', 'PIN_LOGIN_POPUP'] },
        })
    }

    const onClose = () => {
        setPinStatus()
        props.close()
    }

    return (
        <Wrapper pb={3}>
            <Box width={'100%'} pb={2} style={{ textAlign: 'center' }}>
                <Icon src={pinLoginIcon} width={'80px'} />
            </Box>
            <Box px={4}>
                <Text align={'center'} size={'lg'} bold>
                    Log ind med PIN kode
                </Text>
                <Text my={3}>
                    Brug PIN kode fremover for nem og hurtig adgang til din
                    konto
                </Text>
                <CheckboxWrapper>
                    <Checkbox
                        checked={checkboxChecked}
                        activeColor={PALETTE.jade}
                        color={PALETTE.lightGray}
                        onChange={() => setCheckboxChecked(!checkboxChecked)}
                        style={{ margin: 0 }}
                    />
                    <span>Vis ikke igen</span>
                </CheckboxWrapper>
                <Button
                    type={'accent'}
                    bold
                    size={'lgB'}
                    block
                    mt={4}
                    mb={3}
                    onClick={setPinLogin}
                >
                    Brug PIN kode
                </Button>
                <RestrictPINButton onClick={onClose}>
                    Fortsæt uden PIN kode
                </RestrictPINButton>
            </Box>
        </Wrapper>
    )
}

export default PinLoginPopup
