import styled from 'styled-components'

const MediaGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    position: relative;

    @media (min-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
      ${({ itemsPerRow }) =>
          `grid-template-columns: repeat(${itemsPerRow || 4}, 1fr);`}
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        ${({ itemsPerRow }) =>
            `grid-template-columns: repeat(${itemsPerRow || 4}, 1fr);`}
        gap: ${({ gap }) => gap || '2rem 1rem'};
    }
`

export default MediaGrid
