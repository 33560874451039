import styled from 'styled-components'

const Thumb = styled.img`
    overflow: hidden;
    border-radius: 8px;
    height: auto;
    width: ${(props) => (props.width ? props.width : '100%')};
`

export default Thumb
