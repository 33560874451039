import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Knob = styled.button`
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 3px;
    display: block;
    transition: all 0.3s ease-out;
    text-transform: none;
    cursor: pointer;
    font-size: 1.125rem;
    background: ${(props) =>
        props.background ? props.background : props.theme.accent};
    color: ${(props) => (props.color ? props.color : '#033333')};
    padding: 1rem 3rem;

    ${(props) =>
        props.active &&
        css`
            background: ${(props) =>
                props.activeColor ? props.activeColor : props.theme.accent};
        `};
    ${(props) =>
        props.flat &&
        css`
            border-radius: unset;
        `};
    ${(props) =>
        props.bold &&
        css`
            font-weight: bold;
        `};
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.4;
            cursor: not-allowed;
        `};
    ${(props) =>
        props.shadowed &&
        css`
            box-shadow: 0 3px 3px #33333329;
        `};

    ${(props) =>
        props.block &&
        css`
            display: block;
            width: 100%;
        `};
    ${(props) =>
        props.uppercase &&
        css`
            text-transform: uppercase;
        `};
    ${(props) =>
        props.lowerCase &&
        css`
            text-transform: lowercase;
        `};
    ${(props) =>
        props.capitalize &&
        css`
            text-transform: capitalize;
        `};
`

Knob.propTypes = {
    uppercase: PropTypes.bool,
    lowerCase: PropTypes.bool,
    capitalize: PropTypes.bool,
    block: PropTypes.bool,
    bold: PropTypes.bool,
    shadowed: PropTypes.bool,
    disabled: PropTypes.bool,
    flat: PropTypes.bool,
    background: PropTypes.string,
    color: PropTypes.string,
}

export default Knob
