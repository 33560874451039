import React from 'react'
import { CPRConfirmation } from '@it25syv/25syv-ui'
import { modalService } from 'services/ModalService'
import { useHistory } from 'react-router-dom'

const CPRConfirmModal = ({ data }) => {
    const history = useHistory()
    const { idp } = data

    return (
        <CPRConfirmation
            onConfirm={() => {
                history.push(`/mitid-verify/success/login`)
                modalService.closeModal()
            }}
            onError={(e) => {
                history.push(`/mitid-verify/error?error=${e.toString()}`)
            }}
            idp={idp}
            site={'casino'}
        />
    )
}

export default CPRConfirmModal
