import userService from 'services/UserService'
import UrlHistoryService from 'services/UrlHistoryService'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { setCookie } from 'services/CookieService'
import UserService from 'services/UserService'

export const signupHelper = (match, history) => {
    const loginAction = (withRegistration = false) => {
        setCookie('isItInitialLogin', 'true', 90)
        localStorage.setItem('logged', 'true')
        UserService.attachUser()
        userService.setIsUserLoggedIn((userData) => {
            localStorage.setItem('registerDate', userData.registerDate)
            userService.showPinLoginPopup()
            UrlHistoryService.openUserAreaAfterLogin(history)
            window.dataLayer.push({ event: 'login', userId: userData.userId })
            withRegistration &&
                window.dataLayer.push({
                    event: 'red25.completeRegistration',
                    userId: userData.userId,
                })
        })
        selfExclusionNotification()
    }

    if (match.params?.type === 'signup') {
        UserAdapter.register()
            .then((res) => {
                if (res.success) {
                    loginAction(true)
                    NotificationConductor.success('Velkommen til Bet25 Casino')
                } else {
                    res.errorMessage &&
                        NotificationConductor.error(res.errorMessage)
                }
            })
            .catch((error) => {
                NotificationConductor.error(error)
                history.push(`/mitid-verify/error`)
            })
    } else {
        loginAction()
    }
    history.push('/')

    return null
}
