import React, { useEffect, useState, Fragment } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Headline from 'ui/Headline'
import styled from 'styled-components'
import PeriodButtons from 'features/SelfExclusion/Components/PeriodButtons'
import Checkbox from 'ui/controls/Checkbox'
import Button from 'ui/controls/Button'
import Box from 'ui/Box'
import NotificationConductor from 'common/conductors/NotificationConductor'
import userService from 'services/UserService'
import Loader from 'ui/Loader'
import CurrentlyExcluded from 'features/SelfExclusion/CurrentlyExcluded'
import Center from 'ui/Center'
import { modalService as ModalService } from 'services/ModalService'
import UserAdapter, {
    EXCLUSION_TYPE_24_H,
    EXCLUSION_TYPE_PERMANENT,
} from 'common/adapters/UserAdapter'

const t = window.T

const RofusLink = styled.a`
    color: ${(props) => props.theme.dark};
    font-weight: bold;
    display: inline-block;
`

const SelfExclusionHolder = styled(Box)`
    height: auto;
    min-height: 100%;
`

const SelfExclusion = ({ theme, close }) => {
    const [curPeriod, setCurPeriod] = useState(EXCLUSION_TYPE_24_H)
    const [rofusCheckbox, setRofusCheckbox] = useState(false)
    const [permanentCheckbox, setPermanentCheckbox] = useState(false)
    const [selfExcluded, setSelfExcluded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [exclusionTime, setExclusionTime] = useState('')

    const periodButtonClickHandler = (period) => {
        setPermanentCheckbox(false)
        setCurPeriod(period)
    }

    const selfExclusionClick = () => {
        UserAdapter.excludeSelf(curPeriod)
            .then((res) => {
                if (res.success) {
                    NotificationConductor.success(
                        'Du vil blive udelukket i den valgte periode.'
                    )

                    userService.logOut()
                    ModalService.showModal('SELF_EXCLUSION_TEXT', {
                        permanent: curPeriod === EXCLUSION_TYPE_PERMANENT,
                    })
                } else {
                    NotificationConductor.error(
                        'Du er allerede udelukket. Kontakt kundeservice for mere information.'
                    )
                }
            })
            .catch((err) => {
                NotificationConductor.error(err.toString())
            })
    }

    useEffect(() => {
        UserAdapter.getUserStatus().then((res) => {
            if (res.data && res.data.tm) {
                setExclusionTime(res.data.tm)
                setSelfExcluded(true)
            }

            setLoading(false)
        })
        return () => {}
    }, [])

    return (
        <SelfExclusionHolder background={theme.light}>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    {t.userArea.selfExclusion.title}
                </Text>
            </ModalTopBar>
            <Box p={3}>
                {loading ? (
                    <Center>
                        <Loader />
                    </Center>
                ) : selfExcluded ? (
                    <CurrentlyExcluded
                        exclusionTime={exclusionTime}
                        permanent={curPeriod === EXCLUSION_TYPE_PERMANENT}
                    />
                ) : (
                    <Fragment>
                        <Headline as={'h2'} align={'left'} color={'black'}>
                            Spær eller luk din konto
                        </Headline>
                        <Text>{t.userArea.selfExclusion.rofusNote1}</Text>
                        <br />
                        <Text mb={3}>
                            {t.userArea.selfExclusion.rofusNote2}{' '}
                            <RofusLink
                                target="_blank"
                                href="https://www.spillemyndigheden.dk/rofus"
                            >
                                her
                            </RofusLink>
                        </Text>
                        <PeriodButtons
                            curPeriod={curPeriod}
                            clickHandler={periodButtonClickHandler}
                        />
                        <Flex justify={'start'} mb={3}>
                            <Checkbox
                                onChange={() => {
                                    setRofusCheckbox(!rofusCheckbox)
                                }}
                                checked={rofusCheckbox}
                                white
                            />
                            <Text pl={3}>
                                {t.userArea.selfExclusion.checkboxText}
                            </Text>
                        </Flex>

                        {curPeriod === EXCLUSION_TYPE_PERMANENT && (
                            <Flex justify={'start'} mb={3}>
                                <Checkbox
                                    onChange={() => {
                                        setPermanentCheckbox(!permanentCheckbox)
                                    }}
                                    checked={permanentCheckbox}
                                    white
                                />
                                <Text pl={3}>
                                    {
                                        t.userArea.selfExclusion
                                            .permanentCheckboxText
                                    }
                                </Text>
                            </Flex>
                        )}

                        <Button
                            size={'lgB'}
                            block
                            onClick={selfExclusionClick}
                            variant={'danger'}
                            style={{ flex: '50%' }}
                            disabled={
                                !rofusCheckbox ||
                                (!permanentCheckbox &&
                                    curPeriod === EXCLUSION_TYPE_PERMANENT)
                            }
                        >
                            {t.submit}
                        </Button>
                    </Fragment>
                )}
            </Box>
        </SelfExclusionHolder>
    )
}

export default SelfExclusion
