import React from 'react'
import styled, { css } from 'styled-components'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import Icon from 'ui/Icon'
import closeLight from 'icons/basic-icons/close-light.svg'
import filter from 'icons/basic-icons/filter.svg'

const FilterButton = styled.button`
    background: transparent;
    cursor: pointer;
    border: 0;
    width: 100%;
    padding: 0.4rem 0.1rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
`

const DropFilterButton = styled.button`
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    border: 0;
    border-left: 1px solid ${(props) => props.theme.gray};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    transition: all 0.3s ease;
    ${(props) =>
        props.active &&
        css`
            background: ${(props) => props.theme.dark};
            border-left: 1px solid ${(props) => props.theme.dark};
        `};
`

const StyledFlex = styled(Flex)`
    border: 1px solid ${(props) => props.theme.gray};
    border-radius: 4px;
    min-width: 130px;
`

const FilterToggler = ({
    filtersCount,
    onAction,
    onCancel,
    active,
    children,
    icon = filter,
    ...props
}) => {
    return (
        <Flex justify={'flex-start'} {...props}>
            <StyledFlex justify={'space-between'}>
                <FilterButton size={'md'} variant={'dark'} onClick={onAction}>
                    <Text style={{ textAlign: 'center' }} bold px={1}>
                        {children}
                    </Text>
                </FilterButton>
                <DropFilterButton
                    size={'md'}
                    variant={'dark'}
                    active={active}
                    onClick={() => (!active ? onAction() : onCancel())}
                >
                    {active ? (
                        <Icon
                            size={'md'}
                            src={closeLight}
                            color={'white'}
                            px={1}
                        />
                    ) : (
                        <Icon size={'sm'} src={icon} color={'black'} px={1} />
                    )}
                </DropFilterButton>
            </StyledFlex>
        </Flex>
    )
}

export default FilterToggler
