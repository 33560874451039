import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Headline from 'ui/Headline'
import cross from 'icons/basic-icons/cross.svg'
import Icon from 'ui/Icon'
import { modalService } from 'services/ModalService'
import Knob from 'ui/controls/Knob'
import Box from 'ui/Box'
import { restService } from 'services/ContentService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { focusAndOpenKeyboard } from 'utils/dom'

const t = window.T

const FormWrapper = styled.div`
    background: white;
    border-radius: 8px;
    position: relative;
    width: 100%;
`

const Title = styled(Headline)`
    text-align: center;
    color: black;
    margin: 0;

    @media (min-width: 992px) {
        font-size: 1.75em;
    }
`

const ModalControl = styled(Icon)`
    cursor: pointer;
    position: absolute;
`

const Description = styled.div`
    text-align: center;
    color: black;
`

const Hr = styled.hr`
    border-top: none;
    border-color: #f1f1f1;
    margin: 1.5rem;
    border-left: none;
    border-right: none;
`

const OptionButton = styled(Knob)`
    background: #f1f1f1;
    border-radius: 10px;
    display: ${(props) => (props.hide ? 'none' : 'block')};
    width: 100%;
    margin: 1rem 0;
    font-weight: bold;

    ${(props) =>
        props.active &&
        css`
            background: black;
            color: white;
        `};
`

const SubmitButton = styled(Knob)`
    //position: absolute;
    //bottom: 1rem;
    //width: calc(100% - 2rem);
    //left: 50%;
    //transform: translateX(-50%);
`

const TextAria = styled.textarea`
    border: ${(props) =>
        props.show ? `1px solid ${props.theme.lightGray}` : 'none'};
    height: ${(props) => (props.show ? `auto` : '0')};
    width: ${(props) => (props.show ? `100%` : '0')};
    padding: ${(props) => (props.show ? `10px` : '0')};
    border-radius: 10px;
    font-size: 1rem;
`

const OTHER_REASON_VALUE = '4'

const FeedbackForm = ({ onClose = null, style = {}, step = '' }) => {
    const [textFieldValue, setTextFieldValue] = useState('')
    const [activeOption, setActiveOption] = useState('')

    let textField = null

    const focusTextArea = () => {
        focusAndOpenKeyboard(document.getElementById('feedback-field'))
    }

    const FORM_OPTIONS = [
        {
            label: 'Jeg har fortrudt',
            value: '1',
        },
        {
            label: 'Jeg har ikke NemID på mig',
            value: '2',
        },
        {
            label: 'Det tager for lang tid',
            value: '3',
        },
        { label: 'Andet', value: OTHER_REASON_VALUE, action: focusTextArea },
    ]

    const onSubmit = () => {
        if (!activeOption) {
            NotificationConductor.error('Du skal vælge mindst én valgmulighed')
            return
        }
        restService
            .sendFeedback(activeOption, textFieldValue, '', step.toString())
            .then((res) => {
                console.log(res)
                NotificationConductor.success('Tak for din feedback')
            })
            .catch(() => {
                NotificationConductor.error(t.error)
            })
        modalService.closeModal()
    }

    const onOptionClick = (value = '', prevValue, action = (f) => f) => {
        value !== prevValue ? setActiveOption(value) : setActiveOption('')
        action()
    }

    return (
        <FormWrapper style={style}>
            <ModalControl
                size={'md'}
                src={cross}
                color={'black'}
                onClick={() => {
                    modalService.closeModal()
                }}
                style={{ right: '10px', top: '10px' }}
            />
            <Box pt={4} />

            <Title>Vi har brug for din feedback</Title>

            {activeOption !== OTHER_REASON_VALUE && (
                <>
                    <Hr />
                    <Description>
                        Du forlader nu din oprettelse. Hvad <br /> er årsagen
                        til, at du forlader <br /> oprettelsen?
                    </Description>
                </>
            )}

            <Box px={3} style={{ paddingBottom: '17px' }}>
                {FORM_OPTIONS.map((option) => (
                    <OptionButton
                        key={option.value}
                        onClick={() =>
                            onOptionClick(
                                option.value,
                                activeOption,
                                option?.action
                            )
                        }
                        active={activeOption === option.value}
                        hide={
                            option.value !== OTHER_REASON_VALUE &&
                            activeOption === OTHER_REASON_VALUE
                        }
                    >
                        {option.label}
                    </OptionButton>
                ))}

                <Box mb={2}>
                    <TextAria
                        ref={(field) => {
                            textField = field
                        }}
                        id={'feedback-field'}
                        rows={8}
                        value={textFieldValue}
                        onChange={(e) => setTextFieldValue(e.target.value)}
                        show={activeOption === OTHER_REASON_VALUE}
                    />
                </Box>

                <Box mt={5}>
                    <SubmitButton block shadowed onClick={onSubmit}>
                        Send feedback
                    </SubmitButton>
                </Box>
            </Box>
        </FormWrapper>
    )
}

FeedbackForm.propTypes = {}

export default FeedbackForm
