import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import { pipe } from 'rxjs'
import WithdrawBody from 'features/Withdraw/WithdrawBody'
import WithdrawDeposit from 'features/Withdraw/WithdrawDeposit'
import OperationTypeSwitcher from 'features/Deposit/Components/OperationTypeSwitcher'
import userService from 'services/UserService'
import {
    getBonusList,
    getResetBonusesStatus,
    setNtentBonusList,
} from 'services/BonusService'
import { numberWithCommasAndDots } from 'utils/formatters'
import GamesService from 'services/GamesService'
import Loader from 'ui/Loader'

const t = window.T

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
    background: #f9f9f9;
`

const Withdraw = ({ theme }) => {
    const [loading, setLoading] = useState(true)

    const [userBalance, setUserBalance] = useState(0)

    const [bonuses, setBonuses] = useState({})
    const [totalBonus, setTotalBonus] = useState(0)
    const [gameId, setGameId] = useState(null)
    const [relatedGameId, setRelatedGameId] = useState('')

    useEffect(() => {
        setNtentBonusList()
        const ntentBonusSub = getBonusList().subscribe((bonusInfo) => {
            setGameId(bonusInfo.gameId)

            setBonuses(Object.keys(bonusInfo) ? bonusInfo : {})
            setTotalBonus(bonusInfo.totalBonus ? bonusInfo.totalBonus : 0)

            if (bonusInfo) {
                setLoading(false)
            }
        })

        return () => {
            ntentBonusSub.unsubscribe()
            userService.setIsUserLoggedIn()
        }
    }, [])

    useEffect(() => {
        userService.checkUserLogin()
        const userSubscription = userService.getUser().subscribe((data) => {
            setUserBalance(numberWithCommasAndDots(data.balance))
        })
        return () => {
            userSubscription.unsubscribe()
        }
    }, [])

    useEffect(() => {
        const bonusStatus = getResetBonusesStatus().subscribe((resetStatus) => {
            if (resetStatus) {
                setLoading(true)
            }
        })

        return () => {
            bonusStatus.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (gameId !== null) {
                setRelatedGameId(GamesService.getGameByProviderId(gameId))
            }
        },
        [gameId]
    )

    return (
        <Wrapper background={'#F9F9F9'}>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    {t.userArea.bank.title}
                </Text>
            </ModalTopBar>
            <Flex background={theme.primary} pb={3} px={3}>
                <OperationTypeSwitcher active={'WITHDRAW'} />
            </Flex>
            {loading ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : totalBonus ? (
                <WithdrawDeposit
                    theme={theme}
                    balance={userBalance}
                    bonuses={bonuses}
                    relatedGameId={relatedGameId}
                    gameId={gameId}
                />
            ) : (
                <WithdrawBody theme={theme} balance={userBalance} />
            )}
        </Wrapper>
    )
}

export default pipe(
    withAnimation,
    withTheme
)(Withdraw)
