import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Flex from 'ui/Flex'
import Box from 'ui/Box'
import ButtonsArea from 'features/Deposit/Components/ButtonsArea'
import dk from 'icons/basic-icons/payment-methods/dk.svg'
import visa from 'icons/basic-icons/payment-methods/visa.svg'
import visaE from 'icons/basic-icons/payment-methods/visaE.svg'
import mc from 'icons/basic-icons/payment-methods/mastercard.svg'
import maestro from 'icons/basic-icons/payment-methods/maestro.svg'
import o from 'icons/basic-icons/payment-methods/o.svg'
import Img from 'ui/Img'
import { DIBS_CLIENT_NAME, DIBS_PMNT, DIBS_EXCHANGE_DK } from 'configs/dibs'
import { modalService } from 'services/ModalService'
import userService from 'services/UserService'
import Icon from 'ui/Icon'
import Info from 'icons/basic-icons/aсcount-area/info.svg'
import Text from 'ui/Text'
import BonusList from 'features/Deposit/Components/BonusList'
import PaymentType from './PaymentType'
import DepositService, { depositAction } from 'services/DepositService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import WithUser from 'common/Hocs/WithUser'
import Loader from 'ui/Loader'
import { AUTH_REST_PATH } from 'configs/rest'

const paymentMethods = [dk, visa, visaE, mc, o, maestro]
const t = window.T
const errors = t.deposit.fundErrors

const StyledIframe = styled.iframe`
    min-height: 550px;
    border: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: ${(props) => (props.show ? 'block' : 'none')};
    margin-top: 10px;
    z-index: 1;
    position: relative;
`

const DepositBody = ({ bonusList = [], theme, maxDeposit, userProfile }) => {
    const [amount, setAmount] = useState('')
    const [bonusEntity, setBonus] = useState({})
    const [submitDeposit, setSubmitDeposit] = useState(false)
    const [load, setLoad] = useState(false)
    const [minDeposit, setMinDeposit] = useState(50)
    const [minDepositForTest, setMinDepositForTest] = useState(0)
    const [payType, setPayType] = useState('card')
    const [exclusionDate, setExclusionDate] = useState('')

    const submit = () => {
        if (amount > maxDeposit) {
            checkMaxDeposit()
        } else if (payType === 'applepay') {
            let validationURL = ''

            const paymentRequest = {
                currencyCode: 'DKK',
                countryCode: 'DK',
                validationURL,
                total: {
                    label: 'Bet25 Casino deposit',
                    type: 'final',
                    amount,
                },
                merchantIdentifier: 'merchant.824502',
                merchantCapabilities: [
                    'supports3DS',
                    'supportsCredit',
                    'supportsDebit',
                ],
                supportedNetworks: ['amex', 'masterCard', 'visa'],
            }
            let session = new window.ApplePaySession(3, paymentRequest)

            session.onvalidatemerchant = async (event) => {
                validationURL = event.validationURL
                // Call your own server to request a new merchant session.
                DepositService.ApplePayMerchantVerification().then((res) => {
                    if (res?.data?.merchantSessionIdentifier) {
                        session.completeMerchantValidation(res?.data)
                    }
                })
            }

            session.onpaymentauthorized = (event) => {
                let data = event?.payment?.token
                const paymentData = encodeURIComponent(JSON.stringify(data))

                // Define ApplePayPaymentAuthorizationResult
                const result = {
                    status: ApplePaySession.STATUS_SUCCESS,
                }
                DepositService.CompleteApplePayPayment(
                    amount,
                    paymentData,
                    bonusEntity
                )
                    .then((res) => {
                        if (res.url) {
                            setTimeout(() => {
                                modalService.closeModal('DEPOSIT')
                            }, 0)
                            NotificationConductor.success(
                                `Din indbetaling på ${amount} kr. er gennemført`
                            )
                            userService.setIsUserLoggedIn()
                        } else {
                            NotificationConductor.error(
                                errors[res.error] ||
                                    'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                            )
                        }
                    })
                    .catch((e) => {
                        NotificationConductor.error(
                            errors[e.error] ||
                                'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                        )
                    })
                session.completePayment(result)
            }
            session.begin()
        } else {
            setSubmitDeposit(true)
            setLoad(true)
        }
    }

    const checkMaxDeposit = () => {
        modalService.showModal('DEPOSIT_LIMIT_WARNING', {
            maxDeposit,
        })
    }

    const onButtonClickHandler = (value) => {
        setAmount(value)
    }

    const urlChangedHandler = (e) => {
        setLoad(false)
    }

    const onAmountChangeHandler = (e) => {
        const deposit = e.replace(/[^0-9]+/g, '')
        setAmount(deposit)
    }

    const getIframeUrl = () => {
        let dibsUrl =
            AUTH_REST_PATH +
            `payment/dibs?op=deposit&client=${DIBS_CLIENT_NAME}` +
            `&exchcode=${DIBS_EXCHANGE_DK}` +
            `&payType=${payType}` +
            `&amount=${amount *
                100}&input_amount=${amount}&pmnt=${DIBS_PMNT}&rnd=0.328949832`

        if (bonusEntity.externalId) {
            dibsUrl += `&bonusId=${bonusEntity.externalId}`
        }

        return dibsUrl
    }

    const depositHandler = ({ data }) => {
        if (typeof data === 'string' && data.includes('custom-event')) {
            let dibsIframeMessage = JSON.parse(data)
            const url = new URL(dibsIframeMessage.url)
            const status = dibsIframeMessage.status
            const amount = url.searchParams.get('amount')
            const reason = url.searchParams.get('reason')
            depositAction(status, amount, reason)
            modalService.closeModal('DEPOSIT')
        }
    }

    const setBonusActive = (bonus) => {
        setBonus(bonus)
    }

    const setBonusInactive = () => {
        setBonus({})
    }

    const setPayTypeHandler = (type) => {
        setPayType(type)
    }

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0

        if (key === 13) {
            e.preventDefault()
            submit()
        }
    }

    const selfExclusionHandler = () => {
        selfExclusionNotification()
    }

    useEffect(() => {
        window.addEventListener('message', depositHandler)

        userService.getMinDeposit().then((res) => {
            setMinDeposit(res.mindeposit ? res.mindeposit : minDeposit)
        })

        UserAdapter.getUserStatus().then((res) => {
            if (res.data && res.data.tm) {
                setExclusionDate(res.data.tm)
            }
        })

        return () => {
            window.removeEventListener('message', depositHandler)
        }
    }, [])

    useEffect(
        () => {
            if (userProfile.userId === 233832) {
                setMinDepositForTest(t.minDepositForTest)
            }
        },
        [userProfile?.userId]
    ) // min deposit for test user

    return (
        <>
            {!submitDeposit && (
                <Box px={3} background={'#f9f9f9'}>
                    <BonusList
                        bonusEntity={bonusEntity}
                        bonusList={bonusList}
                        setBonusActive={setBonusActive}
                        setBonusInactive={setBonusInactive}
                        theme={theme}
                    />
                    <PaymentType
                        payType={payType}
                        setPayType={setPayTypeHandler}
                    />
                    <Flex width={'100%'} mt={3}>
                        <Icon size={'sm'} src={Info} mr={1} />
                        <Text
                            size="sm"
                            align="center"
                            bold
                            onKeyPress={keyPressHandler}
                        >
                            Minimum {minDepositForTest || minDeposit}
                            kr.
                        </Text>
                    </Flex>
                    <ButtonsArea
                        amount={amount}
                        onButtonClickHandler={onButtonClickHandler}
                        onAmountChangeHandler={onAmountChangeHandler}
                        onSubmit={exclusionDate ? selfExclusionHandler : submit}
                        bonus={bonusEntity}
                        minDeposit={minDepositForTest || minDeposit}
                    >
                        <Flex mt={5} mb={3}>
                            {paymentMethods.map((el, index) => (
                                <Box px={2} key={index}>
                                    <Img src={el} />
                                </Box>
                            ))}
                        </Flex>
                    </ButtonsArea>
                </Box>
            )}

            {submitDeposit && (
                <Fragment>
                    {load && (
                        <Flex style={{ paddingTop: '20px' }}>
                            <Loader />
                        </Flex>
                    )}

                    <StyledIframe
                        width={'100%'}
                        height={'100%'}
                        show={submitDeposit}
                        onLoad={(e) => urlChangedHandler(e)}
                        src={getIframeUrl()}
                    >
                        <div>
                            LOCATION
                            {window.location.href}
                        </div>
                    </StyledIframe>
                </Fragment>
            )}
        </>
    )
}

export default WithUser(DepositBody)
