export const sortingByHotClicks = (games) => {
    return games
        .sort(
            (a, b) =>
                Number(b.rod_hot) - Number(a.rod_hot) ||
                parseFloat(b.clicks || 0) - parseFloat(a.clicks || 0) ||
                a.name.localeCompare(b.name)
        )
        .sort(
            (a, b) =>
                b.rod_hot && a.rod_hot
                    ? parseInt(a.priority) - parseInt(b.priority)
                    : 0
        )
}
