import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes'
import Button from 'ui/controls/Button'
import { modalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    width: 80%;
    background: ${BACKGROUNDS.white};
    color: ${BACKGROUNDS.primary};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 40px;
`
const Title = styled.h3`
    font-size: 1.5rem;
    margin: 0 0 19px;
`
const Description = styled.p`
    font-size: 1rem;
    color: #818181;
    margin: 0 0 36px;
`
const ButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled(Button)`
    width: 47%;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 8px;
    font-size: 1rem;
`

const t = window.T

const SessionPopup = () => {
    const history = useHistory()
    const closePopup = () => {
        modalService.closeModal()
        history.push('/')
    }
    const showLogin = () => {
        modalService.showModal('LOGIN', {
            initialProps: { modalTopMargin: '0' },
        })
    }

    return (
        <Wrapper>
            <Title>{t.sessionPopup.title}</Title>
            <Description>{t.sessionPopup.description}</Description>
            <ButtonsArea>
                <StyledButton
                    variant="light"
                    bold
                    size="lg"
                    onClick={closePopup}
                >
                    OK
                </StyledButton>
                <StyledButton size="lg" bold onClick={showLogin}>
                    {t.login}
                </StyledButton>
            </ButtonsArea>
        </Wrapper>
    )
}

export default SessionPopup
