import styled from 'styled-components'

const CtaButton = styled.a`
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    line-height: 1;
    border-radius: 3px;
    color: ${(props) => (props.color ? props.color : 'black')};
    background: ${(props) => (props.background ? props.background : '#FBD600')};
    padding: 0.5rem 0.7rem;
`

export default CtaButton
