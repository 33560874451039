import React from 'react'
import { useHistory } from 'react-router-dom'

const WithUrlHistory = (Component) => (props) => {
    const history = useHistory()

    return <Component {...props} history={history} />
}

export default WithUrlHistory
