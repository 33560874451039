import React from 'react'
import Logo from 'icons/basic-icons/rodLogo.svg'
import Icon from 'ui/Icon'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { modalService } from 'services/ModalService'

const ModalBackground = styled.div`
    //position: fixed;
    //width: 100%;
    height: 100%;
    background: ${(props) => props.theme.primary};
    background: linear-gradient(
        180deg,
        ${(props) => props.theme.primary} 0%,
        ${(props) => props.theme.primaryAction} 100%
    );
`

const ModalBackgroundDesk = ({
    children,
    bottomSection,
    maxWidth = '100%',
}) => {
    return (
        <ModalBackground>
            <Link to={'/'} onClick={() => modalService.closeModal()}>
                <Icon
                    src={Logo}
                    color={'white'}
                    size={'xxl'}
                    scale={2}
                    style={{ position: 'absolute', left: '25px' }}
                />
            </Link>
            {children}
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: '0',
                    color: 'white',
                    maxWidth: maxWidth,
                }}
            >
                {bottomSection}
            </div>
        </ModalBackground>
    )
}

export default ModalBackgroundDesk
