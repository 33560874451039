import styled from 'styled-components'

const Center = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
    top: ${(props) => (props.top ? props.top : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
`

export default Center
