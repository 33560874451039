import React from 'react'
import styled, { css } from 'styled-components'
import Flex from 'ui/Flex'
import Headline from 'ui/Headline'
import PropTypes from 'prop-types'
import theme from 'themes/themePrimary'

const FilterButton = styled.button`
    border-radius: 1rem;
    padding: 8px 24px;
    margin: 0.25rem;
    border: 1px solid ${(props) => props.theme.gray};
    color: ${(props) => props.theme.gray};
    transition: all 0.3s ease;
    background: transparent;
    &:hover {
        cursor: pointer;
    }
    &:active {
        color: ${(props) => props.theme.gray};
    }
    ${(props) =>
        props.selected &&
        css`
            background-color: ${props.theme.dark};
            color: white;
            &:hover {
                color: ${(props) => props.theme.white};
            }
        `};
`

const StyledFlex = styled(Flex)`
    flex-wrap: wrap;
`

const FilterSection = ({
    title = '',
    options = [],
    values = [],
    onChange = (f) => f,
    button = FilterButton,
    style = {},
}) => {
    const ButtonComponent = button

    const onChangeHandler = (element) => {
        let isSelected = values.find(
            (item) => element && item.value === element.value
        )

        if (!!isSelected) {
            onChange(values.filter((item) => item.value !== element.value))
        } else {
            onChange([
                {
                    key: element.key,
                    value: element.value,
                    customFilter: element.customFilter,
                },
                ...values,
            ])
        }
    }

    return (
        <Flex
            background={'white'}
            width={'100%'}
            p={3}
            mt={1}
            align={'flex-start'}
            flexDirection={'column'}
            style={style}
        >
            <Headline as={'h4'} color={theme.dark}>
                {title}
                {/*<Icon src={info} color={theme.gray} size={'sm'} ml={1} />*/}
            </Headline>
            <StyledFlex justify={'flex-start'}>
                {options.map((button, index) => {
                    let selected = values.find(
                        (item) => button.value === item.value
                    )
                    return (
                        <ButtonComponent
                            key={index}
                            selected={!!selected}
                            onClick={() => onChangeHandler(button)}
                            size="md"
                            variant={'lightHollow'}
                        >
                            {button.label}
                        </ButtonComponent>
                    )
                })}
            </StyledFlex>
        </Flex>
    )
}

FilterSection.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    button: PropTypes.elementType,
}

export default FilterSection
