import moment from 'moment'

export const formatDateWithoutSeparator = (moment) =>
    moment.toLocaleDateString('en-GB').replace(/\//g, '')

/**
 * @param {string} date
 * @param {string} dateFormat
 * @param {string} inputFormat
 * @returns {string} Date/Time according to dateFormat param. DD.MM.YY [kl] HH:mm is default
 */
export function formatDate(date, dateFormat, inputFormat = '') {
    return moment(date, inputFormat ? inputFormat : null).format(
        dateFormat ? dateFormat : 'DD.MM.YY [kl] HH:mm'
    )
}

/**
 * Check date if is's older than current date for some period of days
 * @param {string} inputDate - format must be DD-MM-YYYY
 * @param {number} daysOld
 * @returns {boolean}
 */
export function isDateYongerDays(inputDate, daysOld = 7) {
    const currentDate = new Date()
    const [formatDay, formatMonth, formatYear] = inputDate.split('-')
    const currentDateFormat = new Date(
        `${formatYear}-${formatMonth}-${formatDay}`
    )

    // Subtract X days from the current date
    const daysAgo = new Date(currentDate)
    daysAgo.setDate(currentDate.getDate() - daysOld)

    return currentDateFormat > daysAgo
}
