import React from 'react'
import { TransactionDetails as TransactionDetailsComp } from '@it25syv/25syv-ui'
import { modalService } from '../../services/ModalService'

const TransactionDetails = (props) => {
    return (
        <TransactionDetailsComp
            data={props.data}
            onClose={() => modalService.closeModal('TRANSACTION_INFO')}
        />
    )
}
export default TransactionDetails
