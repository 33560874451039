import styled from 'styled-components'
import { paddingProps } from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const Container = styled.div`
    ${paddingProps};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '600px')};
    margin: 0 auto;
`

Container.propTypes = {
    maxWidth: PropTypes.string,
}

export default Container
