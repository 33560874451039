import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const SideNavigationWrapper = styled.div`
    background: ${({ background }) => background};
    flex-shrink: 0;

    overflow: hidden;
    &:hover {
        -webkit-overflow-scrolling: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &::-webkit-scrollbar {
        width: 2px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
    }

    scrollbar-width: thin;
`

const SideNavigation = ({
    isOpen = false,
    children,
    background = '#DF1C1B',
    ...props
}) => {
    const sidebarRef = useRef()

    useEffect(
        () => {
            sidebarRef.current.scrollTo(0, 0)
        },
        [isOpen]
    )
    return (
        <SideNavigationWrapper
            background={background}
            {...props}
            ref={sidebarRef}
        >
            {children}
        </SideNavigationWrapper>
    )
}

export default SideNavigation
