import React, { useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import styled, { withTheme } from 'styled-components'
import ValidInput from 'ui/controls/ValidInput'
import NotificationConductor from 'common/conductors/NotificationConductor'
import withValidation from '@/common/Hocs/WithValidation'
import UserAdapter from 'common/adapters/UserAdapter'
import Box from 'ui/Box'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import { pipe } from 'rxjs'
import UserService from 'services/UserService'
import COLORS from 'themes/primary/palette'
import { modalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = window.T

const BackgroundHolder = styled(Box)`
    height: 100%;
    background: ${(props) => props.theme.background};
`

const StyledInput = styled(Flex)`
    margin: 1rem 0;
`

const Voucher = ({ setValidationHandler, validateAllFields, theme }) => {
    const [code, setCode] = useState('')
    const [number, setNumber] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        if (validateAllFields()) {
            UserAdapter.applyVoucher(number, code)
                .then((res) => {
                    if (res) {
                        UserService.setIsUserLoggedIn()
                        modalService.closeModal('VOUCHER')
                        NotificationConductor.success(
                            `Voucher godkendt! ${res} kr. er tilføjet til din konto.`
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                    NotificationConductor.error(err)
                })
        } else {
            NotificationConductor.error(t.filledWrong)
        }
    }

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode

        if (key === 13) {
            e.preventDefault()
            submitHandler(e)
        }
    }

    return (
        <BackgroundHolder>
            <ModalTopBar background={theme.primary} back color={theme.light}>
                <Text size={'md'} color={theme.light} my={2}>
                    Voucher
                </Text>
            </ModalTopBar>

            <Wrapper
                style={{
                    textAlign: 'center',
                    background: '#f9f9f9',
                    paddingTop: '32px',
                }}
            >
                <Text size={'lg'} px={3} pb={1} bold color={COLORS.dark}>
                    Indtast og modtag
                </Text>
                <Text px={3} pb={3} color={COLORS.darkGray}>
                    Har du modtaget en voucher, kan du indløse den herunder. Du
                    kan se regler og vilkår på din voucher.
                </Text>
                <StyledInput>
                    <ValidInput
                        name={'number'}
                        type={detectMobile() ? 'tel' : 'text'}
                        onChange={(v) => {
                            setNumber(v.replace(/[^\d.]/gi, ''))
                        }}
                        value={number}
                        validate={{
                            required: true,
                            numeric: true,
                        }}
                        placeholder={t.voucher.number}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('number', validatorHandler)
                        }
                        showErrorOnChange={false}
                        hideCancel
                        onKeyPress={keyPressHandler}
                        autocomplete="off"
                    />
                </StyledInput>
                <StyledInput>
                    <ValidInput
                        name={'code'}
                        type={'text'}
                        onChange={(v) => {
                            setCode(v.trim())
                        }}
                        value={code}
                        placeholder={t.voucher.code}
                        validate={{
                            required: true,
                        }}
                        getValidator={(validatorHandler) => {
                            setValidationHandler('code', validatorHandler)
                        }}
                        showErrorOnChange={false}
                        hideCancel
                        onKeyPress={keyPressHandler}
                        autocomplete="off"
                    />
                </StyledInput>

                <Flex pt={4}>
                    <Button
                        size={'lgB'}
                        onClick={submitHandler}
                        variant={'danger'}
                        style={{ flex: '50%' }}
                        disabled={!code || !number}
                    >
                        {t.voucher.btn}
                    </Button>
                </Flex>
            </Wrapper>
        </BackgroundHolder>
    )
}

export default pipe(
    withValidation,
    withTheme,
    withAnimation
)(Voucher)
