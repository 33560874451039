import React from 'react'
import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import Loader from 'ui/Loader'
import Center from 'ui/Center'

const t = window.T

const StyledWrapper = styled(Wrapper)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(props) =>
        props.background ? props.background : props.theme.primary};
`

const LoaderCover = ({ data = {} }) => {
    return (
        <StyledWrapper
            background={data.initialProps ? data.initialProps.background : null}
        >
            <Center height={'100%'}>
                <Loader color={(props) => props.theme.light} />
            </Center>
        </StyledWrapper>
    )
}

export default LoaderCover
