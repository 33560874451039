import React from 'react'
import styled from 'styled-components'
import Icon from 'ui/Icon'
import Headline from 'ui/Headline'
import COLORS from 'themes/primary/palette'

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`
const Label = styled.div`
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: ${({ color }) => color || COLORS.dark};
    display: flex;
    align-items: center;
    justify-content: center;
`

const NewGamesGridTitle = ({
    icon,
    iconColor = '#fff',
    labelColor = '#000',
    text,
}) => {
    return (
        <TitleWrapper>
            <Label color={labelColor}>
                <Icon
                    src={icon}
                    color={iconColor}
                    width={'20px'}
                    height={'20px'}
                />
            </Label>
            <Headline as={'h3'} color={COLORS.dark}>
                {text}
            </Headline>
        </TitleWrapper>
    )
}

export default NewGamesGridTitle
