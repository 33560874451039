import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Text from 'ui/Text'
import Box from 'ui/Box'
import { setNtentBonusList, getBonusList } from 'services/BonusService'
import ModalTopBar from 'ui/ModalTopBar'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import gift from 'images/icons/basic-icons/aсcount-area/gift.svg'
import info from 'images/icons/basic-icons/aсcount-area/info.svg'
import historyIcon from 'images/icons/basic-icons/aсcount-area/history.svg'
import Headline from 'ui/Headline'
import Button from 'ui/controls/Button'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
import Icon from 'ui/Icon'
import { modalService } from 'services/ModalService'
import { numberWithCommasAndDots } from 'utils/formatters'
import { formatDate } from 'utils/dateTime'
import UserService from 'services/UserService'
import gamesService from 'services/GamesService'
import GamesService from 'services/GamesService'
import NotificationConductor from 'common/conductors/NotificationConductor'

const t = window.T

const GoToBonus = styled(Button)`
    text-align: center;
    text-decoration: none;
`

const UserBonus = ({ theme, close, data }) => {
    const history = useHistory()

    const [totalBonus, setTotalBonus] = useState(
        data.totalBonus ? data.totalBonus : 0
    )
    const [bonuses, setBonuses] = useState({})
    const [gameId, setGameId] = useState(null)
    const [relatedGameId, setRelatedGameId] = useState('')
    const [rollover, setRollover] = useState(0)
    const [loading, setLoading] = useState(true)

    const confirmBonusRemoving = () => {
        if (Object.keys(bonuses).length && totalBonus) {
            modalService.showModal('BONUS_POPUP', {
                props: {
                    theme: theme,
                    totalBonus: totalBonus,
                    bonuses: bonuses,
                },
                initialProps: {
                    modalTopMargin: '0',
                    animation: true,
                },
            })
        } else {
            modalService.closeModal()
            history.push('/automater/alle')
        }
    }

    useEffect(() => {
        setNtentBonusList()
        let timer = null

        const ntentBonusSub = getBonusList().subscribe((bonusInfo) => {
            setGameId(bonusInfo.gameId)
            setBonuses(Object.keys(bonusInfo) ? bonusInfo : {})
            setTotalBonus(
                bonusInfo.totalBonus
                    ? numberWithCommasAndDots(bonusInfo.totalBonus)
                    : 0
            )
            setRollover(
                bonusInfo.rollover
                    ? numberWithCommasAndDots(bonusInfo.rollover)
                    : 0
            )
            setTimeout(() => {
                setLoading(false)
            }, 300)
        })

        return () => {
            clearTimeout(timer)
            ntentBonusSub.unsubscribe()
            UserService.setIsUserLoggedIn()
        }
    }, [])

    useEffect(
        () => {
            if (gameId !== null) {
                setRelatedGameId(GamesService.getGameByProviderId(gameId))
            }
        },
        [gameId]
    )

    const onClickBonus = () => {
        if (relatedGameId) {
            close()
            history.push('/game/' + relatedGameId)
        } else if (gameId === null) {
            close()
            history.push('/automater/alle')
        } else {
            NotificationConductor.error(t.userArea.bonuses.gameIsNotFound)
        }
    }

    return (
        <Box background={theme.lightGray} height={'100%'}>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    Bonusomsætning
                </Text>
            </ModalTopBar>

            <Box p={3}>
                <ProfileCard
                    iconSrc={gift}
                    title={'Bonussaldo'}
                    background={theme.light}
                    borderRadiusTop
                >
                    <Text
                        size={'lg'}
                        align={'right'}
                        color={theme.dark}
                        style={{ whiteSpace: 'nowrap' }}
                        bold
                    >
                        {totalBonus} kr.
                    </Text>
                </ProfileCard>
                <ProfileCard
                    iconSrc={historyIcon}
                    title={'Omsætningskrav'}
                    background={theme.light}
                    style={{ borderBottom: 'none' }}
                    borderRadiusBottom={
                        !Object.keys(bonuses).length &&
                        'border-radius: 0 0 4px 4px'
                    }
                >
                    <Text
                        size={'lg'}
                        align={'right'}
                        color={theme.dark}
                        style={{ whiteSpace: 'nowrap' }}
                        bold
                    >
                        {rollover} kr.
                    </Text>
                </ProfileCard>
                {Object.keys(bonuses).length > 0 && (
                    <Box
                        p={3}
                        background={theme.light}
                        style={{ borderRadius: '0 0 .3rem .3rem' }}
                    >
                        <Box my={3}>
                            <Text lineHeight={1.5} size={'sm'}>
                                Din bonussaldo er tilknyttet et omsætningskrav
                                som skal fuldføres før de overføres til din
                                kontantsaldo og kan udbetales. Din bonus kan
                                omsættes på alle automater under menuen{' '}
                                <Text bold as={'span'}>
                                    Automater
                                </Text>{' '}
                                hvor både din kontantsaldo og din bonussaldo
                                tæller med i din samlede bonusomsætning. Husk at
                                du først spiller fra din kontantsaldo, dernæst
                                din bonussaldo.
                            </Text>
                        </Box>

                        <Flex>
                            <GoToBonus
                                size={'lgB'}
                                onClick={() => {
                                    onClickBonus()
                                }}
                                style={{ flex: '50%' }}
                                disabledClass={!relatedGameId && gameId}
                            >
                                Gå til bonusspil
                            </GoToBonus>
                        </Flex>
                        <Flex mt={3}>
                            <Button
                                size={'lgB'}
                                variant={'danger'}
                                style={{ flex: '50%' }}
                                onClick={() => confirmBonusRemoving()}
                            >
                                Fjern alle aktive bonusser
                            </Button>
                        </Flex>

                        <Box my={3}>
                            <Text lineHeight={1.5} size={'sm'}>
                                <Icon src={info} size={'sm'} mb={1} />{' '}
                                Nulstilling påvirker ikke din kontantsaldo og
                                kan ikke fortrydes.
                            </Text>
                        </Box>
                    </Box>
                )}
                <Headline as={'h5'} color={theme.dark} mt={5}>
                    AKTIVE BONUSSER
                </Headline>
                {loading ? (
                    <Flex mt={3}>
                        <Loader />
                    </Flex>
                ) : Object.keys(bonuses).length ? (
                    <>
                        {
                            //bonuses.map((bonus, index) => (
                            <ProfileCard
                                iconSrc={gift}
                                //key={index}
                                //borderRadiusTop={index === 0}
                                //borderRadiusBottom={index === bonuses.length - 1}
                                title={'Bonus'}
                                background={theme.light}
                                subtitleColor={theme.gray}
                                subtitle={`Udløber ${formatDate(
                                    bonuses.expirationDate,
                                    'DD.MM.YY'
                                )}`}
                            >
                                <Text
                                    size={'lg'}
                                    align={'right'}
                                    color={theme.dark}
                                    bold
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    {totalBonus} kr.
                                </Text>
                            </ProfileCard>
                            //))
                        }
                    </>
                ) : (
                    <Text size={'sm'}>
                        Du har ingen aktive bonusser i øjeblikket
                    </Text>
                )}
            </Box>
        </Box>
    )
}

export default UserBonus
