import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import BrandBook from 'scenes/BrandBook'
import Home from 'scenes/HomePage'
import Casino from 'scenes/CasinoPage'
import InfoPage from 'scenes/InfoPage'
import GamePage from 'scenes/GamePage'
import FindGamePage from 'scenes/FindGamePage'
import LobbyPage from 'scenes/LobbyPage'
import NewsPage from 'scenes/NewsPage'
import CampaignPage from 'scenes/CampaignPage'
import LiveCasino from 'scenes/LiveCasinoPage.jsx'
import UserAreaPage from 'scenes/UserAreaPage'
import BankPage from 'scenes/BankPage'
import ForYouPage from 'scenes/ForYouPage'
import UnsubscribePage from 'scenes/UnsubscribePage'
import Success from 'features/Deposit/Components/Success'
import Error from 'features/Deposit/Components/Error'
import MitIdError from 'features/Login/MitIdVerify/Error'
import Page404 from 'scenes/404'

// const BrandBook = lazy(() => import('scenes/BrandBook'))
// const Home = lazy(() => import('scenes/HomePage'))
// const Casino = lazy(() => import('scenes/CasinoPage'))
// const InfoPage = lazy(() => import('scenes/InfoPage'))
// const GamePage = lazy(() => import('scenes/GamePage'))
// const FindGamePage = lazy(() => import('scenes/FindGamePage'))
// const LobbyPage = lazy(() => import('scenes/LobbyPage'))
// const NewsPage = lazy(() => import('scenes/NewsPage'))
// const CampaignPage = lazy(() => import('scenes/CampaignPage'))
// const LiveCasino = lazy(() => import('scenes/LiveCasinoPage.jsx'))
// const UserAreaPage = lazy(() => import('scenes/UserAreaPage'))
// const BankPage = lazy(() => import('scenes/BankPage'))
// const ForYouPage = lazy(() => import('scenes/ForYouPage'))
// const UnsubscribePage = lazy(() => import('scenes/UnsubscribePage'))
// const Success = lazy(() => import('features/Deposit/Components/Success'))
// const Error = lazy(() => import('features/Deposit/Components/Error'))
// const MitIdError = lazy(() => import('features/Login/MitIdVerify/Error'))
// const Page404 = lazy(() => import('scenes/404'))

import { renderLoader } from 'ui/RenderLoader'

const t = window.T

const DKRouter = ({ userProfile, geoData }) => {
    return (
        <Suspense fallback={renderLoader('100px')}>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                <Route
                    exact
                    path="/log-ind"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                <Route
                    exact
                    path="/testing/log-ind"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                <Route
                    exact
                    path={['/opret-bruger', '/opret-konto']}
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                <Route path={`/${t.pages.casino}`} exact component={Casino}>
                    <Redirect to={`/${t.pages.casino}/alle`} />
                </Route>
                {/*<Route*/}
                {/*    path={`/${t.pages.casino}/${*/}
                {/*        t.casinoCategories.categories*/}
                {/*    }/sort`}*/}
                {/*    component={SortGames}*/}
                {/*/>*/}
                <Route
                    path={`/${t.pages.casino}/:category/:subCategory?`}
                    exact
                    component={Casino}
                />

                <Route
                    path="/live-casino"
                    exact
                    render={() => <LiveCasino userInfo={userProfile} />}
                >
                    <Redirect to={`/${t.pages.liveCasino}/alle`} />
                </Route>
                <Route
                    path="/live-casino/:category"
                    render={() => <LiveCasino userInfo={userProfile} />}
                />
                <Route
                    path="/konto"
                    render={() => <UserAreaPage userInfo={userProfile} />}
                    exact
                />
                <Route
                    path="/konto/:item"
                    render={() => <UserAreaPage userInfo={userProfile} />}
                    exact
                />
                <Route path={`/${t.pages.offer}`} exact component={NewsPage} />
                <Route
                    path={`/${t.pages.offer}/:campaign_id`}
                    component={CampaignPage}
                />
                <Route path="/brand-book" component={() => <BrandBook />} />
                <Route path="/aktuelt" component={BrandBook} />
                <Route path="/deposit/success" component={Success} />
                <Route path="/deposit/error" component={Error} />
                <Route path="/info/:id" component={InfoPage} />
                <Route path="/game/:game_id" component={GamePage} />
                <Route path="/findgame/:id" component={FindGamePage} />
                <Route path="/lobby" component={LobbyPage} />
                <Route path="/indbetaling" component={BankPage} />
                <Route
                    path="/promo/:page"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                {/*<Route path="/nye-spil" component={NewGamesPage} />*/}
                <Route
                    exact
                    path="/til-dig"
                    render={() => (
                        <ForYouPage
                            userProfile={userProfile}
                            geoData={geoData}
                        />
                    )}
                />
                <Route path="/afmeld-nyhedsbrev" component={UnsubscribePage} />
                <Route
                    path="/mitid-verify/success/:type"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                <Route path="/mitid-verify/error" component={MitIdError} />
                <Route
                    path="/mitid-verify/confirm/:type"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />
                <Route
                    path="/mitid-verify/forgotpassword"
                    render={() => (
                        <Home userProfile={userProfile} geoData={geoData} />
                    )}
                />

                {/*<Route*/}
                {/*    path="/spiludviklere/:category?"*/}
                {/*    component={ProvidersPage}*/}
                {/*/>*/}
                {/*<Route path="/eksklusive-spil" component={ExclusiveGamesPage} />*/}
                <Route path="*" component={Page404} />
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    )
}

export default DKRouter
