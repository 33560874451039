import freespins from 'images/categories/feature_freespinsrounds.jpg'
import megaways from 'images/categories/feature_megaways.jpg'
import bonus from 'images/categories/feature_bonusrounds.jpg'
import purchase from 'images/categories/feature_købispil.jpg'
import dropdown from 'images/categories/feature_dropdown.jpg'
import bothway from 'images/categories/feature_vindbeggeveje.jpg'
import respin from 'images/categories/feature_respin.jpg'
import vikinger from 'images/categories/theme_vikinger.jpg'
import musik from 'images/categories/theme_musik.jpg'
import luxus from 'images/categories/theme_luxus.jpg'
import film from 'images/categories/theme_ilm.jpg'
import eventyr from 'images/categories/theme_eventyr.jpg'
import juletid from 'images/categories/theme_juletid.jpg'
import sommer from 'images/categories/theme_sommer.jpg'
import wild_west from 'images/categories/theme_cowboys.jpg'
import sport from 'images/categories/theme_sport.jpg'
import retro from 'images/categories/theme_retro.jpg'
import paske from 'images/categories/theme_påske.jpg'
import irland from 'images/categories/theme_irland.jpg'
import gyser from 'images/categories/theme_gyser.jpg'
import egypten from 'images/categories/theme_egypten.jpg'
import asien from 'images/categories/theme_asien.jpg'
import junglen from 'images/categories/theme_junglen.jpg'
import krimi from 'images/categories/theme_krimi.jpg'
import scifi from 'images/categories/theme_scifi.jpg'
import bordspil from 'images/categories/bordspil.jpg'

import evolutionLobby from 'images/casino/evolution_lobby.jpg'
import baccaratLobby from 'images/casino/baccarat_lobby.jpg'
import blackjackLobby from 'images/casino/blackjack_lobby.jpg'
import danskRoulette from 'images/casino/dansk_roulette.jpg'
import gameshowLobby from 'images/casino/gameshow_lobby.jpg'
import pokerLobby from 'images/casino/poker_lobby.jpg'
import rouletteLobby from 'images/casino/roulette_lobby.jpg'

import baccaratIcon from 'images/casino/icons/baccarat.svg'
import blackjackIcon from 'images/casino/icons/blackjack.svg'
import gameshowIcon from 'images/casino/icons/gameshow.svg'
import pokerIcon from 'images/casino/icons/poker.svg'
import rouletteIcon from 'images/casino/icons/roulette.svg'

import mytologi from 'images/categories/mytologi.jpg'
import adelsten from 'images/categories/adelsten.jpg'
import dyr from 'images/categories/dyr.jpg'
import fest from 'images/categories/fest.jpg'
import frugt from 'images/categories/frugt.jpg'
import havet from 'images/categories/havet.jpg'
import kerlighed from 'images/categories/kerlighed.jpg'
import lasVegas from 'images/categories/las-vegas.jpg'
import middelalder from 'images/categories/middelalder.jpg'
import mysterium from 'images/categories/mysterium.jpg'
import natur from 'images/categories/natur.jpg'
import romerne from 'images/categories/romerne.jpg'
import superhelte from 'images/categories/superhelte.jpg'
import tv from 'images/categories/tv.jpg'

import { PROVIDERS } from 'common/providers'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = window.T
export const DANSK_ROULETTE_ID = detectMobile() && !detectIpad() ? 3265 : 2101

export const casinoFilters = {
    all: (game) => game.name && game.category.includes('slots'),
    bonus: (game) => game.category.includes('slots'),
    jackpot: (game) =>
        game.casinoPageCategories.includes('jackpot') &&
        game.category.includes('slots'),
    new: (game) =>
        game.casinoPageCategories.includes('new') &&
        game.category.includes('slots'),
    categories: () => false,
}

export const liveCasinoFilters = {
    all: (game) => game.category === 'liveint' || game.category === 'livedk',
    roulette: (game) =>
        game.category === 'liveint' && game.subcat === 'roulette',
    blackJack: (game) =>
        game.category === 'liveint' && game.subcat === 'blackjack',
    baccarat: (game) =>
        game.category === 'liveint' && game.subcat === 'baccarat',
    entertainment: (game) =>
        game.category === 'liveint' && game.subcat === 'andet',
}

export const HIGHLIGHTS_CATEGORIES = [
    {
        name: 'Home page',
        label: '',
        url: '/',
        showpage: 'homepage',
    },
    {
        name: '25-nyt',
        label: '',
        url: '25nyt',
        showpage: '25nyt',
    },
    {
        name: 'Alle spiludviklere',
        label: '',
        url: '25nyt',
        showpage: 'spiludviklere',
    },
]

export const LIVE_CASINO_CATEGORIES = [
    {
        name: 'all',
        label: t.liveCasinoPages.all,
        url: 'alle',
        highlightTitle: 'LIVE CASINO',
        thumbs: [
            {
                label: 'Evolution Lobby',
                url: `top_games`,
                logo: evolutionLobby,
            },
            {
                label: 'Dansk Roulette',
                logo: danskRoulette,
                gameId: DANSK_ROULETTE_ID,
                timeStart: '14:00',
            },
        ],
    },
    {
        name: 'roulette',
        label: t.liveCasinoPages.roulette,
        url: 'roulette',
        icon: rouletteIcon,
        thumbs: [
            {
                label: 'Roulette Lobby',
                url: `roulette`,
                logo: rouletteLobby,
            },
            {
                label: 'Dansk Roulette',
                logo: danskRoulette,
                gameId: DANSK_ROULETTE_ID,
                timeStart: '14:00', //temp solution
            },
        ],
    },
    {
        name: 'blackJack',
        label: t.liveCasinoPages.blackJack,
        url: 'blackJack',
        icon: blackjackIcon,
        thumbs: [
            {
                label: 'Blackjack Lobby',
                url: `blackjack`,
                logo: blackjackLobby,
            },
        ],
    },
    {
        name: 'baccarat',
        label: t.liveCasinoPages.baccarat,
        url: 'baccarat',
        icon: baccaratIcon,
        thumbs: [
            {
                label: 'Baccarat Lobby',
                url: `baccarat`,
                logo: baccaratLobby,
            },
        ],
    },
    {
        name: 'poker',
        label: t.liveCasinoPages.poker,
        url: 'poker',
        icon: pokerIcon,
        thumbs: [
            {
                label: 'Poker Lobby',
                url: `poker`,
                logo: pokerLobby,
            },
        ],
    },
    {
        name: 'entertainment',
        label: t.liveCasinoPages.gameShow,
        url: 'game-show',
        icon: gameshowIcon,
        thumbs: [
            {
                label: 'Gameshow Lobby',
                url: `game_shows`,
                logo: gameshowLobby,
            },
        ],
    },
]

export const EXPIRATION_OPTIONS = [
    {
        name: 'Tournaments',
        label: 'Aktuelle',
    },
    {
        name: 'PastTournaments',
        label: 'Afsluttede',
    },
]

export const SMART_FILTERS = [
    {
        name: 'freespins',
        image: freespins,
        label: 'Freespins runder',
        category: 'features',
        text:
            'Freespins er et ekstra spil, som du kan ramme på disse automater. Det betyder, at du har mulighed for at spille gratis i et lille stykke tid med de tildelte freespins-runder. Faktisk er freespins-spillet nøjagtigt det, de fleste spillere går efter, når de spiller på de nyeste automater, for det er her du finder de skjulte gevinster. Hovedparten af freespins-spil aktiveres, når du rammer et sæt scatter symboler. Hver automat har forskellige scatter symboler og du kan se informationerne på automaten.',
    },
    {
        name: 'megaways',
        image: megaways,
        label: 'Megaways™',
        category: 'features',
        text:
            'Megaways har taget verden med storm. Her er alle betalingslinjer aktive og hele hjulet kan blive fyldt med vindende symboler. Megaways automater kan forvandle selv den mindste indsats til en enorm gevinst.',
    },
    {
        name: 'bonus',
        image: bonus,
        label: 'Bonus runder',
        category: 'features',
        text:
            'Bonusrunder er der, hvor alt det sjove sker. Bonusrunder varierer fra automat til automat og tager dig med på en spændende rejse til et dybere niveau, hvor der dykkes ned i automatens tema mens vinderchancerne er i top.',
    },
    {
        name: 'purchase',
        image: purchase,
        label: 'Butik i spil',
        category: 'features',
        text:
            'Denne funktion er forholdsvis ny på markedet og åbner op for en masse nye og sjove muligheder for gevinster. Du kan f.eks. købe dig til freespins, bonusrunder, sticky wilds, respins eller noget helt femte, hvis du er træt af at vente på, at de rette symboler dukker op. Hop foran i køen på disse spil og nyd din yndlings feature, når du vil.',
    },
    {
        name: 'dropdown',
        image: dropdown,
        label: 'Dropdown gevinster',
        category: 'features',
        text:
            'Normalvis spinner symbolerne rundt på spilleautomater. Men ved dropdown falder symbolerne ned og de der rammer vindende kombinationer erstattes af nye symboler - lidt ligesom mange kender det fra spil som Candy Crush. Disse automater muliggør store kombinationer og gevinster.',
    },
    {
        name: 'bothways',
        image: bothway,
        label: 'Vind begge veje',
        category: 'features',
        text:
            'Du ved måske hvordan de fleste automater giver gevinst fra venstre mod højre? Med disse automater kan du også score gevinster fra højre mod venstre, hvilket øger dine chancer for at vinde. Du behøver ikke længere kigge væk, hvis de to første hjul er uforenelige - gevinsten kan stadig komme den anden vej fra.',
    },
    {
        name: 'respin',
        image: respin,
        label: 'Respin',
        category: 'features',
        text:
            'Hos Bet25 Casino tror vi på en chance til - og det kan du få på automaterne i denne kategori. Respins giver dig en ekstra chance for at vinde. På den måde er automaterne lidt mere tilgivende og meget mere spændende.',
    },
    ...PROVIDERS.filter((provider) => !provider.upcoming),
    {
        name: 'vikinger',
        image: vikinger,
        label: 'Vikinger',
        category: 'theme',
        text:
            "Vikinger - de vilde nordiske mennesker med skæg i ansigtet og mjød i kruset. Disse krigere er stærke og maskuline, og deres automater er fyldt med action for selv den svageste jette. Rejs til Asgård og prøv bl.a. Viking Runecraft, Asgardian Stones, Vikings Go to Hell og selvfølgelig NetEnt's hit baseret på serien Vikings ™.",
    },
    {
        name: 'musik',
        image: musik,
        label: 'Musik',
        category: 'theme',
        text:
            'Musik kan booste humøret, underholde dig og gøre dig glad. Alle spillene her kan få dig til at svinge hoften - selv når du sidder i sofaen. Prøv et par af de store som Sabaton, Guns N’ Roses og Jimi Hendrix.',
    },
    {
        name: 'bordspil',
        image: bordspil,
        label: 'Bordspil',
        category: 'theme',
        text:
            'Hvis du er til roulette, blackjack eller lige er kommet fra et spil baccarat, men du alligevel foretrækker oplevelsen i en simplere og mere tilbagelænet stil end suset på live casinoet, så er dette stedet - her du finder vores udvalg af alle klassiske bordspil, som du kender dem.',
    },
    {
        name: 'film',
        image: film,
        label: 'Film',
        category: 'theme',
        text:
            'Store film og tv-serier skaber fans - måske har du selv en yndlings? Denne kategori er en ægte øjenåbner. Chancerne for, at du ikke vidste, at nogle af disse film og serier har spilleautomater opkaldt efter dem, er store. Nogle af de gode inkluderer Narcos, 24 Timer, Paranormal Activity, Vikings og Rango.',
    },
    {
        name: 'eventyr',
        image: eventyr,
        label: 'Eventyr',
        category: 'theme',
        text:
            'Med spillene i denne kategori kan du ligeså godt tage eventyr-hatten på og begynde at løbe fra massive kampesten, springe ned i kaninhullet og svømme væk fra sultne krokodiller. Nyd spil som Red Riding Hood, Jumanji, Alice Adventure og Shield of Athena. Din næste gevinst er kun et eventyr herfra.',
    },
    {
        name: 'juletid',
        image: juletid,
        label: 'Juletid',
        category: 'theme',
        text:
            'Julen er en vidunderlig tid på året. At give såvel som at modtage gaver kan faktisk være ret specielt, men for os er det følelsen af jul, der virkelig får os i stemning. Prøv vores hyggelige julehits som Secrets of Christmas, Santa vs. Rudolf og Jingle Spin.',
    },
    {
        name: 'sommer',
        image: sommer,
        label: 'Sommer',
        category: 'theme',
        text:
            'Selv om sommeren kan man længes efter det gode vejr og en tur til stranden. Her er der ingen regn, ingen sne og ingen sure miner. Få et sommer-smil på læben uanset årstiden. Nogle af vores varmeste sommer-automater inkluderer Sunny Shores, Inferno Star og selvfølgelig Aloha Cluster Pays.',
    },
    {
        name: 'wild_west',
        image: wild_west,
        label: 'Cowboys',
        category: 'theme',
        text:
            'Vi elsker revolvere og dødbringende skud-dueller, i denne kategori finder du alle slags spil fra det vilde vesten, lige fra Dead or Alive til One Armed Bandit og Roo Riches.',
    },
    {
        name: 'sport',
        image: sport,
        label: 'Sport',
        category: 'theme',
        text:
            'Hvem elsker ikke sport? Som du ved handler alt sport ikke om OL eller fodbold - der er utallige måder at konkurrere på, lige fra hestevæddeløb og kampsport til e-sport og golf - og endda professionel wrestling! Nogle af vores favoritter fra sportens verden inkluderer Champions Cup, Street Fighter II og Hugo Goal.',
    },
    {
        name: 'retro',
        image: retro,
        label: 'Retro',
        category: 'theme',
        text:
            'Med fart på hverdagen og fremkomsten af alle de teknologier vi har i dag, er det kun naturligt, at vi også begynder at mindes de gode gamle dage. Retro har aldrig været så in, som det er i dag, og vi har nogle hyggelige nostalgiske spil. Charlie Chance, Swipe and Roll og Orient Express er kun et par af vores største nostalgi-bomber.',
    },
    {
        name: 'paske',
        image: paske,
        label: 'Påske',
        category: 'theme',
        text:
            "Påske er en særlig tid. Den står på chokoladeæg og påskeharer. Selvom der ikke er mange påske-spil at finde derude, har vi samlet nogle til dig her. Easter Eggs fra Play'n GO og Easter Island fra Yggdrasil er altid et par sikre påske klassikere.",
    },
    {
        name: 'irland',
        image: irland,
        label: 'Irland',
        category: 'theme',
        text:
            'De elsker god øl, smiler altid og ved en ting eller to om leprechauns, regnbuer og skjulte krukker med guld. Hvis grøn er din yndlingsfarve, så kan vi varmt anbefale spil som Finn and the Swirly Spin, Leprechaun Goes to Hell og Rainbow Ryan.',
    },
    {
        name: 'gyser',
        image: gyser,
        label: 'Gyser',
        category: 'theme',
        text:
            'Er du typen, der kan lide et godt gys? Og jagter du og vennerne det næste jump scare? Så er du kommet til det rette sted! Disse spil tager dig forbi mørke temaer, vampyrer, zombier og alt det gode - og det er her, du kan få (u)hyggeligt gode gevinster. Prøv bl.a. Paranormal Activity, House of Doom, Blood Suckers 2 og Dark Vortex.. hvis du tør.',
    },
    {
        name: 'egypten',
        image: egypten,
        label: 'Egypten',
        category: 'theme',
        text:
            'Ah, der er ikke et sted som Egypten! Ikke alle har nødvendigvis besøgt Egypten, men vi har alle hørt historierne om Tutankhamon, Kleopatra og alle de Egyptiske guder som Ra, Isis og Horus. Der venter gemte skatte i storslåede pyramider og dybe gravkamre. Spil som Book of Dead, Legacy of Egypt og Mercy Of Gods er særligt fristende.',
    },
    {
        name: 'luxus',
        image: luxus,
        label: 'Luxus',
        category: 'theme',
        text:
            'Lad os indse det. Selvom det er sjovt at spille casino i sig selv, ville det også være lækkert at ramme den helt store jackpot. Disse spil giver dig en forsmag på, hvordan det ville være med dyre ure og sedler, der flyver rundt. Vores favoritter her inkluderer Pimped, Vegas High Roller, Turn Of Fortune og That’s Rich!',
    },
    {
        name: 'asien',
        image: asien,
        label: 'Asien',
        category: 'theme',
        text:
            'Asien har altid været meget fascinerende for os europæere. Kontinentet er simpelthen så langt væk fra os, at der er lidt god mystik omkring det. Det er derfor ikke underligt, at der er så mange østlige automater, som vi bare elsker at spille. Nogle af de bedste inkluderer Koi Princess, Pearls of India og Shangri-La.',
    },
    {
        name: 'junglen',
        image: junglen,
        label: 'Junglen',
        category: 'theme',
        text:
            'Jungler giver følelsen af ukendt territorium, eventyr, nye arter og vilde dyr - og så er de ofte fyldt af mysterier. Der er masser af lian-fyldte spilleautomater, som kan få dig til at føle dig som kongen af junglen. Vores favoritter i denne kategori inkluderer Gorilla Kingdom, Jumanji, Jungle Spirit og Majestic Megaways.',
    },
    {
        name: 'krimi',
        image: krimi,
        label: 'Krimi',
        category: 'theme',
        text:
            'Der er noget ved forbrydelser, der har en evne til at fascinere. Vi skal have fat i det dygtigste hold retsmedicinere og de mest legendariske detektiver. Er det dig, der holder lov og orden i dit hjem, så se her. Du kan fange de allerstørste skurke i spil som Narcos, Agent Destiny og Hotline.',
    },
    {
        name: 'sci-fi',
        image: scifi,
        label: 'Sci-fi',
        category: 'theme',
        text:
            'Hvem elsker ikke science fiction? Mange fremtidsudsigter i de gamle science fiction-flicks er allerede gået i opfyldelse, men flyvende biler og teleportere er stadig ikke blevet til virkelighed. Hvis du kan lide at fantasere om fremtiden, hvorfor så ikke prøve Incinerator, Iron Girl eller Space Race?',
    },

    {
        name: 'mytologi',
        image: mytologi,
        label: 'Mytologi',
        category: 'theme',
        text:
            'Forskellige kulturer har forskellige myter, legender og folklore, og hos Bet25 Casino giver vi dig mulighed for at vække flere forskellige myter til live. Du kan blandt andet besøge den sagnomspundne by Atlantis, tage til Asgård og møde de nordiske guder eller opleve græsk mytologi på nærmeste hold.',
    },
    {
        name: 'tv',
        image: tv,
        label: 'TV',
        category: 'theme',
        text:
            "Mange danskere sidder troligt klar foran fjernsynet uge efter uge for at følge med i deres yndlingsserier, og tv-seriernes popularitet har selvfølgelig fundet vej til spilleautomaterne. Hos os kan du blandt andet opleve Gordon Ramsay's Hell's Kitchen eller hele Danmarks skærmtrold Hugo.",
    },
    {
        name: 'adelsten',
        image: adelsten,
        label: 'Ædelsten',
        category: 'theme',
        text:
            'Diamanter siges at være en piges bedste ven, men vi tiltrækkes alle af de glimtende ædelsten. Derfor har vi selvfølgelig samlet de bedste spil til dig fyldt med alverdens skinnende ædelsten i alle regnbuens farver.',
    },
    {
        name: 'las_vegas',
        image: lasVegas,
        label: 'Las Vegas',
        category: 'theme',
        text:
            'Las Vegas er spillebyen over dem alle, og slentrer du ned ad den berømte hovedgade The Strip, vil du opleve et hav af casinoer i neonlysets skær. Prøv lykken på vores udvalg af Vegas-spil, og mærk pulsen fra byen der aldrig sover.',
    },
    {
        name: 'frugt',
        image: frugt,
        label: 'Frugt',
        category: 'theme',
        text:
            'Det anbefales, at man spiser seks stykker frugt og grønt om dagen, og her hos Bet25 Casino kan vi hjælpe dig godt på vej. Vi har nemlig et stort udvalg af både klassiske frugtautomater og moderne Megaways-automater, hvor du finder masser af saftige frugter.',
    },
    {
        name: 'dyr',
        image: dyr,
        label: 'Dyr',
        category: 'theme',
        text:
            'Dyreriget er stort, og det betyder også, at vi har et stort udvalg af automater med dyr. Du kan stifte bekendtskab med både de kendte husdyr på bondegården og de mere eksotiske dyr, som gemmer sig dybt inde i junglen.',
    },
    {
        name: 'fest',
        image: fest,
        label: 'Fest',
        category: 'theme',
        text:
            'Hos Bet25 Casino spiller vi op til dans, og indbyder dig til både fødselsdagsfest, karneval og nytårsfest!',
    },
    {
        name: 'superhelte',
        image: superhelte,
        label: 'Superhelte',
        category: 'theme',
        text:
            'Du kan kende dem på deres kapper og stramme bukser, som de ifører sig når de skal bekæmpe ondskab og uretfærdighed i verden. Det er selvfølgelig superhelte vi snakker om!',
    },
    {
        name: 'middelalder',
        image: middelalder,
        label: 'Middelalder',
        category: 'theme',
        text:
            'Middelalderen var en mørk tid med mange magtkampe og store omvæltninger, og hvis du vælger et spil fra denne kategori, vil du opleve både middelalderborge, riddere og magtsyge konger. Du kan blandt andet hjælpe den dameglade Henrik d. 8 med at undslippe spøgelserne fra hans mange koner på Battle Royale eller kæmpe med korsridderne på Crusader.',
    },
    {
        name: 'havet',
        image: havet,
        label: 'Havet',
        category: 'theme',
        text:
            'Tag med på eventyr dybt under havets overflade, men pas på havets farer for dybet gemmer ikke kun på skjulte skatte.',
    },
    {
        name: 'kerlighed',
        image: kerlighed,
        label: 'Kærlighed',
        category: 'theme',
        text:
            'Størst af alt er kærligheden, og hos Bet25 Casino kan du blandt andet hente råd til kærlighedslivet hos The Love Guru eller hjælpe det elskende par med at få hinanden på Butterfly Lovers.',
    },
    {
        name: 'natur',
        image: natur,
        label: 'Natur',
        category: 'theme',
        text:
            'Pak picnickurven og tag med ud i den smukke natur på automater som Wild North, Shimmering Woods og Strolling Staxx.',
    },
    {
        name: 'mysterium',
        image: mysterium,
        label: 'Mysterium',
        category: 'theme',
        text:
            'Find din indre Sherlock Holmes frem og tag detektivhatten på, når du skal løse mysterier. Du kan blandt andet se om du kan finde ud af, hvem der står bag mordet på Riddle Reels-automaten og hjælpe med at finde gerningsvåbnet.',
    },
    {
        name: 'romerne',
        image: romerne,
        label: 'Romerne',
        category: 'theme',
        text:
            'Det lykkedes romerne at skabe et af verdens største imperier, som på et tidspunkt strakte sig helt fra Skotland i nord til Egypten i syd. Det var et blomstrende samfund, men alting har jo sin ende - også Romerriget. Vi har dog masser af automater, hvor du kan opleve Romerriget i dets storhedstid.',
    },
]

export const CASINO_CATEGORIES = [
    {
        name: 'all',
        label: t.casinoCategories.all,
        url: 'alle',
        showpage: 'automater-alle',
    },
    {
        name: 'popular',
        label: t.casinoCategories.popular,
        url: 'populære',
        showpage: 'automater-populare',
    },
    {
        name: 'new',
        label: t.casinoCategories.new,
        url: 'nyheder',
        showpage: 'automater-nyheder',
    },
    {
        name: 'jackpot',
        label: t.casinoCategories.jackpot,
        url: 'jackpots',
        showpage: 'automater-jackpots',
    },
    {
        name: 'providers',
        label: t.casinoCategories.providers,
        url: 'spiludviklere',
        showpage: 'providers',
    },
    {
        name: 'categories',
        label: t.casinoCategories.categories,
        url: 'kategorier',
        showpage: 'automater-kategorier',
    },
]
