import React, { useState } from 'react'
import NotificationConductor from 'common/conductors/NotificationConductor'
import Flex from 'ui/Flex'
import ValidInput from 'ui/controls/ValidInput'
import Checkbox from 'ui/controls/Checkbox'
import Text from 'ui/Text'
import Button from 'ui/controls/Button'
import withValidation from 'features/../common/Hocs/WithValidation'

const t = window.T

const Form = ({ setValidationHandler, validateAllFields }) => {
    const [password, setPassword] = useState('')
    const [textField, setTextField] = useState('')
    const [email, setEmail] = useState('')

    const [isPasswordShown, setIsPasswordShown] = useState(false)

    const submitHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (validateAllFields()) {
            NotificationConductor.success(t.filledWrong)
        } else {
            NotificationConductor.error(t.passwordChangedSuccessfully)
        }
    }

    return (
        <div>
            <Flex my={2}>
                <ValidInput
                    m={2}
                    onChange={(v) => setPassword(v)}
                    value={password}
                    placeholder={'Password'}
                    validate={{
                        required: true,
                        minLength: 8,
                        password: {
                            message: t.signupWindow.validation.password,
                        },
                        minLengthMessage: t.signupWindow.validation.password,
                    }}
                    type={isPasswordShown ? 'text' : 'password'}
                    getValidator={(validatorHandler) =>
                        setValidationHandler('password', validatorHandler)
                    }
                />
            </Flex>

            <Flex justify={'left'}>
                <Checkbox
                    m={2}
                    checked={isPasswordShown}
                    onChange={() => setIsPasswordShown(!isPasswordShown)}
                />

                <Text>Show password</Text>
            </Flex>

            <Flex my={2}>
                <ValidInput
                    onChange={(v) => setTextField(v)}
                    value={textField}
                    placeholder={'Number'}
                    validate={{
                        required: true,
                        minLength: 8,
                        numeric: true,
                    }}
                    type={'text'}
                    getValidator={(validatorHandler) =>
                        setValidationHandler('text', validatorHandler)
                    }
                />
            </Flex>

            <Flex my={2}>
                <ValidInput
                    onChange={(v) => setEmail(v)}
                    value={email}
                    placeholder={'Email'}
                    validate={{
                        required: true,
                        email: true,
                    }}
                    getValidator={(validatorHandler) =>
                        setValidationHandler('email', validatorHandler)
                    }
                />
            </Flex>

            <Button mt={2} onClick={submitHandler}>
                Submit
            </Button>
        </div>
    )
}

export default withValidation(Form)
