import { LEVELS_NAME } from 'configs/jackpot.config'
import gigaBg from 'images/casino/jackpots/Giga.png'
import megaBg from 'images/casino/jackpots/Mega.png'
import storBg from 'images/casino/jackpots/Stor.png'
import lilleBg from 'images/casino/jackpots/Lille.png'

class JackpotsDTO {
    constructor(evolution, isoftbet) {
        this.iSoftBetGames = isoftbet?.games?.length ? isoftbet.games : []
        this.evolutionGames = evolution?.all?.length ? evolution.all : []

        this.levels = {
            [LEVELS_NAME.ultra]: {
                name: LEVELS_NAME.ultra,
                title: 'Ultra Jackpot',
                background: gigaBg,
                color: '#002FCB',
                amount: evolution?.max?.amount,
            },
            [LEVELS_NAME.monthly]: (function() {
                const level = isoftbet?.levels?.find(
                    (level) => level.name === LEVELS_NAME.monthly
                )
                return {
                    name: LEVELS_NAME.monthly,
                    title: 'Super Jackpot Drop',
                    background: megaBg,
                    color: '#F68511',
                    amount: level?.amount,
                    closeToWin: level?.closeToWin,
                }
            })(),
            [LEVELS_NAME.weekly]: (function() {
                const level = isoftbet?.levels?.find(
                    (level) => level.name === LEVELS_NAME.weekly
                )
                return {
                    name: LEVELS_NAME.weekly,
                    title: 'Ugentlig Jackpot Drop',
                    background: storBg,
                    color: '#27BB36',
                    amount: level?.amount,
                    closeToWin: level?.closeToWin,
                }
            })(),
            [LEVELS_NAME.daily]: (function() {
                const level = isoftbet?.levels?.find(
                    (level) => level.name === LEVELS_NAME.daily
                )
                return {
                    name: LEVELS_NAME.daily,
                    title: 'Daglig Jackpot Drop',
                    background: lilleBg,
                    color: '#B622B7',
                    amount: level?.amount,
                    closeToWin: level?.closeToWin,
                }
            })(),
        }
    }
}

export default JackpotsDTO
