import gamesService from 'services/GamesService'
import GamesService from 'services/GamesService'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserService from 'services/UserService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { modalService } from 'services/ModalService'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ENV } from 'configs/main'

const t = window.T

const REAL_MODE = 'money'
const FUN_MODE = 'fun'
const NETENT_LIVE = 'netentlive'

const destroy = new Subject()

const playGame = (options, callback) => {
    gamesService.getGameLaunchUrl(options).then((apiUrl) => {
        apiUrl ? callback(apiUrl) : null
    })
}

const showLoginBeforeStart = (checkLogin, playFunMode) => {
    if (checkLogin) {
        modalService
            .getModal()
            .pipe(takeUntil(destroy))
            .subscribe((modal) => {
                if (!modal.visible) {
                    destroy.next()
                    destroy.complete()
                    if (!modal.modalName) {
                        playFunMode()
                    } else {
                        window.location.reload()
                    }
                }
            })
        modalService.showModal('LOGIN', {
            initialProps: { modalTopMargin: '0' },
        })
    } else {
        playFunMode()
    }
}

export const start = (game, callback, checkLogin = true, config) => {
    UserAdapter.isUserLoggedIn()
        .then((res) => {
            if (res) {
                //if (res.status === 'open' || res.status === 'temp') {
                let options = {
                    game_id: game.game_id,
                    server_mode: ENV(),
                    mode:
                        game.group_id === 'net-live' ? NETENT_LIVE : REAL_MODE,
                    ...config,
                    srv: ENV(),
                    pngDiv: game.game_id,
                }

                if (game.subprovider === 'evolution') {
                    options = {
                        ...options,
                        ...{
                            skin: 1,
                            brand: 1,
                        },
                    }
                }

                if (window.location.hostname === 'bet25test.dk') {
                    options.server_mode = 'test'
                }

                const userId = localStorage.getItem('userId')
                GamesService.setPopularGames(game.id, userId)

                playGame(options, callback)
                //}
                // } else {
                //     selfExclusionNotification()
                //     window.history.back()
                // }
            } else {
                UserService.clearUserState()
                showLoginBeforeStart(checkLogin, () => {
                    if (!detectMobile()) {
                        NotificationConductor.warning(t.notLoggedForPlay)
                    }
                    let options = {
                        game_id: game.game_id,
                        mode: FUN_MODE,
                        server_mode: ENV(),
                        ...config,
                        srv: ENV(),
                        pngDiv: game.game_id,
                    }

                    if (window.location.hostname === 'test.bet25.dk') {
                        options.server_mode = 'test'
                    }

                    playGame(options, callback)
                })
            }
        })
        .catch((res) => {
            NotificationConductor.error(res)
            window.history.back()
        })
}
