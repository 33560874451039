import { DEFAULT_GAMES_SERVICE, LOCAL_HOST_IP } from 'configs/main'

const KEY = 'REACT_APP_'
const MODULE_TOGGLING_KEY = 'MT_'

/**
 * Returns environment variable set in the .env files.
 * Variable type will be considered.
 *
 * @param  {string}                  variable        Environment variable name
 *                                                   without REACT_APP_ prefix.
 * @param  {*}                       defaultValue
 * @return {string|number|boolean|undefined}
 */
const env = (variable, defaultValue) => {
    let value = process.env[KEY + variable]
    if (!value) {
        return defaultValue
    }
    try {
        return JSON.parse(value)
    } catch (e) {
        return value
    }
}

const getEnv = () => {
    const env = {
        'test.be25.dk': 'prod',
        'www.be25.dk': 'prod',
        localhost: DEFAULT_GAMES_SERVICE,
        [LOCAL_HOST_IP]: DEFAULT_GAMES_SERVICE,
        'www.bet25dev.dk': 'dev',
        'bet25dev.dk': 'dev',
    }

    return env[window.location.hostname] || DEFAULT_GAMES_SERVICE
}

const toggleModule = (moduleStable, moduleExperiment, envKey) => {
    return env(MODULE_TOGGLING_KEY + envKey) ? moduleExperiment : moduleStable
}

const byEnv = (devCase, testCase, prodCase) => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return devCase
        case 'test':
            return testCase
        case 'production':
            return prodCase
        default:
            return devCase
    }
}

export { toggleModule, byEnv, getEnv }

export default env
