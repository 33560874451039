import { BehaviorSubject } from 'rxjs'

const sortFilter = new BehaviorSubject([])

export default {
    sortGames: (games, sorts) => {
        let [sort] = sorts
        let sortedGames = []

        if (sorts.length > 0) {
            sortedGames = [...games].sort(sort.sortMethod)

            return sortedGames
        }

        return games
    },

    setSorts: (sortValues) => {
        sortFilter.next(sortValues)
    },

    getSorts: () => sortFilter.asObservable(),
}
