import React, { Fragment } from 'react'
import Icon from 'ui/Icon'
import styled from 'styled-components'
import Headline from 'ui/Headline'
import PropTypes from 'prop-types'
import Box from 'ui/Box'
import { useHistory } from 'react-router-dom'
import COLORS from 'themes/primary/palette'

const StyledWrapper = styled(Box)`
    position: relative;
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Title = styled(Headline)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.dark};
    margin: 0;

    span {
        color: ${COLORS.gray};
        margin: 0 0 0 5px;
    }
`

const TitleIcon = styled.div`
    display: flex;
    width: fit-content;
    background: black;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
`
const AllButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9698a1;
    border-radius: 3px;
    padding: 0.3rem 1rem;
    cursor: pointer;
    background: white;
    transition: all 0.3s ease;
    font-size: 1rem;

    &:hover {
        box-shadow: 0 0 3px #9698a1;
    }
`

const GamesGridTitle = ({
    icon,
    title,
    labelTitle,
    buttonLink,
    buttonText,
    children,
}) => {
    const history = useHistory()
    return (
        <Fragment>
            <StyledWrapper>
                <Title as={'h3'}>
                    {icon && (
                        <TitleIcon>
                            <Icon
                                size={'md'}
                                src={icon}
                                color={'white'}
                                m={2}
                            />
                        </TitleIcon>
                    )}
                    {title} {labelTitle && <span>{labelTitle}</span>}
                </Title>
                {buttonLink && (
                    <AllButton onClick={() => history.push(buttonLink)}>
                        {buttonText || 'Alle'}
                    </AllButton>
                )}
            </StyledWrapper>
            {children}
        </Fragment>
    )
}

GamesGridTitle.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    labelTitle: PropTypes.string,
    children: PropTypes.node,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
}

export default GamesGridTitle
