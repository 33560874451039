import { PALETTE } from '@/themes'

export const getColorByProp = (color) => {
    return PALETTE[color]
}

export const numberToPixel = (number, defaultNumber) => {
    return number ? number + 'px' : defaultNumber + 'px'
}

export const DATEPICKER_THEME_CONFIG = {
    BselectionColor: `${PALETTE.accent}`,
    textColor: {
        default: '#333',
        active: '#333',
    },
    weekdayColor: `${PALETTE.lightGray}`,
    headerColor: `${PALETTE.primary}`,
    floatingNav: {
        background: `${PALETTE.primary}`,
        color: '#FFF',
        chevron: '#FFA726',
        opacity: '0.8',
    },
}
