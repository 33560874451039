import React, { Fragment } from 'react'
import { FilledWrapper } from 'ui/Wrapper'
import ModalTopBar from 'ui/ModalTopBar'
import styled from 'styled-components'

const StyledWrapper = styled(FilledWrapper)`
    height: 90%;
    color: ${(props) => props.theme.light};
    padding: 0 15px 50px;
    img {
        display: block;
        margin: 5px auto;
    }
    a {
        color: ${(props) => props.theme.green};
    }
    p,
    span {
        color: ${(props) => props.theme.light};
    }
`

const TextPage = (props) => {
    const { data } = props

    return (
        <Fragment>
            <ModalTopBar title={data.title} modalName="TEXT_PAGE" />
            <StyledWrapper
                overflow="scroll"
                dangerouslySetInnerHTML={{ __html: data.content }}
            />
        </Fragment>
    )
}

export default TextPage
