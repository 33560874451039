import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import Headline from 'ui/Headline'
import propTypes from 'prop-types'

const shine = keyframes`
  0% {
    background-position-x: 400%;
  }
  50% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -400%;
  }
`
const CenterContent = styled.div`
    max-width: 1040px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px 180px;
    @media screen and (max-width: 968px) {
        width: 100%;
        padding: 0 15px 220px;
    }
`
const Title = styled(Headline)`
    max-width: 600px;
    color: #fff;
    font-size: 60px;
    margin: 55px 0 45px;
    text-transform: uppercase;
    font-weight: 900;
    line-height: normal;
    @media screen and (max-width: 968px) {
        width: 290px;
        font-size: 48px;
        margin: 23px 0 0 20px;
    }
`
const ButtonCTA = styled.a`
    color: #404757;
    font-size: 20px;
    text-align: center;
    border-radius: 25px;
    background: #ebcd32;
    border: 2px solid #ebcd32;
    height: 55px;
    line-height: 51px;
    box-shadow: 0 0 35px #00000040;
    padding: 0 35px;
    cursor: pointer;
    margin: 0 25px 0 0;
    display: inline-block;
    text-decoration: none;

    ${({ signUpBtn }) =>
        signUpBtn &&
        css`
            background: linear-gradient(
                -45deg,
                #ebcd32 60%,
                #fbf5d6 65%,
                #ebcd32 70%
            );
            background-size: 600% 100%;
            animation: ${shine} 15s infinite;
            animation-delay: 0s;
            animation-timing-function: linear;
        `}};
    @media screen and (max-width: 968px) {
        width: 258px;
        height: 65px;
        line-height: 60px;
        text-transform: uppercase;
    }
`
const ButtonSecondary = styled.a`
    color: #fff;
    font-size: 20px;
    background-color: transparent;
    border: 2px solid #ebcd32;
    border-radius: 25px;
    height: 55px;
    line-height: 51px;
    box-shadow: 0 0 35px #00000040;
    padding: 0 35px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
`
const ButtonBlock = styled.div`
    margin: 0 0 60px;
    @media screen and (max-width: 968px) {
        position: fixed;
        z-index: 99;
        width: 100%;
        text-align: center;
        bottom: 0;
        & > ${ButtonSecondary} {
            display: none;
        }
    }
`
const TextBlock = styled.div`
    max-width: 420px;
    margin: 0 0 60px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    padding: 16px 20px;
    p {
        color: #dbdbdb;
        line-height: 16px;
        font-size: 14px;
        margin: 0;
    }
    @media screen and (max-width: 968px) {
        border-radius: 15px;
        padding: 16px 20px;
        margin: 50px 7px 0;
        p {
            color: #efefef;
            font-size: 12px;
        }
    }
`
const StepsBlock = styled.ul`
    counter-reset: stepsCouner;
    list-style: none;
    display: flex;
    padding: 0 15px;
    justify-content: space-between;
    li {
        counter-increment: stepsCouner;
        position: relative;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 30px;
        border: 1px solid #555555;
        border-radius: 25px;
        background: #262626;
        padding: 30px 45px 30px 60px;
        min-height: 115px;
        width: 30%;

        &:before {
            content: counter(stepsCouner);
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            line-height: 60px;
            width: 60px;
            height: 60px;
            border: 4px solid #ebcd32;
            border-radius: 50%;
            text-align: center;
            margin: 0 10px 0 0;
            position: absolute;
            top: 20px;
            left: -30px;
            background: #262626;
        }

        p {
            margin: 0;
        }
    }

    @media screen and (max-width: 968px) {
        display: none;
    }
`
const StepsMobile = styled.ul`
    display: none;
    @media screen and (max-width: 968px) {
        counter-reset: stepsCouner;
        list-style: none;
        padding: 0 40px 0 25px;
        margin: 55px 0 0;
        display: block;

      li {
        counter-increment: stepsCouner;
        position: relative;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 30px;
        display: flex;
        align-items: baseline;
        
        &:before {
          content: counter(stepsCouner);
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          line-height: 30px;
          width: 30px;
          height: 30px;
          border: 1px solid #EBCD32;
          border-radius: 50%;
          text-align: center;
          margin: 0 10px 0 0;
          flex: none;
        }
        p {
          margin: 0;
        }
      }
`

const PromoContent = ({ items, signUp }) => {
    return (
        <CenterContent>
            <Title
                dangerouslySetInnerHTML={{
                    __html: items.header,
                }}
            />
            <ButtonBlock>
                <ButtonCTA
                    signUpBtn={!!items.cta_button_link}
                    onClick={() => signUp(items.cta_button_link)}
                >
                    {items.cta_button_text}
                </ButtonCTA>
                <ButtonSecondary href={items.button_link}>
                    {items.button_text}
                </ButtonSecondary>
            </ButtonBlock>
            <TextBlock>
                <p
                    dangerouslySetInnerHTML={{
                        __html: items.terms,
                    }}
                />
            </TextBlock>
            <StepsBlock>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: items.step1,
                        }}
                    />
                </li>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: items.step2,
                        }}
                    />
                </li>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: items.step3,
                        }}
                    />
                </li>
            </StepsBlock>
            <StepsMobile>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: items.step1,
                        }}
                    />
                </li>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: items.step2,
                        }}
                    />
                </li>
                <li>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: items.step3,
                        }}
                    />
                </li>
            </StepsMobile>
        </CenterContent>
    )
}

PromoContent.propTypes = {
    items: propTypes.object,
}

export default PromoContent
