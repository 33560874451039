import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import Icon from 'ui/Icon'
import arrow from 'images/icons/basic-icons/arrow-thin.svg'
import Text from 'ui/Text'
import { useHistory } from 'react-router-dom'
import pageNavigations from 'common/pageNavigations'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`
const ControlFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 50px;
    padding: 8px;
    transition: background-color 0.3s ease;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    margin: 0 15px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
    ${Icon} {
        transform: rotateY(180deg);
    }
`

const Slash = styled.pre`
    color: white;
`

const StyledText = styled(Text)`
    white-space: nowrap;
`

const t = window.T

const BackButton = () => {
    const [textPath, setTextPath] = useState([])
    const history = useHistory()

    const handlePageData = () => {
        const paths = history.location.pathname.split('/')
        const combinePathText = paths.map(
            (item, index) =>
                item === '' && index === 0
                    ? t.home
                    : pageNavigations[item] || item.toUpperCase()
        )
        setTextPath(combinePathText)
    }

    const handleBack = () => {
        const prevPath = history.location.pathname
            .split('/')
            .slice(0, -1)
            .join('/')
        history.push(prevPath)
    }

    useEffect(() => {
        handlePageData()
    }, [])

    useEffect(
        () => {
            handlePageData()
        },
        [window.location.href]
    )

    return (
        <Wrapper>
            <ControlFrame onClick={handleBack}>
                <Icon
                    color={'white'}
                    width={'25px'}
                    height={'25px'}
                    src={arrow}
                />
            </ControlFrame>
            {/*This code needs for future breadcrumbs implementation */}
            {/*{textPath.length &&*/}
            {/*    textPath.map((path, index) => (*/}
            {/*        <Fragment key={index}>*/}
            {/*            {index > 0 && <Slash> / </Slash>}*/}
            {/*            <StyledText*/}
            {/*                color={*/}
            {/*                    index === textPath.length - 1*/}
            {/*                        ? 'white'*/}
            {/*                        : 'rgba(255,255,255, 0.5)'*/}
            {/*                }*/}
            {/*                size={'md'}*/}
            {/*            >*/}
            {/*                {path}*/}
            {/*            </StyledText>*/}
            {/*        </Fragment>*/}
            {/*    ))}*/}
        </Wrapper>
    )
}

export default BackButton
