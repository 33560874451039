import { Subject } from 'rxjs'
import UrlHistoryService from 'services/UrlHistoryService'
import ModalsMap from 'configs/modals'

const modalSubject = new Subject()

const getPrevUrl = () => {
    let filteredStack = UrlHistoryService.getStack().filter(
        (url) => url.indexOf('/konto') < 0
    )

    return filteredStack[0] || '/'
}

export const modalService = {
    showModal: (modalName, data = {}) => {
        let newModal = ModalsMap.find((modal) => modal.name === modalName)
        if (newModal.url)
            window.history.pushState('', '', '/casino' + newModal.url)
        setTimeout(
            () =>
                UrlHistoryService.setModalStack(
                    modalName,
                    window.location.pathname.replace('/casino', '')
                ),
            300
        )
        return modalSubject.next({
            modalName: modalName,
            visible: true,
            modalData: data,
        })
    },

    getModal: () => modalSubject.asObservable(),

    backModal: (modalName) => {
        UrlHistoryService.removeFromModalStack(modalName)
        const prevUrl = UrlHistoryService.getModalStack().length
            ? UrlHistoryService.getModalStack()[0].url
            : getPrevUrl()
        window.history.pushState('', '', '/casino' + prevUrl)
        return modalSubject.next({
            modalName: modalName,
            visible: false,
            hideAll: !modalName,
            modalData: null,
        })
    },

    closeModal: (modalName) => {
        UrlHistoryService.removeFromModalStack(modalName)
        let prevUrl = getPrevUrl()
        window.history.pushState('', '', '/casino' + prevUrl)
        return modalSubject.next({
            modalName: modalName,
            visible: false,
            hideAll: !modalName,
            modalData: null,
        })
    },
}
