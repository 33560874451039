// There are the BASIC BACKGROUNDS COLORS for derby25.primary

const BACKGROUNDS = {
    primary: '#333333',
    accent: '#ebcd32',
    accentAlt: '#FA8904',
    secondary:
        'linear-gradient(90deg, rgba(189,207,3,1) 0%, rgba(120,190,122,1) 100%)',
    greyTransparent: 'rgba(90,90,90,0.45)',
    blackTransparent: 'rgba(0, 0, 0, .2)',
    semiTransparent: 'rgba(51, 51, 51, .65)',
    solid: 'rgba(51, 51, 51)',
    blackSemiTransparent: 'rgba(20, 20, 20, .8)',
    lightSemiTransparent: 'rgba(66, 66, 66, .85)',
    red: '#F44337',
    blue: '#0091D6',
    grey: '#EBEFF6',
    darkGrey: '#262626',
    lightGrey: '#eceff5',
    white: '#FFFFFF',
    black: '#111111',
    darkgreen: '#0e3622',
    mainBgColor: '#232323',
    none: 'none',
}

export default BACKGROUNDS
