import styled, { keyframes } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { BREAKPOINTS } from 'themes'
import { slideToLeft, slideToTop } from 'utils/animations'
import Button from 'ui/controls/Button'
import BACKGROUNDS from 'themes/primary/backgrounds'
import COLORS from 'themes/primary/palette'

export const MainWrapperModal = styled.div`
    width: 400px;
    height: 100dvh;
    position: absolute;
    right: 0;
    background-color: #fff;
    background-position: top center;
    background-repeat: no-repeat;

    animation: ${({ mob }) => (mob ? slideToTop : slideToLeft)} 300ms;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: forwards;
    animation-direction: ${({ close }) => (close ? 'reverse' : 'normal')};

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        width: 100%;
    }
`
export const WrapperModal = styled.div`
    width: 100%;
    height: calc(100% - 83px);
    overflow-y: auto;
    padding: 60px 24px 24px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const FooterModal = styled.div`
    display: flex;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 16px 24px;
    position: absolute;
    border-top: 1px solid #f5f7fc;
    background: #fff;

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        position: fixed;
    }
`

export const Headline = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 24px;
    z-index: 1;
`

export const Title = styled.h4`
    font-size: 18px;
    margin: 0 0 0 8px;
    color: #000;
`

export const SubTitle = styled.h5`
    font-size: 14px;
    margin: 25px 0 15px;
    color: #000;
    font-weight: normal;
`

export const CloseButton = styled.div`
    padding: 4px;
    cursor: pointer;
`
export const CharsTableCol = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0;
`

export const CharsTableRow = styled.div`
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 0 18px 0;
    position: relative;
    border-bottom: 1px solid #f5f7fc;

    :nth-child(2n + 1) {
        :before {
            top: 5px;
            right: 0;
            width: 1px;
            height: 50px;
            background: #f5f7fc;
            position: absolute;
            content: '';
        }
    }
`

export const CharsTableTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 12px;
`

export const CharsTableValue = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #000;
    display: flex;
    align-items: baseline;
    text-transform: capitalize;
`

export const Divider = styled.hr`
    background: #f5f7fc;
    height: 1px;
    width: 100%;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
`

export const Tooltip = styled(ReactTooltip)`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    width: 200px;
    border: 1px solid #0000001a !important;
    opacity: 100% !important;
    :before {
        border-left-color: #0000001a !important;
        border-right-color: #0000001a !important;
    }
`

export const StyledFavoriteButton = styled(Button)`
    width: 80px;
    background: ${COLORS.background};
    :hover {
        background: ${COLORS.lightGray};
    }
`
