import netent from 'images/categories/provider_netent.jpg'
import playngo from 'images/categories/provider_playngo.jpg'
import nolimitcity from 'images/categories/provider_nolimitcity.jpg'
import yggdrasil from 'images/categories/provider_yggdrasil.jpg'
import isoftbet from 'images/categories/provider_isoftbet.jpg'
import evolution from 'images/categories/provider_evolution.jpg'
import authentic from 'images/categories/provider_authentic.jpg'
import playson from 'images/categories/provider_playson.jpg'
import quickspin from 'images/categories/provider_quickspin.jpg'
import elk from 'images/categories/elk.jpg'
import wazdan from 'images/categories/wazdan.jpg'
import scientific from 'images/categories/provider_scientific.png'
import btg from 'images/categories/provider_btg.png'
import gamevy from 'images/categories/provider_gamevy.png'
import lightbox from 'images/categories/provider_lightbox.png'
import hacksaw from 'images/categories/provider_hacksaw.png'
import thunderkick from 'images/categories/provider_thunderkick.jpg'
import redtiger from 'images/categories/provider_redtiger.jpg'
import onetwoGaming from 'images/categories/provider_1X2_gaming.jpg'
import blueprintGaming from 'images/categories/provider_blueprint.jpg'
import relaxGaming from 'images/categories/provider_relax.jpg'
import pragmaticGaming from 'images/categories/provider_pragmatic.jpg'
import stakelogic from 'images/categories/provider_stakelogic.jpg'

import redTigerVert from 'images/providers/Red_Tiger.png'
import scientificVert from 'images/providers/Scientific_gaming.png'
import btgVert from 'images/providers/Big_time_gaming.png'
import gamevyVert from 'images/providers/Gamevy.png'
import lightBoxVert from 'images/providers/Lightning_box.png'
import netentVert from 'images/providers/Netent.png'
import playngoVert from 'images/providers/PlaynGO.png'
import nolimitVert from 'images/providers/Nolimit_city.png'
import quickspinVert from 'images/providers/Quickspin.png'
import elkVert from 'images/providers/ELK.png'
import hacksawVert from 'images/providers/Hacksaw.png'
import isoftbetVert from 'images/providers/iSoftbet.png'
import thunderkickVert from 'images/providers/Thunderkick.png'
import yggdrasilVert from 'images/providers/Yggdrasil.png'
import wazdanVert from 'images/providers/Wazdan.png'
import playsonVert from 'images/providers/Playson.png'
import evolutionVert from 'images/providers/Evolution.png'
import authenticVert from 'images/providers/Authentic_gaming.png'
import onetwogamingVert from 'images/providers/1x2-gaming.png'
import blueprintVert from 'images/providers/Blueprint.jpg'
import relaxVert from 'images/providers/Relax.jpg'
import pragmaticVert from 'images/providers/Pragmatic.jpg'
import stakelogicVert from 'images/providers/Stakelogic.jpg'

import redtigerLogo from 'images/providers/logo_redtiger.svg'
import scientificLogo from 'images/providers/logo_scientific.svg'
import btgLogo from 'images/providers/logo_btg.svg'
import gamevyLogo from 'images/providers/logo_gamevy.svg'
import lightBoxLogo from 'images/providers/logo_lightningbox.svg'
import netentLogo from 'images/providers/logo_netent.svg'
import playngoLogo from 'images/providers/logo_playngo.svg'
import nolimitLogo from 'images/providers/logo_nolimit.svg'
import quickspinLogo from 'images/providers/logo_quickspin.svg'
import elkLogo from 'images/providers/logo_elk.svg'
import hacksawLogo from 'images/providers/logo_hacksaw.svg'
import isoftbetLogo from 'images/providers/logo_isoftbet.svg'
import thunderkickLogo from 'images/providers/logo_thunderkick.svg'
import yggdrasilLogo from 'images/providers/logo_yggdrasil.svg'
import wazdanLogo from 'images/providers/logo_wazdan.svg'
import playsonLogo from 'images/providers/logo_playson.svg'
import evolutionLogo from 'images/providers/logo_evolution.svg'
import authenticLogo from 'images/providers/logo_authentic.svg'
import onetwogamingLogo from 'images/providers/logo_1x2-gaming.svg'
import blueprintLogo from 'images/providers/logo_blueprint.svg'
import relaxLogo from 'images/providers/logo_relax.svg'
import pragmaticLogo from 'images/providers/logo_pragmatic.svg'
import stakelogicLogo from 'images/providers/logo_stakelogic.svg'

import bgOneTwoGaming from 'images/providers/backgrounds/1x2Gaming.jpg'
import bgAuthentic from 'images/providers/backgrounds/Authentic.jpg'
import bgBTG from 'images/providers/backgrounds/BigTimeGaming.jpg'
import bgELK from 'images/providers/backgrounds/Elk.jpg'
import bgEvolution from 'images/providers/backgrounds/Evolution.jpg'
import bgGamevy from 'images/providers/backgrounds/Gamevy.jpg'
import bgHacksaw from 'images/providers/backgrounds/Hacksaw.jpg'
import bgiSoftbet from 'images/providers/backgrounds/iSoftbet.jpg'
import bgLightningBox from 'images/providers/backgrounds/LightningBox.jpg'
import bgNetent from 'images/providers/backgrounds/Netent.jpg'
import bgNoLimitCity from 'images/providers/backgrounds/NoLimitCity.jpg'
import bgPlaynGO from 'images/providers/backgrounds/PlaynGO.jpg'
import bgPlayson from 'images/providers/backgrounds/Playson.jpg'
import bgQuickspin from 'images/providers/backgrounds/Quickspin.jpg'
import bgRedTiger from 'images/providers/backgrounds/RedTiger.jpg'
import bgSGGaming from 'images/providers/backgrounds/SG.jpg'
import bgThunderkick from 'images/providers/backgrounds/Thunderkick.jpg'
import bgWazdan from 'images/providers/backgrounds/Wazdan.jpg'
import bgYggdrasil from 'images/providers/backgrounds/Yggdrasil.jpg'
import bgBlueprint from 'images/providers/backgrounds/Blueprint.jpg'
import bgRelax from 'images/providers/backgrounds/Relax.jpg'
import bgPragmatic from 'images/providers/backgrounds/Pragmatic.jpg'
import bgStakelogic from 'images/providers/backgrounds/Stakelogic.jpg'

export const BASIC_PROVIDERS = [
    {
        name: 'stakelogic',
        value: 'stakelogic',
        key: 'subprovider',
        image: stakelogic,
        verticalImage: stakelogicVert,
        logo: stakelogicLogo,
        background: bgStakelogic,
        label: 'Stakelogic',
        category: 'provider',
        new: true,
    },
    {
        name: 'relax',
        value: 'relax',
        key: 'subprovider',
        image: relaxGaming,
        verticalImage: relaxVert,
        logo: relaxLogo,
        background: bgRelax,
        label: 'Relax',
        category: 'provider',
        new: true,
    },
    {
        name: 'pragmatic',
        value: 'pragmatic',
        key: 'subprovider',
        image: pragmaticGaming,
        verticalImage: pragmaticVert,
        logo: pragmaticLogo,
        background: bgPragmatic,
        label: 'Pragmatic Play',
        category: 'provider',
    },
    {
        name: 'blueprint',
        value: 'blueprint',
        key: 'subprovider',
        image: blueprintGaming,
        verticalImage: blueprintVert,
        logo: blueprintLogo,
        background: bgBlueprint,
        label: 'Blueprint',
        category: 'provider',
    },
    {
        name: '1x2',
        value: '1x2',
        key: 'subprovider',
        image: onetwoGaming,
        verticalImage: onetwogamingVert,
        logo: onetwogamingLogo,
        background: bgOneTwoGaming,
        label: '1X2 Gaming',
        category: 'provider',
    },
    {
        name: 'redtiger',
        image: redtiger,
        verticalImage: redTigerVert,
        logo: redtigerLogo,
        background: bgRedTiger,
        value: 'redtiger',
        key: 'subprovider',
        label: 'Red Tiger',
        category: 'provider',
        text:
            'Red Tiger lever og ånder, ifølge dem selv, for spil, og firmaet har da også siden 2014 skabt spil af høj kvalitet og rige på features. Firmaet er specielt kendt for deres daglige jackpots, som tilføjer spiloplevelsen masser af spænding og højt vinderpotentiale.',
    },
    {
        name: 'scientific',
        image: scientific,
        verticalImage: scientificVert,
        logo: scientificLogo,
        background: bgSGGaming,
        value: 'scientific',
        key: 'subprovider',
        label: 'Scientific Gaming',
        category: 'provider',
        text:
            'Scientific Games har været på markedet i mere end 85 år og er altså en af de ældste spillere på markedet. Det startede med fysiske automater, men gennem opkøb af andre virksomheder er de nu også begyndt at tilbyde online spilleautomater. Blandt nogle af deres ældste spil findes Zeus-automaterne, mens de i nyere tid blandt andet er kendt for Raging Rhino Megaways.',
    },
    {
        name: 'btg',
        image: btg,
        verticalImage: btgVert,
        logo: btgLogo,
        background: bgBTG,
        value: 'btg',
        key: 'subprovider',
        label: 'Big Time Gaming',
        category: 'provider',
        text:
            'Big Time Gaming kom på markedet i 2011, og de har siden da revolutioneret industrien for online automater. Det er nemlig Big Time Gaming der introducerede Megaways-mekanismen, som siden er blevet udliciteret og kopieret af et hav af andre udbydere. Du har måske hørt om Bonanza, White Rabbit eller Extra Chilli, som alle er bygget på Megaways-mekanismen.',
    },
    {
        name: 'gamevy',
        image: gamevy,
        verticalImage: gamevyVert,
        logo: gamevyLogo,
        background: bgGamevy,
        value: 'gamevy',
        key: 'subprovider',
        label: 'Gamevy',
        category: 'provider',
        text:
            'Gamevy startede med at lave lotto og bingospil, men de senere år har de også lavet online automater, og de har vundet flere priser for netop denne del af deres forretning. Blandt deres mest populære automater findes The Good, the Bad and the Ugly, Vegans vs Vampires og Vomiting Unicorns',
    },
    {
        name: 'lightbox',
        image: lightbox,
        verticalImage: lightBoxVert,
        logo: lightBoxLogo,
        background: bgLightningBox,
        value: 'lightbox',
        key: 'subprovider',
        label: 'Lightning Box',
        category: 'provider',
        text:
            'Siden 2004 har Lightning Box leveret automater til et utal af online casinoer, og de har således stor erfaring i udvikling af automater der rammer forskellige målgrupper. For at finde nogle af deres mest spillede automater, skal vi en tur på savannen, hvor Silver lion, Stellar Jackpots with Serengeti Lions og Respin Rhino huserer.',
    },
    {
        name: 'netent',
        image: netent,
        verticalImage: netentVert,
        logo: netentLogo,
        background: bgNetent,
        value: 'netent',
        key: 'subprovider',
        label: 'NetEnt',
        category: 'provider',
        text:
            'NetEnt - tidligere kendt som Net Entertainment - blev grundlagt i 1996 og var en af de allerførste online slot udbydere i verden. Deres største hits inkluderer Starburst, Aloha og Twin Spin, samt mere moderne favoritter som Lost Relics, Narcos, Hotline og Koi Princess.',
    },
    {
        name: 'playngo',
        value: 'playngo',
        key: 'subprovider',
        image: playngo,
        verticalImage: playngoVert,
        logo: playngoLogo,
        background: bgPlaynGO,
        label: 'Play’n Go',
        category: 'provider',
        text:
            "Play'n GO er en af de største spillere i online casino industrien. Dette firma blev grundlagt i Sverige allerede i 1997 og har skabt en stor andel af de største hits inden for online spilleautomater. Vi kunne liste alle de utallige spil, men lad os starte med Reactoonz, Contact, Fire Joker, Book of Dead, Hugo 2, Cash Vandal og Sabaton. Tjek dem ud, hvis du ikke allerede har gjort det.",
    },
    {
        name: 'nolimit',
        value: 'nolimit',
        key: 'subprovider',
        image: nolimitcity,
        verticalImage: nolimitVert,
        logo: nolimitLogo,
        background: bgNoLimitCity,
        label: 'Nolimit City',
        category: 'provider',
        text:
            'Nolimit City har siden 2016 været med til at tage spilindustrien til nye højder med spil af høj kvalitet bygget efter de nyeste standarder. Firmaets spil kombinerer spændende gameplay og spilmekanikker med potentiale for store gevinster. Et eksempel på dette er spillet Hot Nudge, som er et af Nolimit City’s mest populære.',
    },
    {
        name: 'quickspin',
        value: 'quickspin',
        key: 'subprovider',
        image: quickspin,
        verticalImage: quickspinVert,
        logo: quickspinLogo,
        background: bgQuickspin,
        label: 'Quickspin',
        category: 'provider',
        text:
            'Quickspin oprinder fra Sverige og kombinerer stil og flair når det kommer til at skabe deres selvudråbte "Verdens bedste casinospil”. Med et team af industriveteraner har Quickspin rykket markedet op på nye niveauer, både med hensyn til innovation og kvalitet. Faktisk har de til dato skabt nogle af de mest elskede og efterspurgte casinospil.',
    },
    {
        name: 'elk',
        value: 'elk',
        key: 'subprovider',
        image: elk,
        verticalImage: elkVert,
        logo: elkLogo,
        background: bgELK,
        label: 'ELK',
        category: 'provider',
        text:
            'ELK Studios blev grundlagt i 2013, siden da er det gået stærkt for det svenske firma. Deres spil er kendt for at være flotte og sjove, ligesom de har deres helt egne features i spillene',
    },
    {
        name: 'hacksaw',
        value: 'hacksaw',
        key: 'subprovider',
        image: hacksaw,
        verticalImage: hacksawVert,
        logo: hacksawLogo,
        background: bgHacksaw,
        label: 'Hacksaw',
        category: 'provider',
        text:
            'Hacksaw så dagens lys i 2018, hvor de begyndte at designe skrabelodder. Senere bevægede de sig ind på markedet for online automater, hvor de som et ungt og ambitiøst firma var et friskt pust i en veletablerede branche. Deres spil er kendetegnende ved spændende og anderledes gameplay og deres høje volatilitet.',
    },
    {
        name: 'isoftbet',
        value: 'isoftbet',
        key: 'subprovider',
        image: isoftbet,
        verticalImage: isoftbetVert,
        logo: isoftbetLogo,
        background: bgiSoftbet,
        label: 'iSoftBet',
        category: 'provider',
        text:
            'Spiludbyderen der er kendt for at have mange unikke spil og flere spil, som du kan genkende fra film og serier. Her kan Rango, Basic Instinct og Jack Bauer’s 24 timer nævnes. Også den populære Megaways feature kan findes blandt deres kæmpe spilsamling.',
    },
    {
        name: 'thunderkick',
        value: 'thunderkick',
        key: 'subprovider',
        image: thunderkick,
        verticalImage: thunderkickVert,
        logo: thunderkickLogo,
        background: bgThunderkick,
        label: 'Thunderkick',
        category: 'provider',
        text:
            'Thunderkick startede deres studie op i Stockholm i 2012, og siden har firmaet med stor succes skabt spil med fejlfri grafik, spændende features og højt vinderpotentiale, som giver spillerne en unik spiloplevelse. Blandt deres mest populære spil finder du titler som Flame Busters, Pink Elephants og The Falcon Huntress.',
    },
    {
        name: 'yggdrasil',
        value: 'yggdrasil',
        key: 'subprovider',
        image: yggdrasil,
        verticalImage: yggdrasilVert,
        logo: yggdrasilLogo,
        background: bgYggdrasil,
        label: 'Yggdrasil',
        category: 'provider',
        text:
            'Dette maltesiske 2013-firma er en af de bedste spiludbydere på markedet i dag og deres spil er visuelt imponerende. Her er et par af de mere populære: Vikings Go Berzerk, Golden Fish Tank, Gem Rocks og Seasons.',
    },
    {
        name: 'wazdan',
        value: 'wazdan',
        key: 'subprovider',
        image: wazdan,
        verticalImage: wazdanVert,
        logo: wazdanLogo,
        background: bgWazdan,
        label: 'Wazdan',
        category: 'provider',
        text:
            'Wazdan så dagens lys i 2010 og har sidenhen taget spilindustrien med storm. Firmaet er passioneret omkring spil, og som de selv siger, så lever og ånder de for spil. I dag har de en bred portefølje af spil med spændende gameplay og features. Blandt andet kan spillerne på udvalgte spil selv vælge volatilitet.',
    },
    {
        name: 'playson',
        value: 'playson',
        key: 'subprovider',
        image: playson,
        verticalImage: playsonVert,
        logo: playsonLogo,
        background: bgPlayson,
        label: 'Playson',
        category: 'provider',
        text:
            'Playson blev grundlagt i 2012 i hjertet af spilindustrien på Malta, og der har været fart på sidenhen! Til trods for en mindre spilportefølje end de store udbydere, så har de i dag etableret et navn for sig selv med en global tilstedeværelse på spilmarkedet. Playson er nemlig kendt for at levere spil rig på spænding og sjove bonusfeatures, så der venter dig mange timers underholdning, lige meget hvilket af deres spil du vælger.',
    },
]

export const PROVIDERS = [
    ...BASIC_PROVIDERS,
    {
        name: 'evolution',
        value: 'evolution',
        key: 'subprovider',
        image: evolution,
        verticalImage: evolutionVert,
        logo: evolutionLogo,
        background: bgEvolution,
        label: 'Evolution gaming',
        category: 'provider',
        text:
            'Evolution Gaming har valgt at satse anderledes end de typiske online casino virksomheder. I stedet for at skabe spilleautomater fokuserer dette svenske selskab fra 2006 kun på live casino spil. Evolution Gaming er kendt for at være den førende inden for live casino - og du kan endda finde danske dealere på nogle af bordene, så du rigtigt kan føle dig hjemme.',
        live: true,
    },
    {
        name: 'authentic',
        value: 'authentic',
        key: 'subprovider',
        image: authentic,
        verticalImage: authenticVert,
        logo: authenticLogo,
        background: bgAuthentic,
        label: 'Authentic gaming',
        category: 'provider',
        text:
            'Authentic Gaming har tilbudt live casino-løsninger siden 2015 med livestream direkte fra nogle af de største casinoer i verden. Firmaet tilbyder på den måde spillerne en autentisk spiloplevelse hjemme i stuerne og giver dem mulighed for at fordybe sig fuldt ud i spiloplevelsen.',
        live: true,
    },
]
