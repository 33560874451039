import Loader from 'ui/Loader'
import React from 'react'

export const renderLoader = (height = 'auto') => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height,
        }}
    >
        <Loader />
    </div>
)
