import styled, { css } from 'styled-components'
import { getColorByProp } from 'utils/themeHelpers'
import { marginProps, paddingProps } from 'ui/SpacingUtility'

const Wrapper = styled.div`
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: ${(props) => (props.padding ? props.padding : '15px')};
    background: ${(props) => (props.background ? props.background : 'none')};
    ${(props) =>
        props.display
            ? css`
                  display: ${props.display};
              `
            : ''};
    ${(props) =>
        props.align
            ? css`
                  align-items: ${props.align};
              `
            : ''};
    ${(props) =>
        props.justify
            ? css`
                  justify-content: ${props.justify};
              `
            : ''};
    ${(props) =>
        props.borderRadius
            ? css`
                  border-radius: ${props.borderRadius};
              `
            : ''};
    ${(props) => (props.width ? 'width: ' + props.width + ';' : '')};
    ${(props) => (props.height ? 'height: ' + props.height + ';' : '')};
    ${(props) => (props.nowrap ? 'white-space: nowrap;' : '')};
    ${(props) =>
        props.shadow
            ? 'box-shadow: 0px 0px ' +
              (props.shadow ? props.shadow : 0) +
              'px rgba(0,0,0,0.7);'
            : null};
    ${(props) =>
        props.rounded
            ? 'border-radius: ' + (props.rounded ? props.rounded : 10) + 'px;'
            : null};
    ${(props) => (props.flex ? 'display: flex' : '')};
    ${(props) =>
        props.transition
            ? 'transition: ' + (props.transition ? props.transition : '') + ';'
            : null};
    ${(props) =>
        props.overflow
            ? 'overflow: ' + (props.overflow ? props.overflow : 'auto') + ';'
            : null};
    ${marginProps};
    ${paddingProps};
`

export const FilledWrapper = styled(Wrapper)`
    background: ${(props) => getColorByProp(props)};
`

export default Wrapper
