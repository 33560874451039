import styled, { css } from 'styled-components'
import { getColorByProp } from 'utils/themeHelpers'
import PropsTypes from 'prop-types'

const CardHeader = styled.header`
    width: auto;
    height: auto;
    background: ${(props) => getColorByProp(props)};
    color: ${(props) => props.theme.light};
    font-size: 14px;
    padding: 10px;
`

const CardContent = styled.section`
    width: 100%;
    height: 80%;
    background: url(${(props) => (props.imageSrc ? props.imageSrc : '')}) center
        no-repeat;
    background-size: cover;
    color: ${(props) => props.theme.dark};
`

const CardFooter = styled(CardContent)`
    font-size: 12px;
    background: ${(props) => props.theme.primary};
    box-shadow: 0 -6px 15px -2px ${(props) => props.theme.primary};
    overflow: hidden;
    padding: 0 5px;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    align-items: center;
    height: ${(props) => (props.height ? props.height : '20px')};
    justify-content: space-around;
`

const Card = styled.section`
    width: 100%;
    height: auto;
    min-height: 30px;
    color: ${(props) => (props.color ? props.color : 'inherit')};
    border-radius: 6px;
    overflow: hidden;
    background: ${(props) =>
        props.background ? props.background : props.theme.light};
    ${(props) =>
        props.shadow &&
        css`
            box-shadow: 0 0 5px lightgray;
        `};
`

Card.propTypes = {
    background: PropsTypes.string,
}

export { CardHeader, CardContent, CardFooter, Card }
