import React, { lazy, useEffect, useState } from 'react'
import PageContent from 'ui/PageContent'
import { useHistory } from 'react-router-dom'
import Box from 'ui/Box'
import WithGames from 'common/Hocs/WithGames'
import MediaGrid from 'ui/MediaGrid'
import GameList from 'features/GamesList/GameList'
import GamesGridTitle from 'ui/GamesGridTitle'

import GamesUtilsService from 'services/GamesUtilsService'
import GamesService from 'services/GamesService'
import COLORS from 'themes/primary/palette'
import Button from 'ui/controls/Button'
import { withTheme } from 'styled-components'
import gamesService from 'services/GamesService'
import RecentlyPlayedSlider from 'features/RecentlyPlayedGames'

const POPULAR_GAMES_LIMIT = 8
const GAMES_COUNT_TO_SHOW = 8
const ForYouPage = ({ userProfile }) => {
    const history = useHistory()
    const [recentlyGames, setRecentlyGames] = useState([])
    const [recommendedGames, setRecommendedGames] = useState([])
    const [favoriteGames, setFavoriteGames] = useState([])
    const [popularGames, setPopularGames] = useState([])
    const [gamesToShow, setGamesToShow] = useState(GAMES_COUNT_TO_SHOW)

    const loadMore = (increment = GAMES_COUNT_TO_SHOW) => {
        setGamesToShow(gamesToShow + increment)
    }

    const favoriteSubCallback = () =>
        GamesUtilsService.getFavoriteGames().subscribe((res) => {
            if (res) {
                setFavoriteGames(res)
            }
        })

    const recommendedSubCallback = () =>
        GamesUtilsService.getRecommendedGames().subscribe((res) => {
            if (res) {
                setRecommendedGames(res)
            }
        })

    useEffect(() => {
        if (userProfile && userProfile.userId) {
            GamesUtilsService.fetchRecommendedGames()
            GamesUtilsService.fetchFavorite()

            const favoriteSub = favoriteSubCallback()

            GamesService.getPopularGames({
                limit: POPULAR_GAMES_LIMIT,
                period: 7,
            })
                .then((res) => {
                    if (res.length) {
                        setPopularGames(res)
                    }
                })
                .catch((e) => console.error(e))

            const recentlyGamesSub = gamesService
                .getRecentlyPlayedGames()
                .subscribe((res) => {
                    setRecentlyGames(res)
                })

            return () => {
                recentlyGamesSub.unsubscribe()
                favoriteSub.unsubscribe()
            }
        } else {
            history.push('/404')
        }
    }, [])

    useEffect(
        () => {
            if (!userProfile.userId) {
                history.push('/')
            }
        },
        [userProfile]
    )

    useEffect(
        () => {
            if (favoriteGames.length) {
                GamesUtilsService.fetchRecommendedGames()
                const favoriteSub = favoriteSubCallback()
                const recommendedSub = recommendedSubCallback()

                return () => {
                    favoriteSub.unsubscribe()
                    recommendedSub.unsubscribe()
                }
            }
        },
        [favoriteGames.length]
    )

    return (
        <>
            {userProfile.userId && (
                <>
                    {favoriteGames.length ? (
                        <>
                            <PageContent px={3} pb={4} maxWidth={'1140px'}>
                                <GamesGridTitle
                                    title={'Favorit spil'}
                                    labelTitle={'' + favoriteGames?.length}
                                >
                                    <MediaGrid>
                                        <GameList
                                            games={favoriteGames}
                                            limit={gamesToShow}
                                        />
                                    </MediaGrid>
                                    {favoriteGames?.length > gamesToShow && (
                                        <Box style={{ textAlign: 'center' }}>
                                            <Button
                                                size={'smB'}
                                                mt={4}
                                                onClick={() => loadMore()}
                                                background={COLORS.background}
                                                hover={COLORS.lightGray}
                                            >
                                                Se flere spil
                                            </Button>
                                        </Box>
                                    )}
                                </GamesGridTitle>
                            </PageContent>

                            {recommendedGames.length ? (
                                <PageContent px={3} pb={4} maxWidth={'1140px'}>
                                    <GamesGridTitle title={'Anbefalet til dig'}>
                                        <MediaGrid>
                                            <GameList
                                                games={recommendedGames}
                                                limit={4}
                                            />
                                        </MediaGrid>
                                    </GamesGridTitle>
                                </PageContent>
                            ) : null}
                        </>
                    ) : (
                        <PageContent px={3} pb={4} maxWidth={'1140px'}>
                            <GamesGridTitle
                                title={'Populære automater'}
                                buttonLink={'/automater/populære'}
                            >
                                <MediaGrid>
                                    <GameList
                                        games={popularGames}
                                        limit={POPULAR_GAMES_LIMIT}
                                    />
                                </MediaGrid>
                            </GamesGridTitle>
                        </PageContent>
                    )}

                    <PageContent
                        maxWidth={'1140px'}
                        style={{
                            display: 'inherit',
                            marginTop: '1rem',
                            marginBottom: '3rem',
                        }}
                    >
                        <Box px={2}>
                            <RecentlyPlayedSlider gamesData={recentlyGames}>
                                <Box px={2}>
                                    <GamesGridTitle title={'Senest spillede'} />
                                </Box>
                            </RecentlyPlayedSlider>
                        </Box>
                    </PageContent>
                </>
            )}
        </>
    )
}

export default withTheme(WithGames(ForYouPage))
