import React, { useEffect } from 'react'
import { modalService } from 'services/ModalService'
import { RestorePassword, WithClearSafariPageCache } from '@it25syv/25syv-ui'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'
import UserService from 'services/UserService'

const RestorePass = ({ data }) => {
    const userData = data.userProfile
    const history = useHistory()

    useEffect(() => {
        UserService.attachUser()
    }, [])

    return (
        <RestorePassword
            closeAction={() => {
                modalService.closeModal()
                history.push('/')
            }}
            site={'casino'}
            userProfile={userData}
            successHandler={() => {
                NotificationConductor.success('Dit kodeord er blevet ændret')
                history.push('/')
            }}
            onError={(e) => {
                NotificationConductor.error(e.errorMessage)
            }}
        />
    )
}

export default WithClearSafariPageCache(RestorePass)
