import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

import rofusLogo from 'icons/basic-icons/rofus.svg'
import rofus from 'images/footer/promo/rofus_logo_new.png'
import stopspillet from 'images/footer/promo/stopspillet.png'
import centerLudomani from 'images/footer/promo/center_ludomani.png'

const FooterWrapper = styled.div`
    flex-shrink: 0;
    position: relative;
`
const FooterTop = styled.div`
    width: 100%;
    min-height: 220px;
    background-color: #404040;
`
const TopTextBlock = styled.div`
    max-width: 1040px;
    position: relative;
    margin: 0 auto;
    font-size: 13px;
    display: flex;
    padding: 0 20px;
    @media screen and (max-width: 968px) {
        width: 100%;
    }
`
const TopText = styled.div`
    left: 0;
    top: -100px;
    position: relative;
    width: auto;
    background-color: #212121;
    border-radius: 12px;
    min-height: 130px;
    color: #ffffff;
    font-size: 12px;
    padding: 22px 40px;
    @media screen and (max-width: 968px) {
        margin: 0 15px;
    }
`
const TopCenter = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    padding: 0 0 60px;
    @media screen and (max-width: 958px) {
        padding-bottom: 25px;
        flex-direction: column;
    }
`
const TopCenterItem = styled.div`
    width: 270px;

    h3 {
        font-size: 18px;
        margin: 12px 0;
    }

    p {
        color: rgba(256, 256, 256, 0.6);
        font-size: 15px;
    }

    @media screen and (max-width: 968px) {
        width: 100%;
        padding: 0 53px;
        text-align: center;
        h3 {
            font-size: 16px;
        }
        p {
            font-size: 12px;
        }
    }
`
const FooterLogo = styled.img`
    margin: 20px 0 0;
    height: 40px;
    @media screen and (max-width: 968px) {
        margin: 0;
    }
`
const DGABlock = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    color: #fff;
    align-items: center;

    img {
        height: 80px;
    }

    @media screen and (max-width: 968px) {
        font-size: 16px;
        padding: 25px;
    }
`
const DGABlockTitle = styled.div`
    width: 200px;
    margin-left: 10px;
    font-size: 18px;
`
const FooterBottom = styled.div`
    background: #333;
    padding: 50px 0 100px;
    @media screen and (max-width: 968px) {
        padding: 30px;
    }
`
const BottomCenter = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 968px) {
        width: 100%;
    }
`
const Support = styled.div`
    width: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: space-around;
    @media screen and (max-width: 968px) {
        flex-direction: column;
    }
`
const SupportItem = styled.div`
    border-radius: 5px;
    background: #585858;
    width: 33%;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 35px;
    }
    @media screen and (max-width: 968px) {
        width: 100%;
        margin: 0 0 17px;
        justify-content: space-between;
        padding: 0 20px;
    }
`
const SupportButton = styled.a`
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    background: rgba(256, 256, 256, 0.25);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin: 0 0 0 15px;
    @media screen and (max-width: 968px) {
        width: 155px;
        text-align: center;
        font-size: 15px;
    }
`
const Copyright = styled.div`
    color: rgba(256, 256, 256, 0.6);
    display: flex;
    justify-content: center;
    margin: 25px 0 0;
    font-size: 15px;
    @media screen and (max-width: 968px) {
        font-size: 16px;
        flex-direction: column;
        text-align: center;
    }
`
const CopyrightItem = styled.div`
    width: 220px;
    color: rgba(256, 256, 256, 0.6);
    white-space: pre-wrap;
    @media screen and (max-width: 968px) {
        width: 100%;
        margin: 20px 0 0;
    }
`
const AgeRestrict = styled.div`
    position: relative;
    top: 5px;
    float: left;
    width: 35px;
    height: 35px;
    background: #fff;
    font-size: 15px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
    color: #333;
    border-radius: 50%;
    margin: 0 8px 0 0;
`
const t = window.T.Footer
const PromoFooter = ({ items, imgHost, logo, projectName }) => {
    return (
        <FooterWrapper>
            <FooterTop>
                <TopTextBlock>
                    <TopText
                        dangerouslySetInnerHTML={{ __html: items.terms2 }}
                    />
                </TopTextBlock>
                <TopCenter>
                    <TopCenterItem>
                        <FooterLogo
                            src={items.logo ? imgHost + items.logo : logo}
                            alt={projectName}
                        />
                        <h3>{t.offered}</h3>
                        <p>{t.address}</p>
                    </TopCenterItem>
                    <DGABlock>
                        <img
                            src={rofusLogo}
                            alt="Tilladelse fra og under tilsyn af Spillemyndigheden."
                        />
                        <DGABlockTitle>{t.permission}</DGABlockTitle>
                    </DGABlock>
                </TopCenter>
            </FooterTop>
            <FooterBottom>
                <BottomCenter>
                    <Support>
                        <SupportItem>
                            <a href="https://www.stopspillet.dk/">
                                <img src={stopspillet} alt="Stopspillet" />
                            </a>
                            <SupportButton href="tel:70-22-28-25">
                                {`${t.call} 70 22 28 25`}
                            </SupportButton>
                        </SupportItem>
                        <SupportItem>
                            <a href="https://ludomani.dk/">
                                <img src={centerLudomani} alt="Ludomani" />
                            </a>
                            <SupportButton href="https://ludomani.dk/">
                                {t.testYourself}
                            </SupportButton>
                        </SupportItem>
                        <SupportItem>
                            <a href="https://www.rofus.nu/">
                                <img src={rofus} width={90} alt="Rofus" />
                            </a>
                            <SupportButton href="https://www.rofus.nu/">
                                {t.registration}
                            </SupportButton>
                        </SupportItem>
                    </Support>
                    <Copyright>
                        <CopyrightItem>{t.copyright}</CopyrightItem>
                        <CopyrightItem>
                            <AgeRestrict>18+</AgeRestrict>
                            {t.addictiveGame}
                        </CopyrightItem>
                    </Copyright>
                </BottomCenter>
            </FooterBottom>
        </FooterWrapper>
    )
}

PromoFooter.propTypes = {
    items: propTypes.object.isRequired,
    imgHost: propTypes.string,
    logo: propTypes.string,
    projectName: propTypes.string,
}

export default PromoFooter
