import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import PageWrapper from 'ui/PageWrapper'
import PageContent from 'ui/PageContent'
import BannerSlider from 'ui/BannerSlider'
import Box from 'ui/Box'
import MediaGrid from 'ui/MediaGrid'
import NewGamesGridTitle from 'ui/NewGamesGridTitle'
import Loader from 'ui/Loader'
import Flex from 'ui/Flex'
import GameList from 'features/GamesList/GameList'
import { sortingByHotClicks } from 'utils/sorting'
import star from 'images/icons/basic-icons/aсcount-area/star.svg'
import pageBannerImage from 'images/banners/page/exclusive_games_banner.jpg'

const ExclusiveGamesPage = () => {
    const tempSlides = [pageBannerImage] // temp
    const [exclusiveGames, setExclusiveGames] = useState([])

    useEffect(() => {
        const sub = GamesService.combineAllGames().subscribe((games) => {
            if (games?.length) {
                setExclusiveGames(
                    sortingByHotClicks(games.filter((game) => game.exclusive))
                )
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [])

    return (
        <PageContent maxWidth={'1140px'}>
            <NewGamesGridTitle
                icon={star}
                iconColor={'#FAE311'}
                text={'Eksklusive spil'}
            />
            {!exclusiveGames.length ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : (
                <Box my={3}>
                    <MediaGrid>
                        <GameList games={exclusiveGames} />
                    </MediaGrid>
                </Box>
            )}
        </PageContent>
    )
}

export default ExclusiveGamesPage
