import React, { useState, useEffect } from 'react'
import gamesService from 'services/GamesService'
import GamesService from 'services/GamesService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import ContentService from 'services/ContentService'
import { useHistory } from 'react-router-dom'

const t = window.T

const WithGames = (Component) => {
    return (props) => {
        const history = useHistory()
        //const [recentlyGames, setRecentlyGames] = useState([])
        const [allGames, setAllGames] = useState([])
        const [allProvGames, setAllProvGames] = useState([])
        const [gamesToMap, setGamesToMap] = useState([])
        const [isGamesReceived, setIsGamesReceived] = useState(false)

        const [searchText, setSearchText] = useState('')

        const gameStartHandler = (game) => {
            GamesService.removeMultipleGames()

            if (
                (game.liveCas || game.category === 'liveint') &&
                (localStorage.getItem('logged') === 'false' ||
                    localStorage.getItem('logged') === null)
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
                return
            }

            if (
                (localStorage.getItem('logged') === 'false' ||
                    localStorage.getItem('logged') === null) &&
                detectMobile()
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
            }

            GamesService.setSearchDisplay(false)

            GamesService.checkLoginBeforeStart(game, history)

            if (
                localStorage.getItem('logged') !== null &&
                localStorage.getItem('logged') !== 'false'
            ) {
                GamesService.pushGameToRecentlyPlayed(game)
            }
        }

        useEffect(
            () => {
                const allGamesSubscription = gamesService
                    .getAllGames()
                    .subscribe((games) => {
                        setGamesToMap(games)
                        setAllGames(games)
                        setIsGamesReceived(games.length)
                    })

                const searchTextSubscription = gamesService
                    .getSearchText()
                    .subscribe((text) => {
                        setSearchText(text)
                    })

                const allGamesProvSubscription = gamesService
                    .getAllProvidersGames()
                    .subscribe((games) => {
                        if (games && games.length) {
                            setAllProvGames(games)
                            gamesService.setAllProvidersGames(games)
                        }
                    })
                return () => {
                    allGamesSubscription.unsubscribe()
                }

                return () => {
                    allGamesSubscription.unsubscribe()
                    searchTextSubscription.unsubscribe()
                    allGamesProvSubscription.unsubscribe()
                }
            },
            [allGames]
        )

        useEffect(
            () => {
                setGamesToMap(
                    gamesService.filterGamesByName(allGames, searchText)
                )
            },
            [searchText]
        )

        const customProps = {
            allGames,
            allProvGames,
            gamesToMap,
            searchText,
            isGamesReceived,
            gameStartHandler,
        }

        return isGamesReceived ? (
            <Component {...props} {...customProps} />
        ) : null
    }
}

export default WithGames
