import React from 'react'
import styled, { css } from 'styled-components'

const ControlButton = styled.button`
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${({ top }) => top || '3rem'};
    right: 0.5rem;
    border: 1px solid #9698a1;
    padding: 0.7rem;
    cursor: pointer;
    background: white;
    transition: all 0.3s ease;

    ${({ left }) =>
        left &&
        css`
            border-radius: 3px 0 0 3px;
            right: 2.5rem;
        `};
    ${({ right }) =>
        right &&
        css`
            border-radius: 0 3px 3px 0;
        `};
    ${({ disabled }) =>
        disabled
            ? css`
                  filter: contrast(0.6);
                  cursor: default;
              `
            : css`
                  &:hover {
                      box-shadow: 0 0 3px #9698a1;
                  }
              `};
`

const SliderControl = ({ children, ...props }) => {
    return <ControlButton {...props}>{children}</ControlButton>
}

export default SliderControl
