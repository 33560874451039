import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'ui/controls/Button'
import ButtonList from 'features/ButtonList/ButtonList'
import { PALETTE } from 'themes'
import COLORS from 'themes/primary/palette'

const ButtonListHolder = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        display: inline-block;
    }
`

const StyledButton = styled(Button)`
    padding: 0.8rem 0.5rem;
    border-radius: 8px;
    font-size: 12px;

    span {
        color: ${(props) =>
            props.active ? 'rgba(255,255,255,.65)' : COLORS.gray};
    }
    ${(props) =>
        !props.active &&
        css`
            :hover {
                background: ${PALETTE.secondary};
            }
        `};

    @media (min-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
        background: ${(props) => !props.active && (props.background || 'none')};
        margin: 0.25rem;
        padding: 0.8rem 1.2rem;
    }
`

const ButtonsSwitcher = ({ tabs, activeTab, onChange }) => {
    return (
        <ButtonListHolder>
            <ButtonList
                renderButton={(props) => <StyledButton bold {...props} />}
                buttonSet={tabs}
                value={activeTab}
                onChange={onChange}
            />
        </ButtonListHolder>
    )
}

export default ButtonsSwitcher
