import React, { Fragment } from 'react'
import styled from 'styled-components'
import CurrentlyExcluded from 'features/SelfExclusion/CurrentlyExcluded'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Text from 'ui/Text'
import Box from 'ui/Box'

const t = window.T

const StyledWrapper = styled(Wrapper)`
    position: relative;
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const SelfExclusionHolder = styled(Box)`
    height: auto;
    min-height: 100%;
`

const SelfExcludedText = ({ data, theme }) => {
    const { permanent } = data

    return (
        <SelfExclusionHolder background={theme.light}>
            <ModalTopBar
                background={theme.primary}
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    {t.userArea.selfExclusion.title}
                </Text>
            </ModalTopBar>
            <StyledWrapper>
                <CurrentlyExcluded permanent={permanent} />
            </StyledWrapper>
        </SelfExclusionHolder>
    )
}

export default SelfExcludedText
