import React, { useEffect, useRef, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import GamesService from 'services/GamesService'
import styled from 'styled-components'
import Box from 'ui/Box'
import MediaGrid from 'ui/MediaGrid'
import InfinityScroller from 'features/Scroll/InfinityScroller'
import GameList from 'features/GamesList/GameList'
import Displayer from 'ui/Displayer'
import gamesService from 'services/GamesService'
import SearchInput from 'features/SearchModal/SearchInput'
import { renderLoader } from 'ui/RenderLoader'
import { useDebounce } from 'common/Hooks/Debounce'

const StyledDisplayer = styled(Displayer)`
    height: 80%;
    overflow: scroll;
`

const GameSearch = ({ index, gamesForMultiScreen }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [gamesToMap, setGamesToMap] = useState([])
    const [displayedGames, setDisplayedGames] = useState([])
    const [gamesToShow, setGamesToShow] = useState(12)
    const [showLoader, setShowLoader] = useState(true)

    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const gamesRef = useRef(null)

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.target.blur()
        }
    }

    const filterGames = (games) => {
        let filteredGames = games
        gamesForMultiScreen.forEach((multiGame) => {
            filteredGames = filteredGames.filter(
                (game) => !gameRestrictions(game, multiGame)
            )
        })

        return filteredGames
    }

    const isProviderRunInMultiple = (provider) =>
        !!gamesForMultiScreen.find((game) => game.subprovider === provider)

    const gameRestrictions = (game, multiGame) => {
        const isPragmaticRunInMultiple =
            isProviderRunInMultiple('pragmatic') &&
            game.subprovider === 'pragmatic'

        return (
            isPragmaticRunInMultiple ||
            game.subprovider === 'evolution' ||
            multiGame.id === game.id
        )
    }

    useEffect(
        () => {
            setShowLoader(true)

            setTimeout(() => {
                setShowLoader(false)
            }, 2500)
            let games = gamesToMap.sort(
                (prev, next) => (prev.name < next.name ? -1 : 1)
            )
            setDisplayedGames(filterGames(games))
        },
        [gamesToMap]
    )

    useEffect(() => {
        const allGamesSub = gamesService
            .getAllProvidersGames()
            .subscribe((allGames) => {
                setGamesToMap(allGames)
            })

        return () => {
            allGamesSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            setDisplayedGames(
                filterGames(
                    gamesService.filterGamesByName(
                        gamesToMap,
                        debouncedSearchTerm || ' '
                    )
                )
            )
        },
        [debouncedSearchTerm, gamesForMultiScreen]
    )

    return (
        <Wrapper width="100%" overflow="hidden" background="white">
            <SearchInput
                autoFocus
                placeholder={'Søg efter spil'}
                background={'white'}
                controlColor={'gray'}
                hideCancel={true}
                value={searchTerm}
                onChange={(val) => setSearchTerm(val)}
                onKeyPress={keyPressHandler}
            />
            <StyledDisplayer
                display={displayedGames.length ? 'block' : 'none'}
                ref={gamesRef}
            >
                <Box my={3} height={'100%'}>
                    <MediaGrid gap={'0.5rem'}>
                        <InfinityScroller
                            maxItems={displayedGames.length}
                            onScrollToBottom={(countToShow) => {
                                setShowLoader(true)
                                setGamesToShow(countToShow)
                            }}
                            onBottomReach={() => {
                                setShowLoader(false)
                            }}
                            scrollParent={gamesRef.current}
                        >
                            <GameList
                                games={displayedGames}
                                limit={gamesToShow}
                                onGameClick={GamesService.updateMultipleGames}
                                multipleGameIndex={index}
                            />
                        </InfinityScroller>
                    </MediaGrid>
                    {showLoader && renderLoader()}
                </Box>
            </StyledDisplayer>
        </Wrapper>
    )
}

export default GameSearch
