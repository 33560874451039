import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import GamesService from 'services/GamesService'
import MultipleGames from 'features/GameArea/MultipleGames'
import { useHistory } from 'react-router-dom'
import { CMS_PATH } from 'configs/rest'
import { CDN_IMG_PATH } from 'configs/main'

const StyledGamePopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: black;
    position: relative;
    height: ${(props) => {
        return `calc(100vh - ${props.theme.FULL_HEADER_HEIGHT}px);`
    }};
    z-index: 101;
    background: ${({ background }) =>
        background ? `url(${background})` : 'none'};
    background-repeat: no-repeat;
    background-size: cover;
`

const MultipleGamesRatioWrapper = styled.div`
    width: ${({ fullScreen }) => (fullScreen ? '90vw' : '75vw')};
    height: calc(
        ${({ fullScreen }) => (fullScreen ? '90vw' : '75vw')} * 0.5625
    );
    max-height: 80vh;
    max-width: calc(80vh * 1.777);
    display: flex;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1;
`

const GamePopup = ({ game, checkLogin, isMultiscreenMode }) => {
    const history = useHistory()
    const [fullScreen, setFullScreen] = useState(false)
    const [sizeChange, setSizeChange] = useState(false)
    const [multiScreenMode, setMultiScreenMode] = useState(false)
    const [gamesForMultiScreen, setGamesForMultiScreen] = useState([])
    const [mainGame, setMainGame] = useState({})

    const getBGIMage = () => {
        if (mainGame.images?.bg) {
            return CMS_PATH + mainGame.images.bg
        } else {
            return `${CDN_IMG_PATH}img/bgs/${mainGame.game_id}-bg.jpg`
        }
    }

    useEffect(() => {
        const fullScreenSub = GamesService.checkFullScreen().subscribe(
            (state) => {
                setFullScreen(state)
            }
        )
        const multiGamesSub = GamesService.getMultipleGames().subscribe(
            (games) => {
                setGamesForMultiScreen(games)
                setMultiScreenMode(games.length > 1)
            }
        )

        return () => {
            GamesService.removeMultipleGames()
            setGamesForMultiScreen([])
            fullScreenSub.unsubscribe()
            multiGamesSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (game && Object.keys(game).length > 0) {
                GamesService.setMultipleGames(game)
            }
        },
        [game]
    )

    useEffect(
        () => {
            setTimeout(() => {
                setSizeChange(fullScreen)
            }, 45)
        },
        [fullScreen]
    )

    useEffect(
        () => {
            let url = ''
            if (!multiScreenMode && gamesForMultiScreen[0]?.game_id) {
                url = `/game/${gamesForMultiScreen[0].game_id}`
            } else {
                url = `${history.location.pathname}${
                    multiScreenMode ? '?multiscreen' : ''
                }`
            }
            history.push(url)
            isMultiscreenMode(multiScreenMode)
        },
        [multiScreenMode]
    )

    useEffect(
        () => {
            if (gamesForMultiScreen.length) {
                const gameId = gamesForMultiScreen[0]
                setMainGame(gameId)
            }
        },
        [gamesForMultiScreen]
    )

    const removeGameFromMultiple = (key) => {
        GamesService.removeMultipleGames(key)
    }

    return (
        <StyledGamePopupWrapper
            background={gamesForMultiScreen.length === 1 ? getBGIMage() : null}
            full={fullScreen}
        >
            {gamesForMultiScreen.length > 0 && (
                <MultipleGamesRatioWrapper fullScreen={fullScreen}>
                    {gamesForMultiScreen.map((el, index) => (
                        <MultipleGames
                            key={el.id ? el.id : index}
                            index={index}
                            game={el}
                            gamesForMultiScreen={gamesForMultiScreen}
                            fullScreen={fullScreen}
                            sizeChange={sizeChange}
                            multiScreenMode={multiScreenMode}
                            removeGameFromMultiple={removeGameFromMultiple}
                            checkLogin={checkLogin && !multiScreenMode}
                        />
                    ))}
                </MultipleGamesRatioWrapper>
            )}
        </StyledGamePopupWrapper>
    )
}

export default GamePopup
