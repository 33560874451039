import styled from 'styled-components'
import { paddingProps } from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const PageContent = styled.div`
    ${paddingProps};
    flex: 1 0 auto;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
    width: 100%;
    margin: 0 auto;

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        max-width: ${(props) => (props.maxWidth ? props.maxWidth : '800px')};
    }
`

PageContent.propTypes = {
    maxWidth: PropTypes.string,
}

export default PageContent
