import React from 'react'
import { modalService } from 'services/ModalService'
import SmallMultiSwitcher from 'ui/controls/SmallMultiSwitcher'
import palette from 'themes/primary/palette'

const t = window.T

const OperationTypeSwitcher = ({ active }) => {
    const depositAction = () => {
        modalService.showModal('DEPOSIT', {
            props: { closeBefore: ['WITHDRAW'] },
            initialProps: { animation: false },
        })
    }

    const withdrawAction = () => {
        modalService.showModal('WITHDRAW', {
            props: { closeBefore: ['DEPOSIT'] },
            initialProps: { animation: false },
        })
    }

    const ActionList = [
        {
            name: 'DEPOSIT',
            title: t.userArea.deposit.title,
            action: depositAction,
        },
        {
            name: 'WITHDRAW',
            title: t.userArea.withdraw.title,
            action: withdrawAction,
        },
    ]

    return (
        <SmallMultiSwitcher
            value={active}
            optionList={ActionList}
            activeBgColor={palette.light}
            activeColor={palette.dark}
        />
    )
}

export default OperationTypeSwitcher
