import React, { useEffect, useState } from 'react'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import Banner from 'ui/Banner'
import { restService } from 'services/ContentService'
import styled from 'styled-components'
import Text from 'ui/Text'
import Container from 'ui/Container'
import Headline from 'ui/Headline'
import Button from 'ui/controls/Button'
import Flex from 'ui/Flex'
import { byPlatform, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { useHistory } from 'react-router-dom'
import HighlightCard from 'features/Highlight/HighlightCard'
import withGames from 'common/Hocs/WithGames'
import GamesService from 'services/GamesService'

const t = window.T

const Terms = styled(Text)`
    font-size: 14px !important;
    font-style: italic;
    padding: 1rem;
    line-height: 1.5;
`

const CampaignPage = ({
    match = {},
    allProvGames,
    gameStartHandler = (game) => {},
}) => {
    const [campaign, setCampaign] = useState({
        bgimage: '',
        description: '',
    })
    const [game, setGame] = useState({})
    const [gameId, setGameId] = useState(0)
    const history = useHistory()

    const ExternalButton = (props) => {
        if (props.show) {
            return (
                <Button
                    size={'lg'}
                    bold
                    uppercase
                    style={{
                        borderRadius: '25px',
                        fontSize: '1.8rem',
                        margin: '30px 10px',
                    }}
                    onClick={() => {
                        if (props.link) {
                            window.location.href = props.link
                        } else {
                            props.callback(game)
                        }
                    }}
                >
                    {props.text}
                </Button>
            )
        }

        return null
    }

    useEffect(() => {
        restService.getCampaignBySeo((campaign) => {
            //API returns "bool string" - "true" || "false"
            if (campaign.active === 'true') {
                setCampaign(campaign)
            } else {
                history.push(`/25nyt`)
            }
        }, match.params.campaign_id)
    }, [])

    useEffect(
        () => {
            const id = byPlatform(campaign.game_id_mob, campaign.game_id)
            const gameData = GamesService.getGameById(id)

            setGameId(id)

            if (gameData) {
                setGame(gameData)
            }
        },
        [campaign.game_id]
    )

    return (
        <PageWrapper>
            <HighlightCard
                id={'slide'}
                src={
                    campaign.bgimage
                        ? byPlatform(campaign.bgimage_mob, campaign.bgimage)
                        : ''
                }
                videoUrl={byPlatform(campaign.vimeomob, campaign.vimeo)}
                headline={campaign.title}
                desktop={!detectMobile()}
                showBtnArea={false}
                loopVideo={false}
                gameId={byPlatform(campaign.game_id_mob, campaign.game_id)}
                gameClickCallback={() => gameStartHandler(game)}
            />
            <PageContent
                p={3}
                maxWidth={'1140px'}
                dangerouslySetInnerHTML={{ __html: campaign.description }}
            />

            <Flex mt={5} direction={'wrap'} style={{ margin: '0' }}>
                <ExternalButton
                    link={campaign.external_url}
                    text={
                        byPlatform(
                            campaign.button_text_mob,
                            campaign.button_text
                        ) || t.readMore
                    }
                    show={!!campaign.external_url}
                />
                <ExternalButton
                    callback={gameStartHandler}
                    text="Start Spil"
                    show={campaign.game_id || campaign.game_id_mob}
                />
            </Flex>

            {campaign.terms && (
                <Container
                    maxWidth={'1140px'}
                    style={{ width: '-webkit-fill-available' }}
                >
                    <Headline
                        as={'h3'}
                        style={{ textAlign: 'center', margin: '0' }}
                    >
                        Regler for tilbuddet
                    </Headline>
                    <Terms
                        dangerouslySetInnerHTML={{
                            __html: campaign.terms || null,
                        }}
                    />
                </Container>
            )}
        </PageWrapper>
    )
}

export default withGames(CampaignPage)
