import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

const StyledIframe = styled.iframe`
    border: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

const Iframe = ({ src = '', ...props }) => {
    return <StyledIframe src={src} {...props} />
}

Iframe.propTypes = {
    src: propTypes.string,
}

export default Iframe
