import React, { useEffect } from 'react'
import GameCard from 'features/GamesList/GameCard'
import styled, { css } from 'styled-components'
import Text from 'ui/Text'
import { useHistory, useRouteMatch } from 'react-router-dom'
import GamesService from 'services/GamesService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import ThumbWrapper from 'ui/ThumbWrapper'
import crown from 'icons/basic-icons/sideNavIcons/crown-full.svg'
import Icon from 'ui/Icon'
import { EXCLUSIVE_GAME_LABEL } from 'configs/ui'
import { backDropStyle } from 'ui/StylesUtils'
import ReactTooltip from 'react-tooltip'
import { modalService } from 'services/ModalService'

const t = window.T

const MAX_NUMBER_OF_GAMES = 200

const GameHolder = styled.div`
    position: relative;
    padding: ${(props) => (props.itemPadding ? props.itemPadding : 'initial')};
    ${(props) =>
        props.thumb &&
        css`
            grid-column: ${(props) => (props.index % 2 ? '3/5' : '1/3')};
            grid-row: 1/3;
        `};
    &:hover {
        cursor: pointer;
    }
`

const StyledBox = styled.div`
    ${backDropStyle};
    display: inline-block;
    color: white;
    padding: 0 5px;

    font-size: 0.7rem;
    border-radius: 5px;
    position: absolute;
`

const MinStake = styled(StyledBox)`
    bottom: 13px;
    right: 7px;
`

const GameText = styled(Text)`
    text-align: center;
    position: relative;
    top: 5px;
`

const GameList = ({
    games = [],
    limit = MAX_NUMBER_OF_GAMES,
    itemPadding = 'initial',
    onGameClick = (f) => f,
    multipleGameIndex = null,
    gameLabel = true,
}) => {
    const history = useHistory()
    const isInGameSearch = multipleGameIndex !== null

    const gameInfoModal = (e, game) => {
        e.preventDefault()
        e.stopPropagation()
        modalService.showModal('GAME_INFO', {
            game,
            gameClickCallback: () => showGame(game),
            initialProps: {
                animation: true,
                outsideClose: true,
            },
        })
    }

    const showGame = (game, e) => {
        if (isInGameSearch) {
            onGameClick(multipleGameIndex, game)
        } else {
            onGameClick()
            GamesService.removeMultipleGames()

            if (
                (game.liveCas || game.category === 'liveint') &&
                (localStorage.getItem('logged') === 'false' ||
                    localStorage.getItem('logged') === null)
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
                return
            }

            if (
                (localStorage.getItem('logged') === 'false' ||
                    localStorage.getItem('logged') === null) &&
                detectMobile()
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
            }

            GamesService.setSearchDisplay(false)

            GamesService.checkLoginBeforeStart(game, history)
        }

        if (
            localStorage.getItem('logged') !== null &&
            localStorage.getItem('logged') !== 'false'
        ) {
            GamesService.pushGameToRecentlyPlayed(game)
        }
    }

    return (
        <>
            {games.length > 0 &&
                games.map((game, index) => {
                    return (
                        index < limit && (
                            <GameHolder
                                key={'gamesList-' + index}
                                onClick={(e) => showGame(game)}
                                itemPadding={itemPadding}
                                index={index}
                            >
                                <ThumbWrapper width={'100%'}>
                                    <GameCard
                                        key={game.id}
                                        game={game}
                                        labelProps={
                                            gameLabel &&
                                            (game.exclusive === true ||
                                                game.exclusive === '1')
                                                ? EXCLUSIVE_GAME_LABEL
                                                : null
                                        }
                                        showInfoIcon={!isInGameSearch}
                                        gameClickHandler={(e) =>
                                            showGame(game, e)
                                        }
                                    />
                                </ThumbWrapper>
                                {!isInGameSearch && (
                                    <GameText>{game.name}</GameText>
                                )}
                            </GameHolder>
                        )
                    )
                })}
        </>
    )
}

export default GameList
