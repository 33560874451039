export const MEDIAS = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

export const MEDIAS_BASIC = ['xs', 'sm', 'md', 'lg', 'xl']

export const TITLE_DIMENSIONS = [
    {
        name: 'h1',
        fontSizeMob: 2,
    },
    {
        name: 'h2',
        fontSizeMob: 1.625,
    },
    {
        name: 'h3',
        fontSizeMob: 1.375,
    },
    {
        name: 'h4',
        fontSizeMob: 1.125,
    },
    {
        name: 'h5',
        fontSizeMob: 0.83,
    },
    {
        name: 'h6',
        fontSizeMob: 0.67,
    },
]
