import React from 'react'
import ModalsMap from 'configs/modals'
import ModalHandler from './ModalHandler'
import ModalContainer from './ModalContainer'
import { withSuspense } from 'common/Hocs/WithSuspense'

const Modals = (props) => (
    <ModalHandler isModalVisible={props.isModalVisible} modalsMap={ModalsMap}>
        {(modalProps) => {
            return <ModalContainer {...modalProps} />
        }}
    </ModalHandler>
)

export default withSuspense(Modals)
