import React from 'react'
import styled from 'styled-components'
import { modalService } from 'services/ModalService'
import propTypes from 'prop-types'
import Button from 'ui/controls/Button'
import { PALETTE } from 'themes'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { useHistory } from 'react-router-dom'

const StyledWrapper = styled.div`
    position: absolute;
    right: 10px;
`
const OpretBtn = styled(Button)`
    padding: 0.8rem;
    border-radius: 5px;
    @media screen and (max-width: 968px) {
        display: none;
    }
`
const LoginBtn = styled(Button)`
    padding: 0.8rem;
    border-radius: 5px;
    @media screen and (max-width: 968px) {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        font-size: 12px;
        color: #fff;
        font-weight: normal;
    }
`
const t = window.T.promo

const LoginArea = ({ signUp }) => {
    const history = useHistory()
    const logIn = () => {
        modalService.closeModal('PROMO')
        setTimeout(() => {
            history.push('/log-ind')
            modalService.showModal('LOGIN', {
                initialProps: { modalTopMargin: '0' },
            })
        }, 0)
    }

    return (
        <StyledWrapper>
            <OpretBtn mr={2} size={'mdB'} uppercase onClick={() => signUp()}>
                {t.signUp}
            </OpretBtn>
            <LoginBtn
                variant={'danger'}
                uppercase
                size={'mdB'}
                style={{
                    background: PALETTE.primaryAction,
                }}
                onClick={logIn}
            >
                {t.logIn}
            </LoginBtn>
        </StyledWrapper>
    )
}

LoginArea.propTypes = {
    geoData: propTypes.object,
}

export default LoginArea
