import React, { useEffect } from 'react'
import Button from 'ui/controls/Button'
import PropTypes from 'prop-types'
import { PALETTE } from 'themes'

const ButtonList = ({
    renderButton = Button,
    value = '',
    onChange = (f) => f,
    buttonSet = [],
}) => {
    const ButtonComponent = renderButton

    return (
        <>
            {buttonSet.map((button, key) => {
                return (
                    <ButtonComponent
                        key={key}
                        onClick={() => onChange(button)}
                        active={value === button.name}
                        uppercase
                        size="lg"
                        variant={'custom'}
                        capitalize
                        activeBackground={PALETTE.primary}
                        background={PALETTE.lightGray}
                    >
                        {button.title ? button.title : button.label}{' '}
                        <span>{button.count}</span>
                    </ButtonComponent>
                )
            })}
        </>
    )
}

ButtonList.propTypes = {
    renderedButton: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
    buttonSet: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
}

export default ButtonList
