import styled from 'styled-components'

const ContentWrapper = styled.div`
    width: auto;
    height: auto;
    //margin-top: ${(props) => props.theme.FULL_HEADER_HEIGHT}px;
    min-height: 100vh;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
        margin-top: ${(theme) => theme.FULL_HEADER_HEIGHT}px;
        min-height: 100vh;
        margin-bottom: 0;
    }
`

export default ContentWrapper
