import React, { useEffect, useRef } from 'react'
import PropsTypes from 'prop-types'
import styled from 'styled-components'

const Marker = styled.div`
    position: absolute;
    bottom: 15px;
    visibility: hidden;
`

const InfinityScroller = ({
    children,
    onScrollToBottom = (f) => f,
    onScroll = (f) => f,
    onBottomReach = (f) => f,
    maxItems = 0,
    increment = 12,
    scrollParent,
}) => {
    let itemsIncrement = increment
    const marker = useRef(null)
    const scrollEl = scrollParent || window

    const scrollHandler = (e) => {
        onScroll(e)

        const markerY = marker.current.getBoundingClientRect().top
        const containerHeight = scrollParent
            ? scrollEl.getBoundingClientRect().bottom
            : scrollEl.innerHeight

        if (markerY <= containerHeight) {
            if (itemsIncrement <= maxItems) {
                itemsIncrement += increment
                onScrollToBottom(itemsIncrement)
            } else {
                onBottomReach()
            }
        }
    }

    useEffect(
        () => {
            scrollEl.addEventListener('scroll', scrollHandler)

            return () => {
                scrollEl.removeEventListener('scroll', scrollHandler)
            }
        },
        [maxItems]
    )

    return (
        <>
            {children}
            <Marker ref={marker} />
        </>
    )
}

InfinityScroller.propTypes = {
    children: PropsTypes.node,
    onScrollToBottom: PropsTypes.func,
    onScroll: PropsTypes.func,
    onBottomReach: PropsTypes.func,
    maxItems: PropsTypes.number,
    increment: PropsTypes.number,
}

export default InfinityScroller
