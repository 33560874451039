import Flex from 'ui/Flex'
import Text from 'ui/Text'
import styled, { css } from 'styled-components'
import Button from 'ui/controls/Button'

export const HeaderWrapper = styled(Flex)`
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 20px;
    margin-top: 10px;

    &::-webkit-scrollbar {
        display: none;
    }
`
export const CardWrapper = styled(Flex)`
    border: 1px solid #c6ccdb;
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
    min-width: 265px;
    width: max-content;
    overflow: hidden;
    flex: 1;

    &:hover {
        box-shadow: rgba(99, 99, 99, 0.3) 0 2px 8px 0;
        transform: scale(1.01);
    }
`

export const TopWrapper = styled(Flex)`
    ${({ backgroundImage }) =>
        backgroundImage &&
        css`
            background: url(${backgroundImage}) center;
            background-size: cover;
        `};
    padding: 15px 20px 29px;
    width: 100%;
    cursor: pointer;
    position: relative;
`

export const Title = styled(Text)`
    font-family: 'ATG Text Bold', sans-serif;
    font-size: 1.4rem !important;
`

export const Amount = styled(Text)`
    font-family: 'ATG Text BoldItalic', sans-serif;
    padding: 15px 0 15px 20px;

    & span {
        font-family: 'ATG Text BoldItalic', sans-serif;
        opacity: 0.4;
    }
`

export const LoadMoreButton = styled(Button)`
    margin: 32px auto 0;
    display: block;
    background-color: #f5f7fc;
    font-weight: bold;
    border-radius: 8px;

    &:hover {
        background-color: #ebebeb;
    }
`

export const HotLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    position: absolute;
    right: 10px;
    bottom: -15px;
    background-color: #fff7f0;
    border: 1px solid #0000001a;
    padding: 7px 10px;
    border-radius: 20px;

    img {
        width: 12px;
        height: 12px;
    }
`
