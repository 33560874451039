import React from 'react'
import styled from 'styled-components'
import ModalWrapper from 'ui/ModalWrapper'
import { modalService } from 'services/ModalService'
import { getProductBarHeight } from 'configs/layout'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const StyledModalWrapper = styled.div`
    background-size: contain;
    z-index: ${(props) => (props.priority ? props.priority + 110 : 111)};
    position: fixed;
    overflow: ${({ autoScroll }) => (autoScroll ? 'auto' : 'scroll')};
    overflow-x: hidden;
    top: ${({ modalTopMargin }) => {
        return modalTopMargin ? modalTopMargin : 0
    }}px;

    height: calc(
        100% -
            ${({ modalTopMargin }) => (modalTopMargin ? modalTopMargin : 0)}px
    );
    left: 0;
    width: 100%;
    background: ${(props) =>
        props.background ? props.background : 'rgba(0, 0, 0, 0.5)'};

    min-height: ${(props) => (props.minHeight ? props.minHeight : null)};
`

const ModalContainer = ({
    modal = null,
    actions,
    data = {},
    priority,
    ...props
}) => {
    const ModalComponent = modal
    const BackgroundComponent = data.backgroundComponentName || 'span'

    const { styles = {} } = data
    const {
        initialProps = {
            outsideClose: false,
            autoScroll: false,
        },
    } = data

    return (
        <StyledModalWrapper
            autoScroll={initialProps.autoScroll}
            priority={priority}
            position={initialProps.initialProps}
            background={styles.background}
            modalTopMargin={initialProps.modalTopMargin}
            minHeight={initialProps.minHeight}
            onClick={(event) => {
                ;[...event.currentTarget.children].includes(event.target) &&
                initialProps.outsideClose
                    ? modalService.closeModal()
                    : null
            }}
        >
            <ModalWrapper animation={initialProps.animation}>
                <ModalComponent
                    modal={modal}
                    actions={actions}
                    data={data}
                    priority={priority}
                />
            </ModalWrapper>
            <BackgroundComponent />
        </StyledModalWrapper>
    )
}

export default ModalContainer
