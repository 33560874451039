import React from 'react'
import styled, { withTheme } from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'

const SidebarWrapper = styled.section`
    ${(props) =>
        !props.mode || props.mode === 'left' ? `left: 0` : `right: 0`};
    transform: translateX(${(props) => (props.visible ? '0px' : '-100%')});
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    z-index: 111;
    color: ${(props) => props.theme.light};
    transition: 0.3s ease-in-out;
    background: ${(props) => props.theme.primary};
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const Sidebar = ({ visible, onBackgroundClickHandler, theme, children }) => {
    return (
        <SidebarWrapper visible={visible}>
            <ModalTopBar
                p={4}
                noBack
                crossColor={theme.light}
                borderBottom={'none'}
                customClose={onBackgroundClickHandler}
            />
            {children}
        </SidebarWrapper>
    )
}

export default withTheme(Sidebar)
