export const thousandsDots = (value) => {
    if (value === undefined || value === null) {
        return value
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const numberWithCommasAndDots = (x) => {
    if (!x) return 0
    return new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(x || 0)
}

export const sha256 = async (message) => {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message)

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    // convert bytes to hex string

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}
