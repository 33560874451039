import React, { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { modalService } from 'services/ModalService'
import HomePage from 'scenes/HomePage'
import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'services/UserService'

const pageModalMapper = {
    indbetaling: 'DEPOSIT',
    udbetaling: 'WITHDRAW',
    'konto-oplysninger': 'YOUR_INFO',
    voucher: 'VOUCHER',
    'besked-indstillinger': 'NOTIFICATION',
    indbetalingsgraense: 'DEPOSIT_LIMIT',
    selvudelukkelse: 'SELF_EXCLUSION',
    historik: 'HISTORY',
    'sikkerheds-indstillinger': 'SECURITY',
    'anmeld-os': 'PRODUCT_REVIEW',
    bonusomsaetning: 'BONUS',
}

const UserAreaPage = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const getModalName = () => {
        let modalName = 'USER_AREA' //by default

        if (match.params.item) {
            modalName = pageModalMapper[match.params.item] || null
        }

        return modalName
    }

    useEffect(() => {
        const modalName = getModalName()

        UserAdapter.getProfile()
            .then((res) => {
                if (res.userId) {
                    modalService.showModal(modalName, {
                        initialProps: {
                            outsideClose: true,
                        },
                    })
                } else {
                    history.push('/log-ind')
                    UserService.openLoginModal()
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    return <HomePage showUserAreaParts={true} />
}

export default UserAreaPage
