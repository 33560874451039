import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Img from 'ui/Img'
import Headline from 'ui/Headline'
import Text from 'ui/Text'
import Icon from 'ui/Icon'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const BannerContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    color: ${(props) => props.color};
    min-height: 120px;
`

const StyledHeadline = styled(Headline)`
    margin-bottom: 10px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        font-size: ${(props) => (props.titleSize ? props.titleSize : '1em')};
    }
`

const TextHolder = styled.div`
    text-shadow: 3px 3px 5px #000000;
    width: 100%;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
`

const BoxHolder = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: ${detectMobile() ? '0' : '25%'};
`

const ContentWrapper = styled.div`
    position: ${detectMobile() ? 'relative' : 'absolute'};
    width: 100%;

    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        ${({ fullSize }) =>
            fullSize &&
            css`
                height: 100%;

                & img {
                    height: 160px;
                    object-fit: cover;
                }
            `};
    }
`

const CustomIcon = styled(Icon)`
    width: 140px;

    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
        width: 70px;
        height: 70px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        width: 40px;
        height: 40px;
    }
`

const Banner = ({
    src,
    title,
    children,
    color = 'white',
    isBannerFlow = false,
    icon = '',
    titleSize = '',
    fullSize = false,
}) => {
    return (
        <BannerContainer color={color}>
            <BoxHolder>
                <ContentWrapper fullSize={fullSize}>
                    {isBannerFlow ? (
                        <Fragment>{children}</Fragment>
                    ) : (
                        <Fragment>
                            <Img src={src} width={'100%'} loading="lazy" />

                            <TextHolder>
                                {icon && (
                                    <CustomIcon
                                        src={icon}
                                        color={'#FFF'}
                                        style={{
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                )}
                                <StyledHeadline
                                    titleSize={titleSize}
                                    align={'center'}
                                >
                                    {title}
                                </StyledHeadline>
                                <Text size={'lg'} align={'center'}>
                                    {children}
                                </Text>
                            </TextHolder>
                        </Fragment>
                    )}
                </ContentWrapper>
            </BoxHolder>
        </BannerContainer>
    )
}

export default Banner
