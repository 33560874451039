import styled from 'styled-components'

const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-gap: ${(props) => (props.gridGap ? props.gridGap : '0.5rem')};
    grid-template-columns: repeat(
        ${(props) => (props.columns ? props.columns : '3')},
        minmax(${(props) => (props.minmax ? props.minmax : '20px')}, 1fr)
    );

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        grid-template-columns: repeat(
            ${(props) => props.deskColumns},
            minmax(${(props) => (props.minmax ? props.minmax : '20px')}, 1fr)
        );
    }
`

export default Grid
