import { BehaviorSubject } from 'rxjs'

const filteredGames = new BehaviorSubject([])

export default {
    setFilteredGames: (gamesValue) => {
        filteredGames.next(gamesValue)
    },

    getFilteredGames: () => filteredGames.asObservable(),
}
