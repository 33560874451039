import React, { Fragment, useState } from 'react'
import CategoryPage from 'scenes/CategoryPage'
import Displayer from 'ui/Displayer'
import Headline from 'ui/Headline'
import Flex from 'ui/Flex'
import JackpotsView from 'features/Jackpots/JackpotsView'
import MediaGrid from 'ui/MediaGrid'
import InfinityScroller from 'features/Scroll/InfinityScroller'
import GameList from 'features/GamesList/GameList'
import Box from 'ui/Box'
import ExclusiveGamesPage from 'scenes/ExclusiveGamesPage'
import ProvidersPage from 'scenes/ProvidersPage'
import styled from 'styled-components'
import COLORS from 'themes/primary/palette'
import NewsPage from 'scenes/NewsPage'
import NewGamesPage from 'scenes/NewGamesPage'

const CasinoPageContent = ({
    category = {},
    subCategory = '',
    sortedGames = [],
    allGames = [],
    setShowLoader,
}) => {
    const [jackpotGamesAmount, setJackpotGamesAmount] = useState(0)
    const [gamesToShow, setGamesToShow] = useState(12)

    const showContentFor = ['categories', 'exclusive', 'providers', 'new']

    const additionalJackpotGames = (gameIds = []) =>
        allGames.reduce((array, game) => {
            if (gameIds.includes(game.provider_id)) {
                return array.concat(game)
            } else {
                return array
            }
        }, [])

    const selectAllContent = (categoryName) => {
        switch (categoryName) {
            case 'jackpot':
                return (
                    <JackpotsView
                        displayedGames={sortedGames}
                        getAdditionalGames={additionalJackpotGames}
                        categoryLabel={category?.label}
                    />
                )
            case 'categories':
                return <CategoryPage />
            case 'providers':
                return <ProvidersPage />
            case 'exclusive':
                return <ExclusiveGamesPage />
            case 'new':
                return <NewGamesPage />
            default:
                return (
                    <Fragment>
                        {category?.label && (
                            <Flex justify={'start'} gap={'4px'}>
                                <Headline
                                    as={'h3'}
                                    color={COLORS.dark}
                                    label={sortedGames.length}
                                    colorLabel={COLORS.gray}
                                    capitalize
                                >
                                    {category.label}
                                </Headline>
                            </Flex>
                        )}
                        <Box my={3}>
                            <MediaGrid>
                                <InfinityScroller
                                    maxItems={sortedGames.length}
                                    onScrollToBottom={(countToShow) => {
                                        setShowLoader(true)
                                        setGamesToShow(countToShow)
                                    }}
                                    onBottomReach={() => {
                                        setShowLoader(false)
                                    }}
                                >
                                    <GameList
                                        games={sortedGames}
                                        limit={gamesToShow}
                                    />
                                </InfinityScroller>
                            </MediaGrid>
                        </Box>
                    </Fragment>
                )
        }
    }

    return (
        <Displayer
            display={
                sortedGames.length || showContentFor.includes(category?.name)
                    ? 'block'
                    : 'none'
            }
        >
            {category?.name && selectAllContent(category.name)}
        </Displayer>
    )
}

export default CasinoPageContent
