import React from 'react'
import FeedbackForm from 'features/Feedback/FeedbackForm'
import Wrapper from 'ui/Wrapper'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import ModalBackgroundDesk from 'ui/ModalBackgroundDesk'
import ModalBackground from 'features/Feedback/ModalBackground'

const formStyles = {
    width: '500px',
    position: 'relative',
    top: '95px',
    left: '50%',
    transform: 'translateX(-50%)',
}

const FeedbackModal = ({ data }) => {
    const Wrapper = detectMobile() ? ModalBackground : ModalBackgroundDesk

    return (
        <Wrapper>
            <FeedbackForm
                style={detectMobile() ? {} : formStyles}
                step={data?.props?.step}
            />
        </Wrapper>
    )
}

FeedbackModal.propTypes = {}

export default FeedbackModal
