import { BehaviorSubject, Subject } from 'rxjs'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { getBonus } from 'common/adapters/ProviderAdapter'

export const resetBonusesStatus = new BehaviorSubject(false)
const bonusList = new Subject()

export const setResetBonusesStatus = (reset) => {
    resetBonusesStatus.next(reset)
}

export const getResetBonusesStatus = () => resetBonusesStatus.asObservable()

export const setNtentBonusList = () => {
    getBonus()
        .then(({ data }) => {
            try {
                if (data) {
                    bonusList.next({
                        totalBonus: data.amount,
                        rollover: data.rollOver,
                        initialRollover: data.initRollOver,
                        expirationDate: data.expire,
                        gameId: data.gameId,
                    })
                } else {
                    bonusList.next({})
                }
            } catch (e) {
                console.error(e)
            }
        })
        .catch((error) => NotificationConductor.error(error.toString()))
}

export const getBonusList = () => bonusList.asObservable()
