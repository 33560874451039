import React, { useState, useEffect } from 'react'
import { modalService } from 'services/ModalService'
import Text from 'ui/Text'
import Icon from 'ui/Icon'
import UserAdapter from 'common/adapters/UserAdapter'
import userService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import deleteB from 'images/icons/basic-icons/backspace.svg'
import ModalTopBar from 'ui/ModalTopBar'
import LoaderCover from 'features/LoaderCover/LoaderCover'
import {
    setPinLogin,
    getPinLogin,
    removePinLogin,
} from 'services/PinLoginService'
import withUser from 'common/Hocs/WithUser'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import {
    CloseModalButton,
    LoginButton,
    PinIndicator,
    PinIndicatorsWrapper,
    PinKeyboard,
    PinKeyboardButton,
    StyledLoginMobileWrapper,
} from 'features/Login/styled'
import UrlHistoryService from 'services/UrlHistoryService'
import { useHistory } from 'react-router-dom'
import Flex from 'ui/Flex'
import cross from 'icons/basic-icons/cross.svg'
import UserService from 'services/UserService'

const t = window.T

const PIN_LENGTH = 4
const PIN_BUTTONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0]

const PinLogin = ({ data, userProfile }) => {
    const [pin, setPin] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const pinTexts = data.action === 'check' ? t.checkPin : t.pin

    const closeModal = () => {
        if (data.action === 'check') {
            modalService.showModal('LOGIN', {
                props: { closeBefore: ['PIN_LOGIN'] },
                initialProps: { modalTopMargin: '0' },
            })
            UserAdapter.clearHash().then((res) => {
                if (res.success) {
                    removePinLogin()
                    localStorage.removeItem('userName')
                }
            })
        } else {
            modalService.backModal('PIN_LOGIN')
        }
    }

    useEffect(
        () => {
            const pinHash = getPinLogin()
            const userName = localStorage.getItem('pinLoginUserName')

            if (pin && pin.length === PIN_LENGTH) {
                if (data.action === 'set') {
                    UserAdapter.setPin(pin).then((res) => {
                        setPinLogin(res + '|' + userProfile.userId)
                        localStorage.setItem(
                            'pinLoginUserName',
                            userProfile.userName
                        )
                        closeModal()
                    })
                } else if (data.action === 'check') {
                    setLoading(true)
                    UserAdapter.pinLogin(pin, pinHash, userName)
                        .then((res) => {
                            if (!res.success) {
                                NotificationConductor.error(res?.errorMessage)
                                modalService.closeModal('PIN_LOGIN')
                                return
                            }

                            UserService.attachUser()

                            userService.setIsUserLoggedIn((userData) => {
                                setLoading(false)
                                modalService.closeModal()
                                UrlHistoryService.openUserAreaAfterLogin(
                                    history
                                )
                                window.dataLayer.push({
                                    event: 'login',
                                    userId: userData.userId,
                                })
                            })
                            selfExclusionNotification()
                        })
                        .catch(() => {
                            NotificationConductor.error('Forkert pinkode')
                            modalService.closeModal('PIN_LOGIN')
                        })
                }
            }
        },
        [pin]
    )

    const digitClick = (num) => {
        if (pin.length < PIN_LENGTH) {
            setPin(num)
        } else if (!num) {
            setPin('')
        }
    }

    const renderPinIndicators = () => {
        return new Array(PIN_LENGTH)
            .fill(null)
            .map((_, index) => (
                <PinIndicator
                    key={index}
                    isCurrent={pin.length === index}
                    filled={pin.length >= index + 1}
                />
            ))
    }

    return (
        <StyledLoginMobileWrapper zIndex="11">
            {!loading ? (
                <>
                    <ModalTopBar
                        p={4}
                        color={'white'}
                        borderBottom={'none'}
                        noClose={data?.action === 'check'}
                        back={!data?.noBack}
                    >
                        {data?.action === 'check' && (
                            <Flex
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CloseModalButton
                                    style={{
                                        left: '15px',
                                        position: 'relative',
                                    }}
                                >
                                    <Icon
                                        size={'sm'}
                                        src={cross}
                                        color={'white'}
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    />
                                </CloseModalButton>
                            </Flex>
                        )}
                    </ModalTopBar>
                    <Text
                        bold
                        m={4}
                        align={'center'}
                        color="#fff"
                        condensed
                        style={{ fontSize: '28px' }}
                    >
                        {pinTexts.title}
                    </Text>
                    <PinIndicatorsWrapper check={data?.action === 'check'}>
                        {renderPinIndicators()}
                    </PinIndicatorsWrapper>
                    {data?.action === 'check' ? (
                        <LoginButton
                            margin="60px 0 0"
                            color="#333333"
                            background="#eceff6"
                            fontSize="18px"
                            fontWeight="bold"
                            width="100%"
                            onClick={() => {
                                modalService.showModal('LOGIN', {
                                    initialProps: { modalTopMargin: '0' },
                                    isMitId: true,
                                })
                            }}
                        >
                            {t.loginModal.forgetPassword}?
                        </LoginButton>
                    ) : null}
                    <PinKeyboard>
                        {PIN_BUTTONS.map((el, index) => (
                            <PinKeyboardButton
                                key={index}
                                onClick={(e) => {
                                    digitClick(pin + e.target.innerText)
                                }}
                            >
                                {el}
                            </PinKeyboardButton>
                        ))}
                        <PinKeyboardButton style={{ padding: 0 }}>
                            <Icon
                                src={deleteB}
                                width="35px"
                                height="29px"
                                color={'black'}
                                style={{
                                    position: 'relative',
                                    right: '5px',
                                }}
                                onClick={() => digitClick('')}
                            />
                        </PinKeyboardButton>
                    </PinKeyboard>
                </>
            ) : (
                <LoaderCover />
            )}
        </StyledLoginMobileWrapper>
    )
}

export default withUser(PinLogin)
