import styled, { css } from 'styled-components'
import Icon from 'ui/Icon'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { BACKGROUNDS } from 'themes'
import Text from 'ui/Text'

export const ProvidersText = styled.div``
export const ProvidersContainer = styled.div`
    margin-top: 20px;
    display: grid;
    gap: 2rem 1rem;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        grid-template-columns: repeat(4, 1fr);
    }
`

export const ProviderCard = styled.div`
    position: relative;
    box-shadow: 0 10px 10px -8px #000000;
    border-radius: 8px;
    transition: transform 300ms ease-in-out;
`
export const ProviderWrapper = styled.div`
    ${({ upcoming }) =>
        !upcoming &&
        css`
            cursor: pointer;
            &:hover {
                ${ProviderCard} {
                    transform: scale(1.05);
                }
            }
        `};
`
export const ImageOverlay = styled.a`
    display: inline-block;
    position: relative;
    width: 100%;
    &:before {
        content: ' ';
        display: inline-block;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.2) 30%,
            rgba(0, 0, 0, 0) 100%
        );
        ${({ upcoming }) =>
            upcoming &&
            css`
                background-color: rgba(0, 0, 0, 0.7);
            `};
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
`
export const ImageText = styled(Text)`
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
`

export const ProviderImage = styled.img`
    border-radius: 8px;
    width: 100%;
    height: auto;
    min-height: 320px;
    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        min-height: 160px;
    }
`
export const InfoWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
`
export const ProviderLogo = styled(Icon)`
    margin-bottom: 15px;
`
export const GamesCount = styled.div`
    text-align: center;
    color: white;
`

export const SlideGamesWrapper = styled.div`
    display: inline-block;
    position: relative;
    padding: 5px;
    min-height: ${({ topGames }) =>
        !detectMobile() && !topGames ? '320px' : '250px'};
    max-width: 455px;
    transition: all 0.3s ease;

    ${({ topGames }) =>
        topGames &&
        css`
            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 15px;
                z-index: 1;
                border-style: solid;
                border-width: 40px 21px 18px 21px;
                border-color: white white transparent white;
                filter: drop-shadow(0px 3px 3px black);
            }
        `};
    ${({ disable }) =>
        !disable &&
        css`
            &:hover {
                transform: scale(1.01);
                cursor: pointer;
            }
        `};
`
export const GameIndex = styled.div`
    position: absolute;
    z-index: 2;
    color: #db1c1b;
    left: 32px;
    top: 13px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    font-size: 18px;
`

export const CategoryWrapper = styled.div`
    position: relative;
    border-bottom: 1px solid ${BACKGROUNDS.grey};
    padding: 2rem 0;
    &:first-child {
        padding-top: 0;
    }
`

export const LabelWrapper = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
`
