import React from 'react'
import {
    CloseButton,
    PopupButton,
    PopupContent,
    PopupMainImage,
    ProductPopupContainer,
} from './styled'
import closeButton from 'icons/edit_gray.png'
import { modalService } from '../../services/ModalService'

const SidebarPopup = (props) => {
    const modalData = props.data.props

    return (
        <ProductPopupContainer background={modalData.background}>
            <PopupContent name={modalData.name}>
                <img src={modalData.logo} alt={modalData.name} />
                <h4>{modalData.title}</h4>
                {modalData.texts.map((text, index) => (
                    <p key={index}>{text}</p>
                ))}
                <PopupMainImage
                    style={{
                        height: modalData.mainImg.height,
                        right: modalData.mainImg.right,
                        bottom: modalData.mainImg.bottom,
                    }}
                    src={modalData.mainImg.url}
                    alt={modalData.name}
                />
                <PopupButton href={modalData.url}>
                    {modalData.buttonTitle}
                </PopupButton>
                <CloseButton
                    onClick={() => {
                        modalService.closeModal('SIDEBAR_POPUP')
                    }}
                >
                    <img src={closeButton} alt={modalData.name + ' close'} />
                </CloseButton>
            </PopupContent>
        </ProductPopupContainer>
    )
}

export default SidebarPopup
