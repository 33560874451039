import { AUTH_REST_PATH } from 'configs/rest'
import { makeRequest } from 'utils/REST'

export const request = (requestType, url, data) => {
    return makeRequest(requestType, url, data)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
}

export const post = (method, data) => {
    return request('post', AUTH_REST_PATH + method, data)
}

export const get = (method, data) => {
    return request('get', AUTH_REST_PATH + method, data)
}
