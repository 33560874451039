import React from 'react'
import styled from 'styled-components'
import Icon from '@/ui/Icon'
import search from '@/images/icons/basic-icons/search.svg'
import { paddingProps } from '@/ui/SpacingUtility'

const SearchBarWrapper = styled.div`
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px lightgray;
    width: 100%;
    border: 1px solid
        ${(props) =>
            props.borderColor ? props.borderColor : 'rgba(51, 51, 51, .25)'};
    height: ${(props) => (props.height ? props.height : 'auto')};
    background: ${(props) => (props.background ? props.background : '#f1f1f1')};
    ${paddingProps};
    text-align: ${(props) => (!props.align ? 'left' : props.align)};
`

const InputBox = styled.div`
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const StiledValidInput = styled.input`
    border: none;
    background: none;
    font-size: 1rem;
    font-weight: 400;
    padding: 12px 45px;
    transition: all 0.5s ease-in-out;
    color: ${(props) => (props.color ? props.color : '#6e6e6e')};
    position: relative;
    width: 100%;
`

const SearchIcon = styled(Icon)`
    position: absolute;
    left: 20px;
    bottom: 50%;
    z-index: 1;
    transform: translateY(50%);
`

const SearchInput = ({
    color = 'gray',
    background = 'white',
    value,
    onChange,
    onKeyPress,
    onInputFocusedHandler,
    onInputBlueredHandler,
    placeholder,
    controlColor = 'gray',
    borderColor,
    autoFocus,
    ...props
}) => {
    const onInputFocused = () => {
        if (onInputFocusedHandler) {
            onInputFocusedHandler()
        }
    }

    const onInputBluered = () => {
        if (onInputBlueredHandler) {
            onInputBlueredHandler()
        }
    }

    const onInputValueChanged = (event) => {
        event.stopPropagation()
        const { value } = event.target

        onChange(value)
    }

    const onInputKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0

        if (key === 13) {
            e.stopPropagation()
            onKeyPress(e)
        }
    }
    return (
        <SearchBarWrapper
            background={background}
            borderColor={background}
            {...props}
        >
            <InputBox>
                <SearchIcon
                    size={'md'}
                    src={search}
                    color={controlColor ? controlColor : '#646464'}
                />
                <StiledValidInput
                    px={5}
                    autoFocus={autoFocus}
                    color={color}
                    placeholderColor={color}
                    placeholder={placeholder}
                    onChange={onInputValueChanged}
                    onFocus={onInputFocused}
                    onBlur={onInputBluered}
                    onKeyPress={onInputKeyPress}
                    value={value}
                />{' '}
            </InputBox>
        </SearchBarWrapper>
    )
}

export default SearchInput
