import styled from 'styled-components'
import Text from 'ui/Text'

const ThumbWrapper = styled.div`
    display: inline-block;
    justify-content: center;
    transition: all 0.3s ease;
    width: ${({ width }) => (width ? width : 'auto')};
    position: relative;

    &:hover {
        cursor: ${({ cursorOnHover }) =>
            cursorOnHover ? cursorOnHover : 'pointer'};
        transform: scale(1.05);
    }
`

export const ThumbLabel = styled(Text)`
    font-size: 14px;

    @media (min-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        font-size: 16px;
    }
`

export default ThumbWrapper
