import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Text from 'ui/Text'
import Box from 'ui/Box'
import { paddingProps } from 'ui/SpacingUtility'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import { getImagePathsFromDirectory } from 'utils/imageImportHelper'
import { numberWithCommasAndDots } from 'utils/formatters'
import { SUB_DIR_PATH } from 'configs/main'

export const accountAreaIcons = getImagePathsFromDirectory(
    require.context('images/icons/basic-icons/aсcount-area', false, /\.(svg)$/)
)

const t = window.T

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.primaryHover};
    width: 100%;
    ${paddingProps};
`
const UserAvatar = styled.img.attrs((props) => ({
    src: props.src,
}))`
    min-height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
    border: 2px solid ${(props) => props.theme.light};
`

const ProfileInfo = ({ userName, balance, bonus, p, theme }) => {
    const [curImage, setCurImage] = useState(1)

    const avatarClick = () => {
        let newAvatar = parseInt(curImage, 10) + 1
        setCurImage(newAvatar)
        localStorage.setItem('avatar', newAvatar)
    }
    useEffect(() => {
        let avatar = localStorage.getItem('avatar')
        if (avatar) {
            setCurImage(avatar)
        }
    }, [])

    return (
        <Wrapper p={p} pb={5}>
            <UserAvatar
                src={`${SUB_DIR_PATH}avatars/avatar${curImage}.png`}
                onClick={avatarClick}
            />
            <Box px={3} pb={1}>
                <Text color={theme.light} align={'center'} size={'lg'}>
                    {userName}
                </Text>
                <ProfileCard
                    borderRadiusTop
                    title={t.userArea.userInfo.mainBalance}
                    iconSrc={accountAreaIcons.wallet}
                    background={theme.light}
                >
                    <Text bold color={theme.dark} mr={2}>
                        {numberWithCommasAndDots(balance)}
                    </Text>
                    {t.currency}
                </ProfileCard>
                <ProfileCard
                    borderRadiusBottom
                    title={t.userArea.userInfo.bonusBalance}
                    iconSrc={accountAreaIcons.gift}
                    background={theme.light}
                >
                    <Text bold color={theme.dark} mr={2}>
                        {numberWithCommasAndDots(bonus)}
                    </Text>
                    {t.currency}
                </ProfileCard>
            </Box>
        </Wrapper>
    )
}

export default ProfileInfo
