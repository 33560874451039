import styled from 'styled-components'
import { MEDIAS } from '@/themes'
import { marginProps, paddingProps } from '@/ui/SpacingUtility'
import PropsTypes from 'prop-types'

const MOB_TEXT_FONT_SIZES = [0.67, 0.83, 1, 1.125, 1.375]
const WEB_TEXT_FONT_SIZES = [0.67, 0.83, 1, 1.125, 1.75]

const getFontSize = (size = 'md', fontSizes) => {
    for (let i = 0; i < MEDIAS.length; i++) {
        if (size === MEDIAS[i]) {
            return fontSizes[i]
        }
    }
}

const Text = styled.p`
    margin: 0;
    ${paddingProps};
    ${marginProps};
    width: ${(props) => (props.width ? props.width : 'auto')};
    line-height: 1.3;
    font-size: ${(props) =>
        props.size
            ? getFontSize(props.size, MOB_TEXT_FONT_SIZES) + 'em'
            : 'inherit'};
    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        font-size: ${(props) =>
            props.size
                ? getFontSize(props.size, WEB_TEXT_FONT_SIZES) + 'em'
                : 'inherit'};
    }
    color: ${(props) => props.color};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    font-weight: ${(props) => {
        const weight = props.bold || props.weight
        if (weight) {
            return parseInt(weight, 10) > 0 ? weight : '700'
        }
        return 'normal'
    }};
    ${(props) => props.block && 'display: block; width: 100%'};
    ${(props) =>
        props.short
            ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;'
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
    ${(props) => props.capitalize && `text-transform: capitalize;`};
    ${(props) => (props.opacity ? `opacity: ${props.opacity};` : '')};
    ${(props) => (props.height ? `height: ${props.height};` : '')};
    ${(props) => (props.overflow ? `overflow: ${props.overflow};` : '')};
    ${(props) => props.condensed && 'font-family: Roboto Condensed'};
    ${(props) => {
        if (props.lineHeight) {
            return `line-height: ${props.lineHeight}`
        }
    }};
    text-decoration: ${(props) => (props.strike ? 'line-through' : 'none')};
`

Text.propTypes = {
    size: PropsTypes.oneOf(MEDIAS),
    color: PropsTypes.string,
    align: PropsTypes.oneOf(['left', 'right', 'center']),
    bold: PropsTypes.bool,
    block: PropsTypes.bool,
    lineHeight: PropsTypes.number,
}

export default Text
