import styled, { keyframes } from 'styled-components'
import Wrapper from 'ui/Wrapper'
import loginBanner from 'images/banners/login-mobile-banner.png'
import Button from 'ui/controls/Button'
import ValidInput from 'ui/controls/ValidInput'
import Text from 'ui/Text'
import { PALETTE } from 'themes'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'

export const StyledLoginMobileWrapper = styled(Wrapper)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ zIndex }) => (zIndex ? zIndex : '10')};
    overflow: auto;
    margin: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 25px;
    background: linear-gradient(
            to bottom,
            transparent,
            transparent 20%,
            #fff 45%
        ),
        url(${loginBanner}) #fff;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
        background-size: cover;
        background: url(${loginBanner}) #fff;
    }
    ${(detectMobile() || detectIpad()) && 'background-size: cover;'};
`
export const CloseModalButton = styled.div`
    width: 45px;
    height: 45px;
    background: ${PALETTE.primaryAction};
    display: flex;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
`
export const OpretBtn = styled(Button)`
    margin: 0 0 0 auto;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 13px 35px;
`

export const ToggleGroup = styled.label.attrs({ htmlFor: 'toggleLogin' })`
    margin: 200px auto 50px;
    width: 100%;
    background: #ffffff;
    display: flex;
    height: 50px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    padding: 5px;
    border-radius: 7px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .left,
    .right {
        user-select: none;
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        transition: 0.2s;
        width: 50%;
        z-index: 2;
        font-family: 'Roboto Condensed', sans-serif;
    }
    div {
        background: #eceff6;
        border-radius: 5px;
        height: 40px;
        left: 5px;
        position: absolute;
        transition: all 0.5s;
        top: 5px;
        width: calc(50% - 5px);
        z-index: 1;
    }
    input[type='checkbox']:checked ~ div {
        transform: translateX(100%);
    }
    input[type='checkbox']:not(:checked) ~ .right {
        color: #9198aa;
    }
    input[type='checkbox']:checked ~ .left {
        color: #9198aa;
    }

    @media screen and (max-width: 350px) {
        margin: 200px auto 50px;
    }
    @media screen and (max-height: 667px) {
        margin: 150px auto 20px;
    }
    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
        margin: 50px auto;
    }
`

export const CommonLoginInput = styled(ValidInput)`
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    padding: 0 25px;
    background: #eceff6;
    color: #9198aa;
    border-radius: 5px;
    position: relative;
    outline: none;
    border: none;
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;

    span {
        font-size: 18px;
        color: #9198aa;
        font-weight: bold;
        font-family: 'Roboto Condensed', sans-serif;
    }
    & + ${Text} {
        margin-bottom: 50px;
        font-size: 18px;
        font-family: 'Roboto Condensed', sans-serif;
    }
`

export const LoginButton = styled(Button)`
    position: relative;
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    padding: 0 25px;
    border-radius: 5px;
    font-size: 18px;
    outline: none;
    border: none;
    margin: 10px 0;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    transition: all 0.3s ease;
    &:disabled {
        opacity: 1;
        background: #eceff6;
    }
`

export const PinIndicatorsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-bottom: ${({ check }) => (check ? '70px' : '150px')};
    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
        margin-bottom: 50px;
    }
`
export const PinIndicator = styled.div`
    width: ${({ filled }) => (filled ? '30px' : '64px')};
    height: ${({ filled }) => (filled ? '30px' : '64px')};
    border-radius: 64px;
    box-sizing: border-box;
    border: ${({ isCurrent }) => (isCurrent ? '3px solid #DB1C1B' : 'none')};
    transition: all 0.5s ease;
    background: ${({ filled }) => (filled ? '#DB1C1B' : '#fff')};
`
export const PinKeyboard = styled.div`
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    margin-top: 20px;
`
export const PinKeyboardButton = styled.div`
    background: #ececf4;
    border: 1px solid #dee2eb;
    display: flex;
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 33.3%;
    box-sizing: border-box;
    padding: 20px 0;
    font-family: 'Roboto Condensed', sans-serif;
`

const loading = keyframes`
    0%  {
        opacity: 0.5;
        filter: blur(5px);
        letter-spacing: 3px;
    }
    100%{}
`

export const BottomSection = styled.div`
    padding: 15px 0;
    width: 100%;
    position: relative;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    ${LoginButton} {
        @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
            box-shadow: 0 0 15px 2px ${PALETTE.darkGray};
        }
    }
`

export const LoginLabel = styled.p`
    animation: ${(props) =>
        props.loading ? loading + ' 1.2s infinite 0s ease-in-out' : ''};
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
`
