import React from 'react'
import styled from 'styled-components'
import rofusLogo from 'icons/basic-icons/rofus.svg'
import clockIcon from 'images/icons/clock.png'
import { PALETTE } from 'themes'
import LoginArea from 'features/Promo/Components/LoginArea'

const HeaderContainer = styled.div`
    position: relative;
    z-index: 1;
`
const TopBar = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    background: #000000;
    border-bottom: 1px solid rgb(54, 54, 54);

    @media screen and (max-width: 968px) {
        height: 46px;
    }
`
const TopMenu = styled.div`
    width: auto;
    display: flex;
    flex: 1 1 auto;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
`
const TopMenuLogo = styled.a`
    & img {
        display: flex;
        width: 25px;
        height: 25px;
        margin: 0 0.25rem 0 0;
    }
`
const TopMenuText = styled.p`
    color: #fff;
    text-align: left;
    padding: 0;
    font-size: 12px;
    a {
        color: #fff;
        text-decoration: none;
    }
    @media screen and (max-width: 968px) {
        font-size: 10px;
    }
`
const Separator = styled.span`
    display: inline-block;
    margin: 0 7px;
    @media screen and (max-width: 968px) {
        margin: 0 3px;
    }
`
const ClockWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row;
    flex: 1 1 auto;
    color: #fff;
    padding: 0 10px;
    @media screen and (max-width: 968px) {
        font-size: 10px;
    }
`
const ClockIcon = styled.div`
    display: inline-block;
    background-image: url(${clockIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin: 0 5px;
`
const ClockSeparator = styled.span`
    animation: blink 2s steps(1, start) 0s infinite normal none running;
    -webkit-animation: blink 1s steps(5, start) infinite;
`
const HeaderWrapper = styled.div`
    background: ${PALETTE.primary};
    width: 100%;
    position: relative;
    height: 100%;

    padding: 10px;
`
const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
`
const ProductLogo = styled.img`
    height: 40px;
    vertical-align: middle;
    padding-left: 20px;
`

const clock = () => {
    let date = new Date()
    let mins = ('0' + date.getMinutes()).slice(-2)
    let hours = date.getHours()

    return (
        <ClockWrapper>
            {hours}
            <ClockSeparator>:</ClockSeparator>
            {mins}
            <ClockIcon />
        </ClockWrapper>
    )
}

const PromoHeader = ({ defaultLogo, logo, projectName, imgHost, signUp }) => {
    return (
        <HeaderContainer>
            <TopBar>
                <TopMenu>
                    <TopMenuLogo href="https://www.spillemyndigheden.dk/">
                        <img src={rofusLogo} alt="Rofus" />
                    </TopMenuLogo>
                    <TopMenuText>
                        18+
                        <Separator>|</Separator>
                        <a href="tel:70222825">StopSpillet: 70 22 28 25</a>
                        <Separator>|</Separator>
                        <a href="https://www.rofus.nu/">ROFUS.nu</a>
                    </TopMenuText>
                </TopMenu>
                {clock()}
            </TopBar>
            <HeaderWrapper>
                <HeaderContent>
                    <a href="/">
                        <ProductLogo
                            src={logo ? imgHost + logo : defaultLogo}
                            alt={projectName}
                        />
                    </a>
                    <LoginArea signUp={signUp} />
                </HeaderContent>
            </HeaderWrapper>
        </HeaderContainer>
    )
}

export default PromoHeader
