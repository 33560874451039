import React from 'react'
import Box from 'ui/Box'
import { modalService } from 'services/ModalService'
import { ActiveBonuses } from '@it25syv/25syv-ui'
import { useHistory } from 'react-router-dom'

const t = window.T

const WithdrawDeposit = ({ bonuses, relatedGameId, gameId }) => {
    const history = useHistory()
    const confirmBonusRemoving = () => {
        modalService.showModal('REMOVE_BONUS_CONFIRM', {
            bonusAmount: bonuses.totalBonus,
        })
    }
    const getRedirectUrl = () => {
        modalService.closeModal()
        if (relatedGameId) {
            history.push('/game/' + relatedGameId)
        } else if (gameId === null) {
            history.push('/automater/alle')
        }
    }

    return (
        <Box p={3}>
            <ActiveBonuses
                onRemove={confirmBonusRemoving}
                activeBonus={bonuses.totalBonus}
                rollover={bonuses.rollover}
                initialRollover={bonuses.initialRollover}
                gameId={bonuses.gameId}
                redirectUrl={() => getRedirectUrl()}
            />
        </Box>
    )
}

export default WithdrawDeposit
