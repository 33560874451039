import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import { useHistory } from 'react-router-dom'
import Text from 'ui/Text'
import { Card } from 'ui/Card'
import contentService from 'services/ContentService'
import Box from 'ui/Box'
import Headline from 'ui/Headline'
import Img from 'ui/Img'
import Grid from 'ui/Grid'

const t = window.T

const { setCampaignsList, getCampaignsList } = contentService

const HeadlineTitle = styled(Headline)``

const StyledCard = styled(Card)`
    transition: all 0.3s ease;
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 10px 5px lightgray;
        ${HeadlineTitle} {
            color: ${(props) => props.theme.primary};
        }
    }
`
const CardBanner = styled(Img)`
    border-radius: 6px 6px 0 0;
    width: 100%;
`

const Label = styled.div`
    display: flex;
`
const LabelText = styled(Text)`
    color: ${(props) => props.theme.primary};
    background-color: rgba(219, 28, 27, 0.1);
    padding: 0.3rem 0.5rem;
    line-height: normal;
    font-weight: 700;
    border-radius: 4px;
`

const NewsPage = () => {
    const [campaigns, setCampaigns] = useState([])
    const history = useHistory()

    useEffect(() => {
        setCampaignsList()

        const campaignsListSub = getCampaignsList().subscribe((res) => {
            const campaigns = res.filter(
                (el) => el.active === 'true' && el.hidden === 'false'
            )
            setCampaigns(campaigns)
        })

        return () => {
            campaignsListSub.unsubscribe()
        }
    }, [])

    return (
        <PageWrapper>
            <PageContent p={3} maxWidth={'1140px'}>
                <Grid columns={1} deskColumns={2} gridGap={'1rem'}>
                    {campaigns.map((campaign, index) => (
                        <StyledCard
                            shadow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                history.push(
                                    `${t.pages.offer}/` + campaign.seo_url
                                )
                            }
                        >
                            <CardBanner src={campaign.bgimage_mob} />
                            <Box p={3}>
                                {campaign.site && (
                                    <Label>
                                        <LabelText size={'sm'}>
                                            {campaign.site.toUpperCase()}
                                        </LabelText>
                                    </Label>
                                )}
                                <HeadlineTitle
                                    as={'h3'}
                                    pb={2}
                                    dangerouslySetInnerHTML={{
                                        __html: campaign.title,
                                    }}
                                />
                                {campaign.teaser && (
                                    <Headline as={'h4'} p={0}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: campaign.teaser,
                                            }}
                                        />
                                    </Headline>
                                )}
                                <Text
                                    size={'sm'}
                                    mt={2}
                                    dangerouslySetInnerHTML={{
                                        __html: campaign.rules,
                                    }}
                                />
                            </Box>
                        </StyledCard>
                    ))}
                </Grid>
            </PageContent>
        </PageWrapper>
    )
}

export default NewsPage
