import React, { useEffect, useState } from 'react'
import { modalService } from 'services/ModalService'
import { Login as NewLogin, WithClearSafariPageCache } from '@it25syv/25syv-ui'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import userService from 'services/UserService'
import UserService from 'services/UserService'
import UrlHistoryService from 'services/UrlHistoryService'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import { useHistory } from 'react-router-dom'
import { getCookie } from 'services/CookieService'

const Login = ({ data }) => {
    const history = useHistory()
    const [isMitId, setIsMitId] = useState(false)

    const loginHandler = (
        userName,
        password,
        setLoading,
        onSuccess = () => {},
        onError = () => {}
    ) => {
        if (!userName || !password) {
            if (!userName) {
                NotificationConductor.error('Brugernavn mangler')
            }
            if (!password) {
                NotificationConductor.error('Kodeord kræves')
            }
        } else {
            setLoading(true)
            UserAdapter.login(userName, password)
                .then((data) => {
                    onSuccess()
                    modalService.closeModal('LOGIN')
                    localStorage.setItem('logged', 'true')
                    UserService.attachUser()

                    userService.setIsUserLoggedIn((userData) => {
                        setLoading(false)
                        UserService.showPinLoginPopup()
                        UrlHistoryService.openUserAreaAfterLogin(history)
                        window.dataLayer.push({
                            event: 'login',
                            userId: userData.userId,
                        })
                    })
                    selfExclusionNotification()
                })
                .catch((e) => {
                    setLoading(false)

                    if (e) {
                        NotificationConductor.error(e.errorMessage)

                        if (e.data) {
                            forgotPassHandler()
                        }
                    } else {
                        console.error('Error during login: ' + e)
                    }
                })
        }
    }

    const forgotPassHandler = () => {
        modalService.closeModal('LOGIN')
        modalService.showModal('LOGIN_FORGOT_PASS')
    }

    useEffect(() => {
        const itWasMitIdLogin = !!getCookie('isItInitialLogin')
        if (data.isMitId === true) {
            setIsMitId(true)
        } else if (data.isMitId === undefined) {
            if (itWasMitIdLogin === false) {
                setIsMitId(true)
            }
        }
    }, [])

    return (
        <NewLogin
            isMitIdLogin={isMitId}
            //showRegisterModal={showRegisterModal}
            closeAction={() => {
                modalService.closeModal()
            }}
            loginHandler={loginHandler}
            forgotPassHandler={forgotPassHandler}
            site={'casino'}
            showResetPassword={true}
        />
    )
}

export default WithClearSafariPageCache(Login)
