import {
    API_PHP,
    MAIN_REST_BASE_API,
    GAME_LAUNCH_API,
    API_URL,
    API_URL_OLD,
    CONTENT_API_BASE_URL,
} from 'configs/rest'
import { BehaviorSubject, combineLatest, Subject, zip } from 'rxjs'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { fetchDelete, get, post } from 'common/adapters/FetchAdapter'
import {
    byPlatform,
    detectMobile,
    getPlatformForApi,
} from '@/common/adapters/DeviceDetectAdapter'
import { fillTo } from 'utils/arrayHelper'
import GameDTO from 'common/DataObjects/GameDTO'
import { setCookie } from 'services/CookieService'
import { start } from 'services/GameLaunchService'
import { modalService } from 'services/ModalService'
import {
    getEvolutionGamesStateAdapter,
    getLiveCasinoGamesAdapter,
    getGamesFeaturesAdapter,
    getGamesThemesAdapter,
} from 'common/adapters/ProviderAdapter'
import GameJackpotService, { setJackpots } from 'services/GameJackpotService'
import { detectIpad } from 'common/adapters/DeviceDetectAdapter'
import CmsREST from 'features/CMSAPI'
import { RECENTLY_PLAYED_GAMES_AMOUNT } from 'configs/layout'
import { APP_NAME, BASE_URL, ENV, MAX_MULTI_GAMES_COUNT } from 'configs/main'
import lightning from 'icons/basic-icons/lightning-icon.svg'
import { SMART_FILTERS } from 'configs/categories'
import { getEnv } from 'utils/env'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import UserAdapter from 'common/adapters/UserAdapter'

const r = '?r='
const PERIOD_FOR_POPULAR = 7
const t = window.T

/**
 * https://rod25dk-static.casinomodule.com/games/starburst_mobile_html/game/starburst_mobile_html.xhtml?lobbyURL=https://m.roed25.dk&server=https://rod25dk-game.casinomodule.com/&callbackurl=https://rod25dk-game.casinomodule.com/mobile-game-launcher/GameCallback&operatorId=rod25dk&gameId=starburst_mobile_html_sw&lang=da&integration=standard&keepAliveURL=&redirect=true&loadStarted=1590584744578&giOperatorConfig={"gameId":"starburst_mobile_html_sw","staticServer":"https://rod25dk-static.casinomodule.com/","targetElement":"gameEmbed","liveCasinoHost":"rod25dk-livegame.casinomodule.com","lobbyURL":"https://m.roed25.dk","server":"https://rod25dk-game.casinomodule.com/","lang":"da","sessid":"1590584730177-108961-D9Z6Y4K8GUJDM","operatorId":"rod25dk","gameURL":"https://rod25dk-static.casinomodule.com/games/starburst_mobile_html/game/starburst_mobile_html.xhtml"}&liveCasinoHost=rod25dk-livegame.casinomodule.com&logsId=e56d7535-4bf5-4986-94d9-307649d4fe02&statisticEndpointURL=https://gcl.netentcdn.com/gcs/reportData&casinourl=https://m.roed25.dk
 * https://rod25dk-static-test.casinomodule.com/games/starburst_mobile_html/game/starburst_mobile_html.xhtml?lobbyURL=https://m.roed25.dk&server=https://rod25dk-game.casinomodule.com/&callbackurl=https://rod25dk-game.casinomodule.com/mobile-game-launcher/GameCallback&operatorId=rod25dk&gameId=starburst_mobile_html_sw&lang=da&integration=standard&keepAliveURL=&redirect=true&loadStarted=1590584744578&giOperatorConfig={"gameId":"starburst_mobile_html_sw","staticServer":"https://rod25dk-static.casinomodule.com/","targetElement":"gameEmbed","liveCasinoHost":"rod25dk-livegame.casinomodule.com","lobbyURL":"https://m.roed25.dk","server":"https://rod25dk-game.casinomodule.com/","lang":"da","sessid":"1590583706806-145-ON9ELX16N8GH4","operatorId":"rod25dk","gameURL":"https://rod25dk-static.casinomodule.com/games/starburst_mobile_html/game/starburst_mobile_html.xhtml"}&liveCasinoHost=rod25dk-livegame.casinomodule.com&logsId=e56d7535-4bf5-4986-94d9-307649d4fe02&statisticEndpointURL=https://gcl.netentcdn.com/gcs/reportData&casinourl=https://m.roed25.dk
 *
 * **/

const SeedGamesList = (games) => {
    const MIN_LIST_SIZE = 6

    if (games.length === 0) {
        return []
    }

    if (games.length < MIN_LIST_SIZE) {
        return fillTo(games, GameDTO.unserialize({}), games.length)
    }

    return games
}

let intervalLiveCasino = null

const allGames = new BehaviorSubject([])
const evolutionGamesState = new BehaviorSubject([])
const liveCasinoGames = new BehaviorSubject([])
const liveCasinoGamesStatus = new BehaviorSubject({})
const recentlyPlayedGames = new BehaviorSubject([])
const isGameLaunched = new BehaviorSubject(false)
const searchShown = new Subject(false)
const searchText = new BehaviorSubject('')
const allProvidersGames = new BehaviorSubject([])
const isFullScreen = new BehaviorSubject(false)
const multipleGames = new BehaviorSubject([])
const gamesByCategories = new BehaviorSubject([])
const gamesThemes = new BehaviorSubject([])
const gamesFeatures = new BehaviorSubject([])
const endGameUrl = new BehaviorSubject('')

const fetchGames = (
    endpoint,
    data = {
        platform: getPlatformForApi(),
        srv: getEnv(),
        app: APP_NAME,
        period: PERIOD_FOR_POPULAR,
        'page[size]': 4000,
    }
) => {
    if (!data.platform) {
        data.platform = getPlatformForApi()
        data.srv = getEnv()
    }
    return get(API_URL + 'games' + endpoint, data)
}

const getLaunchLink = (options) => {
    let requestParams = {
        ...options,
        lobby: detectMobile() && !detectIpad() ? BASE_URL : BASE_URL + '/lobby',
    }

    if (!detectMobile() || detectIpad()) {
        requestParams.platform = 'web'
    }

    return post(GAME_LAUNCH_API + 'launch/rod25', requestParams)
        .then((res) => {
            if (res.error) {
                // NotificationConductor.error(res.error.message)
                console.error(res.error.message)
            }

            if (res.data) {
                let link = res.data.url

                if (res.data.meta && res.data.meta.endgame) {
                    endGameUrl.next(res.data.meta.endgame)
                }
                if (res.data.params && res.data.params['set-cookie']) {
                    // let cookieValue = res.data.params['set-cookie'].replace('NetEnt=', '') + '; domain=.rod25dk-static.casinomodule.com;'
                    // console.log(cookieValue)
                    // setCookie('NetEnt', cookieValue)

                    let netentCookie =
                        res.data.params['set-cookie']
                            .replace('NetEnt=', '')
                            .replace('; path=/; Secure', '') +
                        ';.domain=rod25dk-static.casinomodule.com'

                    console.log('netentCookie', netentCookie)

                    setCookie('NetEnt', netentCookie)
                }

                return link
            }
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
        })
}

const getGameLaunchUrl = (options) => {
    // const requestOptions = {
    //     ...options,
    //     session_id: 'JSESSIONID=' + 'b25-as6~27EF4FB77A1A2D309F94B0CA00AD94D7.b25-as6',
    // }
    //
    // console.log('requestOptions', requestOptions)
    //
    // return getLaunchLink(requestOptions)
    //
    // console.log(options)

    if (options.mode === 'fun') {
        return getLaunchLink({
            ...options,
        })
    }

    return post(`${CONTENT_API_BASE_URL}core/getSes.php`, '', {
        credentials: 'include',
    })
        .then((data) => {
            return getLaunchLink({
                ...options,
                session_id: 'JSESSIONID=' + data.sessId,
            })
        })
        .catch((err) => {
            NotificationConductor.error(err.toString())
        })
}

const getCasinoLobbyUrl = (category = 'top_games') => {
    return post(`${CONTENT_API_BASE_URL}core/getSes.php`, '', {
        credentials: 'include',
    })
        .then((data) => {
            return getEvoCategoryLink({
                server_mode: ENV[window.location.host],
                srv: ENV[window.location.host],
                category: category,
                session_id: 'JSESSIONID=' + data.sessId,
                app: 'rod25',
                skin: 1,
                brand: 1,
            })
        })
        .catch((err) => {
            NotificationConductor.error(err.toString())
        })
}

const getEvoCategoryLink = (options) => {
    return post(API_URL + 'categories/evolution', options)
        .then((res) => {
            if (res.error) {
                console.error(res.error.message)
            }

            if (res.data) {
                let link = res.data.url

                return link
            }
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
        })
}

const gameMapper = (game) => {
    if (game.name) return GameDTO.unserialize(game)
}

const getGamesByKey = (allGames) => {
    const games = allGames.map((el) => gameMapper(el))
    return games
}

const getGameByProvider = (games, gameId) => {
    let game = games.find((g) => g.provider_id === gameId)

    if (game && game.game_id) {
        return game.game_id
    }

    return null
}

const getGameById = (games, gameId) => {
    return games.find((g) => g.game_id === gameId)
}

const casinoMapper = (games) => {
    let mappedGames = games

    mappedGames.forEach((game) => {
        game['casinoPageCategories'] = []
        game['liveCasinoPageCategories'] = []

        if (game.category === 'slots')
            game['casinoPageCategories'].push('all') &&
                game['casinoPageCategories'].push('bonus')
        if (game.subcat === 'jackpot')
            game['casinoPageCategories'].push('jackpot')
        //@TODO: rod_new to API
        if (game.rod_new === true && game.category === 'slots')
            game['casinoPageCategories'].push('new')
        if (game.category === 'liveint' || game.category === 'livedk')
            game['liveCasinoPageCategories'].push('all')
        if (
            (game.category === 'liveint' || game.category === 'livedk') &&
            game.subcat === 'roulette'
        )
            game['liveCasinoPageCategories'].push('roulette')
        if (game.category === 'liveint' && game.subcat === 'blackjack')
            game['liveCasinoPageCategories'].push('blackJack')
        if (game.category === 'liveint' && game.subcat === 'baccarat')
            game['liveCasinoPageCategories'].push('baccarat')
        if (game.category === 'liveint' && game.subcat === 'poker')
            game['liveCasinoPageCategories'].push('poker')
        if (game.category === 'liveint' && game.subcat === 'andet')
            game['liveCasinoPageCategories'].push('entertainment')
    })

    return mappedGames
        .filter((g) => g.rod_hide === false)
        .sort((a, b) => b.priority - a.priority || a.name.localeCompare(b.name))
}

const filterCategorizedGames = (categoriesList) => {
    let categorizedGames = []

    if (categoriesList) {
        categoriesList.forEach((category) => {
            const categoryName = Object.keys(category)[0]
            const categoryArray = category[categoryName]
            categoryArray
                .filter((el) => el.frontpage === 1 || el.sidebar === 1)
                .forEach((item) => {
                    const filteredGames = allGames.value.filter((game) =>
                        game[categoryName].includes(item.keys)
                    )
                    categorizedGames.push({
                        title: item.title,
                        games: filteredGames,
                        frontpage: item.frontpage === 1,
                        sidebar:
                            item.sidebar === 1 &&
                            SMART_FILTERS.find((el) => el.name === item.keys),
                        key: item.keys,
                        icon: lightning,
                        categoryName,
                    })
                })
        })
        gamesByCategories.next(categorizedGames)
    }
}

const filterGamesByName = (games = [], val = '') => {
    return games.filter((item) =>
        item.name
            .toLowerCase()
            .replace(/\s/g, '')
            .includes(val.replace(/\s/g, '').toLowerCase())
    )
}

const checkLoginBeforeStart = (game, history) => {
    UserAdapter.isUserLoggedIn().then((res) => {
        if (res) {
            if (res.status === 'open' || res.status === 'temp') {
                LaunchGame(game, history)
            } else {
                selfExclusionNotification()
            }
        } else {
            const loggedState = localStorage.getItem('logged')
            const showSessionPopup = loggedState === 'true'

            if (!showSessionPopup) {
                LaunchGame(game, history)
            }
        }
    })
}

const LaunchGame = (game, history) => {
    if (game.subprovider === 'evolution') {
        start(game, (link) => {
            window.location.href = link
        })
        return
    }

    if (game.game_id) {
        if (detectMobile() && !detectIpad()) {
            modalService.showModal('LOADER_COVER', {
                initialProps: { background: 'black' },
            })
            start(
                game,
                (link) => {
                    window.location.href = link

                    setTimeout(() => {
                        modalService.closeModal()
                    }, 1000)
                },
                false
            )
        } else {
            history.push(`/game/${game.game_id}`, { checkLogin: false })
        }
    }
}

const getRecentGames = (userId) => {
    return CmsREST.getRecentGames({
        site: 'rod',
        client_id: userId,
    })
}
const setRecentGame = (gameId, userId) => {
    CmsREST.setRecentGame({
        client_id: userId,
        game_id: gameId,
    }).catch((error) => NotificationConductor.error(error.toString()))
}
const removeRecentGame = (gameId, userId) => {
    return CmsREST.removeRecentGame({
        client_id: userId,
        game_id: gameId,
    })
}
const editRecentGames = (gameId, platform) => {
    const userId = localStorage.getItem('userId')
    const gameIdStr = gameId.toString() //API requires string type of data
    if (!recentlyPlayedGames.value.games) {
        prepareRecentGames(editRecentGames, gameId, platform)
    } else {
        const recentGames = recentlyPlayedGames.value.games.filter(
            (recentGame) => recentGame.platform === platform
        )

        const duplicateGame = recentGames.find(
            (recentGame) => recentGame.id === gameIdStr
        )

        if (duplicateGame) {
            removeRecentGame(duplicateGame.id, userId)
                .then(() => setRecentGame(duplicateGame.id, userId))
                .catch((error) => NotificationConductor.error(error.toString()))
        } else if (recentGames.length >= RECENTLY_PLAYED_GAMES_AMOUNT) {
            const lastRecentGameId = recentGames[recentGames.length - 1].id

            removeRecentGame(lastRecentGameId, userId)
                .then(() => setRecentGame(gameIdStr, userId))
                .catch((error) => NotificationConductor.error(error.toString()))
        } else {
            setRecentGame(gameIdStr, userId)
        }
    }
}

const prepareRecentGames = (cb = () => true, ...args) => {
    const userId = localStorage.getItem('userId')

    getRecentGames(userId)
        .then((res) => {
            if (res.data?.length) {
                let recentGames = []

                const gamesIds = allProvidersGames.value.map((game) => game.id)
                res.data.forEach((recentGame) => {
                    const gamePos = gamesIds.indexOf(+recentGame.game_id)
                    if (gamePos > -1) {
                        recentGames.push(allProvidersGames.value[gamePos])
                    }
                })

                recentGames = GameJackpotService.getJackpotGames(recentGames)
                const gamesData = {
                    received: true,
                    games: recentGames
                        .reverse()
                        .slice(0, RECENTLY_PLAYED_GAMES_AMOUNT),
                }

                recentlyPlayedGames.next(gamesData)
                cb(...args)
            } else {
                recentlyPlayedGames.next({
                    received: true,
                    games: [],
                })
                cb(...args)
            }
        })
        .catch((err) => {
            recentlyPlayedGames.next({
                received: true,
                games: [],
            })
            NotificationConductor.error(err)
        })
}

const getMultipleGameFromTheStore = (game) => {
    const prevValue = multipleGames.value

    if (prevValue.length < MAX_MULTI_GAMES_COUNT) {
        return [...prevValue, game]
    }
}

const updateMultipleGameInTheStore = (key, game) => {
    const prevValue = multipleGames.value
    if (prevValue.length) {
        prevValue[key] = game
        return [...prevValue]
    }
}

const removeMultipleGameFromTheStore = (key = null) => {
    const multipleGamesStore = [...multipleGames.value]
    if (key === null) {
        return []
    } else if (multipleGamesStore.length > 1) {
        multipleGamesStore.splice(key, 1)

        return [...multipleGamesStore]
    }
}
const combineScientificGames = (games) => {
    return games.map((game) => {
        if (game.subprovider === 'gdm') {
            game.subprovider = 'scientific'
        }
        return game
    })
}
/**
 * @typedef {Object} Popular params
 * @property {string} limit popular games count
 * @property {number} period period of time for getting games by the clicks
 * @property {boolean} [null] excludeHot remove games marked as hot (rod_hot, go_hot)
 * @property {boolean} [null] rodHot add hot games to response, sorts by clicks, adds rodHot to the first place  (exclude_hot и rod_hot can not work together)
 */
const getPopularGames = ({
    limit = 100,
    period = 7,
    excludeHot = null,
    rodHot = null,
}) => {
    return new Promise((resolve, reject) => {
        const params = {
            platform: getPlatformForApi(),
            app: APP_NAME,
            ...(typeof excludeHot === 'boolean' && { exclude_hot: excludeHot }),
            ...(typeof rodHot === 'boolean' && { rod_hot: rodHot }),
        }
        get(`${API_URL}games/popular/${period}/${limit}`, params)
            .then((res) => {
                if (res.data) {
                    const popularGames = res.data.map((game) => {
                        if (!Array.isArray(game.features)) {
                            game.features = game.features.split('|')
                        }
                        return game
                    })

                    const gamesWithJackpots = GameJackpotService.getJackpotGames(
                        popularGames
                    )

                    resolve(gamesWithJackpots)
                }
            })
            .catch((e) => {
                reject(e)
            })
    })
}

const setPopularGames = (gameId, userId) => {
    CmsREST.setPopularGames({
        gameId,
        userId,
        app: `rod25-${!detectMobile() || detectIpad() ? 'web' : 'mobile'}`,
    }).catch((e) => console.error('Cannot set game to popular: ', e))
}

const getUpcomingGames = () => {
    return new Promise((resolve, reject) => {
        get(
            MAIN_REST_BASE_API +
                API_PHP +
                r +
                byPlatform('UpcomingGamesMobile', 'UpcomingGames')
        )
            .then((games) => {
                const mappedGames = Object.values(games).map((game) =>
                    GameDTO.unserialize(game)
                )
                resolve(mappedGames)
            })
            .catch((e) => {
                reject(e)
            })
    })
}

const getRelatedGames = (gameObj, cb) => {
    if (gameObj) {
        const maxItemsAmount = 25
        const filteredAllGames = allGames.value
            .sort((a, b) => b.clicks - a.clicks)
            .filter((game) => game.id !== gameObj.id)

        const filterGamesByType = (types) => {
            const gameTags = Object.fromEntries(
                types.map((type) => {
                    return [type, gameObj[type]]
                })
            )

            const relatedGames = types
                .reduce((data, type) => {
                    const shouldTakeOneTag =
                        (types.length > 1 &&
                            types[types.length - 1] !== type) ||
                        types.length === 1

                    return data.filter((game) => {
                        if (game[type]) {
                            //@{theme} string
                            //@{features} array
                            let gameType =
                                typeof game[type] === 'string'
                                    ? game[type].split('|')
                                    : game[type]

                            const tagsOverlap = {
                                [type]: gameType.filter((tag) =>
                                    gameTags[type].includes(tag)
                                ).length,
                            }

                            if (!game.tagsOverlap) {
                                game.tagsOverlap = {}
                            }
                            game.tagsOverlap = {
                                ...game.tagsOverlap,
                                ...tagsOverlap,
                            }

                            return shouldTakeOneTag
                                ? tagsOverlap[type] > 0
                                : true
                        }
                    })
                }, filteredAllGames)
                .sort((a, b) =>
                    types.reduce((res, value) => {
                        return (
                            res || b.tagsOverlap[value] - a.tagsOverlap[value]
                        )
                    }, 0)
                )

            return relatedGames.slice(0, maxItemsAmount)
        }

        const filteredThemes = filterGamesByType(['theme', 'features'])
        const filteredFeature = filterGamesByType(['features'])

        cb(filteredFeature, filteredThemes)
    }
}

const combineAllGames = () => {
    return combineLatest(allGames, liveCasinoGames, (v1, v2) => {
        return v1.length && v2 ? [...v1, ...v2] : []
    })
}

const getAllProvidersGames = () => {
    return zip(liveCasinoGames, allGames, (v1, v2) => {
        return v1.length && v2.length ? [...v1, ...v2] : []
    })
}

const getAllGamesFeatures = () => {
    return combineLatest(gamesFeatures, gamesThemes, (v1, v2) => [
        { features: v1 },
        { theme: v2 },
    ])
}

const prepareLiveCasinoGames = (mappedGames) => {
    getEvolutionGamesStateAdapter()
        .then((res) => {
            if (res && res.data) {
                let liveTableGames = res.data
                let newArr = []

                mappedGames.forEach((game) => {
                    if (game.provider === 'evolution') {
                        Object.entries(liveTableGames).forEach(
                            ([tableID, liveTableGame]) => {
                                if (
                                    liveTableGame.open ||
                                    (!liveTableGame.open &&
                                        liveTableGame.operationHours ===
                                            'Bounded')
                                ) {
                                    if (tableID === game.table_id) {
                                        const newEl = {
                                            ...game,
                                            liveCas: { ...liveTableGame },
                                        }
                                        newArr.push(newEl)
                                    }
                                }
                            }
                        )
                    } else {
                        newArr.push({ ...game, liveCas: {} })
                    }
                })
                evolutionGamesState.next(res)
                liveCasinoGames.next(newArr)
                liveCasinoGamesStatus.next({
                    ...liveCasinoGamesStatus.value,
                    evolution: true,
                })
            }
        })
        .catch((e) => {
            let newArr = []
            liveCasinoGamesStatus.next({
                ...liveCasinoGamesStatus.value,
                evolution: false,
            })

            mappedGames.forEach((game) => {
                if (game.provider !== 'evolution') {
                    newArr.push({ ...game, liveCas: {} })
                }
            })
            liveCasinoGames.next(newArr)
        })
}

export default {
    getSearchDisplay: () => searchShown.asObservable(),
    setSearchDisplay: (state) => searchShown.next(state),
    getGameLaunchState: () => isGameLaunched.asObservable(),
    setGameLaunchState: (state) => isGameLaunched.next(state),
    getSearchText: () => searchText.asObservable(),
    searchGames: (param, value) =>
        get(API_URL + `games/smart-search`, {
            platform: getPlatformForApi(),
            srv: getEnv(),
            app: APP_NAME,
            [param]: value,
            period: 7,
        }).then((res) => {
            return getGamesByKey(res.data)
        }),

    getAllGames: () => allGames.asObservable(),
    getGameByProviderId: (gameId) => getGameByProvider(allGames.value, gameId),
    getGameById: (gameId) => getGameById(allProvidersGames.value, gameId),
    setSearchText: (text) => searchText.next(text),
    getCombinedCasinoData: () =>
        combineLatest(evolutionGamesState, liveCasinoGames, (v1, v2) => ({
            liveTablesState: v1,
            liveCasinoGames: v2,
        })), // TODO remove after release live-casino component
    getEvolutionGamesState: () => evolutionGamesState.asObservable(),
    setEvolutionGamesState: () =>
        getEvolutionGamesStateAdapter().then((res) => {
            evolutionGamesState.next(res)
        }),
    getLiveCasinoGames: () => liveCasinoGames.asObservable(),
    getLiveCasinoGamesStatus: () => liveCasinoGamesStatus.asObservable(),
    setLiveCasinoGames: (withInterval = false) =>
        /**
         * @TODO: rewrite to getting multiple request (this way it will be working with delay)
         */
        getLiveCasinoGamesAdapter().then((res) => {
            let mappedGames = casinoMapper(getGamesByKey(res.data))

            prepareLiveCasinoGames(mappedGames)

            if (withInterval) {
                intervalLiveCasino = setInterval(
                    prepareLiveCasinoGames,
                    30000,
                    mappedGames
                )
            }
        }),
    cleanIntervalLiveCasinoGames: () => clearInterval(intervalLiveCasino),

    setGamesList: (endpoint = '') => {
        const fetchEndpoint = endpoint ? '/' + endpoint : ''
        fetchGames(fetchEndpoint)
            .then((gamesList) => {
                let gamesListArr = gamesList.data

                let mappedGames = combineScientificGames(gamesListArr) // combine Scientific Games and GDM
                mappedGames = casinoMapper(getGamesByKey(mappedGames))

                allGames.next(mappedGames)
                GameJackpotService.getJackpotGames(mappedGames).then(
                    (gamesWithJackpots) => {
                        allGames.next([...gamesWithJackpots])
                    }
                )

                getGamesFeaturesAdapter().then((res) => {
                    gamesFeatures.next(res.data)
                })

                getGamesThemesAdapter().then((res) => {
                    gamesThemes.next(res.data)
                })
            })
            .catch((err) => {
                NotificationConductor.error(err)
            })
    },

    filterGamesByName,
    getGameLaunchUrl,
    getCasinoLobbyUrl,
    LaunchGame,
    setRecentlyPlayedGames: () => prepareRecentGames(),
    getRecentlyPlayedGames: () => recentlyPlayedGames.asObservable(),
    pushGameToRecentlyPlayed: (game) => editRecentGames(game.id, game.platform),
    checkLoginBeforeStart,
    checkFullScreen: () => isFullScreen.asObservable(),
    setFullScreen: (state) => isFullScreen.next(state),
    getMultipleGames: () => multipleGames.asObservable(),
    setMultipleGames: (game) =>
        multipleGames.next(getMultipleGameFromTheStore(game)),
    removeMultipleGames: (key) =>
        multipleGames.next(removeMultipleGameFromTheStore(key)),

    updateMultipleGames: (key, game) =>
        multipleGames.next(updateMultipleGameInTheStore(key, game)),
    getEndGame: () =>
        get(`${endGameUrl.value}`, '', { credentials: 'include' }),

    getGamesByCategories: () => gamesByCategories.asObservable(),
    setGamesByCategories: (categoriesList) =>
        filterCategorizedGames(categoriesList),
    getGamesFeatures: () => gamesFeatures.asObservable(),
    getGamesThemes: () => gamesThemes.asObservable(),
    getAllGamesFeatures,
    getPopularGames,
    setPopularGames,
    getUpcomingGames,
    getRelatedGames,
    getAllProvidersGames,
    combineAllGames,
    setAllProvidersGames: (games) => allProvidersGames.next(games),
    getProvidersGamesCount: (games) => {
        const key = 'subprovider'
        return games.reduce((hash, obj) => {
            return {
                ...hash,
                [obj[key]]: (hash[obj[key]] || []).concat(obj),
            }
        }, {})
    },
}
