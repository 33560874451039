import React from 'react'
import styled, { css } from 'styled-components'
import { marginProps } from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const Label = styled.label`
    display: inline-block;
    height: 25px;
    ${marginProps};
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
`

const StyledCheckbox = styled.div`
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 2px solid #33333334;
    border-radius: 3px;
    position: relative;

    ${(props) =>
        props.checked
            ? css`
                  background: ${props.activeColor
                      ? props.activeColor
                      : '#de1c1b'};
                  border: 2px solid
                      ${props.activeColor ? props.activeColor : '#de1c1b'};
                  &:after {
                      display: inline-block;
                      content: '';
                      position: absolute;

                      left: 6px;
                      top: 1px;
                      width: 7px;
                      height: 12px;
                      border: solid white;
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);
                  }
              `
            : css`
                  background: ${props.color ? props.color : '#ffffff'};
              `};
`

const Checkbox = (props) => (
    <Label {...props}>
        <HiddenCheckbox checked={props.checked} {...props} />
        <StyledCheckbox
            checked={props.checked}
            color={props.color}
            activeColor={props.activeColor}
        />
    </Label>
)

Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
}

export default Checkbox
