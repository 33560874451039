import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes'
import Button from 'ui/controls/Button'
import { modalService } from 'services/ModalService'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    width: 80%;
    background: ${BACKGROUNDS.white};
    color: ${BACKGROUNDS.primary};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 40px;
`
const Title = styled.h3`
    font-size: 1.5rem;
    margin: 0 0 19px;
    font-family: 'Roboto Condensed', sans-serif;
`
const Description = styled.span`
    font-size: 1rem;
    color: #818181;
    margin: 0 0 36px;
    font-family: 'Roboto Condensed', sans-serif;
`
const Bold = styled(Description)`
    font-weight: bold;
`
const ButtonsArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled(Button)`
    width: 90%;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 8px;
    font-size: 1rem;
    margin-bottom: 15px;
    font-family: 'Roboto Consndensed', sans-serif;
`

const t = window.T.depositLimitPopup

const DepositLimitPopup = ({ data }) => {
    const { maxDeposit } = data
    const history = useHistory()
    const closePopup = () => modalService.closeModal('DEPOSIT_LIMIT_WARNING')
    const openDepositLimit = () => {
        modalService.showModal('DEPOSIT_LIMIT')
    }
    return (
        <Wrapper>
            <Title>{t.title}</Title>
            <Description>
                {t.description1}
                <Bold> {maxDeposit} kr. </Bold>
                {t.description2}
            </Description>
            <ButtonsArea>
                <StyledButton
                    size="lg"
                    onClick={openDepositLimit}
                    background={'#333333'}
                    theme={{ accentHover: '#262626' }}
                    color={'white'}
                >
                    {t.submitBtn}
                </StyledButton>
                <StyledButton variant="danger" size="lg" onClick={closePopup}>
                    OK
                </StyledButton>
            </ButtonsArea>
        </Wrapper>
    )
}

export default DepositLimitPopup
