import homeIcon from '@/images/icons/basic-icons/bottom-menu/home.svg'
import casinoIcon from '@/images/icons/basic-icons/bottom-menu/automater.svg'
import liveCasinoIcon from '@/images/icons/basic-icons/sideNavIcons/filled/live-casino-fill.svg'
import jackpotIcon from '@/images/icons/basic-icons/jackpot-mobile.svg'
import categoryIcon from '@/images/icons/basic-icons/sideNavIcons/filled/automater-fill.svg'
import puzzleIcon from '@/images/icons/basic-icons/puzzle.svg'
import startIcon from 'images/icons/basic-icons/aсcount-area/star.svg'
import blackjackIcon from '@/images/icons/basic-icons/sideNavIcons/filled/blackjack-fill.svg'
import netentIcon from '@/images/icons/basic-icons/sideNavIcons/filled/netent.svg'
import playngoIcon from '@/images/icons/basic-icons/sideNavIcons/filled/playngo.svg'
import isoftbetIcon from '@/images/icons/basic-icons/sideNavIcons/filled/iSoftbet.svg'
import elkIcon from '@/images/icons/basic-icons/sideNavIcons/filled/elk.svg'
import newGamesIcon from '@/images/icons/basic-icons/sideNavIcons/filled/new-game-fill.svg'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

export default (history, activeItem, isUserLoggedIn) => {
    const uniqItems = ['/25nyt', '/spiludviklere', '/eksklusive-spil'] // important for highlight "More" item
    const newLabel = {
        background: '#fae311',
        textColor: '#97261f',
        text: 'NYT',
    }

    return {
        backgroundColor: '#DB1C1B',
        secondaryColor: '#F8C3C3',
        initialMenuPosition: 470,
        menuItems: [
            {
                title: 'Hjem',
                icon: homeIcon,
                action: () => history.push('/'),
                active: activeItem === '/',
            },
            {
                title: 'Automater',
                icon: casinoIcon,
                action: () => history.push('/automater'),
                active:
                    activeItem === '/automater' &&
                    history.location.pathname !== '/automater/nyheder',
            },
            {
                title: 'Live casino',
                icon: liveCasinoIcon,
                action: () => history.push('/live-casino/alle'),
                active: activeItem === '/live-casino',
            },
            isUserLoggedIn
                ? {
                      title: 'Til dig',
                      icon: startIcon,
                      action: () => history.push('/til-dig'),
                      active: activeItem === '/til-dig',
                  }
                : {
                      title: 'Nye spil',
                      icon: newGamesIcon,
                      action: () => history.push('/automater/nyheder'),
                      active:
                          history.location.pathname === '/automater/nyheder',
                  },
        ],
        dropDownMenuItems: {
            campaignAction: () => history.push('/25nyt'),
            intercomAction: () => IntercomAdapter.show(),
            uniqItemsActive: uniqItems.some((item) => activeItem === item),
            menuItems: [
                [
                    {
                        logo: puzzleIcon,
                        title: 'Alle spiludviklere',
                        action: () => history.push('/automater/spiludviklere'),
                    },
                ],
                [
                    {
                        logo: jackpotIcon,
                        title: 'Jackpotspil',
                        action: () => history.push('/automater/jackpots'),
                    },
                    {
                        logo: categoryIcon,
                        title: 'Kategorier',
                        action: () => history.push('/automater/kategorier'),
                    },
                ],
                [
                    {
                        logo: liveCasinoIcon,
                        title: 'Roulette',
                        action: () => history.push('/live-casino/roulette'),
                    },
                    {
                        logo: blackjackIcon,
                        title: 'Blackjack',
                        action: () => history.push('/live-casino/blackJack'),
                    },
                ],
                [
                    {
                        logo: netentIcon,
                        title: 'NetEnt',
                        action: () =>
                            history.push('/automater/spiludviklere/netent'),
                    },
                    {
                        logo: playngoIcon,
                        title: 'Play’n GO',
                        action: () =>
                            history.push('/automater/spiludviklere/playngo'),
                    },
                    {
                        logo: isoftbetIcon,
                        title: 'iSoftbet',
                        action: () =>
                            history.push('/automater/spiludviklere/isoftbet'),
                    },
                    {
                        logo: elkIcon,
                        title: 'ELK',
                        action: () =>
                            history.push('/automater/spiludviklere/elk'),
                    },
                ],
            ],
        },
    }
}
