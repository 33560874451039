import React, { useState, useEffect } from 'react'
import Text from 'ui/Text'
import ModalTopBar from 'ui/ModalTopBar'
import styled, { withTheme } from 'styled-components'
import Limits from 'features/DepositLimit/Components/Limits'
import Button from 'ui/controls/Button'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import Box from 'ui/Box'
import { pipe } from 'rxjs'
import withValidation from 'common/Hocs/WithValidation'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import Headline from 'ui/Headline'
import DepositLimitDTO from 'common/DataObjects/DepositLimitDTO'
import LimitForm from 'features/DepositLimit/LimitForm'
import Center from 'ui/Center'
import Loader from 'ui/Loader'
import SmallMultiSwitcher from 'ui/controls/SmallMultiSwitcher'

const t = window.T

const limitIntervals = [
    {
        name: 'daily',
        title: t.userArea.depositLimit.daily,
    },
    {
        name: 'weekly',
        title: t.userArea.depositLimit.weekly,
    },
    {
        name: 'monthly',
        title: t.userArea.depositLimit.monthly,
    },
]

const BackgroundHolder = styled(Box)`
    height: auto;
    min-height: 100%;
    background: ${(props) => props.theme.background};
`

const DepositLimit = ({ theme }) => {
    const [limits, setLimits] = useState(null)
    const [curTab, setCurTab] = useState('daily')
    const [showLimitForm, setShowLimitForm] = useState(false)
    const [input, setInput] = useState('')

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        UserAdapter.getDepositLimits()
            .then((res) => {
                setLimits(res.unserializeLimits())
            })
            .catch(console.error)
            .finally(() => setLoading(false))
    }, [])

    const validateInput = (value) => {
        return value.replace(/[^0-9]+/g, '')
    }

    const setLimitValue = (value, periodSetter) => {
        periodSetter(validateInput(value))
    }

    const changeTab = (tab) => {
        setCurTab(tab)
        setInput('')
    }

    const submit = () => {
        setInput('')
        let newLimits = limits.changeAmount(input, curTab)

        UserAdapter.setDepositLimits(newLimits)
            .then(({ data, errorMessage, success }) => {
                const SerilizedData = new DepositLimitDTO(data)

                if (!success) {
                    NotificationConductor.error(t[errorMessage])
                    return
                }

                setLimits(SerilizedData.unserializeLimits(data))
                NotificationConductor.success('Indbetalingsgrænse er nu ændre')
            })
            .catch((error) => {
                NotificationConductor.error(error)
            })
    }

    return (
        <BackgroundHolder>
            <ModalTopBar background={theme.primary} back color={theme.light}>
                <Text size={'md'} color={theme.light} my={2}>
                    {t.userArea.depositLimit.title}
                </Text>
            </ModalTopBar>
            <Box px={3}>
                <Box mt={3} mb={2}>
                    <SmallMultiSwitcher
                        onChange={changeTab}
                        value={curTab}
                        optionList={limitIntervals}
                        activeBgColor={theme.light}
                        color={theme.dark}
                        activeColor={theme.dark}
                    />
                </Box>
                <Headline as={'h4'}>
                    <Text as={'span'} bold capitalize>
                        {limitIntervals.find((p) => p.name === curTab).title}
                    </Text>{' '}
                    grænse
                </Headline>
                <Text align="left" mb={3}>
                    {t.userArea.depositLimit.description}
                </Text>

                {loading ? (
                    <Center absolutely>
                        <Loader />
                    </Center>
                ) : (
                    limits && (
                        <Limits
                            theme={theme}
                            limits={limits[curTab]}
                            clickHandler={changeTab}
                        />
                    )
                )}

                {showLimitForm ? (
                    <>
                        <LimitForm
                            value={input}
                            limits={limits[curTab]}
                            onChange={(value) => {
                                setLimitValue(value, setInput)
                            }}
                            onCancel={() => {
                                setInput('')
                                setShowLimitForm(false)
                            }}
                            onSubmit={submit}
                        />
                    </>
                ) : (
                    <Button
                        my={3}
                        block
                        size={'lgB'}
                        onClick={() => setShowLimitForm(true)}
                        variant={'danger'}
                    >
                        {t.next}
                    </Button>
                )}
            </Box>
        </BackgroundHolder>
    )
}

export default pipe(
    withValidation,
    withTheme,
    withAnimation
)(DepositLimit)
