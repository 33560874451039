import React from 'react'
import styled from 'styled-components'
import rotatePhone from 'images/turn.gif'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`
const LockOrientationModal = () => {
    return (
        <Wrapper>
            <img width={'30%'} src={rotatePhone} alt="rotatePhone" />
        </Wrapper>
    )
}

export default LockOrientationModal
