import styled from 'styled-components'
import { BREAKPOINTS } from '@/themes'

const getZindex = (props) => {
    if (!!props.popupMode) {
        return `${props.priority ? 2 + props.priority : 2}`
    }
    return `${props.priority ? 1000 + props.priority : 1000}`
}

const ModalWrapper = styled.div`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: 100%;
    z-index: ${getZindex};

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        //overflow: hidden; //it brakes bottom position
    }
`

export default ModalWrapper
