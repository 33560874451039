import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { formatDate } from 'utils/dateTime'

const selfExclusionNotification = () => {
    UserAdapter.getUserStatus().then((res) => {
        if (res.data && res.data.tm) {
            const message = `Din selvudelukkelse er aktiv frem til  ${formatDate(
                res.data.tm
            )}`

            NotificationConductor.error(message)
        }
    })
}
export default selfExclusionNotification
