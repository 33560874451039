import React, { useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import styled, { withTheme } from 'styled-components'
import ValidInput from 'ui/controls/ValidInput'
import NotificationConductor from 'common/conductors/NotificationConductor'
import withValidation from '@/common/Hocs/WithValidation'
import Checkbox from 'ui/controls/Checkbox'
import UserAdapter from 'common/adapters/UserAdapter'
import Box from 'ui/Box'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import { pipe } from 'rxjs'

const t = window.T

const BackgroundHolder = styled(Box)`
    height: 100%;
    background: ${(props) => props.theme.background};
`

const InputStyled = styled(ValidInput)``

const UpdatePassword = ({ setValidationHandler, validateAllFields, theme }) => {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false)

    const submitHandler = (e) => {
        e.preventDefault()

        if (validateAllFields() && newPassword === confirmNewPassword) {
            UserAdapter.updatePassword(
                currentPassword,
                newPassword,
                confirmNewPassword
            )
                .then((res) => {
                    res.success
                        ? NotificationConductor.success(
                              t.passwordChangedSuccessfully
                          )
                        : NotificationConductor.error(
                              t[res.errorMessage]
                                  ? t[res.errorMessage]
                                  : t.error
                          )
                })
                .catch((error) => {
                    console.log('updatePassword err', error)
                })
        } else {
            NotificationConductor.error(t.signupWindow.validation.password)
        }
    }

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0

        if (key === 13 && confirmNewPassword === newPassword) {
            e.preventDefault()
            submitHandler(e)
        }
    }

    return (
        <BackgroundHolder>
            <ModalTopBar background={theme.primary} back color={theme.light}>
                <Text size={'md'} color={theme.light} my={2}>
                    {t.securitySettings.changePassword}
                </Text>
            </ModalTopBar>

            <Wrapper style={{ textAlign: 'center' }}>
                <Text padding="20px 0 0" align="left" margin={'10px 0 35px'}>
                    Ændre dit kodeord til Bet25 Casino. Husk at din kode også
                    ændres på Bet25 Sport og Bet25 Heste.
                </Text>
                <Flex my={3}>
                    <InputStyled
                        onChange={(v) => setCurrentPassword(v)}
                        value={currentPassword}
                        placeholder={
                            t.updatePassword.currentPasswordPlaceholder
                        }
                        validate={{
                            required: true,
                        }}
                        type={isPasswordShown ? 'text' : 'password'}
                        getValidator={(validatorHandler) =>
                            setValidationHandler(
                                'currentPassword',
                                validatorHandler
                            )
                        }
                        onKeyPress={keyPressHandler}
                    />
                </Flex>
                <Flex my={3}>
                    <InputStyled
                        onChange={(v) => setNewPassword(v)}
                        value={newPassword}
                        placeholder={t.updatePassword.newPasswordPlaceholder}
                        validate={{
                            required: true,
                            minLength: 10,
                            password: {
                                message: t.signupWindow.validation.password,
                            },
                            minLengthMessage:
                                t.signupWindow.validation.password,
                        }}
                        type={isPasswordShown ? 'text' : 'password'}
                        getValidator={(validatorHandler) =>
                            setValidationHandler(
                                'newPassword',
                                validatorHandler
                            )
                        }
                        onKeyPress={keyPressHandler}
                    />
                </Flex>
                <Flex my={3}>
                    <InputStyled
                        onChange={(v) => setConfirmNewPassword(v)}
                        value={confirmNewPassword}
                        placeholder={
                            t.updatePassword.confirmMewPasswordPlaceholder
                        }
                        validate={{
                            required: true,
                        }}
                        type={isPasswordShown ? 'text' : 'password'}
                        getValidator={(validatorHandler) =>
                            setValidationHandler(
                                'confirmNewPassword',
                                validatorHandler
                            )
                        }
                        onKeyPress={keyPressHandler}
                    />
                </Flex>

                <Flex justify={'left'} mb={3}>
                    <Checkbox
                        checked={isPasswordShown}
                        onChange={() => setIsPasswordShown(!isPasswordShown)}
                    />

                    <Text ml={2}>Vis adgangskode</Text>
                </Flex>

                <Flex>
                    <Button
                        size={'lgB'}
                        variant={'light'}
                        mr={1}
                        style={{ flex: '50%' }}
                        onClick={() => {
                            setCurrentPassword('')
                            setNewPassword('')
                            setConfirmNewPassword('')
                        }}
                    >
                        {t.cancel}
                    </Button>
                    <Button
                        size={'lgB'}
                        onClick={submitHandler}
                        variant={'danger'}
                        disabled={confirmNewPassword !== newPassword}
                        ml={1}
                        style={{ flex: '50%' }}
                    >
                        {t.submit}
                    </Button>
                </Flex>
            </Wrapper>
        </BackgroundHolder>
    )
}

export default pipe(
    withValidation,
    withTheme,
    withAnimation
)(UpdatePassword)
