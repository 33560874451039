import React, { useState } from 'react'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Icon from 'ui/Icon'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import out from 'images/icons/basic-icons/aсcount-area/out.svg'
import Trustpilot from 'images/icons/basic-icons/aсcount-area/Trustpilot.svg'
import Facebook from 'images/icons/basic-icons/aсcount-area/Facebook.svg'
import girl from 'images/icons/basic-icons/aсcount-area/girl.png'
import star from 'images/icons/basic-icons/aсcount-area/star.svg'
import Headline from 'ui/Headline'
import Flex from 'ui/Flex'
import { Card } from 'ui/Card'
import Img from 'ui/Img'
import { useHistory } from 'react-router-dom'

const t = window.T

const Ancor = styled.a`
    text-decoration: none;
`

const Girl = styled(Img)`
    position: relative;
`

const CardContent = styled(Flex)`
    position: relative;
    padding: 0.5rem 1rem 0 1rem;
`

const ProductReview = ({ theme, close }) => {
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    return (
        <Box background={theme.light} height={'100%'}>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
                customClose={() => {
                    close()
                    history.push('/')
                }}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    Anmeld os
                </Text>
            </ModalTopBar>

            <Box p={3}>
                <Card background={theme.primary}>
                    <CardContent>
                        <Girl src={girl} height={'100px'} />
                        <Box>
                            <Headline as={'h2'} color={theme.light} mb={0}>
                                TAK!
                            </Headline>
                            <Text
                                size={'sm'}
                                color={theme.light}
                                lineHeight={1.5}
                                mb={3}
                            >
                                Din mening er SUPER <br />
                                vigtig for os.
                                <span width={'auto'} ml={1}>
                                    {[1, 2, 3, 4, 5].map((starIndex) => (
                                        <Icon
                                            src={star}
                                            size={'sm'}
                                            key={starIndex}
                                            color={'#F9D800'}
                                            style={{ margin: '1px' }}
                                        />
                                    ))}
                                </span>
                            </Text>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Ancor
                href="https://dk.trustpilot.com/evaluate/r%C3%B8d25.dk"
                target={'_blank'}
            >
                <ProfileCard
                    m={3}
                    iconSrc={Trustpilot}
                    title={'Trustpilot'}
                    background={theme.light}
                >
                    <Icon src={out} size={'lg'} />
                </ProfileCard>
            </Ancor>

            {/*<Ancor*/}
            {/*    href={*/}
            {/*        'https://apps.apple.com/app/996068683?action=write-review'*/}
            {/*    }*/}
            {/*>*/}
            {/*    <ProfileCard*/}
            {/*        m={3}*/}
            {/*        iconSrc={AppStore}*/}
            {/*        title={'App Store'}*/}
            {/*        background={theme.light}*/}
            {/*    >*/}
            {/*        <Icon src={out} size={'lg'} />*/}
            {/*    </ProfileCard>*/}
            {/*</Ancor>*/}

            <Ancor
                href={
                    'https://www.facebook.com/roed25dk/reviews/?ref=page_internal'
                }
                target={'_blank'}
            >
                <ProfileCard
                    m={3}
                    iconSrc={Facebook}
                    title={'Facebook'}
                    background={theme.light}
                >
                    <Icon src={out} size={'lg'} />
                </ProfileCard>
            </Ancor>
        </Box>
    )
}

export default ProductReview
