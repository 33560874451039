import React, { useEffect } from 'react'

const useKeyDown = (key, action) => {
    const eventHandler = (e) => e.keyCode === key && action()

    useEffect(() => {
        document.addEventListener('keydown', eventHandler, false)

        return () =>
            document.removeEventListener('keydown', eventHandler, false)
    })
}

export default useKeyDown
