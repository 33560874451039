import React, { useEffect, useState } from 'react'
import { ChooseDate } from '@it25syv/25syv-ui'
import { DATEPICKER_THEME_CONFIG } from 'utils/themeHelpers'
import { modalService as ModalService } from '../../services/ModalService'
import 'react-infinite-calendar/styles.css'
import UserService from '../../services/UserService'

const themeDatePicker = DATEPICKER_THEME_CONFIG

const TransactionDetails = (props) => {
    const [transactionHistoryDate, setTransactionHistoryDate] = useState({})
    useEffect(
        () => {
            if (Object.keys(transactionHistoryDate).length > 0) {
                UserService.setTransactionDate(transactionHistoryDate)
            }
        },
        [transactionHistoryDate]
    )

    return (
        <ChooseDate
            closeChooseDate={() =>
                ModalService.closeModal('TRANSACTION_CHOOSE_DATE')
            }
            datePickerTheme={themeDatePicker}
            sendChosenDate={(date) => {
                setTransactionHistoryDate(date)
                setTimeout(() => {
                    ModalService.closeModal('TRANSACTION_CHOOSE_DATE')
                }, 50)
            }}
        />
    )
}
export default TransactionDetails
