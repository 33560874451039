/** This function helps to import all files form directory by help of Webpack require method
 Check Webpack doc https://webpack.js.org/guides/dependency-management/#requirecontext
 **/

/**
 *
 * @param required
 */
function importAllImages(required) {
    let imageKeys = required.keys()

    let importedImages = imageKeys.map(required)

    let images = {}

    for (let i = 0; i < importedImages.length; i++) {
        if (imageKeys[i]) {
            images[imageKeys[i].slice(0, -4).substring(2)] = importedImages[i]
        }
    }

    return images
}

/**
 *
 * @param context
 * @returns Object {imageName: 'imagePathInStaticDirectory}
 */
export const getImagePathsFromDirectory = (context) => importAllImages(context)
