import { getJackpots, getJackpotsInfo } from 'common/adapters/ProviderAdapter'
import JackpotsDTO from 'common/DataObjects/Jackpots'
import { BehaviorSubject } from 'rxjs'
import { numberWithCommasAndDots } from 'utils/formatters'
import { LEVELS_NAME } from 'configs/jackpot.config'

const jackpotsInfoSubject = new BehaviorSubject(null)
const jackpotsSubject = new BehaviorSubject([])
const prepareJackpotsInfo = (games) => {
    const jackpotsInfo = jackpotsInfoSubject.value
    const jackpots = jackpotsSubject.value

    return games.map((item) => {
        const jackpotMatch = jackpots.find(
            (jackpot) => item.game_id === jackpot.game_id
        )
        if (jackpotMatch) {
            item.jackpot_amount = jackpotMatch?.jackpot_amount
            item.jackpot_amount_formatted =
                jackpotMatch.jackpot_amount_formatted
        }

        if (item.subcat === 'jackpot') {
            if (item.subprovider === 'netent') {
                const evoGamesInfo = jackpotsInfo?.evolutionGames

                const matchEvoGame = evoGamesInfo.find(
                    (jackpotEvo) => item.provider_id === jackpotEvo.gameId
                )
                const amount = matchEvoGame?.amount || 0
                item.jackpot_amount = amount
                item.jackpot_amount_formatted = numberWithCommasAndDots(amount)
            } else if (item.subprovider === 'isoftbet') {
                const monthlyAmount = LEVELS_NAME.monthly
                const maxJackpotAmount =
                    jackpotsInfo?.levels[monthlyAmount]?.amount || 0

                item.jackpot_amount = maxJackpotAmount
                item.jackpot_amount_formatted = numberWithCommasAndDots(
                    maxJackpotAmount
                )
            }
        }

        return item
    })
}

const setJackpotsInfo = () => {
    getJackpotsInfo().then((res) => {
        if (res?.data) {
            const { evolution, isoftbet } = res.data

            jackpotsInfoSubject.next(new JackpotsDTO(evolution, isoftbet))
        }
    })
}

const setJackpotGames = () => {
    getJackpots().then((jackpots) => {
        if (jackpots?.data && jackpots?.data.length) {
            jackpotsSubject.next(jackpots.data)
        }
    })
}

export default {
    setJackpotsInfo,
    getJackpotsInfo: () => jackpotsInfoSubject.asObservable(),
    setJackpotGames,
    getJackpotGames: (mappedGames) => prepareJackpotsInfo(mappedGames),
}
