import styled from 'styled-components'
import { PALETTE } from '@/themes'
import { BREAKPOINTS } from '@/themes/primary/breakpoints'

export const breakpoint = {
    S: `(max-width: 475px)`,
    M: `(max-width: 768px)`,
    L: `(max-width: 1024px)`,
}

export const FooterWrapper = styled.footer`
    transition: all 0.4s linear;

    * {
        font-weight: 300;
        font-family: 'Roboto Condensed';
    }
`

export const FooterTop = styled.div`
    width: 100%;
    background: #404040;
    min-height: 220px;
    box-sizing: border-box;
`

export const FooterCentered = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        padding-left: 18px;
        padding-right: 18px;
    }
`

export const FooterTopContent = styled(FooterCentered)`
    color: #fff;
    padding-top: 24px;
    padding-bottom: 60px;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        text-align: center;
        padding-bottom: 30px;
    }
`

export const FooterTopMenuWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        flex-direction: column;
        align-items: center;
    }
`

export const FooterTopMenu = styled.div`
    width: ${(props) => (props.width ? props.width : 'auto')};
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        :not(.show-on-mobile) {
            display: none;
        }

        width: 100%;
    }
`

export const FooterTopMenuText = styled.p`
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    padding: 0 0 10px;
    margin: 0;
    line-height: 20px;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 12px;
    }
`

export const FooterTopMenuHeader = styled.h4`
    color: ${PALETTE.light};
    font-size: 18px;
    padding: 12px 0;
    margin: 0;
`

export const FooterTopMenuList = styled.ul`
    list-style: disc;
    padding: 0 0 0 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        list-style: none;
    }
`

export const FooterTopMenuItem = styled.a`
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    margin-left: -5px;
    line-height: 22px;
`

export const FooterPaymentWrapper = styled.div`
    background: #333333;
`

export const FooterPaymentCentered = styled(FooterCentered)`
    display: flex;
    min-height: 220px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        padding: 20px 0;
        min-height: 260px;
        max-width: 500px;
        flex-wrap: wrap;
    }

    @media screen and (max-width: ${BREAKPOINTS.md + 'px'}) {
        min-height: 200px;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        min-height: 260px;
    }
`

export const FooterPaymentItem = styled.div`
    width: 157px;
    height: 90px;
    background: #ffffff;
    box-shadow: 0 0 10px #00000033;
    border-radius: 5px;
    margin: 0 20px;
    text-align: center;

    img {
        height: 100%;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        width: 118px;
        height: 68px;
        flex-wrap: wrap;
    }
`

export const FooterTextBlock = styled.div`
    background: #212121;
    min-height: 180px;
`

export const FooterText = styled(FooterCentered)`
    column-count: 2;
    column-gap: 145px;
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #a6a6a6;
    line-height: 24px;

    h3 {
        padding: 0;
        margin: 0 0 20px;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
    }

    p {
        margin: 0 0 40px;
        display: inline-block;
    }

    a {
        color: #a6a6a6;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        column-count: 1;
    }
`

export const FooterDGA = styled.div`
    background: #404040;
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        min-height: 112px;
    }
`

export const FooterDGABlock = styled.a`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        line-height: 18px;
    }

    img {
        height: 80px;
        @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
            height: 60px;
        }
    }
`

export const FooterDGATitle = styled.div`
    width: 200px;
    margin-left: 10px;
    font-size: 18px;
    color: #fff;
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 16px;
    }
`

export const FooterBottom = styled.div`
    background: #333333;
    padding: 48px 0 60px;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        padding: 28px 0 60px;
    }
`

export const FooterBottomSupport = styled.div`
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        flex-direction: column;
    }
`

export const FooterBottomSupportItem = styled.div`
    border-radius: 5px;
    background: #585858;
    width: 33%;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img {
        height: 35px;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        width: 100%;
        margin: 0 0 17px;
        justify-content: space-between;
        padding: 0 20px;
    }
`

export const FooterBottomSupportBtn = styled.a`
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    background: rgba(256, 256, 256, 0.25);
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    margin: 0 0 0 15px;
    font-size: 15px;
    font-weight: bold;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        width: 165px;
        text-align: center;
        font-size: 14px;
        box-sizing: border-box;
    }
`

export const FooterBottomCopyright = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 0;
    line-height: 20px;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        flex-direction: column;
        text-align: center;
        line-height: 16px;
    }
`

export const FooterBottomCopyrightItem = styled.div`
    width: 220px;
    color: #adadad;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        width: 100%;
        margin: 6px 0 0;
        font-size: 12px;
    }
`

export const FooterBottomAgeRestrictItem = styled(FooterBottomCopyrightItem)`
    text-align: right;

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 14px;
        text-align: left;
        margin: 20px 0 0;
    }
`

export const FooterBottomAgeRestrict = styled.div`
    min-width: 35px;
    width: 35px;
    height: 35px;
    background: #fff;
    font-size: 15px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
    color: #333;
    border-radius: 50%;
    margin: 0 8px 0 0;
`

export const FooterClockDesktop = styled.div`
    color: #fff;
    font-size: 24px;
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        display: none;
    }
`

export const FooterClockMobile = styled(FooterClockDesktop)`
    display: none;
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        display: block;
    }
`
