import NotificationConductor from 'common/conductors/NotificationConductor'
import { getPlatformForApi } from 'common/adapters/DeviceDetectAdapter'
import { BehaviorSubject } from 'rxjs'
import { fetchDelete, get, post } from 'common/adapters/FetchAdapter'
import { API_URL } from 'configs/rest'
import { APP_NAME } from 'configs/main'
import GameJackpotService from 'services/GameJackpotService'

const RECOMMENDED_COUNT = 4
const favoriteGames = new BehaviorSubject([])
const recommendedGames = new BehaviorSubject([])

const gameLike = (game_id) => {
    const user_id = localStorage.getItem('userId')
    if (!user_id) {
        return Promise.reject(new Error(t.LOGINFIRST))
    }
    return post(`${API_URL}clients/${user_id}/games/${game_id}`)
}
const gameUnLike = (game_id) => {
    const user_id = localStorage.getItem('userId')
    if (!user_id) {
        return Promise.reject(new Error(t.LOGINFIRST))
    }
    return fetchDelete(`${API_URL}clients/${user_id}/games/${game_id}`)
}
export default {
    gameLike,
    gameUnLike,
    fetchFavorite: (id = null) => {
        const userId = parseInt(localStorage.getItem('userId'))
        if (userId || id) {
            get(`${API_URL}clients/${userId || id}/games`, {
                platform: getPlatformForApi(),
                app: APP_NAME,
            })
                .then((res) => {
                    if (res.error) {
                        NotificationConductor.error(res.error)
                    } else {
                        const gamesWithJackpots = GameJackpotService.getJackpotGames(
                            res.data
                        )
                        favoriteGames.next(
                            gamesWithJackpots
                                ? gamesWithJackpots.filter(
                                      (g) => g.rod_hide === false
                                  )
                                : []
                        )
                    }
                })
                .catch((error) => {
                    NotificationConductor.error(error.toString())
                })
        } else {
            favoriteGames.next([])
        }
    },
    getFavoriteGames: () => favoriteGames.asObservable(),
    fetchRecommendedGames: (id = null) => {
        const userId = parseInt(localStorage.getItem('userId'))
        if (userId || id) {
            get(
                `${API_URL}clients/${userId ||
                    id}/recommends/${RECOMMENDED_COUNT}`,
                {
                    platform: getPlatformForApi(),
                }
            )
                .then((res) => {
                    if (res.error) {
                        NotificationConductor.error(res.error)
                    } else {
                        const fillInWithJackpots = GameJackpotService.getJackpotGames(
                            res.data
                        )
                        recommendedGames.next(fillInWithJackpots)
                    }
                })
                .catch((error) => {
                    NotificationConductor.error(error.toString())
                })
        }
    },
    getRecommendedGames: () => recommendedGames.asObservable(),
}
