import styled, { css } from 'styled-components'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import { FastDepositLimitForm } from '@it25syv/25syv-ui'
import React from 'react'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import { LIMIT_TYPE_DAILY } from 'common/DataObjects/DepositLimitDTO'
import { thousandsDots } from 'utils/formatters'
import { sprintf } from 'sprintf-js'
import { modalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const fastDepositLimitTexts = {
    title: 'Daglig indbetalingsgrænse',
    description:
        'Du bedes indtaste en daglig indbetalingsgrænse, som bliver det, du maksimalt vil kunne indbetale til din spilkonto. Grænsen kan til enhver tid ændres. Bemærk, at din indbetalingsgrænse er gældende på både Bet25 Sport, Bet25 Heste og Bet25 Casino.',
    inputLabel: 'Indtast grænse',
    maxAmountLabel: 'Eller',
    maxAmountBtn: 'Vælg max grænse',
    confirmBtnLabel: '(50.000 kr.)',
    confirmBtn: 'Gem',
    termsCondsLink: 'ansvarligt spil',
    termsCondsTitle: 'Ansvarligt spil',
    termsCondsText: 'Terms & Conditions',
    readMore: 'Læs mere om ',
    successTitle: `Din indbetalingsgrænse er gemt - %s kr. dagligt.`,
    successMessage:
        'Du kan altid ændre grænsen under dine kontoindstillinger i øverste højre hjørne.',
}

const inputLabelCSS = css`
    font-weight: bold;
`

const MaxAmountButton = styled(Button).attrs({
    bold: true,
    size: 'lgB',
    mt: '3',
})`
    font-size: 16px;
`
const ConfirmationButton = styled(Button).attrs({
    variant: 'danger',
    size: 'lgB',
    mt: '3',
})``

const Label = styled(Text).attrs({ bold: true, align: 'center' })`
    margin: 0.25rem 0 0;
`

const fastDepositLimitInputCSS = css`
    border: 1px solid ${(props) => props.theme.gray};
    position: relative;
    width: 100%;
    height: auto;
    background: #f1f1f1;
    border-radius: 2rem;
    padding: 15px 30px;
`

const ModalWrapper = styled.div`
    background: white;
    padding: 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 85px;
    position: fixed;
    bottom: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        margin-left: auto;
        margin-right: auto;
        top: 10vh;
        height: 80%;
        max-height: 580px;
        width: 40%;
        border-radius: 20px;
        overflow: auto;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 55px;
    }
`

const FastDepositLimit = ({ data }) => {
    const { initialProps = {}, userStatus } = data

    const setDepositLimit = (value) => {
        const newLimits = initialProps?.initialLimits?.changeAmount(
            value,
            LIMIT_TYPE_DAILY
        )

        UserAdapter.setDepositLimits(newLimits)
            .then(() => {
                NotificationConductor.success(
                    sprintf(
                        fastDepositLimitTexts.successTitle,
                        thousandsDots(value)
                    ) +
                        ' ' +
                        fastDepositLimitTexts.successMessage
                )

                modalService.closeModal('FAST_DEPOSIT_LIMIT')

                setTimeout(() => {
                    if (userStatus === 'temp') {
                        modalService.showModal('VERIFICATION_POPUP')
                    } else {
                        detectMobile() &&
                            modalService.showModal('PIN_LOGIN', {
                                action: 'set',
                                initialProps: { modalTopMargin: '0' },
                            })
                    }
                }, 300)
            })
            .catch((err) => {
                NotificationConductor.error(err.toString())
            })
    }

    return (
        <ModalWrapper>
            <FastDepositLimitForm
                MaxAmountButton={MaxAmountButton}
                ConfirmationButton={ConfirmationButton}
                inputCSS={fastDepositLimitInputCSS}
                texts={fastDepositLimitTexts}
                Label={Label}
                inputLabelCSS={inputLabelCSS}
                onConfirm={setDepositLimit}
            />
        </ModalWrapper>
    )
}

export default FastDepositLimit
