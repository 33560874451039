import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { pipe } from 'rxjs'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Flex from 'ui/Flex'
import ModalTopBar from 'ui/ModalTopBar'
import SortSection from 'features/SortModal/Components/SortSection'
import SortService from 'services/SmartSortService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import Knob from 'ui/controls/Knob'

const ButtonsHolder = styled(Flex)`
    position: sticky;
    bottom: 0;
    padding-bottom: 1rem;
    flex-grow: 0;
    z-index: 5;
    background: ${(props) => props.theme.light};
`

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const SmartFilters = ({ theme, close, data = { initialProps: {} } }) => {
    const [selectedSorts, setSelectedSorts] = useState([])

    const castPaybackSorting = (p) => (p ? parseFloat(p.replace('%', '')) : '0')

    const setSorts = (sortFilter) => {
        SortService.setSorts(sortFilter)
        setSelectedSorts(sortFilter)
    }

    const clearSortButton = () => {
        setSorts([])
    }

    useEffect(() => {
        const sortFilterSub = SortService.getSorts().subscribe((filter) => {
            setSelectedSorts(filter)
        })

        return () => sortFilterSub.unsubscribe()
    }, [])

    return (
        <>
            <ModalTopBar
                style={{ position: 'sticky', top: '0', zIndex: '5' }}
                background={theme.light}
                crossColor={theme.gray}
                pt={4}
                px={3}
                customClose={close}
            >
                <Flex justify={'space-between'} pr={5}>
                    <Text
                        size={'md'}
                        color={theme.dark}
                        my={2}
                        align={'center'}
                        style={{ width: '100%' }}
                    >
                        Sorter efter
                    </Text>
                </Flex>
            </ModalTopBar>
            <StyledBox
                width={'100%'}
                height={'100%'}
                px={0}
                background={theme.light}
            >
                <Box width={'100%'}>
                    <SortSection
                        options={[
                            {
                                label: 'Nyeste først',
                                subLabel: '',
                                value: 'a',
                                sortMethod: (prev, next) => {
                                    return (
                                        next.rod_new - prev.rod_new ||
                                        parseInt(prev.priority) -
                                            parseInt(next.priority) ||
                                        prev.name.localeCompare(next.name)
                                    )
                                },
                            },
                            {
                                label: 'Mest populære',
                                subLabel: '',
                                value: 'b',
                                sortMethod: (prev, next) =>
                                    parseInt(prev.priority) -
                                    parseInt(next.priority),
                            },
                            {
                                label: 'Navn',
                                subLabel: 'A - Å',
                                value: 'c',
                                sortMethod: (prev, next) =>
                                    prev.name.toLowerCase() <
                                    next.name.toLowerCase()
                                        ? -1
                                        : 1,
                            },
                            {
                                label: 'Navn',
                                subLabel: 'Å - A',
                                value: 'd',
                                sortMethod: (prev, next) =>
                                    prev.name.toLowerCase() <
                                    next.name.toLowerCase()
                                        ? 1
                                        : -1,
                            },
                        ]}
                        values={selectedSorts}
                        onChange={setSorts}
                    />
                    <SortSection
                        options={[
                            {
                                label: 'Minimumsindsats',
                                subLabel: 'Høj - Lav',
                                value: 'w',
                                sortMethod: (prev, next) =>
                                    parseFloat(next.min_stake || '0') -
                                    parseFloat(prev.min_stake || '0'),
                            },
                            {
                                label: 'Minimumsindsats',
                                subLabel: 'Lav - Høj',
                                value: 'z',
                                sortMethod: (prev, next) =>
                                    parseFloat(prev.min_stake || '0') -
                                    parseFloat(next.min_stake || '0'),
                            },
                        ]}
                        values={selectedSorts}
                        onChange={setSorts}
                    />
                    <SortSection
                        style={{ height: 'auto', minHeight: '100%' }}
                        options={[
                            {
                                label: 'Tilbagebetaling i % (TBP)',
                                subLabel: 'Høj - Lav',
                                value: 'v',
                                sortMethod: (prev, next) =>
                                    castPaybackSorting(next.payback) -
                                    castPaybackSorting(prev.payback),
                            },
                            {
                                label: 'Tilbagebetaling i % (TBP)',
                                subLabel: 'Lav - Høj',
                                value: 'm',
                                sortMethod: (prev, next) =>
                                    castPaybackSorting(prev.payback) -
                                    castPaybackSorting(next.payback),
                            },
                        ]}
                        values={selectedSorts}
                        onChange={setSorts}
                    />
                </Box>

                {detectMobile() && (
                    <ButtonsHolder px={2}>
                        <Knob
                            size={'lgB'}
                            mr={1}
                            background={'theme.lightGray'}
                            shadowed={false}
                            style={{ flex: '100%' }}
                            onClick={() => {
                                clearSortButton()
                            }}
                        >
                            {'Annuller'}
                        </Knob>
                    </ButtonsHolder>
                )}
            </StyledBox>
        </>
    )
}

export default pipe(
    withTheme,
    withAnimation
)(SmartFilters)
