import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Icon from '@/ui/Icon'
import chevronDown from '@/images/icons/basic-icons/chevronDown.svg'
import { useOutsideDetector } from '@/common/Hooks/UseOutsideDetector'
import PropTypes from 'prop-types'
import Text from 'ui/Text'

const LIGHT_GRAY = 'rgba(51, 51, 51, .25)'
const DARK = 'rgba(51, 51, 51, 1)'
const BORDER_WIDTH = 1

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const Control = styled.div`
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '30px')};
    background: #f1f1f1;
    border-radius: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 28px 30px;
    border: ${BORDER_WIDTH}px solid ${LIGHT_GRAY};
    margin: 1px;
    ${(props) =>
        props.listOpen &&
        css`
            margin: 0;
            border-radius: 2rem 2rem 0 0;
            border-bottom: ${BORDER_WIDTH * 2}px solid ${LIGHT_GRAY};
            border-top: ${BORDER_WIDTH * 2}px solid ${DARK};
            border-right: ${BORDER_WIDTH * 2}px solid ${DARK};
            border-left: ${BORDER_WIDTH * 2}px solid ${DARK};
        `};
`

const CommonOptionStyles = css`
    border-radius: 0 0 2rem 2rem;
`

const OptionList = styled.div`
    position: absolute;
    top: 59px;
    width: 100%;
    left: 0;
    z-index: 1000;
    background: #f1f1f1;
    border-left: ${BORDER_WIDTH * 2}px solid ${DARK};
    border-right: ${BORDER_WIDTH * 2}px solid ${DARK};
    border-bottom: ${BORDER_WIDTH * 2}px solid ${DARK};
    ${CommonOptionStyles};
    ${(props) =>
        !props.listOpen &&
        css`
            visibility: hidden;
        `};
`

const Option = styled.div`
    &:last-child {
        ${CommonOptionStyles};
    }
    cursor: pointer;
    &:hover {
        background-color: rgba(74, 227, 100, 0.1);
    }
    padding: 10px 30px;
`

const Select = (props) => {
    const [listOpen, setListOpen] = useState(false)

    const wrapperRef = useRef()

    useOutsideDetector(wrapperRef, setListOpen)

    const optionSelectHandler = (item) => {
        props.onChange(item.props.value)
        setListOpen(false)
    }

    return (
        <Wrapper
            style={{ color: `${listOpen ? DARK : LIGHT_GRAY}` }}
            ref={wrapperRef}
        >
            <Control listOpen={listOpen} onClick={() => setListOpen(!listOpen)}>
                {props.value ? (
                    <Text color={'black'}>{props.value}</Text>
                ) : (
                    props.placeholder
                )}
                <Icon
                    size={'md'}
                    src={chevronDown}
                    style={{
                        position: 'absolute',
                        right: '20px',
                        transform: `${
                            listOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                        }`,
                        background: `${listOpen ? DARK : LIGHT_GRAY}`,
                    }}
                />
            </Control>
            <OptionList listOpen={listOpen}>
                {props.children.map((item, index) => (
                    <Option
                        value={item.props.value}
                        key={index}
                        onClick={() => optionSelectHandler(item)}
                    >
                        {item.props.children}
                    </Option>
                ))}
            </OptionList>
        </Wrapper>
    )
}

Select.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.node,
}

export default Select
