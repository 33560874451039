import React, { useEffect, useRef, useState } from 'react'
import Flex from 'ui/Flex'
import Box from 'ui/Box'
import MediaGrid from 'ui/MediaGrid'
import GameList from 'features/GamesList/GameList'
import Headline from 'ui/Headline'
import { LEVELS_NAME } from 'configs/jackpot.config'
import { HeaderWrapper, LoadMoreButton } from 'features/Jackpots/styled'
import JackpotCard from 'features/Jackpots/JackpotCard'
import Jackpots from 'common/DataObjects/Jackpots'
import COLORS from 'themes/primary/palette'
import Button from 'ui/controls/Button'
import GameJackpotService from 'services/GameJackpotService'

const JackpotsView = ({
    displayedGames = [],
    getAdditionalGames,
    categoryLabel,
}) => {
    const [jackpotsData, setJackpotsData] = useState(new Jackpots(null, null))
    const [games, setGames] = useState({
        all: [],
        isoftbet: [],
    })
    const [gamesToShow, setGamesToShow] = useState(12)
    const [gamesAmount, setGamesAmount] = useState(0)

    // refs for scroll
    const ultraRef = useRef(null)
    const monthlyRef = useRef(null)
    const weeklyRef = useRef(null)
    const dailyRef = useRef(null)

    useEffect(
        () => {
            if (
                displayedGames?.length &&
                jackpotsData.evolutionGames &&
                jackpotsData.iSoftBetGames
            ) {
                const jackpotInfoSub = GameJackpotService.getJackpotsInfo().subscribe(
                    // should be moved to mount useEffect after OSS release
                    (res) => {
                        if (res) {
                            const jackpotInfo = res

                            const isoftbetGames = getAdditionalGames(
                                jackpotInfo.iSoftBetGames
                            )

                            const allGames = prepareEvoJackpots(displayedGames)

                            setGames({
                                all: allGames,
                                isoftbet: isoftbetGames,
                            })

                            setJackpotsData({
                                ...jackpotInfo,
                                levels: {
                                    ...jackpotInfo.levels,
                                    [LEVELS_NAME.ultra]: {
                                        ...jackpotInfo.levels[
                                            LEVELS_NAME.ultra
                                        ],
                                        amount: allGames[0].jackpot_amount,
                                    },
                                },
                            })

                            setGamesAmount(
                                allGames.length + isoftbetGames.length
                            )
                        }
                    }
                )

                return () => {
                    jackpotInfoSub.unsubscribe()
                }
            }
        },
        [displayedGames]
    )

    const prepareEvoJackpots = (games) => {
        const sortedGames = games
            .filter((el) => el.subprovider !== 'isoftbet')
            .sort((a, b) => Number(b.jackpot_amount) - Number(a.jackpot_amount))
        return sortedGames
    }

    const getGamesByLevelName = (name) => {
        switch (name) {
            case LEVELS_NAME.ultra:
                return games.all || []
            default:
                return games.isoftbet || []
        }
    }

    const sortGamesByName = (name) => {
        switch (name) {
            case LEVELS_NAME.ultra:
                return games.all || []
            case LEVELS_NAME.monthly:
                return games.isoftbet?.length
                    ? games.isoftbet
                          .map((game) => {
                              game.levelName = name
                              return game
                          })
                          .sort(
                              (a, b) => parseInt(b.clicks) - parseInt(a.clicks)
                          )
                    : []
            case LEVELS_NAME.weekly:
                return games.isoftbet?.length
                    ? games.isoftbet
                          .map((game) => {
                              game.levelName = name
                              return game
                          })
                          .sort((a, b) => b.name.localeCompare(a.name))
                    : []
            case LEVELS_NAME.daily:
            default:
                return games.isoftbet?.length
                    ? games.isoftbet
                          .map((game) => {
                              game.levelName = name
                              return game
                          })
                          .sort((a, b) => parseInt(b.id) - parseInt(a.id))
                    : []
        }
    }

    const getRefByName = (name) => {
        switch (name) {
            case LEVELS_NAME.ultra:
            default:
                return ultraRef
            case LEVELS_NAME.monthly:
                return monthlyRef
            case LEVELS_NAME.weekly:
                return weeklyRef
            case LEVELS_NAME.daily:
                return dailyRef
        }
    }

    const handleScroll = (name) => {
        let ref = getRefByName(name)

        if (ref?.current) {
            const offset = ref.current.offsetTop

            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            })
        }
    }

    const loadMore = (increment = 12) => {
        setGamesToShow(gamesToShow + increment)
    }

    return (
        <>
            <Headline
                as={'h3'}
                capitalize
                color={COLORS.dark}
                label={displayedGames?.length}
                colorLabel={COLORS.gray}
            >
                {categoryLabel}
            </Headline>
            <Box>
                <HeaderWrapper gap={'16px'} justify={'start'}>
                    {Object.values(jackpotsData?.levels)?.length &&
                        Object.values(jackpotsData.levels).map((item, key) => (
                            <JackpotCard
                                key={key}
                                item={item}
                                handleScroll={() => handleScroll(item?.name)}
                                gamesAmount={
                                    getGamesByLevelName(item.name)?.length
                                }
                            />
                        ))}
                </HeaderWrapper>
                <Flex flexDirection={'column'} align={'start'} gap={'30px'}>
                    {Object.values(jackpotsData?.levels)?.length &&
                        Object.values(jackpotsData.levels).map((item, key) => {
                            const gamesByName = sortGamesByName(item.name)
                            return (
                                <Box ref={getRefByName(item.name)} key={key}>
                                    <Flex
                                        fontSize={'14px'}
                                        justify={'start'}
                                        gap={'4px'}
                                    >
                                        <Headline
                                            as={'h3'}
                                            capitalize
                                            color={'#111111'}
                                        >
                                            {item.title}
                                        </Headline>
                                        <Headline
                                            as={'h3'}
                                            capitalize
                                            color={'#00000067'}
                                        >
                                            {gamesByName.length}
                                        </Headline>
                                    </Flex>
                                    <Box mt={3}>
                                        <MediaGrid>
                                            <GameList
                                                key={key}
                                                games={gamesByName}
                                                limit={gamesToShow}
                                            />
                                        </MediaGrid>
                                        {gamesByName?.length > gamesToShow && (
                                            <Box
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Button
                                                    size={'smB'}
                                                    mt={4}
                                                    onClick={() => loadMore()}
                                                    background={
                                                        COLORS.background
                                                    }
                                                    hover={COLORS.lightGray}
                                                >
                                                    Se flere spil
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )
                        })}
                </Flex>
            </Box>
        </>
    )
}

export default JackpotsView
