import React, { useEffect, useState } from 'react'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
import { CategoryWrapper } from 'features/ProvidersContent/styled'
import heart from 'icons/basic-icons/heart.svg'
import dots from 'icons/basic-icons/four-dots.svg'
import cherries from 'icons/basic-icons/sideNavIcons/filled/cherry-fill.svg'
import GameList from 'features/GamesList/GameList'
import Box from 'ui/Box'
import MediaGrid from 'ui/MediaGrid'
import ProviderGamesSlider from 'features/ProvidersContent/ProviderGamesSlider'
import moment from 'moment'
import { sortingByHotClicks } from 'utils/sorting'
import NewGamesGridTitle from 'ui/NewGamesGridTitle'

const ProviderItem = ({ data }) => {
    const { provider = {}, games: allGames = [] } = data

    const [preparedGames, setPreparedGames] = useState(allGames)
    const [newGames, setNewGames] = useState([])
    const [topGames, setTopGames] = useState([])

    const filterByOpened = (games) => {
        const filteredGames = games.filter(
            (game) =>
                !game.liveCas || (game.liveCas && game.liveCas.open !== false)
        )

        return filteredGames
    }

    const filterNewGames = (games) =>
        games
            .sort(
                (a, b) =>
                    (parseInt(
                        moment(b.release_date).format('YYYYMMDDHHmmss')
                    ) || 0) -
                        (parseInt(
                            moment(a.release_date).format('YYYYMMDDHHmmss')
                        ) || 0) ||
                    b.rod_new - a.rod_new ||
                    parseInt(a.priority) - parseInt(b.priority) ||
                    a.name.localeCompare(b.name)
            )
            .slice(0, 4)

    useEffect(
        () => {
            if (allGames.length) {
                const filteredGames = filterByOpened(allGames)
                const newGamesSorting = new Array(...filteredGames)
                setPreparedGames(sortingByHotClicks(filteredGames))
                setNewGames(filterNewGames(newGamesSorting))
                setTopGames(filteredGames.slice(0, 3))
            }
        },
        [allGames]
    )

    return !Object.keys(provider).length && !allGames.length ? (
        <Flex mt={3}>
            <Loader />
        </Flex>
    ) : (
        <>
            {!!topGames.length && (
                <CategoryWrapper>
                    <NewGamesGridTitle
                        icon={heart}
                        iconColor={'#f36'}
                        labelColor={'#48021F'}
                        text={'Top 3 på Bet25 Casino'}
                    />
                    <ProviderGamesSlider
                        games={topGames}
                        topGames
                        onpage={{
                            sm: 1,
                            md: 2,
                            lg: 2,
                            xl: 3,
                        }}
                    />
                </CategoryWrapper>
            )}
            {!!newGames.length && (
                <CategoryWrapper>
                    <NewGamesGridTitle
                        icon={dots}
                        iconColor={'#31a8ff'}
                        labelColor={'#011E36'}
                        text={'Nyheder'}
                    />
                    <ProviderGamesSlider games={newGames} />
                </CategoryWrapper>
            )}
            <CategoryWrapper>
                <NewGamesGridTitle
                    icon={cherries}
                    iconColor={'#db1c1b'}
                    labelColor={'#4B0807'}
                    text={'Alle spil'}
                />
                <Box my={3}>
                    <MediaGrid>
                        <GameList games={preparedGames} />
                    </MediaGrid>
                </Box>
            </CategoryWrapper>
        </>
    )
}

export default ProviderItem
