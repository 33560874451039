import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Input from 'ui/controls/Input'
import Grid from 'ui/Grid'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = window.T

const commonDepositList = [100, 200, 300, 400, 500, 1000]

const StyledButton = styled(Button)`
    background: ${(props) => props.theme.light};
    padding: 1rem;

    ${(props) =>
        props.active &&
        css`
            background: ${props.theme.primary};
            color: ${props.theme.light};
            border-color: ${props.theme.primary};
            &:hover {
                background: ${props.theme.primary};
                color: ${props.theme.light};
                border-color: ${props.theme.primary};
            }
        `};
`

const ButtonsArea = ({
    amount,
    onButtonClickHandler,
    onAmountChangeHandler,
    onSubmit,
    children,
    bonus = {},
    minDeposit = 50,
    disable,
    type = 'deposit', //or withdraw
}) => {
    const bonusAmount =
        bonus &&
        amount >= bonus.minAmount &&
        bonus.percent &&
        ((parseInt(bonus.percent) / 100) * amount > bonus.maxAmount
            ? bonus.maxAmount
            : (parseInt(bonus.percent) / 100) * amount)

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            if (formatAmount(amount) < minDeposit) {
                NotificationConductor.error(
                    `Mindste ${t.userArea[type]?.title} er ${minDeposit} kr.`
                )
            } else {
                event.preventDefault()
                onSubmit()
            }
        }
    }

    const formatAmount = (amount) => {
        const str = amount + '' //convert to string
        if (str.includes(',')) {
            let a = str.replace(',', '.')
            return parseFloat(a).toFixed(2)
        }

        return amount
    }

    // useEffect(() => {
    //     //fixes unpredictable touch (click) on submit button
    //     if (detectMobile()) {
    //         const className = 'ios-scroll-fix'
    //         document.body.classList.add(className)
    //         return () => {
    //             document.body.classList.remove(className)
    //         }
    //     }
    // }, [])

    return (
        <>
            <Flex width={'100%'} my={3}>
                <Input
                    pattern="[0-9]+([,][0-9]{1,2})?"
                    inputMode="decimal"
                    step="0.01"
                    type="text"
                    solid
                    width="100%"
                    margin="0 auto 30px"
                    placeholder={t.userArea.deposit.amount}
                    value={amount}
                    align="center"
                    hideCancel
                    onChange={(v) => onAmountChangeHandler(v)}
                    onKeyPress={keyPressHandler}
                    rightText={
                        <>
                            {bonus.externalId && (
                                <Text as={'span'} bold color={'#51A351'}>
                                    + {bonusAmount ? ` ${bonusAmount}` : '0'}{' '}
                                    (bonus)
                                </Text>
                            )}
                            <Text as={'span'} bold>
                                {' '}
                                kr.
                            </Text>
                        </>
                    }
                />
            </Flex>
            <Grid>
                {commonDepositList.map((commonDeposit) => (
                    <StyledButton
                        onClick={() => onButtonClickHandler(commonDeposit)}
                        key={commonDeposit}
                        variant={'lightHollow'}
                        size={'mdB'}
                        active={commonDeposit === amount}
                    >
                        {commonDeposit + ' ' + 'kr.'}
                    </StyledButton>
                ))}
            </Grid>
            {children && children}
            <Button
                variant={'danger'}
                size={'lgB'}
                block
                disabled={disable || formatAmount(amount) < minDeposit}
                my={2}
                onClick={(e) => {
                    formatAmount(amount) >= minDeposit ? onSubmit(e) : null
                }}
            >
                {t.userArea.deposit.proceed}
            </Button>
        </>
    )
}

export default ButtonsArea
