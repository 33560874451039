import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { pipe } from 'rxjs'
import { modalService } from 'services/ModalService'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Flex from 'ui/Flex'
import ModalTopBar from 'ui/ModalTopBar'
import Button from 'ui/controls/Button'
import FilterSection from 'features/SmartFilters/Components/FilterSection'
import SmartFilterService from 'services/SmartFilterService'
import FilterGamesService from 'services/FilterGamesService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { BASIC_PROVIDERS } from 'common/providers'

const t = window.T

const ButtonsHolder = styled(Flex)`
    position: sticky;
    bottom: 0;
    padding-bottom: 1rem;
    background: ${(props) => props.theme.light};
`

const ResetButton = styled(Button)`
    color: ${(props) => props.theme.primary};
    border: 0;
    font-weight: bold;
    &:hover {
        color: ${(props) => props.theme.primaryHover};
        border: 0;
        box-shadow: none;
    }
    &:disabled {
        color: ${(props) => props.theme.light};
    }
`

const SmartFilters = ({ theme, close, data = { initialProps: {} } }) => {
    const [selectedFilters, setSelectedFilters] = useState([])
    const [filteredGames, setFilteredGames] = useState(0)

    const setFilters = (filters) => {
        SmartFilterService.setFilters(filters)
        setSelectedFilters(filters)
        SmartFilterService.setTempFilters(filters)
    }

    const goToGames = () => {
        modalService.closeModal()
    }

    const clearFiltersButton = () => {
        setFilters([])
    }

    function themeFilter(g) {
        let showGame = false

        for (let i = 0; i < this.value.length; i++) {
            if (g.theme.includes(this.value[i])) {
                showGame = true
                break
            }
        }

        return showGame
    }

    function funktionerFilter(g) {
        let showGame = false

        for (let i = 0; i < this.value.length; i++) {
            if (g[this.key].includes(this.value[i])) {
                showGame = true
            } else {
                showGame = false
                break
            }
        }

        return showGame
    }

    useEffect(() => {
        const SmartFilterSub = SmartFilterService.getFilters().subscribe(
            (filters) => {
                setSelectedFilters(filters)
            }
        )

        return () => SmartFilterSub.unsubscribe()
    }, [])

    useEffect(() => {
        const FilterGamesSub = FilterGamesService.getFilteredGames().subscribe(
            (games) => {
                setFilteredGames(games)
            }
        )

        return () => FilterGamesSub.unsubscribe()
    }, [])

    return (
        <>
            <ModalTopBar
                style={{ position: 'sticky', top: '0', zIndex: '5' }}
                background={theme.light}
                crossColor={theme.gray}
                pt={4}
                customClose={close}
            >
                <Flex justify={'space-between'} pr={5}>
                    <Text size={'md'} color={theme.dark} my={2}>
                        Smart Filter
                    </Text>
                    <ResetButton
                        variant={'lightHollow'}
                        size={'sm'}
                        onClick={() => setFilters([])}
                    >
                        Nulstil alle
                    </ResetButton>
                </Flex>
            </ModalTopBar>
            <Box width={'100%'} px={0} background={theme.background}>
                <FilterSection
                    title={'Funktioner'}
                    options={[
                        {
                            label: 'Freespins runder',
                            value: 'freespins',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Megaways',
                            value: 'megaways',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Dropdown gevinster',
                            value: 'dropdown',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Butik i spil',
                            value: 'purchase',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Vind begge veje',
                            value: 'bothways',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Bonus runder',
                            value: 'bonus',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Respins',
                            value: 'respin',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                    ].sort((a, b) => a.label.localeCompare(b.label))}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    title={'Spiludvikler'}
                    options={BASIC_PROVIDERS.filter(
                        (provider) => !provider.upcoming
                    )}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    title={'Volatilitet'}
                    options={[
                        {
                            label: 'Lav',
                            value: 'lowfreq',
                            key: 'feature',
                        },
                        {
                            label: 'Mellem',
                            value: 'midfreq',
                            key: 'feature',
                        },
                        {
                            label: 'Høj',
                            value: 'hifreq',
                            key: 'feature',
                        },
                    ]}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    title={'Gevinstlinjer'}
                    options={[
                        {
                            label: '1 - 10',
                            value: '1-10',
                            key: 'paylines',
                        },
                        {
                            label: '10 - 30',
                            value: '11-30',
                            key: 'paylines',
                        },
                        {
                            label: '30 - 100',
                            value: '31-100',
                            key: 'paylines',
                        },
                        {
                            label: '100+',
                            value: '100+',
                            key: 'paylines',
                        },
                    ]}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    style={{ paddingBottom: '1rem' }}
                    title={'Tema'}
                    options={[
                        {
                            label: 'Vikinger',
                            value: 'vikinger',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Musik',
                            value: 'musik',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Bordspil',
                            value: 'bordspil',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Film',
                            value: 'film',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Eventyr',
                            value: 'eventyr',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Juletid',
                            value: 'juletid',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Sommer',
                            value: 'sommer',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Cowboys',
                            value: 'wild_west',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Sport',
                            value: 'sport',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Retro',
                            value: 'retro',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Påske',
                            value: 'paske',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Irland',
                            value: 'irland',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Gyser',
                            value: 'gyser',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Egypten',
                            value: 'egypten',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Luxus',
                            value: 'luxus',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Asien',
                            value: 'asien',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Junglen',
                            value: 'junglen',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Krimi',
                            value: 'krimi',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Sci-fi',
                            value: 'sci-fi',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Mytologi',
                            value: 'mytologi',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'TV',
                            value: 'tv',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Ædelsten',
                            value: 'adelsten',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Las Vegas',
                            value: 'las_vegas',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Frugt',
                            value: 'frugt',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Dyr',
                            value: 'dyr',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Fest',
                            value: 'fest',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Superhelte',
                            value: 'superhelte',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Middelalder',
                            value: 'middelalder',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Havet',
                            value: 'havet',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Kærlighed',
                            value: 'kerlighed',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Natur',
                            value: 'natur',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Mysterium',
                            value: 'mysterium',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Romerne',
                            value: 'romerne',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                    ].sort((a, b) => a.label.localeCompare(b.label))}
                    values={selectedFilters}
                    onChange={setFilters}
                />
                {detectMobile() && (
                    <ButtonsHolder px={2}>
                        <Button
                            size={'lgB'}
                            variant={'light'}
                            mr={1}
                            style={{ flex: '50%' }}
                            onClick={clearFiltersButton}
                        >
                            {'Annuller'}
                        </Button>
                        <Button
                            size={'lgB'}
                            onClick={goToGames}
                            variant={'danger'}
                            ml={1}
                            style={{
                                flex: '50%',
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                            }}
                        >
                            {'Se ' + filteredGames + ' resultater'}
                        </Button>
                    </ButtonsHolder>
                )}
            </Box>
        </>
    )
}

export default pipe(
    withTheme,
    withAnimation
)(SmartFilters)
