import styled, { css } from 'styled-components'
import { BACKGROUNDS, PALETTE } from '@/themes'
import Icon from 'ui/Icon'
import COLORS from 'themes/primary/palette'
import forward from 'icons/basic-icons/forward.svg'

export const DesktopSidebarSubTitle = styled.h3`
    color: ${PALETTE.gray};
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 10px;
`

export const ProductPopupContainer = styled.div`
    height: 415px;
    width: 790px;
    border-radius: 15px;
    background-color: ${({ background }) => background || '#a9a9a9'};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-overflow-style: none; /* Hide scroll in IE and Edge */
`
export const PopupContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 55px 90px;
    box-sizing: border-box;
    color: white;

    h4 {
        font-size: 20px;
        font-weight: bold;
    }
    p {
        font-size: 12px;
        line-height: 1rem;
        max-width: 42%;
        font-weight: normal;
    }
    button {
        color: white;
    }
`
export const PopupMainImage = styled.img`
    position: absolute;
    right: -135px;
    bottom: -17px;
    height: 487px;
`
export const PopupButton = styled.a`
    text-decoration: none;
    text-align: center;
    height: 35px;
    margin-top: 20px;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 25px;
    background: #eacc43;
    color: #333333;
    font: Bold 13px/15px 'Roboto Condensed';
    text-transform: uppercase;
    box-shadow: 0 0 25px #00000029;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    &:active {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
`
export const CloseButton = styled.div`
    position: absolute;
    top: -7px;
    right: -7px;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: #eceff5;
    cursor: pointer;
    img {
        height: 10px;
        width: 10px;
        line-height: 25px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`

export const PaddingDividor = styled.hr`
    color: rgba(255, 255, 255, 0.1);
    border-top: 1px solid;
    border-bottom: 0;
    width: calc(100% + 17px);
    position: relative;
    left: -8px;
`

export const LogoContainer = styled.span`
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 8px;
    cursor: pointer;
`

export const LinkText = styled.span`
    display: block;
    color: white;
`

export const LinkIcon = styled(Icon).attrs(() => ({
    height: '1.25rem',
    width: '1.25rem',
}))`
    margin-right: 21px;
`

export const NavItem = styled.div`
    display: flex;
    text-decoration: none;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem;
    margin-bottom: 8px;
    transition: all 0.3s ease;
    position: relative;

    ${({ isactive }) =>
        isactive &&
        css`
            &:before {
                content: '';
                display: block;
                width: 4px;
                height: 100%;
                position: absolute;
                background: #ffd804;
                left: -8px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                top: 0;
            }
        `};
    &:hover {
        ${({ isactive }) =>
            isactive
                ? css`
                      background: ${COLORS.primaryAction};
                  `
                : css`
                      background: ${BACKGROUNDS.red};
                      &:after {
                          content: ' ';
                          display: block;
                          width: 10px;
                          height: 10px;
                          position: absolute;
                          right: 10px;
                          background-color: white;
                          opacity: 0.2;
                          -webkit-mask-image: url(${forward});
                          mask-image: url(${forward});
                          -webkit-mask-repeat: no-repeat;
                          mask-repeat: no-repeat;
                      }
                  `};
        ${LinkIcon} {
            opacity: 1;
        }
    }

    ${LinkIcon} {
        transition: all 0.3s ease;
        background: #fff;
        opacity: ${(props) => (props.isactive ? 1 : 0.5)};
    }
`

export const ProviderNavItem = styled(NavItem)`
    padding: 0.4rem 1rem;
    &:hover {
        padding-left: 1.5rem;
        &:after {
            display: none;
        }
    }
`

const scrollWidth = css`
    width: ${(props) => (props.isOpen ? '224px' : '56px')};
`

export const ContentWrapper = styled.div`
    padding: 6px 8px 8px;

    .tooltip {
        opacity: 1 !important;
        font-size: 14px;
        font-weight: bold;
        color: #505050;
        transition: all 0.2s ease;
        @media screen and (max-width: 700px) {
            display: none !important;
        }
    }

    ${LinkText} {
        display: ${(props) => (props.isOpen ? 'block' : 'none')};
    }

    ${LinkIcon} {
        margin-right: ${(props) => (props.isOpen ? '21px' : '0')};
    }

    ${NavItem} {
        justify-content: ${(props) => (props.isOpen ? 'left' : 'center')};
        ${({ isOpen }) =>
            !isOpen &&
            css`
                &:hover {
                    &:after {
                        display: none;
                    }
                }
            `};
        ${scrollWidth};
    }

    ${LogoContainer} {
        ${scrollWidth};
    }

    ${ProviderNavItem} {
        display: ${(props) => (props.isOpen ? 'block' : 'none')};
    }
`
