import { isMobile } from 'react-device-detect'

export const VERSION = '1.0.0'
export const APP_NAME = 'rod25'
export const SUB_DIR_NAME = 'casino'
export const RESET_API_VERSION = 2

export const APP_MODE_TERMINAL = process.env.REACT_APP_TERMINAL_MODE
    ? JSON.parse(process.env.REACT_APP_TERMINAL_MODE)
    : false

if (process.env.NODE_ENV !== 'test') {
    window.T = process.env.TRANSLATES
}

export const PAYMENT_SERVICE_ID = 'Rod'

export const APP_MODE_MOBILE = 'APP_MODE_MOBILE'
export const APP_MODE_DESKTOP = 'APP_MODE_DESKTOP'

window.APP_MODE = APP_MODE_TERMINAL
    ? APP_MODE_TERMINAL
    : isMobile
        ? APP_MODE_MOBILE
        : APP_MODE_DESKTOP

export const DK_THEME = 'DK'
export const DE_THEME = 'DE'
export const THEME = process.env.THEME
export const DEBUG = true
export const LOCALE = DK_THEME

export const PIN_ATTEMPTS = 3
export const PIN_LENGTH = 4

export const LENGTH_SHORT = 3000
export const LENGTH_LONG = 30000

export const LOCAL_HOST = 'localhosts'
export const LOCAL_HOST_IP = '192.168.0.225' //change according to yours
export const LOCAL_IPS = []

export const DEFAULT_GAMES_SERVICE = 'dev' // 'dev' or 'prod'

export const CASINO_LOBBY_PATH =
    window.location.hostname === ('localhost' || 'dev.roed25.dk')
        ? 'https://bet25dk.uat1.evo-test.com'
        : 'https://live.roed25.dk'

export const DOMAIN_PATH = (() => {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'https://cms.bet25dev.dk'
    } else {
        switch (global.location.hostname) {
            case 'localhost':
                if (process.env.REACT_APP_LOCAL_ENV) {
                    return 'http://cms.local'
                } else {
                    return ''
                }
            case LOCAL_HOST_IP:
                return ''
            case 'bet25dev.dk':
                return 'https://cms.bet25dev.dk'
            case 'bet25test.dk':
                return 'https://cms.bet25test.dk'
            default:
                return 'https://cms.bet25.dk'
        }
    }
})()

export function ENV() {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'dev'
    } else {
        switch (window.location.hostname) {
            case 'localhost':
            case LOCAL_HOST_IP:
            case 'bet25dev.dk':
            case 'www.bet25dev.dk':
                return 'dev'
            case 'bet25test.dk':
                return 'test'
            default:
                return 'prod'
        }
    }
}

export const MAX_MULTI_GAMES_COUNT = 4
export const SUB_DIR_PATH =
    window.location.hostname === 'localhost' ||
    window.location.hostname === LOCAL_HOST_IP
        ? '/'
        : `/${SUB_DIR_NAME}/` //https://bet25.dk/${SUB_DIR_NAME}

export const BASE_URL = window.location.origin + '/casino'

export const CDN_IMG_PATH =
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'bet25dev.dk' //@TODO: rewrite it
        ? 'https://cdn.bet25dev.dk/casino/'
        : 'https://cdn.bet25.dk/casino/'
