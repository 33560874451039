import React, { useEffect } from 'react'
import { FixedWrapper } from 'features/Deposit/Components/Success'
import { useHistory } from 'react-router-dom'

const Error = () => {
    const history = useHistory()
    useEffect(() => {
        let message = JSON.stringify({
            type: 'custom-event',
            status: 'error',
            url: window.location.href,
        })

        window.parent.postMessage(message, '*')
        history.push('/', {
            deposit: true,
            status: 'error',
            url: window.location.href,
        })
    }, [])

    return <FixedWrapper />
}

export default Error
