import React, { Fragment, useEffect, useState } from 'react'
import { withTheme } from 'styled-components'

import { useHistory, useRouteMatch } from 'react-router-dom'
import WithGames from 'common/Hocs/WithGames'

import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import GamesService from 'services/GamesService'
import LiveCasino from '@it25syv/live-casino'
import NotificationConductor from 'common/conductors/NotificationConductor'
import selfExclusionNotification from 'features/SelfExclusion/SelfExlclutionNotification'
import { sortingByHotClicks } from 'utils/sorting'
import { liveTableGamesMock } from 'configs/live-casino-mock-data'
import { PALETTE } from 'themes'

const t = window.T

const LiveCasinoPage = ({ userInfo }) => {
    //gamesToMap can be removed is not used
    const history = useHistory()
    const match = useRouteMatch()

    const [showLoader, setShowLoader] = useState(true)
    const [liveCasinoGames, setLiveCasinoGames] = useState([])

    const getLobbyUrl = (provider) => {
        /**
         * @TODO: extend functionality for another providers when it will be ready
         */
        if (
            localStorage.getItem('logged') === 'false' ||
            localStorage.getItem('logged') === null
        ) {
            NotificationConductor.warning(t.notLoggedForPlay)
            return
        } else {
            if (userInfo.status === 'open' || userInfo.status === 'temp') {
                if (provider === 'evolution')
                    //for evolution session lobby
                    GamesService.getCasinoLobbyUrl().then((apiUrl) => {
                        window.location.href = apiUrl
                    })
            } else {
                selfExclusionNotification()
            }
        }
    }

    const showGame = (game, isGameOpened) => {
        if (game && isGameOpened) {
            GamesService.removeMultipleGames()
            if (
                localStorage.getItem('logged') === 'false' ||
                localStorage.getItem('logged') === null
            ) {
                NotificationConductor.warning(t.notLoggedForPlay)
                return
            }

            if (localStorage.getItem('logged') === 'false' && detectMobile()) {
                NotificationConductor.warning(t.notLoggedForPlay)
                return
            }

            GamesService.setSearchDisplay(false)

            GamesService.checkLoginBeforeStart(game, history)

            if (
                localStorage.getItem('logged') !== null &&
                localStorage.getItem('logged') !== 'false'
            ) {
                GamesService.pushGameToRecentlyPlayed(game)
            }
        }
    }

    //for mock data testing
    const getLiveCasinoGames = async () => {
        try {
            return new Promise((res, rej) => {
                setTimeout(() => res(liveTableGamesMock), 100)
            })
        } catch (err) {
            throw new Error('error.unknown')
        }
    }

    useEffect(() => {
        //GamesService.setLiveCasinoGames(true)
        const providersStatusSub = GamesService.getLiveCasinoGamesStatus().subscribe(
            (status) => {
                if (
                    Object.keys(status).length &&
                    status['evolution'] === false
                ) {
                    NotificationConductor.error(
                        'Live casino fra Evolution er i øjeblikket ikke tilgængelig, prøv igen senere.'
                    )
                }
            }
        )

        return () => {
            //dgaAPI.disconnect()
            providersStatusSub.unsubscribe()
            GamesService.cleanIntervalLiveCasinoGames()
        }
    }, [])

    useEffect(
        () => {
            // UserAdapter.isUserLoggedIn().then((res) => {
            //     setUserInfo(res)
            // })

            const sub = GamesService.getLiveCasinoGames().subscribe((res) => {
                if (res) {
                    setShowLoader(false)
                    //console.log('res111', res)
                    setLiveCasinoGames(sortingByHotClicks(res))
                }
            })
            //for mock data
            // getLiveCasinoGames().then((res) => {
            //     if (res && res.length) {
            //         console.log('res', res)
            //         setLiveCasinoGames(res)
            //     }
            // })

            return () => {
                sub.unsubscribe()
            }
        },
        [match.params.category]
    )

    return (
        <Fragment>
            <LiveCasino
                appName={'rod25'}
                //liveCasinoGames={liveCasinoGames}
                matchUrl={(category) => {
                    history.push('/live-casino/' + category)
                }}
                urlParams={match.params}
                menuColors={{
                    btnColor: '#333333',
                    btnActiveColor: PALETTE.secondary,
                    btnActiveTextColor: '#DA1D1B',
                    btnHoverColor: '#ebebeb',
                }}
                showThumbsByProviders={['evolution']}
                showGamesByProviders={['evolution', 'authentic', 'pragmatic']}
                userInfo={userInfo}
                thumbClickHandler={(thumb, game = {}, isGameOpened) => {
                    if (thumb.gameId) {
                        showGame(game, isGameOpened)
                    } else {
                        getLobbyUrl(thumb.providerKey)
                    }
                }}
                gameClickHandler={(game, isGameOpened = true) => {
                    showGame(game, isGameOpened)
                }}
                notificationHandler={(notification) => {
                    // switch (notification.type) {
                    //     case 'warning':
                    //         NotificationConductor.warning(notification.text)
                    //         break
                    //     case 'error':
                    //         NotificationConductor.error(notification.text)
                    //         break
                    //     case 'selfExcluded':
                    //         //call modal
                    //         break
                    //     default:
                    //         NotificationConductor.error(notification.text)
                    //         break
                    // }
                }}
            />
        </Fragment>
    )
}

export default withTheme(WithGames(LiveCasinoPage))
