import { THEME, DK_THEME } from '../configs/main'
import DK_BACKGROUNDS from './primary/backgrounds'
import DK_TYPOGRAPHY from './primary/typography'
import { TITLE_DIMENSIONS } from './primary/dimensions'
import { BREAKPOINTS } from './primary/breakpoints'
import { MEDIAS, MEDIAS_BASIC } from './primary/dimensions'
import DK_PALETTE from './primary/palette'

let COLORS, BACKGROUNDS, TYPOGRAPHY, PALETTE

switch (THEME) {
    case DK_THEME:
        BACKGROUNDS = DK_BACKGROUNDS
        TYPOGRAPHY = DK_TYPOGRAPHY
        PALETTE = DK_PALETTE
        break
    default:
        BACKGROUNDS = DK_BACKGROUNDS
        TYPOGRAPHY = DK_TYPOGRAPHY
        PALETTE = DK_PALETTE
}

export {
    BACKGROUNDS,
    TYPOGRAPHY,
    PALETTE,
    BREAKPOINTS,
    MEDIAS,
    MEDIAS_BASIC,
    TITLE_DIMENSIONS,
}
