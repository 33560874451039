import React, { useLayoutEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
    slideToBottom,
    slideToLeft,
    slideToRight,
    slideToTop,
} from 'utils/animations'
import { modalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const defaultAnimationSpeed = 300

const WithAnimation = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    ${(props) =>
        props.animation === 'slideToLeft' &&
        css`
            animation: ${slideToLeft} ${defaultAnimationSpeed}ms ease;
        `};
    ${(props) =>
        props.animation === 'slideToRight' &&
        css`
            animation: ${slideToRight} ${defaultAnimationSpeed}ms ease;
            transform: translateX(100%);
        `};
    ${(props) =>
        props.animation === 'slideToTop' &&
        css`
            animation: ${slideToTop} ${defaultAnimationSpeed}ms ease;
        `};
    ${(props) =>
        props.animation === 'slideToBottom' &&
        css`
            animation: ${slideToBottom} ${defaultAnimationSpeed}ms ease;
        `};

    ${(props) =>
        !props.fullSize &&
        css`
            @media (min-width: ${(props) =>
                    props.theme.breakpoints.lg + 'px'}) {
                width: ${(props) => (props.lgWidht ? props.lgWidht : '400px')};
            }
        `};
`

export const withAnimation = (Component) => ({
    animationSpeed = defaultAnimationSpeed,
    ...props
}) => {
    const [animation, setAnimation] = useState('')
    const animate = props.data.initialProps?.animation
    const fullSize = props.data.initialProps?.fullSize

    const animateForward = () =>
        setAnimation(detectMobile() ? 'slideToTop' : 'slideToLeft')
    const animateBackward = () =>
        setAnimation(detectMobile() ? 'slideToBottom' : 'slideToRight')

    const close = (modalName) => {
        animateBackward()

        setTimeout(() => {
            modalName
                ? modalService.closeModal(modalName)
                : modalService.closeModal()
        }, defaultAnimationSpeed)
    }

    const hocProps = { close, open }

    useLayoutEffect(() => {
        animate && animateForward()
    }, [])

    return (
        <WithAnimation animation={animation} fullSize={fullSize}>
            <Component {...hocProps} {...props}>
                {props.children}
            </Component>
        </WithAnimation>
    )
}
