import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import home from 'icons/basic-icons/sideNavIcons/filled/home-fill.svg'
import cherries from 'icons/basic-icons/sideNavIcons/filled/cherry-fill.svg'
import live from 'icons/basic-icons/sideNavIcons/filled/live-casino-fill.svg'
import message from 'icons/basic-icons/sideNavIcons/filled/message-fill.svg'
import thinLogo from 'icons/basic-icons/sideNavIcons/filled/25-fill.svg'
import star from 'images/icons/basic-icons/aсcount-area/star.svg'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { modalService } from 'services/ModalService'

import betPopupBanner from 'images/banners/bet-popup.png'
import derbyPopupBanner from 'images/banners/derby-popup.png'
import betLogo from 'images/logos/bet25.svg'
import derbyLogo from 'images/logos/derby25.svg'
import betImage from 'images/banners/betPlayers.png'
import derbyImage from 'images/banners/derbyHorses.png'

import {
    ContentWrapper,
    LinkIcon,
    LinkText,
    NavItem,
    PaddingDividor,
} from './styled'
import GamesService from 'services/GamesService'

const getPopupsConfig = (handlers = {}) => [
    {
        name: 'bet',
        title: 'Bet25 Sport - din danske bookmaker',
        background: '#1C3E60',
        logo: betLogo,
        smallImg: betImage,
        mainImg: {
            url: betPopupBanner,
            height: '427px',
            bottom: '-10px',
            right: '-35px',
        },
        onCardClick: handlers.onCardClick,
        texts: [
            'Ligegyldig om du foretrækker at spille på Christian Eriksen eller den næste vinder af Vild med Dans, så har Bet25 Sport odds på det.',
            'Bet25 Sport har flest odds på dansk sport og det største udvalg af specials på alle de danske stjerner fra både ind- og udland. Foruden sport tilbyder Bet25 altid flest odds på danske begivenheder som Melodi Grand Prix, X Factor, Folketingsvalget, Vild Med Dans og meget mere.',
        ],
        url:
            window.location.host === 'test.roed25.dk'
                ? '/transfer.php?u=test.bet25.dk'
                : '/transfer.php?u=www.bet25.dk',
        buttonTitle: 'GÅ TIL BET25 SPORT',
    },
    {
        name: 'derby',
        title: 'Bet25 Heste - jagt milliongevinsterne på hestespil',
        logo: derbyLogo,
        background: '#18AA63',
        smallImg: derbyImage,
        mainImg: {
            url: derbyPopupBanner,
            height: '607px',
            bottom: '-100px',
            right: '-217px',
        },
        onCardClick: handlers.onCardClick,
        texts: [
            'Hver uge kan du spille med om de store millionpuljer på Bet25 Heste – og det er lige meget om du er hesteekspert eller nybegynder.',
            'Med en Derby Lyn skal du ikke bekymre dig om, hvilke heste der skal vælges. Her vælges hestene automatisk for dig, så du bare skal læne dig tilbage og nyde spændingen.',
        ],
        url:
            window.location.host === 'test.roed25.dk'
                ? '/transfer.php?u=test.derby25.dk'
                : '/transfer.php?u=www.derby25.dk',
        buttonTitle: 'GÅ TIL Bet25 HESTE',
    },
]

const SideNavigationContent = ({ isOpen = false, ...props }) => {
    const [activeItem, setActiveItem] = useState('')
    const [gamesByCategories, setGamesByCategories] = useState([])

    const history = useHistory()

    const setTooltipProps = (title) => {
        return {
            'data-tip': title,
            'data-place': 'right',
            'data-type': 'light',
            'data-effect': 'solid',
            'data-class': 'tooltip',
        }
    }

    const onCardClick = (product) => {
        const betModalOpened = window.localStorage.getItem('betSidebarPopup')
        const derbyModalOpened = window.localStorage.getItem(
            'derbySidebarPopup'
        )

        if (
            (product.name === 'derby' && !derbyModalOpened) ||
            (product.name === 'bet' && !betModalOpened)
        ) {
            product.name === 'derby'
                ? window.localStorage.setItem('derbySidebarPopup', 'true')
                : window.localStorage.setItem('betSidebarPopup', 'true')
            modalService.showModal('SIDEBAR_POPUP', {
                props: product,
                initialProps: { animation: true, modalTopMargin: '0' },
            })
        } else {
            window.location.href = product.url
        }
    }

    const popupsConfig = getPopupsConfig({ onCardClick })

    useEffect(
        () => {
            const urlParts = history.location.pathname + history.location.search

            setActiveItem(urlParts)
        },
        [window.location.href]
    )

    useEffect(() => {
        const gamesFeaturesSub = GamesService.getGamesByCategories().subscribe(
            (res) => {
                const filteredGames = res.filter((el) => el.sidebar)
                setGamesByCategories(filteredGames)
            }
        )
        return () => {
            gamesFeaturesSub.unsubscribe()
        }
    }, [])

    return (
        <ContentWrapper {...props} isOpen={isOpen}>
            <NavItem
                isactive={activeItem === '/'}
                onClick={() => {
                    history.push('/')
                    setActiveItem('/')
                }}
                data-tip-disable={isOpen}
                {...setTooltipProps('Hjem')}
            >
                <LinkIcon src={home} />
                <LinkText>Hjem</LinkText>
            </NavItem>

            <NavItem
                isactive={activeItem === '/25nyt'}
                onClick={() => {
                    history.push('/25nyt')
                    setActiveItem('/25nyt')
                }}
                data-tip-disable={isOpen}
                {...setTooltipProps('25-nyt')}
            >
                <LinkIcon src={thinLogo} />
                <LinkText>25-nyt</LinkText>
            </NavItem>

            <NavItem
                onClick={() => {
                    IntercomAdapter.show()
                }}
                data-tip-disable={isOpen}
                {...setTooltipProps('Hjælp')}
            >
                <LinkIcon src={message} />
                <LinkText>Hjælp</LinkText>
            </NavItem>

            <PaddingDividor />

            <NavItem
                isactive={activeItem.includes('/automater')}
                onClick={() => {
                    history.push('/automater/alle')
                    setActiveItem('/automater/alle')
                }}
                data-tip-disable={isOpen}
                {...setTooltipProps('Automater')}
            >
                <LinkIcon src={cherries} />
                <LinkText>Automater</LinkText>
            </NavItem>
            <NavItem
                isactive={activeItem === '/live-casino/alle'}
                onClick={() => {
                    history.push('/live-casino/alle')
                    setActiveItem('/live-casino/alle')
                }}
                data-tip-disable={isOpen}
                {...setTooltipProps('Live Casino')}
            >
                <LinkIcon src={live} />
                <LinkText>Live Casino</LinkText>
            </NavItem>
            {props.userProfile.userId && (
                <NavItem
                    isactive={activeItem.includes('/til-dig')}
                    onClick={() => {
                        history.push('/til-dig')
                        setActiveItem('/til-dig')
                    }}
                    data-tip-disable={isOpen}
                    {...setTooltipProps('Til dig')}
                >
                    <LinkIcon src={star} color={'#fff'} />
                    <LinkText>Til dig</LinkText>
                </NavItem>
            )}
        </ContentWrapper>
    )
}

export default SideNavigationContent
