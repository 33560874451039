export const priority = {
    topBar: 100,
    bottomBar: 104,
    overlapTopBar: 101,
    overlapBottomBar: 105,
    betslipNotifier: 6,
    liveStream: 5,
    overlapBetslipNotifier: 7,
    horseStats: 6,
    replays: 8,
}

export const sidebar = {
    width: 15,
}

export const getNavigationHeight = () =>
    document.getElementById('app-navigation')?.offsetHeight

export const getProductBarHeight = () =>
    document.getElementById('product-transition-bar')?.offsetHeight

export const getTopBarHeight = () =>
    document.getElementById('full-header')?.offsetHeight

export const MINIMIZED_TOP_BAR_HEIGHT = 45

export const getFullHeaderHeight = () =>
    getNavigationHeight() + getTopBarHeight()

export const getBottomNavigationHeight = () =>
    document.getElementById('navigation-mobile')?.offsetHeight

export const RECENTLY_PLAYED_GAMES_AMOUNT = 10
