import { CMS_API_BASE_URL, CMS_REST_BASE_URL } from '../../configs/rest'
import * as FetchAdapter from 'common/adapters/FetchAdapter'

export function post(method, data, apiMode = false) {
    return FetchAdapter.post(
        (!apiMode ? CMS_REST_BASE_URL : CMS_API_BASE_URL) + method,
        data
    )
}

export function get(method, data, apiMode = false) {
    return FetchAdapter.get(
        (!apiMode ? CMS_REST_BASE_URL : CMS_API_BASE_URL) + method,
        data
    )
}
