import React from 'react'
import styled from 'styled-components'

const ModalWrapper = styled.div`
    background: #f9f9f9;
    padding: 0.8rem;
    display: flex;
    align-items: stretch;
    height: auto;
    min-height: 100%;
`

const Insert = styled.div`
    background: ${(props) => props.theme.primary};
    border-radius: 0% 0% 65% 65% / 0% 0% 14% 14%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 200px;
`

const ModalBackground = ({ children }) => {
    return (
        <ModalWrapper>
            <Insert />
            {children}
        </ModalWrapper>
    )
}

ModalBackground.propTypes = {}

export default ModalBackground
