import React, { useContext, useEffect, useState } from 'react'
import { modalService } from 'services/ModalService'
import {
    byPlatform,
    detectIpad,
    detectMobile,
} from '@/common/adapters/DeviceDetectAdapter'
import { ThemeContext } from 'styled-components'
import WithUrlHistory from 'common/Hocs/WithUrlHistory'
import { getPinLogin } from 'services/PinLoginService'
import { TopBar } from '@it25syv/25syv-ui'
import GameControls, {
    ADD_GAME,
    CLOSE,
    FULLSCREEN,
} from 'ui/controls/GamesControls'
import { getTopBarHeight } from 'configs/layout'
import BackButton from 'features/BackButton/BackButton'
import { useHistory } from 'react-router-dom'

const backButtonPaths = ['spiludviklere', 'kategorier']

const MainNavigation = ({
    onBurgerMenuClickHandler,
    userProfile,
    history,
    geoData,
    isGamePage,
}) => {
    const themeContext = useContext(ThemeContext)
    const [showBackButton, setShowBackButton] = useState(false)

    const showLoginModal = () => {
        if (geoData) {
            if (!geoData?.allowed) {
                modalService.showModal('GEO_DATA')

                return
            }

            if (window.location.pathname.split('/')[1] === 'game') {
                history.push('/')
            }

            detectMobile()
                ? !!getPinLogin()
                    ? modalService.showModal('PIN_LOGIN', {
                          action: 'check',
                          noBack: true,
                          initialProps: { modalTopMargin: '0' },
                      })
                    : modalService.showModal('LOGIN', {
                          initialProps: { modalTopMargin: '0' },
                      })
                : modalService.showModal('LOGIN', {
                      initialProps: { minHeight: '678px', modalTopMargin: '0' },
                  })
        }
    }

    const showRegisterModal = () => {
        if (geoData) {
            if (!geoData?.allowed) {
                modalService.showModal('GEO_DATA')

                return
            }

            modalService.showModal('SIGN_UP', {
                initialProps: {
                    modalTopMargin: '0',
                    ...byPlatform('', { autoScroll: true }),
                },
            })
        }
    }

    const showUserArea = () => {
        modalService.showModal('USER_AREA', {
            initialProps: {
                animation: true,
                outsideClose: true,
                totalBonus:
                    userProfile.bonusInfo !== null &&
                    userProfile.bonusInfo?.amount
                        ? userProfile.bonusInfo.amount
                        : 0,
            },
        })
    }

    const checkPathname = backButtonPaths.some((link) => {
        return (
            window.location.pathname.split('/').indexOf(link) ===
            window.location.pathname.split('/').length - 2
        )
    })

    useEffect(() => {
        setShowBackButton(checkPathname)
    }, [])
    useEffect(
        () => {
            setShowBackButton(checkPathname)
        },
        [window.location.pathname]
    )

    return (
        <TopBar
            background={themeContext.primary}
            onBurgerMenuClickHandler={onBurgerMenuClickHandler}
            userEmail={userProfile.email}
            username={userProfile.userName}
            balance={userProfile.balance}
            searchHandler={() => {
                modalService.showModal('SEARCH', {
                    initialProps: {
                        modalTopMargin: byPlatform(getTopBarHeight(), null),
                    },
                    props: {
                        initSearchText: '',
                        initNotEmpty: false,
                    },
                })
            }}
            afterSearchArea={
                !detectMobile() && showBackButton && <BackButton />
            }
            isGameRunning={isGamePage}
            secondColor={themeContext.primaryAction}
            bankClickHandler={() => {
                window.dataLayer.push({
                    event: 'red25.clickToPaymentSystem',
                    userId: userProfile.userId,
                })
                modalService.showModal('DEPOSIT', {
                    initialProps: {
                        animation: true,
                        outsideClose: true,
                    },
                })
            }}
            showUserArea={showUserArea}
            signUpHandler={() => {
                showRegisterModal()
            }}
            showLoginModal={showLoginModal}
            beforeUserArea={
                (!detectMobile() || detectIpad()) &&
                isGamePage && (
                    <GameControls
                        controls={[ADD_GAME, FULLSCREEN]}
                        hoverColor={themeContext.primaryAction}
                    />
                )
            }
            afterUserArea={
                (!detectMobile() || detectIpad()) &&
                isGamePage && (
                    <GameControls
                        controls={[CLOSE]}
                        hoverColor={themeContext.primaryAction}
                    />
                )
            }
            withoutSidebar={detectMobile() || detectIpad()}
        />
    )
}

export default WithUrlHistory(MainNavigation)
