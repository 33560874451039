import { BehaviorSubject } from 'rxjs'
import primaryTheme from 'themes/themePrimary'

const theme = new BehaviorSubject(primaryTheme)

export default {
    setTheme: (themeValue) => {
        theme.next(themeValue)
    },
    getTheme: () => theme.asObservable(),
}
