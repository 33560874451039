import React from 'react'
import Sidebar from 'ui/Sidebar'
import Wrapper from 'ui/Wrapper'
import { useHistory, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Button from 'ui/controls/Button'
import { BURGERMENU_PAGES } from 'configs/burger-menu'
import Img from 'ui/Img'
import rofus from 'icons/basic-icons/rofus.svg'
import adult from 'icons/basic-icons/18.svg'
import IntercomAdapter from 'common/adapters/IntercomAdapter'

const TextRow = styled.div`
    padding: 10px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 1.5rem;
    text-transform: capitalize;
    color: ${(props) => props.theme.light};
`

const BottomSection = styled(Flex)`
    position: absolute;
    bottom: 15px;
    transform: translateX(-50%);
    left: 50%;
`

const LeftSidebarContainer = (props) => {
    const history = useHistory()

    const close = () => {
        props.onBackgroundClickHandler()
    }

    const changeLink = (value) => {
        history.push(`/info/${value}`)
        close()
    }

    return (
        <Sidebar visible={props.visible} onBackgroundClickHandler={close}>
            <Wrapper pt={5}>
                {Object.keys(BURGERMENU_PAGES).map((value, index) => {
                    return (
                        <TextRow key={index}>
                            <Text onClick={() => changeLink(value)}>
                                {BURGERMENU_PAGES[value]}
                            </Text>
                        </TextRow>
                    )
                })}
                <Flex mt={5}>
                    <Button
                        size={'lg'}
                        onClick={() => IntercomAdapter.show()}
                        style={{ fontSize: '1.5rem' }}
                    >
                        Kontakt os
                    </Button>
                </Flex>
            </Wrapper>
            <BottomSection>
                <Img src={rofus} width={'30px'} height={'30px'} m={1} />
                <Img src={adult} width={'30px'} height={'30px'} m={1} />
            </BottomSection>
        </Sidebar>
    )
}

export default withRouter(LeftSidebarContainer)
