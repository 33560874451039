import React from 'react'
import styled from 'styled-components'
import COLORS from 'themes/primary/palette'

export const IconVolatilityBasicHeight = 4

const IconVolatilityWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 20px;
    height: 12px;
    align-items: flex-end;
    margin-right: 6px;
`

const IconVolatilityLevel = styled.div`
    width: ${IconVolatilityBasicHeight}px;
    height: ${(props) =>
        props.num
            ? IconVolatilityBasicHeight * props.num
            : IconVolatilityBasicHeight}px;
    background: ${(props) => (props.active ? COLORS.primary : COLORS.gray)};
    border-radius: 1px;
`

const IconVolatility = ({ level }) => {
    const levels = ['lowfreq', 'midfreq', 'hifreq']
    return (
        <IconVolatilityWrapper>
            {levels.map((el, index) => (
                <IconVolatilityLevel
                    key={index}
                    num={index + 1}
                    active={levels.indexOf(level) >= index}
                />
            ))}
        </IconVolatilityWrapper>
    )
}

export default IconVolatility
