import React, { useEffect, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import styled, { withTheme } from 'styled-components'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Switcher from 'ui/controls/Switcher'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import { modalService } from 'services/ModalService'
import dots from 'images/icons/basic-icons/aсcount-area/connectedDots.svg'
import lock from 'images/icons/basic-icons/aсcount-area/lock.svg'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import {
    getPinLogin,
    getPinLoginId,
    removePinLogin,
    setPinLogin,
} from 'services/PinLoginService'
import UserAdapter from 'common/adapters/UserAdapter'

const t = window.T

const SecurityWrapper = styled(Box)`
    height: 100%;
    background: ${(props) => props.theme.background};
`

const checkUserPin = () => localStorage.getItem('userId') === getPinLoginId()

const Security = ({ theme }) => {
    const [pinLoginCheck, setPinLoginCheck] = useState(checkUserPin())

    useEffect(() => {
        setPinLoginCheck(checkUserPin())
    })

    const showPinLogin = (state) => {
        if (state) {
            modalService.showModal('PIN_LOGIN', { action: 'set' })
        } else {
            UserAdapter.clearHash().then((res) => {
                if (res.success) {
                    removePinLogin()
                    setPinLoginCheck(false)
                }
            })
        }
    }

    return (
        <>
            <SecurityWrapper>
                <ModalTopBar
                    background={theme.primary}
                    back
                    color={theme.light}
                >
                    <Text size={'md'} color={theme.light} my={2}>
                        {t.securitySettings.title}
                    </Text>
                </ModalTopBar>
                <Box p={3}>
                    {detectMobile() && (
                        <>
                            <ProfileCard
                                borderRadiusTop
                                title={t.securitySettings.pinLoginTitle}
                                iconSrc={dots}
                                background={theme.light}
                                subtitleColor={theme.gray}
                                color={theme.gray}
                            >
                                <Switcher
                                    style={{ width: '65px' }}
                                    checked={pinLoginCheck}
                                    onChange={() => {
                                        showPinLogin(!pinLoginCheck)
                                    }}
                                />
                            </ProfileCard>
                            <ProfileCard
                                borderRadiusBottom
                                background={theme.light}
                            >
                                <Text m={3}>
                                    {t.securitySettings.pinLoginDescription}
                                </Text>
                            </ProfileCard>
                        </>
                    )}

                    <ProfileCard
                        borderRadiusTop
                        title={t.securitySettings.changePassword}
                        iconSrc={lock}
                        background={theme.light}
                        subtitleColor={theme.gray}
                        color={theme.gray}
                        my={3}
                        onClick={() =>
                            modalService.showModal('UPDATE_PASSWORD', {
                                styles: { background: 'transparent' },
                                initialProps: { animation: true },
                            })
                        }
                    />
                </Box>
            </SecurityWrapper>
        </>
    )
}

export default withTheme(withAnimation(Security))
