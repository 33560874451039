import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import rodLogo from 'icons/basic-icons/rodLogo.svg'

import PromoHeader from 'features/Promo/Components/PromoHeader'
import PromoLoader from 'features/Promo/Components/PromoLoader'
import PromoContent from 'features/Promo/Components/PromoContent'
import PromoFooter from 'features/Promo/Components/PromoFooter'
import { modalService } from '../../services/ModalService'
import {
    byPlatform,
    detectIpad,
    detectMobile,
} from '../../common/adapters/DeviceDetectAdapter'
import { getGeoPosition } from '../../services/GeoPositionService'
import { CMS_PATH } from 'configs/rest'

const PromoWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    z-index: 999999;
    background-color: white;
    -webkit-overflow-scrolling: auto;
    font-family: 'Roboto Condensed', sans-serif;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;

    & * {
        font-family: 'Roboto Condensed', sans-serif;
    }
`
const ContentWrapper = styled.div`
    flex: 1 0 auto;
`
const Container = styled.div`
    width: 100%;
    padding-top: 40px;
    margin-top: -40px;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
`

const projectData = {
    loginLink: '/',
    signLink: '/opret-konto',
    projectName: 'Bet25 Casino',
    logo: rodLogo,
    color: 'rgb(236, 64, 60)',
}

const PromoPage = () => {
    const [items, setItems] = useState([])
    const [pending, setPending] = useState(true)
    const [geoData, setGeoData] = useState('')

    const imgHost = 'https://cms.25syv.dk'

    const buildRequestUrl = () => {
        const urlParam = window.location.pathname.split('/').slice(-1)
        const hostName = 'rod'

        return `${CMS_PATH}/rest/landing-page/get-page?url=${urlParam}&hostName=${hostName}_`
        //return "https://cms.bet25.dk/rest/landing-page/get-page?url=velkommen&hostName=rod_";
    }

    const showSignUpModal = () => {
        window.history.pushState('', '', '/opret-konto')
        window.dataLayer.push({
            event: 'red25.clickToSignup',
        })

        modalService.closeModal('PROMO')

        setTimeout(() => {
            modalService.showModal('SIGN_UP', {
                initialProps: {
                    modalTopMargin: '0',
                    ...byPlatform('', { autoScroll: true }),
                },
            })
        }, 0)
    }

    useEffect(() => {
        getGeoPosition().then((res) => {
            setGeoData(res)
        })
    }, [])

    const signUp = (link) => {
        if (link) {
            link.indexOf('opret-konto') > -1
                ? showSignUpModal()
                : (window.location.href = link)
        } else {
            if (!geoData?.allowed) {
                modalService.showModal('GEO_DATA')
                return
            }

            showSignUpModal()
        }
    }

    useEffect(() => {
        fetch(buildRequestUrl())
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setItems(result.data)
                        setInterval(() => setPending(false), 500)
                    } else {
                        window.location = '/404'
                    }
                },
                (error) => {
                    console.error(error)
                }
            )
    }, [])

    return (
        <PromoWrapper>
            {pending ? (
                <PromoLoader />
            ) : (
                <ContentWrapper>
                    <PromoHeader
                        defaultLogo={projectData.logo}
                        logo={items.logo}
                        projectName={projectData.projectName}
                        imgHost={imgHost}
                        signUp={signUp}
                    />
                    <Container
                        style={{
                            backgroundImage:
                                'url(' + imgHost + items.background_image + ')',
                        }}
                    >
                        <PromoContent items={items} signUp={signUp} />
                    </Container>
                    <PromoFooter
                        projectName={projectData.projectName}
                        items={items}
                        imgHost={imgHost}
                        logo={projectData.logo}
                    />
                </ContentWrapper>
            )}
        </PromoWrapper>
    )
}

export default PromoPage
