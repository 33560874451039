import React, { Fragment } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from '@/ui/Wrapper'

const BrandBookModal = () => {
    return (
        <Fragment>
            <ModalTopBar title="Test modal" />
            <Wrapper>
                <h1>Hello brand book modal</h1>
            </Wrapper>
        </Fragment>
    )
}

export default BrandBookModal
