import React, { Fragment, useCallback, useState } from 'react'
import Flex from 'ui/Flex'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import PropTypes from 'prop-types'
import { ValidatingInput } from '@it25syv/25syv-ui'
import { thousandsDots } from 'utils/formatters'

const t = window.T

const ActionButton = styled(Button)``

const LimitForm = ({
    value = '',
    limits = {},
    onChange = (f) => f,
    onSubmit = (f) => f,
    onCancel = (f) => f,
}) => {
    const [errors, setErrors] = useState({
        depositLimit: '',
    })

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13 && value) {
            e.preventDefault()
            onSubmit()
        }
    }

    const checkValue = (error) => {
        if (error) {
            setErrors({ ...errors, [error.name]: error.value })
        }
    }

    const isDataValid = value && !errors.depositLimit

    const formattedBottomLabel = useCallback(
        (maxValue) => (
            <Fragment>
                Max. grænse er <b>{thousandsDots(maxValue)}</b> kr.{' '}
                <span style={{ textTransform: 'lowercase' }}>
                    {limits.label}
                </span>
            </Fragment>
        ),
        [limits]
    )

    return (
        <>
            <Flex mt={3}>
                <ValidatingInput
                    format={String(limits.max).replace(/\d/g, '#')}
                    type="number"
                    name="depositLimit"
                    onCheckValue={checkValue}
                    validate="required, numeric, minimum-kr, maximum-kr"
                    onChangeHandler={(value) => {
                        onChange(value)
                    }}
                    altLabel="kr."
                    allowFirstZero={false}
                    maxNumber={limits.max}
                    minNumber={50}
                    value={value}
                    bottomLabel={formattedBottomLabel(limits.max)}
                    styles={{ InputWrapper: { marginTop: '14px' } }}
                    autoComplete="new-depositLimit"
                    role="presentation"
                    shouldRevalidateOnBlur={(relatedTarget) => {
                        return relatedTarget
                            ? !relatedTarget.hasAttribute(
                                  'data-deposit-max-amount'
                              )
                            : true
                    }}
                    validateOnChange
                    onKeyPress={(e) => value && onKeyPress(e)}
                    placeholder={t.userArea.depositLimit.inputPlaceholder}
                />
            </Flex>
            <Flex>
                <ActionButton
                    my={3}
                    mr={1}
                    size={'lgB'}
                    onClick={onCancel}
                    variant={'light'}
                    style={{ flex: '50%' }}
                >
                    {t.cancel}
                </ActionButton>
                <ActionButton
                    ml={1}
                    my={3}
                    size={'lgB'}
                    onClick={(e) => {
                        e.preventDefault()
                        onSubmit(e)
                    }}
                    variant={'danger'}
                    style={{ flex: '50%' }}
                    disabled={!isDataValid}
                >
                    {t.submit}
                </ActionButton>
            </Flex>
        </>
    )
}

LimitForm.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default LimitForm
