import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useState,
} from 'react'
import Spinner from 'ui/Spinner'
import { BACKGROUNDS } from 'themes'
import Icon from 'ui/Icon'
import starActiveIcon from 'icons/basic-icons/star_with_bg.svg'
import starIcon from 'icons/basic-icons/star_wihout_bg.svg'
import GamesUtilsService from 'services/GamesUtilsService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserService from 'services/UserService'

const t = window.T

const FavoriteButton = forwardRef(
    ({ game, setFavorite = () => {}, iconColor = BACKGROUNDS.white }, ref) => {
        const [isLoading, setIsLoading] = useState(false)
        const [isFavorite, setIsFavorite] = useState(false)
        const [favoriteGames, setFavoriteGames] = useState([])
        const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)

        useImperativeHandle(ref, () => ({
            toggleFavorite: (e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsLoading(true)
                if (isUserLoggedIn) {
                    isFavorite
                        ? GamesUtilsService.gameUnLike(game.game_id).then(
                              (res) => {
                                  GamesUtilsService.fetchFavorite()
                                  setIsLoading(false)
                                  setIsFavorite(false)
                                  setFavorite(false)
                              }
                          )
                        : GamesUtilsService.gameLike(game.game_id).then(
                              (res) => {
                                  GamesUtilsService.fetchFavorite()
                                  setIsLoading(false)
                                  setIsFavorite(true)
                                  setFavorite(true)
                              }
                          )
                } else {
                    setIsLoading(false)
                    NotificationConductor.error(t.loginForFavorites)
                }
            },
        }))

        const checkFavorite = () =>
            favoriteGames.length && game
                ? favoriteGames.map((e) => e.game_id).indexOf(game.game_id) >= 0
                : false

        useEffect(() => {
            const logedInSub = UserService.getUserLoggedStatus().subscribe(
                (res) => {
                    setIsUserLoggedIn(res)
                }
            )

            const subscription = GamesUtilsService.getFavoriteGames().subscribe(
                (res) => {
                    if (res) {
                        setFavoriteGames(res)
                    }
                }
            )

            return () => {
                subscription.unsubscribe()
                logedInSub.unsubscribe()
            }
        }, [])

        useEffect(
            () => {
                setIsFavorite(checkFavorite())
                setFavorite(checkFavorite())
            },
            [favoriteGames]
        )

        return (
            <>
                {isLoading ? (
                    <Spinner
                        width="12px"
                        height="12px"
                        size={'sm'}
                        color={BACKGROUNDS.white}
                    />
                ) : (
                    <Icon
                        src={isFavorite ? starActiveIcon : starIcon}
                        size={'sm'}
                        color={isFavorite ? BACKGROUNDS.accent : iconColor}
                    />
                )}
            </>
        )
    }
)

export default FavoriteButton
