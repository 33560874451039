import styled, { css } from 'styled-components'
import { marginProps, paddingProps } from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const sizes = {
    xs: 0.67,
    sm: 0.83,
    md: 1.125,
    lg: 1.75,
    xl: 2.25,
    xxl: 3,
}

/**
 *
 * @param size {string}
 * @param scale {int} Use this number to increase default dimensions
 * @return {string}
 */
const getSize = ({ size, scale = 1 }) => {
    let dim = scale * sizes[size]

    return `
        width: ${dim}rem;
        height: ${dim}rem;
    `
}

const Icon = styled.svg`
    ${marginProps};
    ${paddingProps};
    vertical-align: middle;
    ${(props) => getSize(props)};
    mask-image: url(${(props) => (props.src ? props.src : '')});
    mask-size: ${(props) => (props.maskSize ? props.maskSize : 'contain')};
    mask-repeat: no-repeat;
    mask-position: center center;
    ${(props) =>
        !props.size &&
        css`
            width: ${props.width};
            height: ${props.height};
        `};
    background: ${(props) => (props.color ? props.color : 'black')};
    height: ${(props) => props.height && props.height};
    width: ${(props) => props.width && props.width};
`

Icon.propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
}

export default Icon
