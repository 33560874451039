import { BehaviorSubject } from 'rxjs'

const navStateFromStore = () => {
    const state = JSON.parse(localStorage.getItem('sideNavState'))
    return state === null ? false : state
}
const isSideNavOpen = new BehaviorSubject(navStateFromStore())

export default {
    initialNavState: () => navStateFromStore(),
    setNavOpen: (open) => {
        localStorage.setItem('sideNavState', open)
        isSideNavOpen.next(navStateFromStore())
    },
    getNavOpen: () => isSideNavOpen.asObservable(),
}
