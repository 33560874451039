const COLORS = {
    dark: '#111111',
    darkGray: '#646464',

    lightGray: '#f1f1f1',
    gray: '#8E8E8E',
    light: '#FFFFFF',
    primary: '#DB1C1B',
    background: '#f9f9f9',
    primaryHover: '#C61918',
    primaryAction: '#A51514',
    primaryDisable: '#F7C7C6',
    secondary: '#fde9e9',
    accent: '#FBD600',
    accentHover: '#E1C200',
    accentAction: '#BCA300',
    accentDisabled: '#FFF5BF',
    categoryOffers: '#FFA300',
    orange: '#FB9800',
    jade: '#51a351',
    azure: '#007AFE',
    categoryJackpot: '#000000',
    categoryNewGames: '#4ADD64',
    categoryTournaments: '#007AFE',
    derbyMainColor: '#34B474',
    betMainColor: '#1C3E60',
}

export default COLORS
