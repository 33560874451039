import React from 'react'
import { HotLabelWrapper } from 'features/Jackpots/styled'
import Text from 'ui/Text'
import fireIcon from 'images/icons/fire.svg'

const HotLabel = () => {
    return (
        <HotLabelWrapper>
            <img src={fireIcon} alt={fireIcon} />
            <Text color={'#F3441B'} size={'xs'} condensed>
                Brandvarm
            </Text>
        </HotLabelWrapper>
    )
}

export default HotLabel
