import React from 'react'
import styled, { css } from 'styled-components'
import { marginProps } from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const SIZE_PARAMETERS = {
    xs: {
        mob: 0.67,
        web: 0.67,
    },
    sm: {
        mob: 0.83,
        web: 0.83,
    },
    md: {
        mob: 1,
        web: 1,
    },
    lg: {
        mob: 1.125,
        web: 1.125,
    },
    xl: {
        mob: 1.5,
        web: 1.5,
    },
}

const getPaddingProportion = (coefficient) => {
    return `padding: ${coefficient}rem ${coefficient * 3}rem`
}

const getPaddingProportionWeb = (coefficient) => {
    return `padding: ${coefficient}rem ${coefficient * 2}rem`
}

export const SIZES = {
    xs: css`
        font-size: ${SIZE_PARAMETERS.xs.mob}rem;
        ${getPaddingProportion(SIZE_PARAMETERS.xs.mob / 2)};
    `,
    sm: css`
        font-size: ${SIZE_PARAMETERS.sm.mob}rem;
        ${getPaddingProportion(SIZE_PARAMETERS.sm.mob / 2)};
    `,
    md: css`
        font-size: ${SIZE_PARAMETERS.md.mob}rem;
        ${getPaddingProportion(SIZE_PARAMETERS.md.mob / 2)};
    `,
    lg: css`
        font-size: ${SIZE_PARAMETERS.lg.mob}rem;
        ${getPaddingProportion(SIZE_PARAMETERS.lg.mob / 2)};
    `,

    xsB: css`
        font-size: ${SIZE_PARAMETERS.xs.mob}rem;
        ${getPaddingProportionWeb(SIZE_PARAMETERS.xs.mob)};
    `,
    smB: css`
        font-size: ${SIZE_PARAMETERS.sm.mob}rem;
        ${getPaddingProportionWeb(SIZE_PARAMETERS.sm.mob)};
    `,
    mdB: css`
        font-size: ${SIZE_PARAMETERS.md.mob}rem;
        ${getPaddingProportionWeb(SIZE_PARAMETERS.md.mob)};
    `,
    lgB: css`
        font-size: ${SIZE_PARAMETERS.lg.mob}rem;
        ${getPaddingProportionWeb(SIZE_PARAMETERS.lg.mob)};
    `,
    xlB: css`
        font-size: ${SIZE_PARAMETERS.lg.mob}rem;
        ${getPaddingProportionWeb(SIZE_PARAMETERS.xl.mob)};
    `,
}

const BorderRadius = 3

const StyledButton = styled.button`
    line-height: 1;
    border: 1px solid transparent;
    border-radius: ${BorderRadius}px;
    display: block;
    transition: all 0.3s ease-out;
    text-transform: none;
    ${SIZES.lg};
    ${marginProps};
    cursor: pointer;
    background: ${(props) =>
        props.background ? props.background : props.theme.accent};
    color: ${(props) => (props.color ? props.color : '#333333')};
    &:hover {
        background: ${(props) => props.hover || props.theme.accentHover}};
    }
    ${(props) =>
        props.active &&
        css`
            background: ${(props) =>
                props.activeColor ? props.activeColor : props.theme.accent};
        `};
    ${(props) =>
        props.flat &&
        css`
            border-radius: unset;
        `};
    ${(props) =>
        props.flatRight &&
        css`
            border-radius: 3px 0 0 3px;
        `};
    ${(props) =>
        props.flatLeft &&
        css`
            border-radius: 0 3px 3px 0;
        `};
    ${(props) =>
        props.bold &&
        css`
            font-weight: bold;
        `};
    ${(props) =>
        (props.disabled || props.disabledClass) &&
        css`
            opacity: 0.4;
            cursor: not-allowed;
        `};
    ${(props) =>
        props.shadowed &&
        css`
            box-shadow: 0 3px 9px #33333329;
        `};

    ${(props) =>
        props.block
            ? css`
                  display: block;
                  width: 100%;
              `
            : css`
                  display: inline-block;
              `};
    ${(props) =>
        props.rounded &&
        css`
            border-radius: 3rem;
        `};
    ${(props) =>
        props.uppercase &&
        css`
            text-transform: uppercase;
        `};
    ${(props) =>
        props.lowerCase &&
        css`
            text-transform: lowercase;
        `};
    ${(props) =>
        props.capitalize &&
        css`
            text-transform: capitalize;
        `};

    ${(props) =>
        props.variant === 'danger' &&
        css`
            background: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.light};
            &:hover {
                background: ${(props) => props.theme.primaryHover}};
            }
        `};
    ${(props) =>
        props.variant === 'light' &&
        css`
            box-shadow: 0 0 5px #33333329;
            background: ${(props) => props.theme.light};
            color: ${(props) => props.theme.dark};
            &:hover {
                box-shadow: 0 3px 9px #33333329;
                background: ${(props) => props.theme.light}};
            }
        `};
    ${(props) =>
        props.variant === 'dark' &&
        css`
            box-shadow: 0 0 5px #33333329;
            background: ${(props) => props.theme.dark};
            color: ${(props) => props.theme.light};
            &:hover {
                box-shadow: 0 3px 9px #33333329;
                background: ${(props) => props.theme.dark}};
            }
        `};
    ${(props) =>
        props.variant === 'lightHollow' &&
        css`
            background: transparent;
            color: ${(props) => props.theme.dark};
            border: 1px solid #33333380;
            transition: all 0.1s ease-out;

            &:hover {
                box-shadow: 0 3px 9px #33333329;
                background: transparent;
                border: 1px solid black;
                color: ${(props) => props.theme.darkGray};
            }
            &:disabled {
                background: black;
                color: ${(props) => props.theme.light};
            }
        `};

    ${(props) =>
        props.variant === 'custom' &&
        css`
            background: ${props.background ? props.background : 'none'};

            &:hover {
                background: ${props.background ? props.background : 'none'};

                ${(props) =>
                    props.active &&
                    css`
                        background: ${props.activeBackground
                            ? props.activeBackground
                            : props.theme.primary};
                        color: ${props.color ? props.color : props.theme.light};
                    `};
            }

            ${(props) =>
                props.active &&
                css`
                    background: ${props.activeBackground
                        ? props.activeBackground
                        : props.theme.primary};
                    color: ${props.color ? props.color : props.theme.light};
                `};
        `};

    ${(props) => (props.size === 'xs' ? SIZES.xs : '')};
    ${(props) => (props.size === 'sm' ? SIZES.sm : '')};
    ${(props) => (props.size === 'md' ? SIZES.md : '')};
    ${(props) => (props.size === 'lg' ? SIZES.lg : '')};

    ${(props) => (props.size === 'xsB' ? SIZES.xsB : '')};
    ${(props) => (props.size === 'smB' ? SIZES.smB : '')};
    ${(props) => (props.size === 'mdB' ? SIZES.mdB : '')};
    ${(props) => (props.size === 'lgB' ? SIZES.lgB : '')};
    ${(props) => (props.size === 'xlB' ? SIZES.xlB : '')};
`
const Button = (props) => <StyledButton type={'button'} {...props} />

Button.propTypes = {
    size: PropTypes.oneOf([
        'xs',
        'sm',
        'md',
        'lg',
        'xsB',
        'smB',
        'mdB',
        'lgB',
        'xlB',
    ]),
    variant: PropTypes.oneOf([
        'danger',
        'light',
        'dark',
        'link',
        'custom',
        'lightHollow',
    ]),
    rounded: PropTypes.bool,
    uppercase: PropTypes.bool,
    lowerCase: PropTypes.bool,
    capitalize: PropTypes.bool,
    block: PropTypes.bool,
    bold: PropTypes.bool,
    shadowed: PropTypes.bool,
    disabled: PropTypes.bool,
    flat: PropTypes.bool,
    flatLeft: PropTypes.bool,
    flatRight: PropTypes.bool,
    background: PropTypes.string,
    activeBackground: PropTypes.string,
    color: PropTypes.string,
}

export default Button
