import React, { useEffect, useState } from 'react'
import {
    FooterWrapper,
    FooterTop,
    FooterCentered,
    FooterTopContent,
    FooterTopMenuWrapper,
    FooterTopMenu,
    FooterTopMenuText,
    FooterTopMenuHeader,
    FooterTopMenuList,
    FooterTopMenuItem,
    FooterPaymentWrapper,
    FooterPaymentCentered,
    FooterPaymentItem,
    FooterTextBlock,
    FooterText,
    FooterDGA,
    FooterDGABlock,
    FooterDGATitle,
    FooterBottom,
    FooterBottomSupport,
    FooterBottomSupportItem,
    FooterBottomSupportBtn,
    FooterBottomCopyright,
    FooterBottomCopyrightItem,
    FooterBottomAgeRestrictItem,
    FooterBottomAgeRestrict,
    FooterClockDesktop,
    FooterClockMobile,
} from './styled'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import dankortCredit from 'images/footer/credits/dankort.svg'
import dinersclubCredit from 'images/footer/credits/dinersclub.svg'
import maestroCredit from 'images/footer/credits/maestro.svg'
import mastercardCredit from 'images/footer/credits/mastercard.svg'
import visaCredit from 'images/footer/credits/visa.svg'
import visaECredit from 'images/footer/credits/visa-e.svg'

import logo from 'images/icons/basic-icons/rodLogo.svg'
import stopSpilled from 'images/footer/stopspillet.svg'
import centerLudomani from 'images/footer/center_ludomani.png'
import rofus from 'images/footer/rofus.png'
import footerSpl from 'images/icons/spillemyndigheden.png'

import { DigitalClock, Img, WithClock } from '@it25syv/25syv-ui'

const Clock = WithClock(DigitalClock)

const getMenuItemsConfig = (handlers = {}) => [
    // Information
    {
        section: 'Information',
        showOnMobile: true,
        items: [
            {
                title: 'Vilkår og betingelser',
                path:
                    'https://intercom.help/25syv/da/articles/3493598-generelle-regler-og-vilkar',
                blank: true,
            },
            {
                title: 'Cookiepolitik',
                onClick: handlers.cookiesHandler,
            },
            {
                title: 'Ansvarligt spil',
                path:
                    'https://intercom.help/25syv/da/articles/3493600-ansvarligt-spil-25syv',
                blank: true,
            },
            {
                title: 'Persondata politik',
                path:
                    'https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s ', //add link
                blank: true,
            },
            {
                title: 'Kalaallisut',
                path:
                    'https://intercom.help/25syv/da/articles/4425281-kalaallisut',
                blank: true,
            },
        ],
    },
    {
        section: 'Vigtige punkter',
        showOnMobile: false,
        items: [
            {
                title: 'Casino',
                path: '/casino/automater/alle',
            },
            {
                title: 'Live Casino',
                path: '/casino/live-casino/alle',
            },
            {
                title: '25-nyt',
                path: '/casino/25nyt',
            },
        ],
    },
    {
        section: 'Quick links',
        showOnMobile: false,
        items: [
            {
                title: 'Bet25 Heste',
                path: '/heste/',
            },
            {
                title: 'Bet25 Sport',
                path: '/sport/',
            },
        ],
    },
    {
        section: 'Kundeservice',
        showOnMobile: false,
        items: [
            {
                title: 'Udbetaling',
                path:
                    'https://intercom.help/25syv/da/collections/559324-indbetaling-udbetaling',
                blank: true,
            },
            {
                title: 'Konto verificering',
                path:
                    'https://intercom.help/25syv/da/collections/559314-min-25syv-konto-rod25-bet25-derby25',
                blank: true,
            },
            {
                title: 'Freespins',
                path:
                    'https://intercom.help/25syv/da/articles/2298297-gennemspilning-af-bonusser-bonusspins-gevinster',
                blank: true,
            },
            {
                title: 'Bonusomsætning',
                path:
                    'https://intercom.help/25syv/da/collections/2020208-kampagner-bonusser',
                blank: true,
            },
        ],
    },
]

const uniqFooterContent = [
    {
        url: '/casino/live-casino/alle',
        text: () => (
            <FooterText>
                <p>
                    Spil live casino hos Bet25, som er Danmarks hyggeligste
                    online live casino. Få masser af hyggelige, spændende og
                    sjove stunder og se, om ikke der er gevinst – store som små.
                </p>

                <p>
                    Hos Bet25 Casino kan du naturligvis kaste dig over de
                    klassiske casinospil som{' '}
                    <a href="/casino/live-casino/blackJack">Blackjack</a> eller
                    give den et skud ved{' '}
                    <a href="/casino/live-casino/roulette">Rouletten</a>.
                    Derudover har vi en perlerække af andre populære casino
                    spil, som du kan give dig i kast med direkte fra sofaen. Vi
                    er jo ikke Danmarks hyggeligste casino for ingenting.
                </p>
                <p>
                    Ikke nok med, at du slipper for at stryge skjorten og bøvle
                    med slipset, får du også den klassiske casino-oplevelse, som
                    du kender den fra Las Vegas' grønne borde, direkte ind i
                    stuen. Her møder du vores dygtige live dealere i et miljø,
                    hvor du lige så godt kunne være der selv.
                </p>
                <p>
                    Hos Bet25 Casino kan selvfølgelig spille live blackjack, som
                    er en af de mest populære casinospil i hele verden. Smøg
                    ærmerne op og se, om du kan skrabe en hånd sammen, som er
                    tættere på 21 end dealernes hånd, men uden at overgå 21. Når
                    vi siger dealeren, mener vi dealeren. Hos Bet25 Casino
                    spiller du ikke mod maskiner, men imod rigtige live dealere.
                    Dét gør det også en hel del sjovere, når du rammer 21, eller
                    rouletten giver gevinst.
                </p>
                <p>
                    Vi har gjort det nemt og bekvemt for dig at få en plads
                    omkring bordet, hvis du ikke lige har computeren i nærheden.
                    Du behøver nemlig ikke at gøre andet end at hive telefonen
                    frem, logge ind og kaste dig over dit favoritspil.
                </p>
                <p>
                    Hvis du aldrig har spillet live casino før, anbefaler vi hos
                    Bet25 Casino, at du sætter dig ind i reglerne, samt hvad
                    spillene går ud på - det gør det hele meget sjovere. Er du
                    debutant til live casino? Så er rouletten et rigtig godt
                    sted at starte. Spillet er nemt og overskueligt at forstå,
                    da det grundlæggende handler om at gætte den farve eller det
                    nummer, som kuglen lander på.
                </p>
            </FooterText>
        ),
    },
    {
        url: '/casino/automater/jackpots',
        text: () => (
            <FooterText>
                <p>
                    Hos Bet25 Casino har vi tonsvis af fede og sjove spil og
                    specials, hvor smilet kan strække sig fra øre til øre, hvis
                    alt går, som man håber på. Vi har tilmed også de spil, hvor
                    smilet bliver lidt større og har lidt sværere ved at aftage.
                    Hos Bet25 Casino har vi nemlig spil, hvor du kan hive den
                    helt store jackpot. Du har nok, ligesom alle andre, drømt om
                    at vinde den helt store jackpot, hvor man får muligheden for
                    gøre drømme til virkelighed.
                </p>
                <p>
                    Hos Danmarks hyggeligste casino har vi et bredt udvalg af
                    spil, hvor du kan spille med om den helt store jackpot. Om
                    du prøver lykken ved Golden Goal, Speed Cash eller Mega
                    Joker er op til dig. Hos os har vi spil til enhver smag.
                </p>
                <p>
                    Hos Bet25 Casino har vi gjort det nemt og bekvemt for dig at
                    få et lod i jackpotpuljen, hvis du ikke lige har computeren
                    i nærheden. Du behøver nemlig ikke at gøre andet end at hive
                    telefonen frem, logge ind og kaste dig over dit favoritspil
                    - og måske krydse fingrene.
                </p>
            </FooterText>
        ),
    },
]

const Footer = (props) => {
    const [footerContent, setFooterContent] = useState('')
    const cookiesHandler = window.CookieConsent
        ? window.CookieConsent.renew
        : null

    const menuConfig = getMenuItemsConfig({ cookiesHandler })

    const selectFooterText = () => {
        const footerText = uniqFooterContent.find(
            (item) => item.url === window.location.pathname
        )
        setFooterContent(footerText?.text)
    }
    useEffect(() => {
        selectFooterText()
    }, [])

    useEffect(
        () => {
            selectFooterText()
        },
        [window.location.pathname]
    )

    return (
        <FooterWrapper
            isOpen={props.isOpen}
            sidebarWidth={props.sidebarWidth}
            sidebarClosedWidth={props.sidebarClosedWidth}
        >
            <FooterTop>
                <FooterTopContent>
                    <Img
                        style={{
                            width: '120px',
                        }}
                        src={logo}
                    />
                    <FooterTopMenuWrapper>
                        <FooterTopMenu
                            width={'265px'}
                            className={'show-on-mobile'}
                            key={5}
                        >
                            <FooterTopMenuHeader>
                                Udbydes af 25syv
                            </FooterTopMenuHeader>
                            <FooterTopMenuText>
                                25syv A/S, Mariane Thomsens Gade, 8000 Aarhus C.
                            </FooterTopMenuText>
                        </FooterTopMenu>
                        {menuConfig.map((section, index) => (
                            <FooterTopMenu
                                className={
                                    section.showOnMobile && 'show-on-mobile'
                                }
                                key={index}
                            >
                                <FooterTopMenuHeader>
                                    {menuConfig[index].section}
                                </FooterTopMenuHeader>
                                <FooterTopMenuList key={index}>
                                    {menuConfig[index].items.map(
                                        (item, ind) => (
                                            <li key={ind}>
                                                <FooterTopMenuItem
                                                    href={item.path || null}
                                                    target={
                                                        item.blank
                                                            ? '_blank'
                                                            : ''
                                                    }
                                                    onClick={
                                                        item.onClick || null
                                                    }
                                                    {...item.props}
                                                >
                                                    {item.title}
                                                </FooterTopMenuItem>
                                            </li>
                                        )
                                    )}
                                </FooterTopMenuList>
                            </FooterTopMenu>
                        ))}
                    </FooterTopMenuWrapper>
                </FooterTopContent>
            </FooterTop>
            <FooterPaymentWrapper>
                <FooterPaymentCentered>
                    <FooterPaymentItem>
                        <img src={dankortCredit} alt="Dankort" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaCredit} alt="Visa" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaECredit} alt="Visa Electron" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={mastercardCredit} alt="Master Card" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={maestroCredit} alt="Maestro" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={dinersclubCredit} alt="Dinersclub" />
                    </FooterPaymentItem>
                </FooterPaymentCentered>
            </FooterPaymentWrapper>
            <FooterTextBlock>
                {footerContent || (
                    <FooterText>
                        <h3>Danmarks hyggeligste online casino</h3>
                        <p>
                            Hos Bet25 Casino finder du Danmarks hyggeligste
                            online casino med et stort udvalg af spillemaskiner,
                            slots og bordspil. Bet25 Casino er din hjemmebane,
                            når det kommer til online hygge og stedet hvor du
                            kan trylle casino-oplevelsen og spænding om til
                            gevinster - store som små. Hvad enten du er ude
                            efter de{' '}
                            <a href="/casino/automater/populære">
                                mest populære spilleautomater
                            </a>{' '}
                            eller du er på udkig efter de{' '}
                            <a href="/casino/automater/nyheder">nyeste spil</a>,
                            så finder du det ypperste casino-underholdning hos
                            Bet25 Casino. Du kan selvfølgelig også sigte efter
                            den helt store gevinst i de vilde{' '}
                            <a href="/casino/automater/jackpots">
                                jackpot spil
                            </a>
                            , som utvivlsomt også kan bidrage til
                            underholdningen.
                        </p>
                        <h3>
                            De klassiske spilformer, som du kender og elsker -
                            og meget mere
                        </h3>

                        <p>
                            Der er noget for enhver smag når du vælger mellem
                            mere end 1700 forskellige spilleautomater, som{' '}
                            <a
                                href={
                                    detectMobile()
                                        ? '/casino/game/godsoffortune_mobile_html'
                                        : '/casino/game/godsoffortune_not_mobile'
                                }
                            >
                                Divine Fortune
                            </a>
                            ,{' '}
                            <a
                                href={
                                    detectMobile()
                                        ? '/casino/game/eldorado_mobile_html'
                                        : '/casino/game/eldorado_not_mobile'
                                }
                            >
                                Gonzo’s quest
                            </a>{' '}
                            eller all-time favoritten{' '}
                            <a
                                href={
                                    detectMobile()
                                        ? '/casino/game/starburst_mobile_html'
                                        : '/casino/game/starburst_not_mobile'
                                }
                            >
                                Starburst
                            </a>
                            . I samarbejde med de største og mest respekterede
                            spiludbydere i branchen giver vi adgang til spil fra
                            Play’n GO, Yggddrasil, NetEnt og mange flere. Bet25s
                            spilleautomater kommer desuden i en stor variation
                            af temaer. Her kan du udforske alt lige fra det
                            vilde vesten og vikingetiden til filmklassikere og
                            eventyr. Der er med sikkerhed noget for alle. Er du
                            til live action i top professionelle omgivelser, kan
                            du kaste dig over vores førsteklasses{' '}
                            <a href="/casino/live-casino/alle">live casino</a>,
                            hvor ægte live croupiers servicerer dig på dine
                            foretrukne bordspil. Spil blandt andet med danske
                            dealere på live Roulette bord. Eller prøv nogle af
                            de andre bordspils favoritter som fx Blackjack,
                            Texas Hold’em og Baccarat.
                        </p>
                        <h3>25 gange sjovere</h3>

                        <p>
                            Hos Bet25 Casino er alt sjovere. Hos os er der ingen
                            kedelige øjeblikke, kun ren underholdning og hygge.
                            Vores spil er designet til at tilfredsstille selv de
                            mest krævende spilleglade sjæle og vores store
                            udvalg af spil sikrer, at der altid er noget for
                            enhver smag. Uanset om du er til de klassiske bord-
                            og kortspil eller kaffe hygge i sofaen, er Bet25
                            Casino til for at gøre din spiloplevelse fantastisk.
                            Du kan altid se mere om aktuelle tilbud til nye og
                            eksisterende kunder under{' '}
                            <a href="/casino/25nyt">‘25-nyt’</a>. Vores mest
                            trofaste kunder tilbyder vi også, at blive medlem af{' '}
                            <a href="/casino/25nyt/25loungen">25-Loungen</a>,
                            hvor eksklusive tilbud vil blive delt med
                            medlemmerne så snart der er nyt. Som spiller hos
                            Bet25 bliver du dermed inviteret ind i en verden af
                            online spil, der rækker udover spillemaskiner og
                            live casino. Så hvis du er på udkig efter en sjov og
                            hyggelig spiloplevelse, er vores online casino det
                            perfekte sted at være.Opret en konto i dag og oplev
                            den ultimative underholdning i vores online casino.
                        </p>
                    </FooterText>
                )}
            </FooterTextBlock>
            <FooterDGA>
                <FooterDGABlock
                    href={'https://www.spillemyndigheden.dk/'}
                    target={'_blank'}
                >
                    <img
                        src={footerSpl}
                        alt="Tilladelse fra og under tilsyn af Spillemyndigheden."
                    />

                    <FooterDGATitle>
                        Tilladelse fra og under tilsyn af Spillemyndigheden.
                    </FooterDGATitle>
                </FooterDGABlock>
            </FooterDGA>

            <FooterBottom>
                <FooterCentered>
                    <FooterBottomSupport>
                        <FooterBottomSupportItem>
                            <a
                                href="https://www.stopspillet.dk/"
                                target={'_blank'}
                            >
                                <img src={stopSpilled} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="tel:70-22-28-25"
                                className="footerBottomSupportBtn"
                            >
                                Ring til 70 22 28 25
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://ludomani.dk/" target={'_blank'}>
                                <img src={centerLudomani} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="https://ludomani.dk/"
                                target={'_blank'}
                            >
                                Test dig selv
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://www.rofus.nu/" target={'_blank'}>
                                <img src={rofus} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="https://www.rofus.nu/"
                                target={'_blank'}
                            >
                                Opret registrering
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                    </FooterBottomSupport>
                    <FooterBottomCopyright>
                        <FooterClockMobile>{<Clock />}</FooterClockMobile>
                        <FooterBottomCopyrightItem>
                            © 2020 25syv A/S. <br />
                            Alle rettigheder forbeholdes.
                        </FooterBottomCopyrightItem>
                        <FooterClockDesktop>{<Clock />}</FooterClockDesktop>
                        <FooterBottomAgeRestrictItem>
                            <FooterBottomAgeRestrict>
                                18+
                            </FooterBottomAgeRestrict>
                            Spil kan være vanedannende, spil altid med omtanke.
                        </FooterBottomAgeRestrictItem>
                    </FooterBottomCopyright>
                </FooterCentered>
            </FooterBottom>
        </FooterWrapper>
    )
}
export default Footer
