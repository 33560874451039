export const setPinLogin = (pin) => {
    localStorage.setItem('pinHash', pin)
}

export const getPinLogin = () => {
    return localStorage.getItem('pinHash')?.split('|')[0]
}

export const getPinLoginId = () => {
    return localStorage.getItem('pinHash')?.split('|')[1]
}

export const removePinLogin = () => {
    localStorage.removeItem('pinHash')
}
