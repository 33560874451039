import React, { useEffect, useRef, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import { UserHistory as UserHistoryComponent } from '@it25syv/25syv-ui'
import styled from 'styled-components'
import UserService from '../../services/UserService'
import { modalService as ModalService } from '../../services/ModalService'

const t = window.T

const ModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const UserHistory = ({ theme }) => {
    const [showMore, setShowMore] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTabData, setActiveTabData] = useState({})
    const [configs, setConfigs] = useState({
        dateFrom: null,
        dateTo: null,
        rowCount: 0,
    })
    const [date, setDate] = useState({
        dateFrom: configs.dateFrom,
        dateTo: configs.dateTo,
    })

    useEffect(
        () => {
            if (date.dateTo !== null) {
                let subscription
                const groupKey = activeTabData.groupKey
                setLoading(true)

                UserService.fetchTransactions(
                    groupKey,
                    date.dateFrom,
                    date.dateTo,
                    0,
                    configs.rowCount
                )

                subscription = UserService.subjectsMap[groupKey].subscribe(
                    (data) => {
                        setTransactions(data.transactions)
                        setShowMore(data.count > data.transactions.length)
                        setLoading(false)
                    }
                )

                return () => {
                    if (subscription) {
                        subscription.unsubscribe()
                    }
                }
            }
        },
        [date, activeTabData]
    )

    useEffect(() => {
        const subscription = UserService.getTransactionDate.subscribe(
            ({ dateFrom, dateTo }) => {
                setDate({ dateFrom, dateTo })
            }
        )

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (configs.dateFrom && configs.dateTo) {
                UserService.setTransactionDate({
                    dateFrom: configs.dateFrom,
                    dateTo: configs.dateTo,
                })
            }
        },
        [configs]
    )

    const setDefaultDate = (date) => {
        setDate(date)
        UserService.setTransactionDate(date)
    }

    const loadMoreHandler = () => {
        const groupKey = activeTabData.groupKey
        const offset = Math.floor(transactions.length / configs.rowCount)
        setLoading(true)
        UserService.fetchTransactions(
            groupKey,
            date.dateFrom,
            date.dateTo,
            offset,
            configs.rowCount
        )
    }

    return (
        <ModalWrapper background={theme.light}>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
                style={{ width: '100%' }}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    Kontohistorik
                </Text>
            </ModalTopBar>
            <UserHistoryComponent
                transactions={transactions}
                loading={loading}
                showMore={showMore}
                loadMoreHandler={() => loadMoreHandler()}
                setTab={setActiveTabData}
                setInitialConfig={setConfigs}
                currentDate={date}
                showDetails={(data) => {
                    ModalService.showModal('TRANSACTION_INFO', data)
                }}
                openChooseDate={() =>
                    ModalService.showModal('TRANSACTION_CHOOSE_DATE')
                }
                resetDateHandler={(defaultDate) => setDefaultDate(defaultDate)}
            />
        </ModalWrapper>
    )
}

export default UserHistory
