import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { start } from 'services/GameLaunchService'
import gamesService from 'services/GamesService'
import { BACKGROUNDS, PALETTE } from 'themes'
import { BREAKPOINTS } from '@/themes/primary/breakpoints'
import GameSearch from 'features/GameArea/GameSearch'
import Loader from 'ui/Loader'
import Flex from 'ui/Flex'
import Iframe from 'ui/Iframe'
import Icon from 'ui/Icon'
import searchIcon from 'icons/basic-icons/search.svg'
import closeIcon from 'icons/basic-icons/close-light.svg'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { useHistory } from 'react-router-dom'

/**
 * Run games only for embeded games. It runs now only for PlayAndGo games. Use it carefully to avoid double launch with iframe
 * @param source {string} - launchUrl
 * @param callback {function}
 */
function getScript(source, callback) {
    var script = document.createElement('script')
    var prior = document.getElementsByTagName('script')[0]
    script.async = 1

    script.onload = script.onreadystatechange = function(_, isAbort) {
        if (
            isAbort ||
            !script.readyState ||
            /loaded|complete/.test(script.readyState)
        ) {
            script.onload = script.onreadystatechange = null
            script = undefined

            if (!isAbort && callback) setTimeout(callback, 0)
        }
    }

    script.src = source
    prior.parentNode.insertBefore(script, prior)
}

const LoaderHolder = styled(Flex)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
`
const getGameHeight = ({ fullScreen, multiScreenMode }) => {
    let height = '100%'
    let index = 0.5625 // 16 : 9 = 0.5625

    if (fullScreen && multiScreenMode) {
        height = `calc(45vw * ${index} - 50px)`
    } else if (fullScreen && !multiScreenMode) {
        height = `100%`
    } else if (!fullScreen && multiScreenMode) {
        height = `calc(40.5vw * ${index} - 55px)`
    }

    return height
}
const MultipleGamesRatio = styled.div`
    position: relative;
    width: ${({ multiScreenMode }) =>
        multiScreenMode ? 'calc(49% - 50px)' : '100%'};
    height: ${(props) => getGameHeight(props)};
    ${({ multiScreenMode }) =>
        multiScreenMode &&
        css`
            max-height: 40vh;
            max-width: calc(40vh * 1.777);
        `};
    background: #000;
    display: flex;
    flex-direction: row;
    margin-right: ${({ multiScreenMode }) => (multiScreenMode ? '50px' : '0')};
`
const commonGameHolder = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const StyledIframe = styled(Iframe)`
    ${commonGameHolder};
`

const Embed = styled.div`
    background: transparent;
    ${commonGameHolder};
`
export const Controls = styled.div`
    position: absolute;
    top: 120px;
    right: ${({ fullScreen }) => (fullScreen ? '5px' : '15px')};
    left: auto;
    display: none;
    z-index: 10;

    @media (min-width: ${BREAKPOINTS.md + 'px'}) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
`
const ControlFrame = styled.div`
    margin: ${({ groupMargin }) =>
        groupMargin ? '1rem .25rem .25rem' : '.25rem'};
    background-color: ${(props) => (props.bg ? props.bg : BACKGROUNDS.grey)};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    position: relative;
    opacity: ${({ opacity }) => (opacity ? opacity : 1)};
    overflow: hidden;
    transition: all 0.3s ease;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.3;
            pointer-events: none;
        `};
`
const MultiGamesControls = styled(Controls)`
    height: 100%;
    top: 0;
    right: -50px;
    width: 50px;
    background-color: ${BACKGROUNDS.black};
`
const MultiGamesFrame = styled(ControlFrame)`
    background-color: initial;
    margin: 0;

    &:hover {
        background-color: ${BACKGROUNDS.greyTransparent};
    }
`
export const ControlIcon = styled(Icon)`
    position: absolute;
    right: 16px;
    top: 16px;
`
export const MultiGamesSearchFrame = styled(ControlFrame)`
    background-color: initial;
    margin: 0 0 10px;

    &:hover {
        background-color: ${BACKGROUNDS.greyTransparent};
    }

    &.active {
        background-color: ${BACKGROUNDS.white};
        ${ControlIcon} {
            background: ${BACKGROUNDS.black};
        }
    }
`

const MultipleGames = ({
    index,
    game,
    fullScreen,
    sizeChange,
    multiScreenMode,
    gamesForMultiScreen,
    removeGameFromMultiple,
    checkLogin,
}) => {
    const [launchUrl, setLaunchUrl] = useState('')
    const [load, setLoad] = useState(false)
    const [isGameActive, setIsGameActive] = useState(false)
    const history = useHistory()
    const launchGame = (launchUrl) => {
        if (launchUrl) {
            setLaunchUrl(launchUrl)
        }
    }

    const enableSearch = () => {
        if (isGameActive) {
            setIsGameActive(false)
            gamesService.updateMultipleGames(index, {})
        }
    }

    useEffect(
        () => {
            if (game.game_id) {
                setIsGameActive(true)
                gamesService.setGameLaunchState({
                    isLaunched: true,
                    isInit: true,
                })
                start(
                    game,
                    (link) => {
                        if (detectMobile() && !detectIpad()) {
                            history.push('/')
                            window.location.href = link
                        } else {
                            launchGame(link)
                        }
                    },
                    checkLogin
                )
            }
        },
        [game]
    )

    useEffect(
        () => {
            if (launchUrl && launchUrl.includes('playngonetwork')) {
                getScript(launchUrl, (res) => {
                    setLoad(false)
                })
            } else if (Object.keys(game).length) {
                setLoad(true)
            }
        },
        [launchUrl]
    )

    return (
        <Fragment>
            <MultipleGamesRatio
                multiScreenMode={multiScreenMode}
                fullScreen={fullScreen}
            >
                {load && (
                    <LoaderHolder my={3}>
                        <Loader color={BACKGROUNDS.primary} />
                    </LoaderHolder>
                )}
                {Object.keys(game).length > 0 ? (
                    launchUrl && !launchUrl.includes('playngonetwork') ? (
                        <StyledIframe
                            sizeChange={sizeChange}
                            src={launchUrl}
                            onLoad={() => {
                                setLoad(false)
                            }}
                            name="gameIframe"
                            id={`gameEmbed_${index}`}
                        />
                    ) : (
                        <Embed id={game.game_id} sizeChange={sizeChange} />
                    )
                ) : (
                    <GameSearch
                        index={index}
                        gamesForMultiScreen={gamesForMultiScreen}
                    />
                )}
                {multiScreenMode && (
                    <MultiGamesControls fullScreen={fullScreen}>
                        <MultiGamesFrame
                            onClick={() => removeGameFromMultiple(index)}
                        >
                            <ControlIcon
                                width="18px"
                                height="18px"
                                src={closeIcon}
                                color={PALETTE.lightGray}
                            />
                        </MultiGamesFrame>
                        <MultiGamesSearchFrame
                            className={!isGameActive ? 'active' : null}
                            onClick={() => enableSearch(true)}
                        >
                            <ControlIcon
                                width="18px"
                                height="18px"
                                src={searchIcon}
                                color={PALETTE.lightGray}
                            />
                        </MultiGamesSearchFrame>
                    </MultiGamesControls>
                )}
            </MultipleGamesRatio>
        </Fragment>
    )
}

export default MultipleGames
