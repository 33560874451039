import React from 'react'
import styled, { css } from 'styled-components'

const DotsWrapper = styled.section`
    width: auto;
    height: auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
    pointer-events: all;
    ${(props) =>
        props.background &&
        css`
            background: #f1f1f1;
            display: inline-flex;
            padding: 3px 15px;
            border-radius: 2rem;
        `};
`

const Dot = styled.button`
    width: ${(props) =>
        props.width ? props.width : props.dimension ? props.dimension : '10px'};
    height: ${(props) => (props.dimension ? props.dimension : '10px')};
    border-radius: 5px;
    margin: 5px;
    padding: 0;
    background: ${(props) => (props.color ? props.color : 'lightgray')};
    opacity: 0.5;
    pointer-events: all;
    border: none;

    ${(props) =>
        props.hollow &&
        css`
            border: 1px solid
                ${(props) => (props.color ? props.color : 'white')};
            background: transparent;
        `};

    ${(props) =>
        props.active &&
        css`
            width: 40px;
            opacity: 1;
            border: 1px solid
                ${(props) =>
                    props.activeDotColor ? props.activeDotColor : 'red'};
            background: ${(props) =>
                props.activeDotColor ? props.activeDotColor : 'red'};
        `};
`

const getDots = ({
    dotsNumber = 3,
    activeDot,
    sequentially,
    color = 'lightgray',
    activeDotColor = 'red',
    onDotClick,
    dimension,
    width,
    hollow,
}) => {
    let dots = []

    for (let i = 1; i < dotsNumber + 1; i++) {
        if (activeDot === i || (sequentially && activeDot >= i)) {
            dots.push(
                <Dot
                    active
                    key={i}
                    activeDotColor={activeDotColor}
                    dimension={dimension}
                    width={width}
                />
            )
            continue
        }
        dots.push(
            <Dot
                key={i}
                color={color}
                onClick={(e) => {
                    onDotClick(i - 1)
                }}
                hollow={hollow}
                dimension={dimension}
                width={width}
            />
        )
    }

    return dots
}

export default (props) => (
    <DotsWrapper background={props.background}>{getDots(props)}</DotsWrapper>
)
