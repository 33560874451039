import React from 'react'
import styled from 'styled-components'
import CtaButton from 'ui/CtaButton'
import Headline from 'ui/Headline'
import Box from 'ui/Box'
import CardRegular from 'ui/Cards/CardRegular'
import betPlayers from 'images/banners/betPlayers.png'
import derbyHorses from 'images/banners/derbyHorses.png'
import betImg from 'images/icons/basic-icons/betLogo.svg'
import derbyImg from 'images/icons/basic-icons/derbyLogo.svg'
import Icon from 'ui/Icon'

const CardText = styled.p`
    line-height: 1.3;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
`

const CardLogo = styled(Icon)`
    position: absolute;
    top: 10px;
    left: 10px;
`

const ProductTransition = ({ theme }) => {
    const getDomain = (hostname) => {
        const domain = hostname.split('.')[0]
        return domain === 'localhost' ? 'dev' : domain
    }
    return (
        <>
            <Headline as={'h4'} mb={0} mt={3}>
                Én konto - tre spillemuligheder!
            </Headline>

            <Box my={3}>
                <CardRegular
                    theme={theme}
                    px={3}
                    src={betPlayers}
                    title={'Sportsbetting'}
                    description={
                        <CardText>
                            Champions League, Superliga eller faste odds på
                            hestevæddeløb.
                        </CardText>
                    }
                    background={theme.betMainColor}
                >
                    <Box my={2}>
                        <CtaButton href={`/sport`}>
                            GÅ TIL BET25 SPORT
                        </CtaButton>
                    </Box>
                </CardRegular>
            </Box>
            <Box>
                <CardRegular
                    theme={theme}
                    px={3}
                    src={derbyHorses}
                    title={'Hestevæddeløb'}
                    description={
                        <CardText>
                            Spil på de store millionpuljer i vores nye
                            millionspil på Bet25 Heste.
                        </CardText>
                    }
                    background={theme.derbyMainColor}
                >
                    <Box my={2}>
                        <CtaButton href={`/heste`}>
                            GÅ TIL BET25 HESTE
                        </CtaButton>
                    </Box>
                </CardRegular>
            </Box>
        </>
    )
}

export default ProductTransition
